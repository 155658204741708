import React, {Component, Fragment} from 'react'

//import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import highchartsMore from "highcharts/modules/solid-gauge";

// Material UI
import Grid from '@material-ui/core/Grid'
import grey from '@material-ui/core/colors/grey';
import blue from '@material-ui/core/colors/blue';
import {Typography} from "@material-ui/core";

highchartsMore(Highcharts);


class Stats extends Component {
    render() {
        return (
            <Fragment>
                <Grid container justify="center">


                            <Grid container justify="center" alignItems="center" style={{height: '100%'}}>
                            <div style={{width: '95%', height: 40, margin: 15, background: 'linear-gradient(to right, #64b5f6 0%, #64b5f6 30%, #fff 30%, #fff 100%)', borderStyle: 'solid', borderColor: grey[300], borderWidth: 5, borderRadius: 5}}>
                                <Grid container justify="flex-end" alignItems="center" style={{height: '100%'}}>
                                    <div style={{marginRight: 5}}>
                                    <Typography>30%</Typography>
                                    </div>
                                </Grid>
                                
                            </div>
                            </Grid>


                </Grid>
            </Fragment>
        )
    }
}

export default Stats