import React, {Component} from 'react'

import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import CustomOutlinedInput from "../Inputs/CustomOutlinedInput";

const styles = theme => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 400,
    },
});

class ReportBug extends Component {
    
    
    render() {
        const {classes, handleChange, fields} = this.props;
        
        return (
            <form>
                <div style={{width: 400}}>
                    <CustomOutlinedInput
                        // id="standard-name"
                        label="Subject"
                        name="subject"
                        value={fields.subject}
                        onChange={handleChange}
                        // margin="normal"
                    />
                    <br/>
                    <CustomOutlinedInput
                        // id="standard-name"
                        label="Message"
                        name="message"
                        // className={classes.textField}
                        multiline={true}
                        rows={7}
                        value={fields.message}
                        onChange={handleChange}
                        // margin="normal"
                    />
                </div>
                
            </form>
        )
    }
}

export default withStyles(styles)(ReportBug)