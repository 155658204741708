import React, { Component } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Material UI
import withStyles from '@material-ui/core/styles/withStyles'

// Services
import { resetPassword } from "../services/user.service";

// Carousel
import ResetPasswordContent from '../ResetPassword/index'
import validate from "../js/validate";
import CustomSnackbar from "../components/Snackbar/CustomSnackbar";

import queryString from 'query-string'

const styles = theme => ({
    
});

let blankChangePasswordForm = {
    password: {
        label: "Password",
            value: null,
            showPassword: false,
            valid: false,
            touched: false,
            message: 'required',
            validationRules: {
            isRequired: true,
                isPassword: true
        }
    },
    confirmPassword: {
        label: "Confirm Password",
            value: null,
            showPassword: false,
            valid: false,
            touched: false,
            message: 'required',
            validationRules: {
            isRequired: true,
                isPassword: true
        }
    },

}

class ResetPassword extends Component {
    state = {
        changePasswordForm: {...blankChangePasswordForm},
        snackbar: {
          open: false,
          message: null,
          variant: null,  
        },
        error: null,
        passwordChanged: false,
    }

    handleShowPassword = (name) => {
        if (name) {
            let changePasswordForm = {...this.state.changePasswordForm};
            changePasswordForm[name].showPassword = !changePasswordForm[name].showPassword;
            this.setState({changePasswordForm})
        }
    }

    openSnackbar = (value) => {
        let snackbar = { ...this.state.snackbar }
        snackbar.open = value;
        this.setState({ snackbar })
    }

    handleChange = event => {
        // debugger;
        let value = event.target.value;
        let name = event.target.name;
        let updatedControls = {...this.state.changePasswordForm};

        const updatedFormElement = {...updatedControls[name]};

        updatedFormElement.value = value;
        updatedFormElement.touched = true;
        updatedFormElement.valid = validate(value, updatedFormElement.validationRules);

        updatedControls[name] = updatedFormElement;


        // Check if form is valid
        let formIsValid = true;
        for (let inputIdentifier in updatedControls) {
            formIsValid = updatedControls[inputIdentifier].valid && formIsValid;
        }
        this.setState({
            changePasswordForm: updatedControls,
            changePasswordFormIsValid: formIsValid
        })

    }

    setError = (value) => {
        let error = {...this.state.error}
        error = value;
        this.setState({error})
    }

    onSubmit = (e) => {
        e.preventDefault();
        
        const { password, confirmPassword} = this.state.changePasswordForm;
        let match = (password.value === confirmPassword.value);
        if (!match) {
            let snackbar = {...this.state.snackbar};
            snackbar.open = true;
            snackbar.message = "Passwords did not match. Try again.";
            snackbar.variant = "error";
            let changePasswordForm = {...blankChangePasswordForm};
            this.setState({snackbar});
            this.setState({submitError: {value: true, message: 'Passwords did not match'}})
        } else {
            this.setState({submitError: {value: false, message: ''}});
            
            let search = window.location.search;
            const parsed = queryString.parse(search);
            let token = parsed.token;
            resetPassword(token, password.value).then(response => {
                let snackbar = {...this.state.snackbar};

                if (!response.isSuccess) {
                    snackbar.open = true;
                    snackbar.message = "Something went wrong";
                    snackbar.variant = "error";
                    this.setState({snackbar});

                } else {
                    this.setState({passwordChanged: true})
                }
            })
            //TODO: Submit new password
            
        }

    };
    
    render() {
        return (
            <div>
                <ResetPasswordContent
                    onSubmit={this.onSubmit}
                    form={this.state.changePasswordForm}
                    handleChange={this.handleChange}
                    passwordChanged={this.state.passwordChanged}
                    handleShowPassword={this.handleShowPassword}

                />
                <CustomSnackbar
                    snackbar={this.state.snackbar}
                    openSnackbar={this.openSnackbar}
                    
                />
            </div>
            
        );
    }
    
}

export default withStyles(styles)(ResetPassword);