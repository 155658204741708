/* eslint-disable */
import React, { Component, Fragment } from "react";

/// Material UI
import { withStyles } from '@material-ui/styles';
import { green } from '@material-ui/core/colors';

// Redux
import { getProjectsFacilities, selectProject } from '../../redux/actions/project-actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// components
import ProjectHome from '../components/ProjectHomeComponent'

// Services
import { getFacilitiesByProjectId, facilityModel, createOrUpdateFacility, deleteFacility } from "../../services/facility.service";
import { getProjectById } from "../../services/project.service"
import { getErrorMessage } from "../../services/serviceHelper";
import {intervalDataRequestObject, getIntervalDataTimestampByFacilityId} from "../../services/intervalData.service";

// Validate
import validate from '../../js/validate'
import EditProject from "../../Dashboard/components/EditProject";
// import Highcharts from "highcharts";
import moment from "moment";

import TimeSeriesData from "../../js/CustomDataClasses/TimeSeriesData";


import HighchartsReact from "highcharts-react-official";
import Highcharts from 'highcharts/highstock';

import highchartsExporting from "highcharts/modules/exporting";
import LoadingWeather from "../../components/Spinners/loading-weather";
import UploadingDataDialog from "../../ImportData/components/spinner/UploadingDataDialog";

highchartsExporting(Highcharts);

const styles = theme => ({
    paper: {
        margin: 20,
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        color: theme.palette.common.white,
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[600],
        }
    },
});

function getZoomableChart(seriesObject) {
    let axisTitle;
    let tooltipSuffix;
    axisTitle = 'Demand (kW)';
    tooltipSuffix = 'kW';
    // switch (chartDataFilterForm.dataType) {
    //     case 'kw':
    //         axisTitle = 'Demand (kW)';
    //         tooltipSuffix = 'kW';
    //         break;
    //     case 'kwh':
    //         axisTitle = 'Usage (kWh)';
    //         tooltipSuffix = 'kWh';
    //         break;
    // }
    
    let series = [];
    for (let key in seriesObject) {
        series.push({
            name: key,
            data: seriesObject[key]
        })
    }

    return {
        chart: {
            zoomType: 'x',
            height: 500,
            style: {
                fontFamily: "Roboto"
            },
        },
        time: {
            useUTC: true
        },
        title: {
            text: ''
        },
        subtitle: {
            text: document.ontouchstart === undefined ?
                '' : ''
        },
        xAxis: {
            type: 'datetime'
        },
        yAxis: {
            title: {
                text: axisTitle
            },
            opposite: true
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            area: {
                fillColor: {
                    linearGradient: {
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 1
                    },
                    stops: [
                        [0, Highcharts.getOptions().colors[0]],
                        [1, Highcharts.Color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
                    ]
                },

                marker: {
                    radius: 2
                },
                lineWidth: 1,
                states: {
                    hover: {
                        lineWidth: 1
                    }
                },
                // threshold: null
            }
        },
        tooltip: {
            // formatter: function () {
            //     //debugger;
            //     let timeRange = this.points[0].series.closestPointRange;
            //     let startDateTime = moment.utc(this.x).subtract(timeRange, 'milliseconds')
            //     //.format("ddd M/D/YY HH:mm");
            //     let endDateTime = moment.utc(this.x)
            //     let diffDays = endDateTime.date() !== startDateTime.date();
            //     let timeString;
            //     if (diffDays === true) {
            //         timeString = '<div font-size: 18px"><center>' + startDateTime.format("ddd M/D/YY HH:mm") + '<br/> to ' + endDateTime.format("ddd M/D/YY HH:mm") + '</center></div>'
            //     } else {
            //         timeString = '<div font-size: 18px"><center>' + endDateTime.format("ddd M/D/YY") + ' <br /> ' + startDateTime.format("HH:mm") + ' to ' + endDateTime.format("HH:mm") + '</center></div>'
            //     }
            //     let color;
            //     if (this.y >= 0) {
            //         color = this.points[0].series.color;
            //     } else {
            //         color = 'rgba(76, 175, 80, 1)'
            //     }
            //
            //     let line = '<svg height="20" width="30"><line x1="0" y1="13" x2="30" y2="13" style="stroke:' + color +';stroke-width:2" /></svg> '
            //     //return '<div font-size: 15px"><center>' + startDateTime + '<br/> to ' + endDateTime + '</center></div><br/><div style="color: ' + color + '; font-weight: 800; font-size: 20px" >' + line + this.y.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' kW ' + line + '</div>';
            //     return timeString + '<div style="color: ' + color + '; font-weight: 800; font-size: 20px" >' + line + this.y.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' ' + tooltipSuffix + ' ' + line + '</div>';
            // },
            useHTML: true,
            backgroundColor: '#fafafa'
        },
        series: series,

        credits: {
            enabled: false
        }
    }
}


class ProjectHomeContainer extends Component {

    state = {
        loading: false,
        project: null,
        finishedRetrievingMeters: false,
        createFacilityFormIsValid: false,
        attemptedCreateFacilitySubmit: false,
        createFacilityDialogOpen: false,
     
        createFacilityFormControls: {
            name: {
                label: 'Name',
                name: 'name',
                value: null,
                valid: false,
                touched: false,
                message: 'required',
                validationRules: {
                    isRequired: true
                }
            },
            description: {
                label: 'Description (Optional)',
                name: 'description',
                value: null,
                valid: true,
                touched: false,
                message: 'required',
                validationRules: {

                }
            },
            meterNumber: {
                label: 'Meter Number',
                name: 'meterNumber',
                value: null,
                valid: true,
                touched: false,
                validationRules: {

                }
            },
            accountNumber: {
                label: 'Account Number',
                name: 'accountNumber',
                value: null,
                valid: true,
                touched: false,
                validationRules: {

                }
            },
            utility: {
                label: 'Utility',
                name: 'utility',
                value: null,
                valid: true,
                touched: false,
                validationRules: {

                }
            },
            address1: {
                label: 'Address 1',
                name: 'address1',
                value: null,
                valid: true,
                touched: false,
                validationRules: {

                }
            },
            address2: {
                label: 'Address 2',
                name: 'address2',
                value: null,
                valid: true,
                touched: false,
                validationRules: {

                }
            },
            city: {
                label: 'City',
                name: 'city',
                value: null,
                valid: true,
                touched: false,
                validationRules: {
                    isRequired: false
                }
            },
            state: {
                label: 'State',
                name: 'state',
                value: null,
                valid: true,
                touched: false,
                validationRules: {

                }
            },
            postalCode: {
                label: 'Zip Code',
                name: 'postalCode',
                value: null,
                valid: false,
                touched: false,
                validationRules: {
                    isRequired: true,
                    //isZipcode: true
                }
            },
            
        },

        importDataDialogOpen: false,
        importDataFacility: null,

        // editFacilityFormIsValid: true,
        // attemptedEditFacilitySubmit: false,
        // editFacilityDialogOpen: false,
        // selectedFacilityForEdit: null,
        // fetchingNewWeatherData: false,
        // editFacilityFormControls: {
        //     name: {
        //         label: 'Name',
        //         value: null,
        //         valid: true,
        //         touched: false,
        //         message: 'required',
        //         validationRules: {
        //             isRequired: true
        //         }
        //     },
        //     meterNumber: {
        //         label: 'Meter Number',
        //         value: null,
        //         valid: true,
        //         touched: false,
        //         validationRules: {
        //
        //         }
        //     },
        //     accountNumber: {
        //         label: 'Account Number',
        //         value: null,
        //         valid: true,
        //         touched: false,
        //         validationRules: {
        //
        //         }
        //     },
        //     utility: {
        //         label: 'Utility',
        //         value: null,
        //         valid: true,
        //         touched: false,
        //         validationRules: {
        //
        //         }
        //     },
        //     address1: {
        //         label: 'Address 1',
        //         value: null,
        //         valid: true,
        //         touched: false,
        //         validationRules: {
        //
        //         }
        //     },
        //     address2: {
        //         label: 'Address 2',
        //         value: null,
        //         valid: true,
        //         touched: false,
        //         validationRules: {
        //
        //         }
        //     },
        //     city: {
        //         label: 'City',
        //         value: null,
        //         valid: true,
        //         touched: false,
        //         validationRules: {
        //             // isRequired: false
        //         }
        //     },
        //     state: {
        //         label: 'State',
        //         value: null,
        //         valid: true,
        //         touched: false,
        //         validationRules: {
        //
        //         }
        //     },
        //     postalCode: {
        //         label: 'Zip Code',
        //         value: null,
        //         valid: true,
        //         touched: false,
        //         validationRules: {
        //             isRequired: true,
        //             isZipcode: true
        //         }
        //     },
        //
        // },

        // Delete Facility State
        selectedFacilityForDelete: null,
        deleteFacilityDialogOpen: false,

        // Edit Project State
        // editProjectDialogOpen: false,

        snackbar: {
            open: false,
            message: '',
            varient: ''
        },

        // aggregated zoomable chart
        allDataReceived: false,
        allData: {},
        chartOptions: null,
    }
    
    componentDidMount() {
        const {  facilityId, projectId } = this.props.match.params;
        const {selectProject, getProjectsFacilities} = this.props;
        getProjectById(projectId).then(project => {
            selectProject(project);
            this.setState({project});
            getProjectsFacilities();
        });


    };

    toggleEditProjectDialog = (project = null) => {
        let editProjectDialogOpen = !this.state.editProjectDialogOpen;
        this.setState({ editProjectDialogOpen, selectedProjectForEdit: project })
    }

    openSnackbar = (value) => {
        let snackbar = { ...this.state.snackbar }
        snackbar.open = value;
        this.setState({ snackbar })
    }

    toggleImportDataDialog = (facilityObject = null) => {
        if (facilityObject !== null) {
            this.setState({ importDataFacility: facilityObject})
        }
        let importDataDialogOpen = !this.state.importDataDialogOpen;
        this.setState({ importDataDialogOpen })
    }

    toggleCreateFacilityDialog = () => {
        let createFacilityDialogOpen = !this.state.createFacilityDialogOpen;
        this.setState({ createFacilityDialogOpen })
    }

    toggleDeleteFacilityDialog = (facility = null) => {
        let deleteFacilityDialogOpen = !this.state.deleteFacilityDialogOpen;
        this.setState({ deleteFacilityDialogOpen, selectedFacilityForDelete: facility })
    }

    onCreateFacilitySubmit = () => {
        this.setState({loading: true});
        let submittedForm = { ...this.state.createFacilityFormControls };
        let keys = Object.keys(submittedForm);
        let finalSubmission = {};
        keys.forEach(key => { finalSubmission[key] = submittedForm[key].value });

        if (this.state.createFacilityFormIsValid) {
            let facilityRequest = {...facilityModel};

            facilityRequest.ProjectId = this.props.match.params.projectId;
            facilityRequest.Name = finalSubmission.name;
            facilityRequest.Description = finalSubmission.description;
            facilityRequest.AccountNumber = finalSubmission.accountNumber;
            facilityRequest.MeterNumber = finalSubmission.meterNumber;
            facilityRequest.Utility = finalSubmission.utility;
            facilityRequest.Address1 = finalSubmission.address1;
            facilityRequest.Address2 = finalSubmission.address2;
            facilityRequest.City = finalSubmission.city;
            facilityRequest.State = finalSubmission.state;
            facilityRequest.PostalCode = finalSubmission.postalCode;

            createOrUpdateFacility(facilityRequest).then((response)=>{
                if (response.isSuccess){
                    this.toggleCreateFacilityDialog();
                    this.setState({ attemptedCreateFacilitySubmit: false })

                    // set snackbar
                    let snackbar = { ...this.state.snackbar };
                    snackbar.message = response.successMessage ? response.successMessage : "Successfully Created Facility";
                    snackbar.open = true;
                    snackbar.variant = "success";
                    this.setState({ snackbar });

                    this.updateTable();
                }else{

                    //default error message
                    let errorMessage = getErrorMessage(response);
                    
                    // set snackbar
                    let snackbar = { ...this.state.snackbar };
                    snackbar.message = errorMessage;
                    snackbar.open = true;
                    snackbar.variant = "error";
                    this.setState({ snackbar });
                }
            });
            
        } else {
            this.setState({ attemptedCreateFacilitySubmit: true })
        }
        this.setState({loading: false})
    }

    onDeleteFacilitySubmit = () => {
        console.log(this.state.selectedFacilityForDelete);

        
        this.toggleDeleteFacilityDialog();
        // TODO Delete Project. projectId = this.state.selectedProjectForDelete.projectId

        deleteFacility(this.state.selectedFacilityForDelete.facilityId).then((response)=>{
            if (response.isSuccess){
                //this.toggleDeleteProjectDialog();

                // set snackbar
                let snackbar = { ...this.state.snackbar };
                snackbar.message = response.successMessage ? response.successMessage : "Successfully Deleted Facility";
                snackbar.open = true;
                snackbar.variant = "success";
                this.setState({ snackbar });

                this.updateTable();
            }else{

                //default error message
                let errorMessage = getErrorMessage(response);
                
                // set snackbar
                let snackbar = { ...this.state.snackbar };
                snackbar.message = errorMessage;
                snackbar.open = true;
                snackbar.variant = "error";
                this.setState({ snackbar });
            }
        });
    }

    handleCreateFacilityInputChange = (event) => {
        
        const value = event.target.value;
        const name = event.target.name;
        debugger;
        const updatedControls = { ...this.state.createFacilityFormControls };

        const updatedFormElement = { ...updatedControls[name] };

        updatedFormElement.value = value;
        updatedFormElement.touched = true;
        updatedFormElement.valid = validate(value, updatedFormElement.validationRules);

        updatedControls[name] = updatedFormElement;


        // Check if form is valid
        let formIsValid = true;
        for (let inputIdentifier in updatedControls) {
            formIsValid = updatedControls[inputIdentifier].valid && formIsValid;
        }
        this.setState({
            createFacilityFormControls: updatedControls,
            createFacilityFormIsValid: formIsValid
        })
    }

    
    getProject = () => {
        const {  projectId } = this.props.match.params;
        getProjectById(projectId).then(project => {
            this.setState({project});
        });
    }
    
    updateTable(){
        this.props.getProjectsFacilities()
            // .then(() => this.getZoomableForAllFacilities());
        
    }
    
    getZoomableForAllFacilities = () => {
        const {facilities} = this.props;
        let dataReceivedObject = {};
        facilities.forEach(facility => {
            
            let request = intervalDataRequestObject;
            request.ProjectId = facility.projectId;
            request.FacilityId = facility.facilityId;
            request.DataType = "kw";
            request.StartDate = facility.intervalDataStartDate;
            request.EndDate = facility.intervalDataEndDate;
            request.IncludePercent = false;

            dataReceivedObject[facility.facilityId] = false;
            getIntervalDataTimestampByFacilityId(request).then(data => {
                const timeSeriesData = new TimeSeriesData(data);
                let array = timeSeriesData.formatDataForZoomableChart("kw");
                let allData = {...this.state.allData};
                allData[facility.name] = array;
                this.setState({allData})
                dataReceivedObject[facility.facilityId] = true;
                this.allDataForZoomableReceived(dataReceivedObject)
            })
        });

    };
    
    allDataForZoomableReceived = (dataReceivedObject) => {
        let allDataReceived = true;
        for (let key in dataReceivedObject) {
            if (dataReceivedObject[key] === false) {
                allDataReceived = false;
                break
            }
        }
        
        this.setState({allDataReceived})
    };
    
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.allDataReceived !== this.state.allDataReceived) {
            let temp = {...this.state.allData};
            let chartOptions = getZoomableChart({...this.state.allData});
            this.setState({chartOptions});
        }
    }

    render() {
        const { facilities, classes } = this.props;
        return (
            <div>
                <ProjectHome
                    facilities={facilities}
                    project={this.state.project}
                    loading={this.state.loading}
                    fetchingFacilities={this.props.fetchingFacilities}
                    
                    // Edit Project
                    toggleEditProjectDialog={this.toggleEditProjectDialog}

                    // Import Data
                    importDataDialogOpen={this.state.importDataDialogOpen}
                    toggleImportDataDialog={this.toggleImportDataDialog}
                    importDataFacility={this.state.importDataFacility}

                    // Create Facility
                    createFacilityFormIsValid={this.state.createFacilityFormIsValid}
                    createFacilityFormControls={this.state.createFacilityFormControls}
                    onCreateFacilitySubmit={this.onCreateFacilitySubmit}
                    createFacilityDialogOpen={this.state.createFacilityDialogOpen}
                    toggleCreateFacilityDialog={this.toggleCreateFacilityDialog}
                    attemptedCreateFacilitySubmit={this.state.attemptedCreateFacilitySubmit}
                    handleCreateFacilityInputChange={this.handleCreateFacilityInputChange}

                    // Delete Facility
                    selectedFacilityForDelete={this.state.selectedFacilityForDelete}
                    deleteFacilityDialogOpen={this.state.deleteFacilityDialogOpen}
                    onDeleteFacilitySubmit={this.onDeleteFacilitySubmit}
                    toggleDeleteFacilityDialog={this.toggleDeleteFacilityDialog}

                    // Snackbar
                    snackbar={this.state.snackbar}
                    openSnackbar={this.openSnackbar}
                />

                <EditProject
                    toggleEditProjectDialog={this.toggleEditProjectDialog}
                    editProjectDialogOpen={this.state.editProjectDialogOpen}
                    selectedProjectForEdit={this.state.project}
                    onSubmit={this.getProject}
                />
                {/*<LoadingWeather open={this.state.fetchingNewWeatherData}/>*/}
                <UploadingDataDialog open={this.props.fetchingNewWeatherData} message={"Fetching new weather data..."}/>

            </div>
        )




    }
}

function mapStateToProps(state) {
    return {
        facilities: state.facilities,
        selectedProject: state.selectedProject,
        fetchingNewWeatherData: state.fetchingNewWeatherData,
        fetchingFacilities: state.fetchingFacilities
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getProjectsFacilities, 
        selectProject 
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ProjectHomeContainer));

