import config from "../config";
import { authHeader } from "../helpers/auth-header";
import { REQUESTTYPES, fetchHelper, responseStatusHandler } from "./serviceHelper";

const ENDPOINTS = {
    intervalData: {
        getIntervalDataByFacilityId: "GetIntervalDataByFacilityId",
        importIntervalData: "ImportIntervalData",
        importIntervalDataJson: "ImportIntervalDataJson",
        getIntervalDataTimestampByFacilityId: "GetIntervalDataTimestampByFacilityId",
        getIntervalDataHeatmapByFacilityId: "GetIntervalDataHeatmapByFacilityId",
        getIntervalDataCalendarChartByFacilityId: "GetIntervalDataCalendarChartByFacilityId",
        getIntervalDataLoadCurveByFacilityId: "GetIntervalDataLoadCurveByFacilityId",
        getIntervalDataSummaryChartByFacilityId: "GetIntervalDataSummaryChartByFacilityId",
        getDegreeDays: "GetDegreeDays",
        getFacilityWeather: "GetFacilityWeather"
    }
};

const intervalDataRequestObject = {
    ProjectId: null,
    FacilityId: null,
    Interval: null, //don't worry about
    IncludePercent: false, //boolean
    ChartType: null, //don't worry about
    DataType: null, //string - "kw", "kwh"
    SeriesType: null, //string - "Average", "Max", "Min"
    SeriesPer: null, //string - "Year","Month",etc
    Months: null, //array - example [1,2,3,4,11]
    Days: null, //array - example [1,2,3,7]
    Years: null, //array - example [2017,2018]
    StartDate: null,
    EndDate: null,
    StartTime: null,
    EndTime: null,
    IntervalDuration: null
};

const importIntervalDataJsonRequestObject = {
    ProjectId: null,
    FacilityId: null,
    IntervalDuration: null,
    IntervalDataType: "",
    Data: []
}

const getDegreeDaysRequestObject = {
    FacilityId: null,
    StartDate: null,
    EndDate: null,
    HeatingDegreeBase: null,
    CoolingDegreeBase: null
}

const getFacilityWeatherRequestObject = {
    FacilityId: null,
    StartDate: null,
    EndDate: null
}

// Get api base URLs
const INTERVALDATA = config.intervalData;

const getDegreeDays = (getDegreeDaysRequestObject) => {
    const response = fetchHelper(
        INTERVALDATA,
        ENDPOINTS.intervalData.getDegreeDays,
        getDegreeDaysRequestObject,
        "post"
    ).then(response => {
        // login successful if there's a user in the response
        if (!response.isSuccess) {
            
            //todo: do something on error
        } else {
            return response;
        }
    });

    return response;
};

const getFacilityWeather = (getFacilityWeatherRequestObject) => {
    const response = fetchHelper(
        INTERVALDATA,
        ENDPOINTS.intervalData.getFacilityWeather,
        getFacilityWeatherRequestObject,
        "post"
    ).then(response => {
        // login successful if there's a user in the response

        if (!response.isSuccess) {
            //todo: do something on error
        } else {
            return response.weather;
        }
    });

    return response;
};

const getIntervalDataByFacilityId = (intervalDataRequestObject) => {
    const response = fetchHelper(
        INTERVALDATA,
        ENDPOINTS.intervalData.getIntervalDataByFacilityId,
        intervalDataRequestObject,
        "post"
    ).then(response => {
        // login successful if there's a user in the response

        if (!response.isSuccess) {
            //todo: do something on error
        } else {
            return response.intervalData;
        }
    });

    return response;
};

const getIntervalDataCalendarChartByFacilityId = (intervalDataRequestObject) => {
    
    const response = fetchHelper(
        INTERVALDATA,
        ENDPOINTS.intervalData.getIntervalDataCalendarChartByFacilityId,
        intervalDataRequestObject,
        "post"
    ).then(response => {
        // login successful if there's a user in the response
        if (!response.isSuccess) {
            //todo: do something on error
        } else {
            return response.monthData;
        }
    });

    return response;
};

const getIntervalDataLoadCurveByFacilityId = (intervalDataRequestObject) => {

    const response = fetchHelper(
        INTERVALDATA,
        ENDPOINTS.intervalData.getIntervalDataLoadCurveByFacilityId,
        intervalDataRequestObject,
        "post"
    ).then(response => {
        // login successful if there's a user in the response
        if (!response.isSuccess) {
            //todo: do something on error
        } else {
            return response.monthData;
        }
    });

    return response;
};

const getIntervalDataSummaryChartByFacilityId = (intervalDataRequestObject) => {

    const response = fetchHelper(
        INTERVALDATA,
        ENDPOINTS.intervalData.getIntervalDataSummaryChartByFacilityId,
        intervalDataRequestObject,
        "post"
    ).then(response => {
        // login successful if there's a user in the response
        if (!response.isSuccess) {
            //todo: do something on error
        } else {
            return response.seriesData;
        }
    });
    return response;
};

const getIntervalDataTimestampByFacilityId = (intervalDataRequestObject) => {
    
    if (!intervalDataRequestObject.IncludePercent)
        intervalDataRequestObject.IncludePercent = false;
    
    const response = fetchHelper(
        INTERVALDATA,
        ENDPOINTS.intervalData.getIntervalDataTimestampByFacilityId,
        intervalDataRequestObject,
        "post"
    ).then(response => {
        // login successful if there's a user in the response
        if (!response.isSuccess) {
            //todo: do something on error
        } else {
            return response.intervalData;
        }
    });

    return response;
};

const getIntervalDataHeatmapByFacilityId = (intervalDataRequestObject) => {
    const response = fetchHelper(
        INTERVALDATA,
        ENDPOINTS.intervalData.getIntervalDataHeatmapByFacilityId,
        intervalDataRequestObject,
        "post"
    ).then(response => {
        // login successful if there's a user in the response

        if (!response.isSuccess) {
            //todo: do something on error
        } else {
            return response.intervalData;
        }
    });

    return response;
};

const importIntervalDataJson = (importIntervalDataJsonRequestObject) => {
    const response = fetchHelper(
        INTERVALDATA,
        ENDPOINTS.intervalData.importIntervalDataJson,
        importIntervalDataJsonRequestObject,
        "post"
    ).then(response => {
        // login successful if there's a user in the response

        if (!response.isSuccess) {
            //todo: do something on error
            return response
        } else {
            return response;
        }
    });

    return response;
};

const importIntervalData = (inputEvent, projectId, facilityId, intervalDuration) => {

    //const files = Array.from(inputEvent.target.files);
    //this.setState({ uploading: true })

    const formData = new FormData(inputEvent.target);

    //formData.append('file', files[0]);

    // files.forEach((file, i) => {
    //     formData.append('file', file)
    // });
    
    formData.append("projectId", projectId);
    formData.append("facilityId", facilityId);
    formData.append("intervalDuration", intervalDuration)
    
    const response = fetchHelper(
        INTERVALDATA,
        ENDPOINTS.intervalData.importIntervalData,
        formData,
        "postMultiPart"
    ).then(response => {
        // login successful if there's a user in the response
        if (!response.isSuccess) {
            //todo: do something on error
        } else {
            return response;
        }
    });

    return response;
};


export {
    getIntervalDataByFacilityId,
    importIntervalData,
    importIntervalDataJson,
    getIntervalDataTimestampByFacilityId,
    getIntervalDataHeatmapByFacilityId,
    getIntervalDataCalendarChartByFacilityId,
    getIntervalDataLoadCurveByFacilityId,
    getIntervalDataSummaryChartByFacilityId,
    intervalDataRequestObject,
    importIntervalDataJsonRequestObject,
    getDegreeDays,
    getDegreeDaysRequestObject,
    getFacilityWeather,
    getFacilityWeatherRequestObject
};
