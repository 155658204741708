import React, {Component, Fragment} from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import DataViewLoadingSpinnger from "../../FacilityHome/components/spinners/DataViewLoadingSpinner";
import grey from "@material-ui/core/colors/grey";
import {withStyles} from '@material-ui/core/styles';
import PropTypes from "prop-types";

let borderColor = grey[300];

const styles = theme => ({
    content: {
        flexGrow: 1,
        border: 'solid',
        borderColor: borderColor,
        borderWidth: 1,
        borderTop: 'none',
        borderRadius: 5
    },
    subtitle: {
        flexGrow: 1
    },
    title: {
        backgroundColor: grey[300],
        padding: 5,
        paddingLeft: 20,
        paddingRight: 20,
        align: 'center',
        border: 'solid',
        //backgroundColor: grey[200],
        borderColor: borderColor,
        borderRadius: '3px 0 0 0',
        borderWidth: 1,
        borderTop: 'none',
        borderBottom: 'none',
        borderLeft: 'none',
    },
    chartTitleBar: {
        border: 'solid',
        borderColor: borderColor,
        borderWidth: 1,
        borderTop: 'none',
        borderLeft: 'none',
        borderRight: 'none',
    },
    chartSection: {
        paddingBottom: 5,
        // border: 'solid',
        // borderColor: borderColor,
        // borderWidth: 1,
        // borderTop: 'none',
        // borderRadius: 10,
        paddingTop: 10,
    },
    chartTypeTab: {
        border: 'solid',
        borderColor: borderColor,
        borderWidth: 1,
        borderTop: 'none',
        borderBottom: 'none',
        borderRight: 'none',
        paddingLeft: 10,
        paddingRight: 10,
        height: '100%',
        color: grey[700],
        backgroundColor: grey[200],
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: grey[300],
        }
    },
    toggleContainer: {
        //margin: theme.spacing(2, 0),
    },
    heatmapLegend: {
        backgroundImage: 'linear-gradient(to right, red , yellow, green, blue)',
    },
    button: {
        marginBottom: theme.spacing(1)
    },
    button2: {
        marginBottom: theme.spacing(1),
        backgroundColor: grey[300],
    },
    heatMapGradient: {
        backgroundImage: 'linear-gradient(to right, #003d99 0%, #66a3ff 20%, #63BE7B 40%, #fae052 60%, #f40b0f 80%, #b30000 100%)',
        height: 20,
        width: 300
    }
});

class ChartWrapper extends Component {
    render() {

        const {
            classes,
            message,
            title,
            chartArea,
            titleBarRight
        } = this.props;

        return (
            <Paper style={{width: '100%'}}>
                <Grid container className={classes.chartTitleBar}>
                    <Grid item className={classes.title} spacing={1}>
                        <Typography variant="h6" color="inherit">
                            {title}
                        </Typography>

                    </Grid>

                    <Grid item style={{flexGrow: 1}}>
                        <Grid container alignItems="center" spacing={0}
                              style={{height: '100%', paddingLeft: 5, paddingRight: 5}}>
                            <Grid item className={classes.subtitle}>
                                <Typography color="inherit">
                                    {message}

                                </Typography>


                            </Grid>
                            {titleBarRight}

                        </Grid>
                    </Grid>

                </Grid>

                <Grid container className={classes.chartSection}>
                    <Grid item xs>
                        {chartArea}
                    </Grid>
                </Grid>
            </Paper>
        )
    }
}

ChartWrapper.propTypes = {
    classes: PropTypes.object.isRequired,
    // message: PropTypes.string,
    // title: PropTypes.string.isRequired,
    // chartArea: PropTypes.element
};

export default withStyles(styles)(ChartWrapper)