import {scatterPlotOptions, demandDurationOptions, zoomableOptions, cbecsZoomableOptions} from "../generateDefaultChartOptions";
import createHandsontableSettingsFromData from "../createHandsontableSettingsFromData";

const _formatDataForScatterOrZoomable = new WeakMap();

class TimeSeriesData {
    constructor(data, timezone) {
        this.data = data;
        this.timezone = timezone;
        
        _formatDataForScatterOrZoomable.set(this, (dataType) => {
            let array = [];

            if (dataType === 'kwh') {
                this.data.forEach(interval => {
                    array.push([interval.dateTimestamp, interval.kWh])
                });
            } else if (dataType === 'kw') {
                this.data.forEach(interval => {
                    array.push([interval.dateTimestamp, interval.kW])
                });
            } else if (dataType === 'temp') {
                this.data.forEach(interval => {
                    array.push([interval.dateTimestamp, interval.temperature])
                });
            }

            return array;
        })
    }

    formatDataForZoomableChart = (dataType = "kw") => {
        return _formatDataForScatterOrZoomable.get(this)(dataType)
    };

    formatDataForScatterPlot = (dataType = "kw") => {
        return _formatDataForScatterOrZoomable.get(this)(dataType)
    };
    
    formatDataForDemandDurationChart = () => {
        let data = [];
        let dateTimeArray = [];
        this.data.forEach(interval => {
            data.push([interval.fakeTimestamp, interval.kW]);
            dateTimeArray.push({ intervalDateTime: interval.dateTimestamp })
        });
        return {data, dateTimeArray}
    };
    
    generateScatterPlotChartOptions = (dataType = "kw", view = "week", timezone, intervalDuration) => {
        let array = this.formatDataForScatterPlot(dataType);
        return scatterPlotOptions(array, dataType, view, timezone, intervalDuration)
    }

    generateTimeSeriesHandsontableSettings = () => {
        return createHandsontableSettingsFromData(this.data, 'scatter-plot', 60, null, this.timezone)
    }
    
    generateDemandDurationChartOptions = () => {
        const {data, dateTimeArray} = this.formatDataForDemandDurationChart();
        return demandDurationOptions(data, dateTimeArray)
    }
    
    generateDemandDurationHandsontableSettings = () => {
        return createHandsontableSettingsFromData(this.data, 'demand-duration', 60, null, this.timezone)
    }
    
    generateZoomableChartOptions = (dataType = "kw", timezone, temperatureView = false, plotLines = null, plotBands = null) => {
        let data = this.formatDataForZoomableChart(dataType);
        let temperatureData = null;
        if (temperatureView) {
            temperatureData = this.formatDataForZoomableChart('temp'); 
        }
        // return zoomableOptions(data, dataType)
        return zoomableOptions(data, dataType, timezone, temperatureData, plotLines, plotBands)
    }
    
}

export default TimeSeriesData;