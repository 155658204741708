import React, {Component} from 'react'

//Material UI
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';

class PeriodLength extends Component {
    
    handleChange = (event) => {
        const {setPeriodLength} = this.props;
        setPeriodLength(event.target.value);
    };
    
    // componentDidUpdate(prevProps, prevState, snapshot) {
    //     const {periodLength} = this.props;
    //     debugger;
    // }

    render() {
        const {periodLength} = this.props;
        return (
            <div>
                {/*<FormLabel component="legend">Gender</FormLabel>*/}
                <RadioGroup aria-label="gender" name="gender1" value={periodLength} onChange={this.handleChange}>
                    <FormControlLabel value="month" control={<Radio />} label="Month" />
                    <FormControlLabel value="day" control={<Radio />} label="Day" />
                    <FormControlLabel value="hour" control={<Radio />} label="Hour" />
                    <FormControlLabel value="30min" control={<Radio />} label="30 min" />
                    <FormControlLabel value="15min" disabled control={<Radio />} label="15 min" />

                </RadioGroup>
            </div>
        )
    }
}

export default PeriodLength