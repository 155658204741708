import { TOGGLE_STRIPE_DIALOG, ORGANIZATION_MOUNTED, PAYMENT_METHODS_MOUNTED, ADD_ORGANIZATION_SUBSCRIPTION, CHANGE_THEME} from '../actions/admin-actions'

const toggleStripeCreditCardDialog = (state = false, action) => {
    switch (action.type) {
        case TOGGLE_STRIPE_DIALOG:
            return !action.payload;
    }
    return state;
};

const organization = (state = null, action) => {
    switch (action.type) {
        case ORGANIZATION_MOUNTED:
            return action.payload;
    }
    return state
}

const paymentMethods = (state = [], action) => {
    switch (action.type) {
        case PAYMENT_METHODS_MOUNTED:
            return action.payload;
    }
    return state
}

const organizationSubscription = (state = null, action) => {
    switch (action.type) {
        case ADD_ORGANIZATION_SUBSCRIPTION:
            return action.payload;
    }
    return state
}

const tempThemeColor = (state = null, action) => {
    switch (action.type) {
        case CHANGE_THEME:
            return action.payload;
    }
    return state
}


export { toggleStripeCreditCardDialog, organization, paymentMethods, organizationSubscription, tempThemeColor };