import {
    Elements,
} from "@stripe/react-stripe-js";
import React from "react";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {loadStripe} from "@stripe/stripe-js";
import {makeStyles} from '@material-ui/core/styles';
import CardForm from "./Components/CardForm";
import {getConfigurationSettings} from "../../../../../services/admin.service";
import moment from 'moment'


// let settings = getConfigurationSettings();
// debugger;

const useStyles = makeStyles(theme => ({
    center: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}));

let stripePromise;
getConfigurationSettings().then(x => {
    stripePromise = loadStripe(x.stripePublishKey);
});


export default function CheckoutForm(props) {

    const {toggleShowUpdateCreditCard} = props;
    let userInfo = JSON.parse(window.sessionStorage.user);
    const classes = useStyles();
    return (


        <Grid container>
            <Grid item xs={12} lg={6}
                  style={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10}}>
                <Elements stripe={stripePromise}>
                    <CardForm
                        onSubmit={props.onSubmit}
                        onClose={props.onClose}
                        showCancel={props.showCancel}
                    />
                </Elements>
            </Grid>
            <Grid item xs={12} lg={6}
                  style={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10}}>
                <Grid container className={classes.center}>
                    <Grid item xs={12} className={classes.center}>
                        <Typography align={'center'}>Updating your payment method <span
                            style={{fontWeight: 600}}>will not</span> result in any immediate charge to you
                            account.</Typography>
                    </Grid>
                    {!userInfo.subscription.cancelAtPeriodEnd ?
                        <Grid item xs={12} className={classes.center} style={{paddingTop: 10}}>
                            <Typography align={'center'}>Your next payment is scheduled
                                for {moment(userInfo.subscription.currentPeriodEndDate).format('MMMM D')} in the amount
                                of ${userInfo.subscription.plan.price}, and your current subscription is
                                billed <span
                                    style={{fontWeight: 600}}>{userInfo.subscription.plan.interval === 'month' ? 'monthly' : 'annually'}</span>.</Typography>
                        </Grid> : null}
                </Grid>

            </Grid>
        </Grid>

    );
};