import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux'

//Material UI
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

//Component
import UploadDataStepper from './UploadDataStepper'

const useStyles = makeStyles(theme => ({
    root: {

    },
    gridContainer: {
        alignItems: 'center',
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },


}));


const ImportDataForm = ({ activeStep, steps, handleReset, handleBack, handleNext, getStepContent, csvData, importMethod, csvHandsontableSettings }) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>

            <Grid container className={classes.gridContainer}>
                <Grid item xs={12}>
                    <UploadDataStepper activeStep={activeStep} steps={steps}  />
                </Grid>
                <Grid item xs={12}>
                    <div >

                        {activeStep === steps.length ? (
                            <div>
                                <Typography className={classes.instructions}>All steps completed</Typography>
                                <Button onClick={handleReset}>Reset</Button>
                            </div>
                        ) : (
                                <div >
                                    <div >
                                        {getStepContent(activeStep)}
                                    </div>
                                    <div style={{margin: 10}}>
                                        {
                                            activeStep === 0 ? null :
                                                <Fragment>
                                                    <Button
                                                        disabled={activeStep === 0}
                                                        onClick={handleBack}
                                                        className={classes.backButton}
                                                    >
                                                        Back
                                    </Button>

                                                    <Button 
                                                        variant="contained" 
                                                        color="primary" 
                                                        disabled={(activeStep === steps.length - 1 && (importMethod === 'csv' || importMethod === 'excel') && csvHandsontableSettings === null)} 
                                                        onClick={() => { handleNext() }}
                                                    >
                                                        {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
                                                    </Button>
                                                </Fragment>
                                        }

                                    </div>
                                </div>
                            )}
                    </div>
                </Grid>
            </Grid>

        </div>
    );
}


export default connect()(ImportDataForm)