import React, {Component, Fragment} from 'react';

import clsx from 'clsx';


import {withStyles} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import MeterDataFilterFormContainer from "./MeterDataFilterFormContainer";

import OverviewLeftSidebar from "../Overview/LeftSidebar";
import WeatherFilterForm from '../Weather/Sidebar/WeatherFilterForm/index'
import WeatherSidebar from "../Weather/Sidebar";
import CalendarChartSidebar from "../Charts/CalendarChart/CalendarChartSidebar";
import EmissionsSidebar from "../Emissions/components/Sidebar";
import RawWeatherDataSidebar from '../Weather/Sidebar/RawDataSidebar/index'

const drawerWidth = 300;

const styles = theme => ({
    root: {
        display: 'flex',
        backgroundColor: theme.palette.background.sidebar,
        flexGrow: 1
    },

    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    tabs: {
        width: drawerWidth
    },

    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        border: 'none',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: 0,
        [theme.breakpoints.up('sm')]: {
            width: 0,
        },
    },
    drawerPaper: {
        width: drawerWidth,
        border: 'none'
    },

    toolbar: theme.mixins.toolbar,
    // customTab: {
    //     backgroundColor: '#d7dadd',
    //     height: 50,
    //     borderStyle: 'solid',
    //     borderWidth: 1,
    //     borderColor: '#e6e6e6',
    //     color: blue[500],
    //     cursor: 'pointer',
    //     '&:hover': {
    //         backgroundColor: '#d0d3d6',
    //         textDecoration: 'underline'
    //     },
    //     '&:active': {
    //         borderBottom: 'none',
    //         backgroundColor: null
    //     }
    // },
    // customTabSelected: {
    //     //backgroundColor: '#d7dadd',
    //     height: 50,
    //     borderStyle: 'solid',
    //     borderWidth: 1,
    //     borderColor: '#e6e6e6',
    //     borderBottom: 'none',
    //     cursor: 'pointer',
    //     '&:hover': {
    //         backgroundColor: '#fcfcfc',
    //     }
    // },
    // formSubmitToolbar: {
    //     position: 'fixed',
    //     bottom: theme.spacing(0),
    //     backgroundColor: 'pink',
    // }
});

const chartTabs = [
    {
        label: 'Main',
        value: 'main',
    },
    {
        label: 'Filter',
        value: 'data',
    },
    // {
    //     label: 'Chart',
    //     value: 'chart',
    // }
];


class FacilitySidebar extends Component {

    state = {
        tab: 1,
        content: null,
    };

    setTab = (value) => {
        this.setState({tab: value})
    };
    
    setNavbarState = () => {
        const {
            selectFacilityView,
            selectedFacility,
            selectedFacilityView,
            selectedFacilitySubview,
            selectedChartView,
            projectId,
            facilityId,
            facilitySidebarOpen,
        } = this.props;
        let content;
        let visible = true;
        switch (selectedFacilityView) {
            case 'overview':
                content = <OverviewLeftSidebar/>;
                break;
            case 'charts':
                switch (selectedFacilitySubview) {
                    case 'load-curve':
                    case 'summary-chart':
                    case 'heat-map':
                    case 'zoomable-chart':
                    case 'scatter-plot':
                    case 'demand-duration':
                    
                    case 'surface-plot':
                        content = <MeterDataFilterFormContainer 
                            selectedFacility={selectedFacility}
                            selectedFacilitySubview={selectedFacilitySubview}
                        />;
                        break;
                    case 'calendar-chart':
                        content = <CalendarChartSidebar />;
                        break;
                    default:
                        content = null;
                        break;
                }
                break;
            case 'weather':
                switch (selectedFacilitySubview) {
                    case 'location':
                        content = null;
                        visible = false;
                        break;
                    case 'degree-days':
                        content = <WeatherFilterForm selectedFacilitySubview={selectedFacilitySubview}/>;
                        break;
                    case 'raw-data':
                        content = <RawWeatherDataSidebar selectedFacilitySubview={selectedFacilitySubview}/>;
                        break;
                }
                break;
            case 'emissions':
                content = <EmissionsSidebar />;
                break;
            case 'regression':
                content = 'REGRESSION';
                break;
            case 'notes':
                content = null;
                visible = false;
                break;
            case 'data':
                content = null;
                visible = false;
                break;
            default:
                content = null;
                break;
        };
        this.setState({content, visible})
    };
    
    componentDidMount() {
        this.setNavbarState();
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
        const {
            selectedFacilityView,
            selectedFacilitySubview
        } = this.props;
        
        if (selectedFacilityView !== prevProps.selectedFacilityView || selectedFacilitySubview !== prevProps.selectedFacilitySubview) {
            this.setNavbarState();
        }
    }


    render() {
        const {
            classes,
            facilitySidebarOpen,
        } = this.props;
        
        let width;
        if (this.state.visible) {
            width = 300
        } else {
            width = 0
        }
        if (this.props.selectedFacility) {
            return (
                <div style={{width: width, height: '100%'}}>
                    <Grid style={{height: '100%', width: width, borderRight: '1px solid #e8e8e8'}} className={classes.root}>
                        <div style={{width: '100%', position: 'sticky', top: 0, display: this.state.visible ? 'hidden' : null}}>
                            {this.state.content ? this.state.content : null}
                        </div>
                    </Grid>
                </div>


            );
        } else {
            return 'Loading'
        }
        
    }


};

export default withStyles(styles)(FacilitySidebar)
