import React, {Component} from 'react'

//Material UI
import Paper from '@material-ui/core/Paper';
import {Grid, Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import FacilitiesTable from "../../ProjectHome/containers/FacilitiesTable";

class CustomInfoLayout2 extends Component {

    render() {
        const {title, mainContent, topRightContent = null} = this.props;

        return (
            <Grid container style={{padding: 2}}>
                <Grid item xs={12}
                      style={{paddingRight: 10, paddingLeft: 10, paddingBottom: 5, height: '100%', border: '1px solid #eee'}}>

                    <Grid container style={{justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                        <Typography style={{flexGrow: 1}} variant={"h5"}>
                            {title}
                        </Typography>
                        <div style={{height: '100%', justifyContent: 'center'}}>
                            {topRightContent ? topRightContent : null}
                        </div>
                    </Grid>
                    {mainContent}





                </Grid>
            </Grid>
            
        )
    }
}

export default CustomInfoLayout2