import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({

});



export default function RegressionSummary(props) {
    const classes = useStyles();
    const {regression} = props;
    

    return (
        <Paper>
            <Table aria-label="simple table">
                {/*<TableHead>*/}
                {/*    <TableRow>*/}
                {/*        <TableCell>Name</TableCell>*/}
                {/*        <TableCell align="right">Coefficient</TableCell>*/}
                {/*        <TableCell align="right">Standard Error</TableCell>*/}
                {/*        <TableCell align="right">tStat</TableCell>*/}
                {/*        <TableCell align="right">p Value</TableCell>*/}
                {/*        /!*<TableCell align="right">Protein&nbsp;(g)</TableCell>*!/*/}
                {/*    </TableRow>*/}
                {/*</TableHead>*/}
                <TableBody>

                        <TableRow >
                            <TableCell component="th" scope="row">
                                R
                            </TableCell>
                            <TableCell align="right">{regression.r.toFixed(4)}</TableCell>
                        </TableRow>
                    <TableRow >
                            <TableCell component="th" scope="row">
                                R2
                            </TableCell>
                            <TableCell align="right">{regression.r2.toFixed(4)}</TableCell>
                        </TableRow>
                    <TableRow >
                            <TableCell component="th" scope="row">
                                R2 Adjusted
                            </TableCell>
                            <TableCell align="right">{regression.r2adjusted.toFixed(4)}</TableCell>
                        </TableRow>

                </TableBody>
            </Table>
        </Paper>
    );
}