import validate from "../validate";


const handleTextInputChangeFormControl = (event, formControls) => {
    const value = event.target.value;
    const name = event.target.name;

    const updatedControls = { ...formControls };
    const updatedFormElement = { ...updatedControls[name] }

    updatedFormElement.value = value;
    updatedFormElement.touched = true;
    updatedFormElement.valid = validate(value, updatedFormElement.validationRules);

    updatedControls[name] = updatedFormElement;

    // Check if form is valid
    let formIsValid = true;
    for (let inputIdentifier in updatedControls) {
        formIsValid = updatedControls[inputIdentifier].valid && formIsValid;
    }
    
    return {isValid: formIsValid, updatedControls}
};

export {handleTextInputChangeFormControl}