import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {makeStyles, withStyles} from '@material-ui/core/styles';
import CloudDownload from '@material-ui/icons/CloudDownload';
import GreenButton from "../../../components/CustomButtons/GreenButton";

const useStyles = makeStyles(theme => ({
    title: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(2),
        flexGrow: 1
    },
}));


export default function MeterSectionTitleBar(props) {
    const classes = useStyles();
    const {title, rightContent} = props;

    return (
        <Grid item xs={12}>
            <Grid container>
                <Typography variant={"h5"} className={classes.title}>
                    {title}
                </Typography>
                {rightContent ? rightContent : null}


            </Grid>
        </Grid>

    )

}



