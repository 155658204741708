import {withStyles} from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";

const AntTabs = withStyles(theme => ({
    root: {
        borderBottom: '1px solid #e8e8e8',
        height: 20
    },
    wrapper: {
        height: 0
    },
    indicator: {
        backgroundColor: theme.palette.button.green.main,
    },
}))(Tabs);

export default AntTabs