import React, {Component} from 'react'
import {makeStyles} from "@material-ui/core/styles";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import {Typography} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';


const useStyles = makeStyles(theme => ({
    textField: {
        // margin: theme.spacing(1),
        width: '100%'
    },
    adornment: {
        color: theme.palette.icons.grey,
        marginRight: 10
    },
    label: {
        color: theme.palette.text.formLabel,
        // marginLeft: theme.spacing(0),
        // marginRight: theme.spacing(0)
    },
    root: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    }
}));

export default function CustomOutlinedInput(props) {
    const classes = useStyles();
    const {label = null, value, onChange, name, placeHolder, error = null, required = null, inputComponent = 'input', startAdornment = null, multiline = false, disabled = false} = props;
     
    return (
        <Grid container className={classes.root}>
            {label ? <Typography className={classes.label} variant={"caption"}>{label}</Typography> : null}
            <FormControl className={classes.textField} error={error}>
                <OutlinedInput
                    margin={"dense"}
                    id="component-error"
                    labelWidth={0}
                    value={value}
                    name={name}
                    onChange={onChange}
                    aria-describedby="component-error-text"
                    inputComponent={inputComponent}
                    placeholder={placeHolder}
                    startAdornment={startAdornment ? <Icon className={classes.adornment}>{startAdornment}</Icon> : null}
                    multiline={multiline}
                    rows={multiline ? 5 : null}
                    disabled={disabled}
                />
                {/*{required ?*/}
                {/*<FormHelperText id="component-error-text">required</FormHelperText>*/}
                {/*    : null }*/}
            </FormControl>
        </Grid>
    )
}