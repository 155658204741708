import React from 'react';
import {Link} from 'react-router-dom';



import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import Grid from '@material-ui/core/Grid';


import blue from '@material-ui/core/colors/blue';
import grey from '@material-ui/core/colors/grey';
import PropTypes from 'prop-types';


const useStyles = makeStyles(theme => ({
    root: {
        // justifyContent: 'center',
        flexWrap: 'wrap',
        //display: 'flex',
    },
    paper: {
        padding: theme.spacing(1, 2),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    link: {
        color: theme.palette.appBar.contrast,
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline',
            color: blue[500],
        }
    },
    breadcrumbs: {
        color: theme.palette.appBar.contrast
    }
}));

export default function SimpleBreadcrumbs({
                                              selectedFacility = null,
                                              selectedProject = null,
                                              facilities = null,
                                              selectedFacilityView = null,
                                              location,
                                              match,
                                              history
                                          }) {
    
    
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    function handleClick(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }
    



        return (
            <Grid container alignContent="center" className={classes.root}>
                <Breadcrumbs aria-label="Breadcrumb" className={classes.breadcrumbs}>
                    <Link to={`/`}>
                        <Typography className={classes.link}>

                            Dashboard
                        </Typography>
                    </Link>
                    {/*{selectedProject  ?*/}
                    {/*    <Link to={`/${selectedProject.projectId}`}>*/}
                    {/*        <Typography className={classes.link}>*/}
                    
                    {/*            {selectedProject.name}*/}
                    {/*        </Typography>*/}
                    {/*    </Link> : null*/}
                    {/*}*/}
                    
                    {/*{selectedFacility ?*/}
                    {/*    <Link to={`/${selectedFacility.projectId}/${selectedFacility.facilityId}/facility-dashboard`}>*/}
                    {/*    <Typography className={classes.link}*/}
                    {/*                // onClick={handleClick}*/}
                    {/*    >{selectedFacility.name}</Typography></Link> : ''}*/}
                </Breadcrumbs>
                {/*<Popover*/}
                {/*    id="simple-menu"*/}
                {/*    anchorEl={anchorEl}*/}
                {/*    // keepMounted*/}
                {/*    open={Boolean(anchorEl)}*/}
                {/*    onClose={handleClose}*/}
                {/*    anchorOrigin={{*/}
                {/*        vertical: 'bottom',*/}
                {/*        horizontal: 'left',*/}
                {/*    }}*/}
                {/*>*/}
                {/*    {facilities ? facilities.map(facility => {*/}
                {/*        return (*/}
                {/*            <Link to={`/${facility.projectId}/${facility.facilityId}/${selectedFacilityView}`}>*/}
                {/*                <MenuItem value={facility.facilityId} onClick={handleClose}>*/}
                
                {/*                    <Typography className={classes.link}>{facility.name}</Typography>*/}
                
                
                {/*                </MenuItem>*/}
                {/*            </Link>*/}
                
                {/*        )*/}
                
                {/*    }) : ''}*/}
                {/*</Popover>*/}


                <br/>


            </Grid>
        );
    
}

SimpleBreadcrumbs.propTypes = {
    location: PropTypes.object.isRequired,
};