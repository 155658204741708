import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
    card: {
        minWidth: 345,
        height: "100%"
    },
    media: {
        height: 140,
    },
    cardActionArea: {
        height: "100%"
    },
});

export default function CsvCard({ title, description, handleClick, img }) {
    const classes = useStyles();

    return (
        <Card className={classes.card}>
            <CardActionArea className={classes.cardActionArea} onClick={() => handleClick("csv")}>
                <CardMedia
                    className={classes.media}
                    image={require("../../../assets/img/uploadDataPics/csvIcon.png")}
                    title="Upload CSV file"
                />


                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        {title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                        {description}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}