import {
    SELECTED_CHART_VIEW,
} from '../actions/chart-actions'

const selectedChartView = (state = 'load-curve', action) => {
    switch (action.type) {
        case SELECTED_CHART_VIEW:
        return action.payload;
    }
    return state
}; 


export { selectedChartView }