import React, {Component} from "react";
import {bindActionCreators} from "redux";
import {selectFacility, selectFacilityView, toggleFacilitySideBar} from "../../../../../redux/actions/facility-actions";
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FilterList from "@material-ui/icons/FilterList";
import {getDegreeDaysRedux, setDegreeDayChartToUpdate, clearDegreeDaysRedux} from "../../../../../redux/actions/weather-actions";
import {getDegreeDaysRequestObject} from "../../../../../services/intervalData.service";
import CustomOutlinedInput from "../../../../../components/Inputs/CustomOutlinedInput";

const styles = theme => ({
    formSubmitToolbar: {
        position: 'sticky',
        paddingTop: 5,
        paddingBottom: 5,
        // backgroundColor: 'white',
    },
    degreeDayTextField: {
        margin: '10px 0',
        // backgroundColor: 'red'
    }
});


class WeatherFilterForm extends Component {

    state = {
        cdd: [],
        hdd: [],
        dates: [],
        chartType: 'column',
        chartOptions: null,
        formIsValid: false,
        cddBaseInput: {
            value: 65,
            error: false
        },
        hddBaseInput: {
            value: 65,
            error: false
        },
        cddBaseCurrent: null,
        hddBaseCurrent: null,
        chartCanUpdate: false,
        formHasChanged: false
    };

    onFormSubmit = () => {
        this.props.clearDegreeDaysRedux();
        const {selectedFacility} = this.props;
        let degreeDaysRequest = { ...getDegreeDaysRequestObject };
        degreeDaysRequest.FacilityId = selectedFacility.facilityId;
        degreeDaysRequest.StartDate = selectedFacility.intervalDataStartDate;
        degreeDaysRequest.EndDate = selectedFacility.intervalDataEndDate;
        degreeDaysRequest.CoolingDegreeBase = parseFloat(this.state.cddBaseInput.value);
        degreeDaysRequest.HeatingDegreeBase = parseFloat(this.state.hddBaseInput.value);
        this.props.getDegreeDaysRedux(selectedFacility, this.state.cddBaseInput.value, this.state.hddBaseInput.value);
        this.setState({formHasChanged: false})
    };

    onDegreeDayChange = (event) => {
        let value = event.target.value;
        let baseType = event.target.name;
        let isError = !parseFloat(value);
        // if (event.target.value === '') {
        //     this.setState({[baseType]: event.target.value})
        // }
        // let parsed = parseFloat(event.target.value);
        // if (parsed || parsed === 0) {
        //    
        // }
        this.setState({[baseType]: {value, error: isError}, formHasChanged: true}, () => {
            if (this.state.cddBaseInput.error || this.state.hddBaseInput.error) {
                
                this.setState({formIsValid: false})
            } else {
                this.setState({formIsValid: true})
            }
        })
    };
    
    
    
    render() {
        
        const {classes} = this.props;
        return (
            <form>
                <Grid container style={{ marginTop: 10, padding: 10 }}>
                    <Grid item xs={12}>
                        <Grid container
                              className={classes.formSubmitToolbar}
                              direction="column"
                              alignItems="center"
                              justify="center"
                        >
                            <Button
                                color="secondary"
                                onClick={this.onFormSubmit}
                                // disabled={!this.state.formHasChanged && this.state.formIsValid}
                                disabled={!this.state.formIsValid || !this.state.formHasChanged}
                                variant="contained"
                            >
                                <FilterList style={{paddingRight: 5}}/> Get Degree Days
                            </Button>

                        </Grid>
                        
                    </Grid>
                    <Grid item xs={12} className={classes.degreeDayTextField} style={{marginTop: 20}}>
                        <CustomOutlinedInput
                            label={"Cooling Degree Day Base (°F)"}
                            id="cddBaseInput"
                            name={"cddBaseInput"}
                            value={this.state.cddBaseInput.value} 
                            onChange={this.onDegreeDayChange}
                            fullWidth
                            error={this.state.cddBaseInput.error}
                            startAdornment={'brightness_5'}
                            // className={classes.degreeDayTextField}
                            // variant={"outlined"}
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.degreeDayTextField}>
                        <CustomOutlinedInput
                            label={"Heating Degree Day Base (°F)"}
                            id="hddBaseInput"
                            name="hddBaseInput"
                            value={this.state.hddBaseInput.value} 
                            onChange={this.onDegreeDayChange}
                            startAdornment={'ac_unit'}
                            // fullWidth
                            error={this.state.hddBaseInput.error}
                            // variant={"outlined"}
                        />
                    </Grid>
                    
                </Grid>
            </form>
        )
    }
}

function mapStateToProps(state) {
    return {
        selectedFacilityView: state.selectedFacilityView,
        // intervalData: state.intervalData,
        // facilitySidebarOpen: state.facilitySidebarOpen,
        selectedFacility: state.selectedFacility,
        // facilities: state.facilities,
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        // selectFacilityView,
        // selectFacility,
        // toggleFacilitySideBar,
        getDegreeDaysRedux,
        clearDegreeDaysRedux
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)((WeatherFilterForm)));

