import React, {Component, Fragment} from 'react'
import CountUp from 'react-countup';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {withStyles} from "@material-ui/core";
import EmissionsComparisons from "./EmissionsComparisons";
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    expandDown: {
        marginTop: theme.spacing(1)
    },
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    eGridRegionName: {
        marginTop: theme.spacing(2),
    },
    table: {
        minWidth: '100%',
    },
});

const cardContent = (value, title, suffix) => {
    return (
        <Fragment>
            <Grid item xs={12} container justify="center">
                <Typography variant="h6">
                    <CountUp
                        start={0}
                        end={value}
                        duration={1}
                        separator=","
                        decimal="."
                        decimals={2}
                        useEasing={true}
                    />
                    {suffix}
                </Typography>


            </Grid>
            <Grid item xs={12} container justify="center">
                {title}
            </Grid>
        </Fragment>
    )
};

// const cardContent = (value, title, suffix) => {
//     return (
//         <Fragment>
//             <Grid item xs={12} container justify="center">
//                 <Typography >
//                     {title}: 
//                     <CountUp
//                         start={0}
//                         end={value}
//                         duration={1}
//                         separator=","
//                         decimal="."
//                         decimals={2}
//                         useEasing={true}
//                     />
//                     {suffix}
//                 </Typography>
//
//
//             </Grid>
//             {/*<Grid item xs={12} container justify="center">*/}
//             {/*    {title}*/}
//             {/*</Grid>*/}
//         </Fragment>
//     )
// };

class MeterCo2ECard extends Component {
    state = {
        comparisonsOpen: false,
    };

    toggleComparisonOpen = () => {
        this.setState({comparisonsOpen: !this.state.comparisonsOpen});
    };


    render() {
        
        
        const {locationEmissions, selectedFacility, classes} = this.props;
        let co2 = locationEmissions.emissions.filter(x => x.abbr === 'co2')[0];
        let ch4 = locationEmissions.emissions.filter(x => x.abbr === 'ch4')[0];
        let n2o = locationEmissions.emissions.filter(x => x.abbr === 'n2o')[0];
        let co2e = locationEmissions.emissions.filter(x => x.abbr === 'co2e')[0];
        let annualCo2 = selectedFacility.annualKwhValue * co2.value / 1000;
        let annualCh4 = selectedFacility.annualKwhValue * ch4.value / 1000;
        let annualN2o = selectedFacility.annualKwhValue * n2o.value / 1000;
        let annualCo2e = selectedFacility.annualKwhValue * co2e.value / 1000;
        
        const {subRegion1Name} = this.props;

        return (
            <Grid container className={classes.root}>
                {/*<Grid container xs={12} className={classes.eGridRegionName}>*/}
                {/*    <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>*/}
                {/*        eGrid Subregion*/}
                {/*    </Grid>*/}
                {/*    <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}} >*/}
                {/*        <Typography variant={'h6'}>*/}
                {/*            {subRegion1Name}*/}
                {/*        </Typography>*/}
                {/*        */}
                {/*    </Grid>*/}
                {/*    */}
                {/*</Grid>*/}
                {/*<Grid item md={12} className={classes.divider}>*/}
                {/*    <Divider variant="middle"/>*/}
                {/*</Grid>*/}
                <Grid item md={12}>

                    <Grid container className={classes.root}>
                        {/*<Table className={classes.table} aria-label="simple table">*/}
                        {/*    <TableHead>*/}
                        {/*        <TableRow>*/}
                        {/*            <TableCell>Header 1</TableCell>*/}
                        {/*            <TableCell>Header 2</TableCell>*/}
                        
                        {/*        </TableRow>*/}
                        {/*    </TableHead>*/}
                        {/*    <TableBody>*/}
                        
                        {/*            <TableRow >*/}
                        {/*                <TableCell component="th" scope="row">*/}
                        {/*                    Total Consumption*/}
                        {/*                </TableCell>*/}
                        {/*                <TableCell>{selectedFacility.annualKwhValue}</TableCell>*/}
                        
                        {/*            </TableRow>*/}
                        {/*        <TableRow >*/}
                        {/*                <TableCell component="th" scope="row">*/}
                        {/*                    Carbon Dioxide*/}
                        {/*                </TableCell>*/}
                        {/*                <TableCell>{annualCo2}</TableCell>*/}
                        
                        {/*            </TableRow>*/}
                        {/*        <TableRow >*/}
                        {/*                <TableCell component="th" scope="row">*/}
                        {/*                    Nitrous Oxide*/}
                        {/*                </TableCell>*/}
                        {/*                <TableCell>{annualN2o}</TableCell>*/}
                        
                        {/*            </TableRow>*/}
                        {/*        <TableRow >*/}
                        {/*                <TableCell component="th" scope="row">*/}
                        {/*                    Methane*/}
                        {/*                </TableCell>*/}
                        {/*                <TableCell>{annualCh4}</TableCell>*/}
                        
                        {/*            </TableRow>*/}
                        
                        {/*    </TableBody>*/}
                        {/*</Table>*/}
                        <Grid item xs={3}>
                            {cardContent(selectedFacility.annualKwhValue, 'Total Consumption', ' kWh')}
                        </Grid>
                        <Grid item xs={3}>
                            {cardContent(annualCo2, 'Carbon Dioxide', ' lbs')}
                        </Grid>
                        <Grid item xs={3}>
                            {cardContent(annualN2o, 'Nitrous Oxide', 'lbs')}
                        </Grid>
                        <Grid item xs={3}>
                            {cardContent(annualCh4, 'Methane', 'lbs')}
                        </Grid>
                        
                        
                        
                        

                    </Grid>
                </Grid>
                {this.state.comparisonsOpen ?
                    <Fragment>
                        <Grid item md={12} className={classes.divider}>
                            <Divider variant="middle"/>
                        </Grid>
                        <Grid item md={12}>
                            <Grid container>
                                <EmissionsComparisons carbonEquivalentPounds={annualCo2e}/>
                            </Grid>
                        </Grid>
                    </Fragment> : null}
                <Grid item md={12}>
                    <Grid container className={classes.expandDown} style={{backgroundColor: '#f5f5f5'}}
                          justify="center">
                        <Button fullWidth onClick={this.toggleComparisonOpen}>
                            <Typography color="secondary" style={{fontWeight: 700}}>{this.state.comparisonsOpen ? 'Close' : 'See Comparisons'}</Typography>
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default withStyles(styles)(MeterCo2ECard);