import React, {Component, Fragment} from 'react';

// Components
import CustomInfoLayout from "../../../../../components/CustomInfoLayout/CustomInfoLayout";
import MeterEmissions from "./MeterEmissionsTable.js";
import MeterMonthlyEmissionsChart from "./MeterMonthlyEmissionsChart";
import MeterResourceMixChart from "./MeterResourceMixChart";

// Material UI
import Grid from "@material-ui/core/Grid";
import {Typography, withStyles} from "@material-ui/core";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import MeterCo2ECard from "./MeterCo2ECard";
import GhgClass from "../../../../../js/CustomDataClasses/GhgClass";
import MeterSectionTitleBar from "../../../components/MeterSectionTitleBar";
import {toggleEmissionsIncludeLosses} from "../../../../../redux/actions/EmissionsActions/emissions-actions";


const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    formControl: {
        margin: theme.spacing(0),
    },
});

class GhgMeter extends Component {
    
    state = {
        subRegionEmissionChartOptions: null,
        stateOrRegion: 'subRegion', // 'state' or 'subRegion',
        includeLosses: true,
        GhgObject: null
    }

    //
    //
    // toggleIncludeLosses = (event) => {
    //     let GhgObject = new GhgClass(this.props.selectedFacility.postalCode, this.state.stateOrRegion, !this.state.includeLosses);
    //     this.setState({includeLosses: !this.state.includeLosses, GhgObject});
    // };
    //
    // handleStateVsRegionChange = (event) => {
    //     let GhgObject = new GhgClass(this.props.selectedFacility.postalCode, event.target.value, !this.state.includeLosses);
    //     this.setState({stateOrRegion: event.target.value, GhgObject})
    // };


    render() {
        const {
            selectedFacility, 
            classes, 
            emissionsObject,
            toggleIncludeLosses,
            handleStateVsRegionChange,
            emissionsStateVsRegion,
            emissionsIncludeLosses
        } = this.props;

        if (Object.keys(emissionsObject).length > 0) {
            const {locationEmissions, locationResourceMix} = this.props.emissionsObject;
            return (
                    <Grid container className={classes.root}>
                        <Grid item md={12}>
                            
                            <Grid container className={classes.root}>
                                <Grid item md={12}>
                                    <Grid container className={classes.root}>
                                    <Grid item md={6} lg={3}>
                                        <RadioGroup aria-label="stateVsRegion" name="stateVsRegion" value={emissionsStateVsRegion} row={true} onChange={handleStateVsRegionChange}>
                                            <FormControlLabel value="subRegion" control={<Radio />} label="Sub-Region" />
                                            <FormControlLabel value="state" control={<Radio />} label="State" />
                                        </RadioGroup>
                                    </Grid>
                                        {emissionsStateVsRegion === "subRegion" ?
                                    <Grid item md={6} lg={3}>
                                        <FormControlLabel
                                            control={
                                                <Switch checked={emissionsIncludeLosses} onChange={toggleIncludeLosses} name="includeLosses"/>
                                            }
                                            label="Include Line Losses"
                                        />
                                    </Grid> : null}
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <CustomInfoLayout
                                        title={"Emissions"}
                                        mainContent={locationEmissions ?
                                            <MeterCo2ECard locationEmissions={locationEmissions} subRegion1Name={this.props.emissionsObject.location.subRegion1Name} selectedFacility={selectedFacility}/>
                                            : 'no chart options yet'}
                                    />
                                    
                                </Grid>
                                <Grid item md={12} lg={6}>
                                    <CustomInfoLayout
                                        title="Greenhouse Gas Emissions"
                                        mainContent={locationEmissions ?
                                            <MeterEmissions locationEmissions={locationEmissions} selectedFacility={selectedFacility}/>
                                            : 'no chart options yet'}
                                    />
                                </Grid>
                                <Grid item md={12} lg={6} >
                                    <CustomInfoLayout
                                        title={<Typography>CO<sub>2</sub> Equivalent</Typography>}
                                        mainContent={locationEmissions ?
                                            <MeterMonthlyEmissionsChart locationEmissions={locationEmissions} selectedFacility={selectedFacility} />
                                            : 'no chart options yet'}
                                    />
                                </Grid>
                                <Grid item md={12} lg={6}>
                                    <CustomInfoLayout
                                        title="Resource Mix"
                                        mainContent={locationResourceMix ?
                                            <MeterResourceMixChart locationResourceMix={locationResourceMix} selectedFacility={selectedFacility}/>
                                            : 'no chart options yet'}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>


            )
        } else {
            return 'Loading'
        }
    }
}

export default withStyles(styles)(GhgMeter)