import React, {Component, Fragment} from "react";
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom'
import {selectFacility, selectFacilityView, toggleFacilitySideBar} from '../redux/actions/facility-actions';
import {selectProject} from '../redux/actions/project-actions';
import {getFacilityById} from "../services/facility.service";
import {getProjectById} from "../services/project.service"
import {Toolbar, withStyles} from "@material-ui/core";
import FacilityDashboardView from "./components/Overview";
import FacilityNotes from "./components/Notes";
import Charts from "./components/Charts";
import ImportDataView from "./components/ImportData";
import MeterNavbar from "./components/MeterNavbar";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import StyledLink from '@material-ui/core/Link';
import Weather from "./components/Weather";
import 'moment-timezone';
import Regression from "./components/Regression";
import NavigationTabs from "./components/NavigationTabs/NavigationTabs";
import Emissions from "./components/Emissions";
import CustomPulseLoader from "../components/Spinners/pulse-loader";
import MeterSettings from "./components/Settings";


const styles = theme => ({
    root: {
        display: 'flex',
        flexGrow: 1,
        minHeight: '100vh'
    },
    title: {
        flexGrow: 1,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(1),
    },
    toolbar: theme.mixins.toolbar,
    fab: {
        zIndex: 2,
        alignItems: 'center'
    },
    subTitleBar: {
        backgroundColor: theme.palette.subTitleBar.main,
        zIndex: theme.zIndex.drawer + 1,
        borderBottom: '1px solid #e8e8e8'
    }
});

class FacilityHomeContainerTemp extends Component {

    state = {
        prevUrlParams: null,
        prevFacilityView: null,
    };

    componentDidMount() {

        const {facilityId, projectId, view, subview} = this.props.match.params;
        const {selectProject, selectFacility, selectedProject, selectedFacility} = this.props;

        this.props.selectFacilityView(view, subview);

        if (!selectedProject) {
            getProjectById(projectId).then(project => {
                selectProject(project);
            });
        };
        
        if (!selectedFacility) {
            getFacilityById(facilityId).then(facility => {
                selectFacility(facility);
            });
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {projectId, facilityId, view, subview} = this.props.match.params;
        const {selectFacility} = this.props;

        if (view !== prevProps.match.params.view || subview !== prevProps.match.params.subview) {
            this.props.selectFacilityView(view, subview)
        }

        if (this.props.match.params.facilityId !== prevProps.match.params.facilityId) {
            selectFacility(null);
            getFacilityById(facilityId).then(facility => {
                this.props.selectFacility(facility);
            });
        }

    }

    pushToDashboard = () => {
        const {projectId, facilityId} = this.props.selectedFacility;
        this.props.history.push(`/${projectId}/${facilityId}/overview`)
    };

    getComponent(selectedFacilityView) {

        switch (selectedFacilityView) {
            case 'overview':
                return <FacilityDashboardView selectedFacility={this.props.selectedFacility}/>;
            case 'notes':
                return <FacilityNotes selectedFacility={this.props.selectedFacility}/>;
            case 'charts':
                return <Charts selectedFacility={this.props.selectedFacility}/>;
            case 'weather':
                return <Weather/>;
            case 'data':
                return <ImportDataView
                    facilityId={this.props.selectedFacility.facilityId}
                    projectId={this.props.selectedFacility.projectId}
                    onDataSubmissionSuccess={this.pushToDashboard}
                />;
            case 'emissions':
                return <Emissions/>;
            case 'regression':
                return <Regression selectedFacility={this.props.selectedFacility}/>;
            case 'settings':
                return <MeterSettings />
        }
    };

    render() {
        const {
            selectedFacilityView,
            selectedFacilitySubview,
            toggleFacilitySideBar,
            facilitySidebarOpen,
            selectedFacility,
            selectedProject,
            selectedChartView,
            classes
        } = this.props;
        const {facilityId, projectId, view} = this.props.match.params;


        return (
            <div>
                <Toolbar className={classes.subTitleBar}>
                    {selectedProject ?

                        <Link to={`/${selectedProject.projectId}`} style={{textDecoration: 'none'}}>
                            <StyledLink>
                                <Typography variant={"h5"}>
                                    {selectedProject ? selectedProject.name : null}
                                </Typography>
                            </StyledLink>
                        </Link>
                        : null}


                    <div style={{paddingLeft: 10, paddingRight: 10}}>
                        <Typography variant={"h5"}>
                            {selectedFacility ? '/' : null}
                        </Typography>
                    </div>
                    <Typography variant={"h5"}>
                        {selectedFacility ? selectedFacility.name : null}
                    </Typography>

                </Toolbar>
                

                    {/*<CssBaseline />*/}
                    {selectedFacility ?
                        selectedFacility.maximumkWValue ?
                            <div className={classes.root}>
                                <div>
                                    <MeterNavbar
                                        selectFacilityView={selectFacilityView}
                                        selectedFacilitySubview={selectedFacilitySubview}
                                        projectId={projectId}
                                        facilityId={facilityId}
                                        toggleFacilitySideBar={toggleFacilitySideBar}
                                        facilitySidebarOpen={facilitySidebarOpen}
                                        selectedFacilityView={selectedFacilityView}

                                        selectedChartView={selectedChartView}
                                        selectedFacility={selectedFacility}

                                    />
                                </div>


                                <main className={classes.content}>
                                    <NavigationTabs selectedFacility={selectedFacility}
                                                    selectedFacilityView={selectedFacilityView}/>


                                    <div className={classes.toolbar}>
                                        {this.getComponent(selectedFacilityView, projectId, facilityId)}
                                    </div>
                                    {/*<Grid*/}
                                    {/*    container*/}
                                    {/*    spacing={0}*/}
                                    {/*    direction="column"*/}
                                    {/*    alignItems="center"*/}
                                    {/*    justify="center"*/}
                                    {/*    style={{marginTop: 20}}*/}
                                    {/*>*/}
                                    
                                    {/*    <Typography style={{margin: 10}}>Fetching Facility Data..</Typography>*/}
                                    {/*    <PulseLoader/>*/}
                                    {/*</Grid>*/}

                                </main>
                            </div>
                             :
                            <Grid container style={{padding: 15}} justify={"center"}>

                                    <ImportDataView facilityId={facilityId} projectId={projectId}/>

                               
                            </Grid>
                            
                        : <Grid container style={{ display: 'flex', justifyContent: 'center', marginTop: '20vh'}}>
                                <Grid container>
                                    <Grid container style={{ display: 'flex', justifyContent: 'center'}}>
                                        <Typography style={{margin: 10}}>Fetching Facility Data..</Typography>
                                    </Grid>
                                    <Grid container style={{ display: 'flex', justifyContent: 'center'}}>
                                        <CustomPulseLoader/>
                                    </Grid>
                                    
                                    
                                </Grid>
                                
                               
                         
                                
                        </Grid>
                    }

                </div>

            
        )
    }
}

function mapStateToProps(state) {
    return {
        selectedProject: state.selectedProject,
        selectedChartView: state.selectedChartView,
        selectedFacilityView: state.selectedFacilityView,
        selectedFacilitySubview: state.selectedFacilitySubview,
        // intervalData: state.intervalData,
        facilitySidebarOpen: state.facilitySidebarOpen,
        selectedFacility: state.selectedFacility,
        // facilities: state.facilities,
        // intervalDataForLoadCurve: state.intervalDataForLoadCurve,
        // intervalDataForZoomable: state.intervalDataForZoomable,
        // chartDataFilterFormObject: state.chartDataFilterFormObject
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        selectFacilityView,
        selectFacility,
        selectProject,
        toggleFacilitySideBar,
    }, dispatch);
};

let FacilityHomeContainer = connect(mapStateToProps, mapDispatchToProps)(FacilityHomeContainerTemp);

export default withStyles(styles)(FacilityHomeContainer)


