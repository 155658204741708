import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { Link } from 'react-router-dom';
import highchartsStreamgraph from "highcharts/modules/streamgraph";
import moment from 'moment'


highchartsStreamgraph(Highcharts);

let colors = Highcharts.getOptions().colors;

const getCategories = () => {
    let array = [];
    let initial = moment().date(1).hour(0).minute(0).second(0).millisecond(0).subtract(1, 'year');
    for (let i = 0; i < 12; i++) {
        array.push(moment(initial).add(i, 'month').format("MMMM YYYY"))
    }
    return array
}

function getSeries(facilities) {
    let array = [];
    let data = [];
    for (let i = 0; i < 12; i++) {
        data.push(Math.random() * (2000000 - 1500000) + 1500000)
    }
    facilities.forEach(facility => array.push({ name: facility.name, data: data}))
    return array;
}

const getOptions = (facilities) => {


    const options = {
        chart: {
            type: 'streamgraph',
            marginBottom: 30,
            zoomType: 'x',
            style: {
                fontFamily: "Roboto"
            }
        },

        //// Make sure connected countries have similar colors
        //colors: [
        //    colors[0],
        //    colors[1],
        //    colors[2],
        //    colors[3],
        //    colors[4],
        //    // East Germany, West Germany and Germany
        //    Highcharts.color(colors[5]).brighten(0.2).get(),
        //    Highcharts.color(colors[5]).brighten(0.1).get(),
        //    colors[5],
        //    colors[6],
        //    colors[7],
        //    colors[8],
        //    colors[9],
        //    colors[0],
        //    colors[1],
        //    colors[3],
        //    // Soviet Union, Russia
        //    Highcharts.color(colors[2]).brighten(-0.1).get(),
        //    Highcharts.color(colors[2]).brighten(-0.2).get(),
        //    Highcharts.color(colors[2]).brighten(-0.3).get()
        //],

        title: {
            floating: true,
            align: 'left',
            text: 'Aggregate Energy Usage'
        },
        subtitle: {
            floating: true,
            align: 'left',
            y: 30,
        },

        xAxis: {
            maxPadding: 0,
            type: 'category',
            crosshair: true,
            categories: getCategories(),
            labels: {
                align: 'left',
                reserveSpace: false,
                rotation: 270
            },
            lineWidth: 0,
            margin: 20,
            tickWidth: 0
        },

        yAxis: {
            visible: false,
            startOnTick: false,
            endOnTick: false
        },

        legend: {
            enabled: false
        },

        annotations: [
            {
                labels: [
                    {
                        point: {
                            x: 5.5,
                            xAxis: 0,
                            y: 30,
                            yAxis: 0
                        },
                        text: 'Cancelled<br>during<br>World War II'
                    }, {
                        point: {
                            x: 18,
                            xAxis: 0,
                            y: 90,
                            yAxis: 0
                        },
                        text: 'Soviet Union fell,<br>Germany united'
                    }
                ],
                labelOptions: {
                    backgroundColor: 'rgba(255,255,255,0.5)',
                    borderColor: 'silver'
                }
            }
        ],

        plotOptions: {
            series: {
                label: {
                    minFontSize: 5,
                    maxFontSize: 15,
                    style: {
                        color: 'rgba(255,255,255,0.75)'
                    }
                }
            }
        },

        // Data parsed with olympic-medals.node.js
        series: getSeries(facilities),

        exporting: {
            sourceWidth: 800,
            sourceHeight: 600
        }
    }
    return options
}


//const getSeriesForChart = (facilities) => {
//    let series = [
//        {
//            name: 'Peak Demand (kW)',
//            data: [],
//            yAxis: 0
//        },
//        {
//            name: 'Annual Usage (kWh)',
//            data: [],
//            yAxis: 1
//        }
//    ];
//    let facilitiesList = [];
//    let facilityIds = [];

//    facilities.forEach((facility, index) => {
//        series[0].data.push(facility.maximumkWValue);
//        series[1].data.push((facility.maximumkWValue * 1000));
//        facilitiesList.push(facility.name);
//        facilityIds.push(facility.facilityId)

//    })
//    return { series, facilitiesList, facilityIds }
//};

export default function FacilitiesChartTable({ facilities }) {
    const options = getOptions(facilities)
    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    )
}