import {withStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const BigGreenButton = withStyles(theme =>({
    root: {
        // boxShadow: 'none',
        textTransform: 'none',
        fontSize: '1.5em',
        color: 'white',
        padding: '15px 30px',
        // border: '1px solid',
        lineHeight: 1.5,
        backgroundColor: theme.palette.button.green.main,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.palette.button.green.main,
        // borderColor: '#007bff',
        fontFamily: [
            // '-apple-system',
            // 'BlinkMacSystemFont',
            // '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            backgroundColor: theme.palette.button.green.hover,
            // borderColor: '#0062cc',
            // boxShadow: 'none',
        },
        '&:active': {
            // boxShadow: 'none',
            // backgroundColor: '#0062cc',
            // borderColor: '#005cbf',
        },
        '&:disabled': {
          border: 'none',
            backgroundColor: theme.palette.button.disabled
        },
        '&:focus': {
            // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
        },
    },
}))(Button);

export default BigGreenButton