import { useTheme } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import React from "react";
import PulseLoader from 'react-spinners/PulseLoader';
import PropTypes from "prop-types";

function LoadingButton(props) {
    
    const {loading, variant = null, onClick, color, message, disabled = false} = props;
    const theme = useTheme();
    if (variant === 'contained') {
        return (
            <Button onClick={onClick} color={color} variant={variant} disabled={disabled}>
                {loading ? <PulseLoader color={'white'} size={5} /> : null}
                <div style={{paddingLeft: loading ? 5 : null}}>
                    {message}
                </div>
            </Button>
        )
    } else {
        return (
            <Button onClick={onClick}  color={color} disabled={disabled}>
                {loading ? <PulseLoader size={5} color={theme.palette[color].main}/> : null}
                <div style={{paddingLeft: loading ? 5 : null}}>
                    {message}
                </div>
                
            </Button>
            )
        
    }
    
}

LoadingButton.propTypes = {
    loading: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    color: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
};

export default LoadingButton

