import React, {Component} from 'react'
import {Link} from "react-router-dom";
import AntTab from "../../../../../components/AntTabs/AntTab";
import AntTabs from "../../../../../components/AntTabs/AntTabs";

function WeatherTabs(props) {
        const {selectedFacility} = props;
        const {projectId, facilityId} = selectedFacility;

        let tabs = [
            
            {
                label: 'Degree Days',
                link: `/${projectId}/${facilityId}/weather/degree-days`,
                chart: 'degree-days'
            },
            {
                label: 'Raw Data',
                link: `/${projectId}/${facilityId}/weather/raw-data`,
                chart: 'raw-data'
            },
            {
                label: 'Location',
                link: `/${projectId}/${facilityId}/weather/location`,
                chart: 'location'
            },
        ];

        let selectedIndex;
        tabs.forEach((x, index) => {
            if (x.chart === props.selectedFacilitySubview) {
                selectedIndex = index;
            }
        });

        return (
            <AntTabs value={selectedIndex}>
                {tabs.map(tab => (
                    <Link to={tab.link} style={{textDecoration: 'none'}}  >
                        <AntTab label={tab.label} style={{padding: 0}} />
                    </Link>

                ))}
            </AntTabs>
        )

}

export default WeatherTabs