import React, {Component} from 'react'
import {Link} from "react-router-dom";
import AntTab from "../../../../components/AntTabs/AntTab";
import AntTabs from "../../../../components/AntTabs/AntTabs";

class ChartsTabs extends Component {
    
    render() {

        const {selectedFacility} = this.props;
        const {projectId, facilityId} = selectedFacility;

        let tabs = [
            {
                label: 'Load Curve',
                link: `/${projectId}/${facilityId}/charts/load-curve`,
                chart: 'load-curve'
            },
            {
                label: 'Summary Chart',
                link: `/${projectId}/${facilityId}/charts/summary-chart`,
                chart: 'summary-chart'
            },
            {
                label: 'Heat Map',
                link: `/${projectId}/${facilityId}/charts/heat-map`,
                chart: 'heat-map'
            },
            {
                label: 'Time Series',
                link: `/${projectId}/${facilityId}/charts/zoomable-chart`,
                chart: 'zoomable-chart'
            },
            {
                label: 'Calendar Chart',
                link: `/${projectId}/${facilityId}/charts/calendar-chart`,
                chart: 'calendar-chart'
            },
            {
                label: 'Demand Duration',
                link: `/${projectId}/${facilityId}/charts/demand-duration`,
                chart: 'demand-duration'
            },
            {
                label: 'Scatter Plot',
                link: `/${projectId}/${facilityId}/charts/scatter-plot`,
                chart: 'scatter-plot'
            },
            {
                label: 'Surface Plot',
                link: `/${projectId}/${facilityId}/charts/surface-plot`,
                chart: 'surface-plot'
            },
        ];  
        
        let selectedIndex;
        tabs.forEach((x, index) => {
            if (x.chart === this.props.selectedFacilitySubview) {
                selectedIndex = index;
            } 
        });
        
        return (
            <AntTabs value={selectedIndex}>
                {tabs.map(tab => (
                    <Link 
                        to={tab.link} 
                        style={{textDecoration: 'none'}} 
                        // onClick={() => selectChartView(tab.chart)} 
                    >
                        <AntTab label={tab.label} style={{padding: 0}} />
                    </Link>

                ))}
            </AntTabs>
        )
    }
}

export default ChartsTabs