/* eslint-disable */
import React, { Component, Fragment } from "react";

// Services
import { getProjectsForUser, createOrUpdateProject, projectModel, deleteProject, getProjectsForOrganization } from "../../services/project.service";
import { getErrorMessage } from "../../services/serviceHelper";

// Redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { addProjectsToState } from '../../redux/actions/dashboard-actions';

//Components
import DashboardComponent from '../components/DashboardComponent'
import ThreeTest from "../components/EditProject/Three";

// Validate
import validate from '../../js/validate'

import CustomSnackbar from '../../components/Snackbar/CustomSnackbar'

import EditProject from '../components/EditProject/index'
import EditProjectDialog from "../components/EditProject/EditProjectDialog";
import PhoneNumberTextMaskInput from "../../components/TextMasks/PhoneNumberTextMaskInput";

class Dashboard extends Component {

    state = {
        loading: false,
        fetchingProjects: false,
        createProjectFormIsValid: false,
        attemptedCreateProjectSubmit: false,
        createProjectDialogOpen: false,
        createProjectFormControls: {
            name: {
                label: 'Project Name',
                value: null,
                valid: true,
                name: 'name',
                startAdornment: null,
                touched: false,
                validationRules: {
                    isRequired: true
                }
            },
            description: {
                label: 'Project Description (Optional)',
                value: null,
                valid: true,
                name: 'description',
                startAdornment: null,
                touched: false,
                validationRules: {

                }
            },
            companyName: {
                label: 'Company Name',
                value: null,
                valid: true,
                touched: false,
                startAdornment: null,
                name: 'companyName',
                validationRules: {

                }
            },
            utility: {
                label: 'Utility',
                value: null,
                valid: true,
                touched: false,
                startAdornment: null,
                name: 'utility',
                validationRules: {

                }
            },
            contactName: {
                label: 'Contact Name',
                value: null,
                valid: true,
                touched: false,
                startAdornment: 'person',
                name: 'contactName',
                validationRules: {

                }
            },
            contactJobTitle: {
                label: 'Contact Job Title',
                value: null,
                valid: true,
                touched: false,
                startAdornment: 'work_outline',
                name: 'contactJobTitle',
                validationRules: {

                }
            },
            contactPhoneNumber: {
                label: 'Contact Phone Number',
                value: null,
                valid: true,
                touched: false,
                startAdornment: 'phone',
                name: 'contactPhoneNumber',
                validationRules: {

                },
                textMask: PhoneNumberTextMaskInput
            },
            contactEmail: {
                label: 'Contact Email',
                value: null,
                valid: true,
                touched: false,
                startAdornment: 'email',
                name: 'contactEmail',
                validationRules: {
                    isEmail: true
                }
            },
        },

        editProjectDialogOpen: false,
        selectedProjectForEdit: null,

        attemptedEditProjectSubmit: false,
        editProjectFormControls: {
            name: {
                label: 'Name',
                value: null,
                valid: true,
                touched: false,
                message: 'required',
                validationRules: {
                    isRequired: true
                },
                textMask: null
            },
            companyName: {
                label: 'Company Name',
                value: null,
                valid: true,
                touched: false,
                validationRules: {

                },
                textMask: null
            },
            utility: {
                label: 'Utility',
                value: null,
                valid: true,
                touched: false,
                validationRules: {

                },
                textMask: null
            },
            contactName: {
                label: 'Contact Name',
                value: null,
                valid: true,
                touched: false,
                validationRules: {

                },
                textMask: null
            },
            contactJobTitle: {
                label: 'Contact Job Title',
                value: null,
                valid: true,
                touched: false,
                validationRules: {

                },
                textMask: null
            },
            contactPhoneNumber: {
                label: 'Contact Phone Number',
                value: null,
                valid: true,
                touched: false,
                validationRules: {

                },
                textMask: PhoneNumberTextMaskInput
            },
            contactEmail: {
                label: 'Contact Email',
                value: null,
                valid: true,
                touched: false,
                validationRules: {
                    isEmail: true
                },
                textMask: null
            },
        },

        selectedProjectForDelete: null,
        deleteProjectDialogOpen: false,

        snackbar: {
            open: false,
            message: '',
            varient: ''
        }
        
        

    }

    openSnackbar = (value) => {
        let snackbar = { ...this.state.snackbar }
        snackbar.open = value;
        this.setState({ snackbar })
    }

    toggleCreateProjectDialog = () => {
        let createProjectDialogOpen = !this.state.createProjectDialogOpen;
        this.setState({ createProjectDialogOpen })
    }

    toggleEditProjectDialog = (project = null) => {
        let editProjectDialogOpen = !this.state.editProjectDialogOpen;
        this.setState({ editProjectDialogOpen, selectedProjectForEdit: project })
    }

    toggleDeleteProjectDialog = (project = null) => {
        if (project !== null) {
            let selectedProjectForDelete = project
            this.setState({ selectedProjectForDelete })
        }

        let deleteProjectDialogOpen = !this.state.deleteProjectDialogOpen;
        this.setState({ deleteProjectDialogOpen })
    }

    onCreateProjectSubmit = () => {
        this.setState({loading: true});
        let submittedForm = { ...this.state.createProjectFormControls };
        let keys = Object.keys(submittedForm);
        let finalSubmission = {};
        keys.forEach(key => { finalSubmission[key] = submittedForm[key].value });
        if (this.state.createProjectFormIsValid) {
            console.log(finalSubmission);

            let projectRequest = {...projectModel};
            
            projectRequest.CompanyName = finalSubmission.companyName;
            projectRequest.ContactName = finalSubmission.contactName;
            projectRequest.ContactEmail = finalSubmission.contactEmail;
            projectRequest.ContactJobTitle = finalSubmission.contactJobTitle;
            projectRequest.ContactPhoneNumber = finalSubmission.contactPhoneNumber;
            projectRequest.Name = finalSubmission.name;
            projectRequest.Utility = finalSubmission.utility;
            
            createOrUpdateProject(projectRequest).then((response)=>{
                if (response.isSuccess){
                    this.toggleCreateProjectDialog();

                    // set snackbar
                    let snackbar = { ...this.state.snackbar };
                    snackbar.message = response.successMessage ? response.successMessage : "Successfully Created New Project";
                    snackbar.open = true;
                    snackbar.variant = "success";
                    this.setState({ snackbar });
                    
                    this.updateTable();
                }else{

                    //default error message
                    let errorMessage = getErrorMessage(response);
                    
                    // set snackbar
                    let snackbar = { ...this.state.snackbar };
                    snackbar.message = errorMessage;
                    snackbar.open = true;
                    snackbar.variant = "error";
                    this.setState({ snackbar });
                }
            });
        } else {
            this.setState({ attemptedCreateProjectSubmit: true })
        }
        this.setState({loading: false});

        
    }

    onEditProjectSubmit = () => {
        let submittedForm = { ...this.state.editProjectFormControls };
        let keys = Object.keys(submittedForm);
        let finalSubmission = {};
        keys.forEach(key => { finalSubmission[key] = submittedForm[key].value });

        if (this.state.editProjectFormIsValid) {
            console.log(finalSubmission);
            // TODO Edit Project. ProjectId = this.state.selectedProjectForEdit.projectId. finalSubmission is the new object that should have what you need

            let projectRequest = {...projectModel};

            projectRequest.ProjectId = this.state.selectedProjectForEdit.projectId;
            projectRequest.CompanyName = finalSubmission.companyName;
            projectRequest.ContactName = finalSubmission.contactName;
            projectRequest.ContactEmail = finalSubmission.contactEmail;
            projectRequest.ContactJobTitle = finalSubmission.contactJobTitle;
            projectRequest.ContactPhoneNumber = finalSubmission.contactPhoneNumber;
            projectRequest.Name = finalSubmission.name;
            projectRequest.Utility = finalSubmission.utility;

            createOrUpdateProject(projectRequest).then((response)=>{
                if (response.isSuccess){
                    this.toggleEditProjectDialog();
                    this.setState({ attemptedEditProjectSubmit: false })

                    // set snackbar
                    let snackbar = { ...this.state.snackbar };
                    snackbar.message = response.successMessage ? response.successMessage : "Successfully Updated Project";
                    snackbar.open = true;
                    snackbar.variant = "success";
                    this.setState({ snackbar });

                    this.updateTable();
                }else{

                    //default error message
                    let errorMessage = getErrorMessage(response);
                    
                    // set snackbar
                    let snackbar = { ...this.state.snackbar };
                    snackbar.message = errorMessage;
                    snackbar.open = true;
                    snackbar.variant = "error";
                    this.setState({ snackbar });
                }
            });

        } else {
            this.setState({ attemptedEditProjectSubmit: true })
        }


    }

    onDeleteProjectSubmit = () => {
        console.log(this.state.selectedProjectForDelete);
        this.toggleDeleteProjectDialog();
        // TODO Delete Project. projectId = this.state.selectedProjectForDelete.projectId

        deleteProject(this.state.selectedProjectForDelete.projectId).then((response)=>{
            if (response.isSuccess){
                //this.toggleDeleteProjectDialog();

                // set snackbar
                let snackbar = { ...this.state.snackbar };
                snackbar.message = response.successMessage ? response.successMessage : "Successfully Deleted Project";
                snackbar.open = true;
                snackbar.variant = "success";
                this.setState({ snackbar });

                this.updateTable();
            }else{

                //default error message
                let errorMessage = getErrorMessage(response);
                
                // set snackbar
                let snackbar = { ...this.state.snackbar };
                snackbar.message = errorMessage;
                snackbar.open = true;
                snackbar.variant = "error";
                this.setState({ snackbar });
            }
        });
    }

    handleCreateProjectInputChange = (event, form) => {
        
        const value = event.target.value;
        const name = event.target.name;

        const updatedControls = { ...this.state.createProjectFormControls };
        const updatedFormElement = { ...updatedControls[name] }

        updatedFormElement.value = value;
        updatedFormElement.touched = true;
        updatedFormElement.valid = validate(value, updatedFormElement.validationRules);

        updatedControls[name] = updatedFormElement;

        // Check if form is valid
        let formIsValid = true;
        for (let inputIdentifier in updatedControls) {
            formIsValid = updatedControls[inputIdentifier].valid && formIsValid;
        }
        this.setState({
            createProjectFormControls: updatedControls,
            createProjectFormIsValid: formIsValid
        })
    }

    handleEditProjectInputChange = (event, form) => {

        const value = event.target.value;
        const name = event.target.name;

        const updatedControls = { ...this.state.editProjectFormControls };
        const updatedFormElement = { ...updatedControls[name] }

        updatedFormElement.value = value;
        updatedFormElement.touched = true;
        updatedFormElement.valid = validate(value, updatedFormElement.validationRules);

        updatedControls[name] = updatedFormElement;

        // Check if form is valid
        let formIsValid = true;
        for (let inputIdentifier in updatedControls) {
            formIsValid = updatedControls[inputIdentifier].valid && formIsValid;
        }
        this.setState({
            editProjectFormControls: updatedControls,
            editProjectFormIsValid: formIsValid
        })
    }

    

    componentDidMount() {
        this.updateTable();
    }

    updateTable = () => {
        this.setState({fetchingProjects: true});
        getProjectsForOrganization().then(projects => {
            this.props.addProjectsToState(projects);
            this.setState({fetchingProjects: false});
        });
    }

    render() {

        return (
            <div>
                <DashboardComponent
                    loading={this.state.loading}
                    fetchingProjects={this.state.fetchingProjects}
                    projects={this.props.projects}
                    
                    // create project 
                    createProjectDialogOpen={this.state.createProjectDialogOpen}
                    toggleCreateProjectDialog={this.toggleCreateProjectDialog}
                    createProjectFormControls={this.state.createProjectFormControls}
                    handleCreateProjectInputChange={this.handleCreateProjectInputChange}
                    onCreateProjectSubmit={this.onCreateProjectSubmit}
                    attemptedCreateProjectSubmit={this.state.attemptedCreateProjectSubmit}

                    // edit project
                    toggleEditProjectDialog={this.toggleEditProjectDialog}
                    
                    // Delete Project
                    toggleDeleteProjectDialog={this.toggleDeleteProjectDialog}
                    selectedProjectForDelete={this.state.selectedProjectForDelete}
                    deleteProjectDialogOpen={this.state.deleteProjectDialogOpen}
                    onDeleteProjectSubmit={this.onDeleteProjectSubmit}

                    //snackbar
                    snackbar={this.state.snackbar}
                    openSnackbar={this.openSnackbar}
                /> 

                <EditProject
                    toggleEditProjectDialog={this.toggleEditProjectDialog}
                    editProjectDialogOpen={this.state.editProjectDialogOpen}
                    selectedProjectForEdit={this.state.selectedProjectForEdit}
                    onSubmit={this.updateTable}
                />
                {/*<ThreeTest />*/}



            </div>
        )




    }
}

function mapStateToProps(state) {
    return {
        projects: state.projects,
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ addProjectsToState }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

