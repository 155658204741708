import config from "../config";
import { authHeader } from "../helpers/auth-header";
import { getUser } from "../services/user.service";
import {logout} from "../services/user.service";

let REQUESTTYPES = {
    get: {
        method: "GET",
        headers: authHeader()
    },
    post: {
        method: "POST",
        headers: authHeader()
    },
    postMultiPart: {
        method: "POST",
        headers: authHeader(true),
    }
};

// Primary fetch helper function
const fetchHelper = (apiURL, endpoint, param, requestType) => {
    
    //set LastUserFeedback on any URL request except refreshToken
    if(endpoint.toLowerCase() !== "refreshtoken")
        sessionStorage.setItem("LastUserFeedback", new Date());
    
    //REQUESTTYPES[requestType].headers = authHeader(); // make sure the header includes the token
    let fetchUrl = `${apiURL}/${endpoint}`;

    let requestOptions = {};
    
    switch(requestType){
        case "get":{
            requestOptions = {
                method: "GET",
                headers: authHeader()
            }
        }
        break;
        case "post":{
            requestOptions = {
                method: "POST",
                headers: authHeader()
            }
        }
        break;
        case "postMultiPart":{
            requestOptions = {
                method: "POST",
                headers: authHeader(true),
            }
        }
        break;
    }
    
    // append url if param is present
    if (param && requestType === "get") {
        fetchUrl += "/" + param;
    }

    if (requestType === "postMultiPart") {
        requestOptions.body = param;
    }else if (requestType === "post") {
        requestOptions.body = JSON.stringify(param);
    }

    return fetch(fetchUrl, requestOptions).then(response => {
        // debugger;
        // if (!response.ok) {
        //     throw Error(response.statusText);
        // }
        return responseStatusHandler(response);
    }).catch(function(error) {
        return {isSuccess: false}
    });
};

// Function for handling statuses inside fetchHelper()
const responseStatusHandler = response => {
    //debugger;
    // Unauthorized and server issue catch
    if (response.status === 401) {
        logout();
        window.location.reload();
        return response;
    }

    if (response.status === 501) {
        logout();
        return response;
    }
    
    if (response.status === 200) {
        response = response.json();
        return response;
    } else {
        // ...
    }
    // TODO: deal with friendly error responses here?
};

//get error message out of response
const getErrorMessage = (response) =>{
    //default error message
    let errorMessage = "Something went wrong. Please try again or contact React Energy Support.";

    //check if response returned a better error message
    if (response.errors && response.errors.length > 0){
        errorMessage = response.errors[0].message;
    }
    
    return errorMessage;
};

export {
    REQUESTTYPES,
    fetchHelper,
    getErrorMessage
};