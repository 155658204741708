import {
    TOGGLE_SHOW_PEAK_DEMAND, 
    SET_CALENDAR_CHART_TEMPERATURE_VIEW
} from '../../actions/DataChartActions/calendar-chart-actions'

const calendarChartShowPeakDemand = (state = false, action) => {
    switch (action.type) {
        case TOGGLE_SHOW_PEAK_DEMAND:
            return action.payload;
    }
    return state;
};

const calendarChartShowTemperature = (state = false, action) => {
    switch (action.type) {

        case SET_CALENDAR_CHART_TEMPERATURE_VIEW:
            return action.payload;
    }
    return state
};

export { calendarChartShowPeakDemand, calendarChartShowTemperature };