import React from 'react';

//Material UI
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';


const useStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
    appBar: {
        boxShadow: "none"
    },
    card: {
        maxWidth: 345,
    },
    media: {
        height: 140,
    },
    link: {
        color: '#0da3e2',
        '&:hover': {
            color: '#0da3e2',
            textDecoration: 'underline',
        }

    },
});

const helpers = [
    {
        label: 'Getting Started with React Energy'
    },
    {
        label: 'How-to Videos'
    },
    {
        label: 'Contact Support'
    },
]

export default function SimpleAppBar() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Card>

                <CardContent>
                    {helpers.map(helper => (
                    <Typography variant="body2" color="textSecondary" component="p" className={classes.link}>
                        {helper.label}
                    </Typography>))}

                </CardContent>

            </Card>


        </div>
    );
}