import React, {Fragment} from 'react'
import MaterialTable from 'material-table'
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from '@material-ui/core/styles';
import EditAndDeleteIcons from "../../../Dashboard/components/ProjectEditAndDeleteIcons";
import NoteEditAndDeleteIcons from "./NoteEditAndDeleteIcons";
import moment from 'moment'

const useStyles = makeStyles(theme => ({
    detailPanel: {
        backgroundColor: theme.palette.background.default,
        padding: 10
    },
}));

function NotesTable(props) {
    const {notes, toggleDeleteNoteDialog, toggleEditNoteDialog} = props;
    const classes = useStyles();
            return (
                <div style={{ maxWidth: '100%' }}>
                <MaterialTable
                    title="Notes"
                    columns={[
                        { title: 'Title', field: 'title' },
                        { title: 'Last Updated', render: rowData => (<Typography>{moment(rowData.modifiedDate).format("MM/DD/YY HH:mm")}</Typography>) },
                        {
                            title: 'Actions', render: rowData => (
                                <div>
                                    <NoteEditAndDeleteIcons 
                                        note={rowData} 
                                        toggleDeleteNoteDialog={toggleDeleteNoteDialog}
                                        toggleEditNoteDialog={toggleEditNoteDialog}
                                    />

                                </div>
                            )

                        },
                    ]}
                    data={notes}
                    detailPanel={rowData => {
                        return (
                            <Grid className={classes.detailPanel}>
                                <Typography>{rowData.notes}</Typography>
                            </Grid>
                            
                        )
                    }}
                    components={{
                        Container: props => (
                            <div>
                                {props.children}
                            </div>
                        )
                    }}
                    // onRowClick={(event, rowData, togglePanel) => togglePanel()}
                    options={{
                        search: true,
                        pageSize: 10,
                        padding: 'dense'
                    }}
                />
                </div>
            )
}

export default NotesTable;