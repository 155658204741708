import React, {Component} from 'react'

// Material UI

import Grid from '@material-ui/core/Grid';
import {withStyles} from '@material-ui/core/styles';

// Components
import AccountSummary from './AccountSummary'
import OrganizationSettingsContainer from "../../OrganizationSettings/containers/OrganizationSettingsContainer";
import AccountNavbar from "./NavBar";

// import ManageBilling from './Billing/ManageBilling'
import PasswordManagement from "./Password/index";
import AccountSubscription from "./Subscription";
import TestLayout from "./TestLayout";

// Icons
import LockIcon from '@material-ui/icons/Lock';
import HomeIcon from '@material-ui/icons/Home';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import SettingsIcon from '@material-ui/icons/Settings';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import {bindActionCreators} from "redux";
import {setActiveUser} from "../../redux/actions/user-actions";
import {connect} from "react-redux";
import AccountTabs from "./AccountTabs";
import MeterSectionTitleBar from "../../FacilityHome/components/components/MeterSectionTitleBar";




const styles = theme => ({

    accountSection: {
        backgroundColor: theme.palette.background.grey,
        minHeight: '100vh'
    }

});


class AccountHome extends Component {
    state = {
        currentTab: 2,
        activeComponent: null,
        tabs: [  {
                label: 'Account Home',
                icon: <HomeIcon />,
                link: `/account/home`
            }, {
                label: 'Password',
                icon: <LockIcon />,
                link: `/account/password`
            }, {
                label: 'Subscription',
                icon: <CreditCardIcon />,
                link: `/account/subscription`
            },{
                label: 'Team',
                icon: <PeopleIcon />,
                link: `/account/team`
            }, 
            // {
            //     label: 'Settings',
            //     icon: <SettingsIcon />,
            //     link: `/account/settings`
            // }
        ],
        tabs2: [
            {
                label: 'Back to Dashboard',
                icon: <DashboardIcon/>,
                link: `/`
            },
        ]
    };
    
    componentDidMount = () => {
        const {component} = this.props.match.params;
        this.getMainComponent(component)
    }
    
    componentDidUpdate = (prevProps, prevState, snapshot) => {
        if (prevProps.match.params.component !== this.props.match.params.component) {
            const {component} = this.props.match.params;
            this.getMainComponent(component)
        }
        
    };
    
    getMainComponent = (component) => {
        
        let userInfo = JSON.parse(window.sessionStorage.user);
        
        if (userInfo.subscription !== null) {
            switch (component) {
                case 'subscription':
                    this.setState({activeComponent: <AccountSubscription activeUser={this.props.activeUser} resetActiveUser={this.props.setActiveUser}/>});
                    break;
                case 'home':
                    this.setState({activeComponent: <AccountSummary/>});
                    break;
                case 'password':
                    this.setState({activeComponent: <PasswordManagement /> });
                    break;
                case 'settings':
                    this.setState({activeComponent: <TestLayout />});
                    break;
                case 'team':
                    this.setState({activeComponent: <OrganizationSettingsContainer />});
                    break;
            }
        } else {
            this.setState({activeComponent: <AccountSubscription />});
        }
        
        
        
    }

    render() {
        const {classes} = this.props;

        // let userInfo = JSON.parse(window.sessionStorage.user);
        // userInfo.subscription = null;
       
        return (
            <div className={classes.root}>
                <Grid container>
                    <Grid item xs={12} style={{padding: '10px 10px 0 10px'}}>
                        <MeterSectionTitleBar title={'Account'}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container style={{display: 'flex', justifyContent: 'center', }}>
                            <div style={{width: '100%', padding: '0px 10px'}}>
                                <AccountTabs currentComponent={this.props.match.params.component}/>
                            </div>
                            
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className={classes.accountSection}>
                        <Grid container>
                                {this.state.activeComponent}
                        </Grid>
                    </Grid>
                    
                </Grid>
                

                {/*<AccountNavbar currentPath={this.props.location.pathname}/> */}
                
                
                

            </div>


        )
    }
}

function mapStateToProps(state) {
    return {
        activeUser: state.activeUser,
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({setActiveUser }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AccountHome));