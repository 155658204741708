const validate = (value, rules) => {

    let isValid = true;

    for (let rule in rules) {

        switch (rule) {
            case 'minLength': isValid = isValid && minLengthValidator(value, rules[rule]); break;

            case 'isRequired': isValid = isValid && requiredValidator(value); break;

            case 'isEmail': isValid = isValid && emailValidator(value); break;

            case 'isPassword': isValid = isValid && passwordValidator(value); break;

            case 'isZipcode': isValid = isValid && zipcodeValidator(value); break;

            default: isValid = true;
        }

    }

    return isValid;
}


const minLengthValidator = (value, minLength) => {
    return value.length >= minLength;
}

const requiredValidator = value => {
    return value.trim() !== '';
}

const emailValidator = value => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(value).toLowerCase());
}

const passwordValidator = value => {
    let letter = false;
    let capital = false;
    let number = false;
    let specialCharacter = false;
    let length = false;

    let lowerCaseLetters = /[a-z]/g;

    // if (value.match(lowerCaseLetters)) {
    //     letter = true;
    // } else {
    //     letter = false;
    // }
    // this is the same as the above statement
    letter = !!value.match(lowerCaseLetters);

    let upperCaseLetters = /[A-Z]/g;
    capital = !!value.match(upperCaseLetters);

    let numbers = /[0-9]/g;
    number = !!value.match(numbers);

    let specialCharacters = /[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/;
    specialCharacter = !!value.match(specialCharacters);

    length = value.length >= 8;

    return (letter && capital && number && length && specialCharacter)
}

const zipcodeValidator = value => {
    return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value) 
         || /(^\d{4}$)|(^\d{4}-\d{3}$)/.test(value);
}

export default validate