import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import PaymentMethodRev2 from "../Subscription/PaymentMethodRev2";

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        minHeight: 200,
        border: '1px solid #dadce0',
        borderRadius: 8
    },
    title: {
        fontFamily: 'Google Sans,Roboto,Arial,sans-serif',
        fontSize: '1.375rem',
        fontWeight: 400,
        letterSpacing: 0,
        lineHeight: '1.75rem',
        hyphens: 'auto',
        wordBreak: 'break-word',
        wordWrap: 'break-word',
        color: '#202124',
    }
});

export default function GoogleLayout() {
    const classes = useStyles();


    return (
        <div>
            <Grid container>
                <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                    <div className={classes.title}>
                        Subscription
                    </div>
                </Grid>
            </Grid>
            <Grid container className={classes.root}>
                <Grid item xs={12} style={{padding: 20}}>
                    <Grid item xs={8}>
                        
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}