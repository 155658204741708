import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';

// Font Awesome
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCircle, faCloudUploadAlt, faTrashAlt} from '@fortawesome/free-solid-svg-icons'

// Colors
import {green, red} from '@material-ui/core/colors';

// Material UI
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        // justifyContent: 'center',
        alignItems: 'flex-end',
    },
    iconDelete: {
        margin: theme.spacing(0),
        color: '#4a4a4a',
        fontSize: 30
    },
    iconEdit: {
        margin: theme.spacing(0),
        color: '#4a4a4a',
        fontSize: 30
    },
    iconAdd: {
        margin: theme.spacing(0),
        color: '#4a4a4a',
        fontSize: 30

    },
    margin: {
        margin: theme.spacing(0),
    },


}));

export default function Icons(props) {
    const classes = useStyles();

    const {toggleImportDataDialog, facilityObject, toggleEditFacilityDialog, setSelectedMeterForEdit, toggleDeleteFacilityDialog} = props;


    return (
        <div className={classes.root}>
            <IconButton aria-label="Edit" className={classes.margin} size="small"
                        onClick={() => {
                            toggleEditFacilityDialog(); 
                            setSelectedMeterForEdit(facilityObject)
                        }}>
                <Icon className={classes.iconEdit}>
                    edit
                </Icon>
            </IconButton>
            <IconButton aria-label="Delete" className={classes.margin} size="small" onClick={() => {
                toggleDeleteFacilityDialog(facilityObject);
            }}
            >
                <Icon className={classes.iconDelete}>
                    delete_outline
                </Icon>
            </IconButton>
            {/*<IconButton aria-label="Add" className={classes.margin} size="small" onClick={() => toggleImportDataDialog(facilityObject)}>*/}
            {/*    <Icon className={classes.iconAdd}>*/}
            {/*        cloud_upload*/}
            {/*    </Icon>*/}
            {/*</IconButton>*/}


        </div>
    );
}