import React, {Component} from 'react'
import {Link} from "react-router-dom";
import AntTab from "../../../../../components/AntTabs/AntTab";
import AntTabs from "../../../../../components/AntTabs/AntTabs";

function EmissionsTabs(props) {



        const {selectedFacility, selectedFacilitySubview} = props;
        const {projectId, facilityId} = selectedFacility;

        let tabs = [
            {
                label: 'Summary',
                link: `/${projectId}/${facilityId}/emissions/summary`,
                chart: 'summary'
            },
            {
                label: 'Sub-Region',
                link: `/${projectId}/${facilityId}/emissions/sub-region`,
                chart: 'sub-region'
            },
            {
                label: 'State',
                link: `/${projectId}/${facilityId}/emissions/state`,
                chart: 'state'
            },
        ];

        let selectedIndex;
        tabs.forEach((x, index) => {
            if (x.chart === selectedFacilitySubview) {
                selectedIndex = index;
            }
        });

        return (
            <AntTabs value={selectedIndex}>
                {tabs.map(tab => (
                    <Link to={tab.link} style={{textDecoration: 'none'}}  >
                        <AntTab label={tab.label} style={{padding: 0}} />
                    </Link>

                ))}
            </AntTabs>
        )

}

export default EmissionsTabs