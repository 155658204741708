// index.js
import React, {Component} from 'react';
import {StripeProvider} from 'react-stripe-elements';
import MyStoreCheckout from './MyStoreCheckout';


//Material UI
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {withStyles} from '@material-ui/core/styles';
import CustomInfoLayout from "../../../../components/CustomInfoLayout/CustomInfoLayout";

const styles = theme => ({
    root: {
        display: 'flex',
        width: '100%',
        paddingLeft: 15,
        paddingRight: 15
    },
    paper: {
        padding: 20,
        width: 600,
    }
});

class PaymentMethod extends Component {
    render() {
        const {classes} = this.props;
        return (
            
            <StripeProvider apiKey="pk_test_12345">

                    <CustomInfoLayout title={"Payment Method"} mainContent={<MyStoreCheckout />} />
               
                
            </StripeProvider>
        )
    }
}

export default withStyles(styles)(PaymentMethod);