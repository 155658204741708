import React from 'react'
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from '@material-ui/core/styles';
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import moment from "moment";
import 'moment-timezone';
import Table from "@material-ui/core/Table";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.default,
        paddingLeft: 10,
        paddingRight: 10,
        // paddingBottom: 70
    },
    table: {
        
    }

}));

export default function ProjectTableDetailPanel(props) {
    const {facility} = props;
    const classes = useStyles();
    
    return (
        <Grid container className={classes.root}>
            <Grid item  xs={12} lg={6}>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                Data Start Date
                            </TableCell>
                            <TableCell>
                                {facility.intervalDataStartDate ? moment.tz(facility.intervalDataStartDate, facility.timezone).format("MM/DD/YYYY  HH:mm") : null}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                Data End Date
                            </TableCell>
                            <TableCell>
                                {facility.intervalDataEndDate ? moment.tz(facility.intervalDataEndDate, facility.timezone).format("MM/DD/YYYY  HH:mm") : null}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                Peak Demand
                            </TableCell>
                            <TableCell>
                                {facility.maximumkWValue ? facility.maximumkWValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : null} kW
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                Annual kWh
                            </TableCell>
                            <TableCell>
                                {facility.annualKwhValue ? facility.annualKwhValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : null} kWh
                            </TableCell>
                        </TableRow>
                        
                    </TableBody>
                </Table>
            </Grid>
            <Grid item  xs={12} lg={6}>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                Utility
                            </TableCell>
                            <TableCell>
                                {facility.utility ? facility.utility : null}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                Account Number
                            </TableCell>
                            <TableCell>
                                {facility.accountNumber ? facility.accountNumber : null}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                Meter Number
                            </TableCell>
                            <TableCell>
                                {facility.meterNumber ? facility.meterNumber : null}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Grid>
        </Grid>
        
    )
}