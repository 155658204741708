import React from 'react'

// Material UI
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormLabel from '@material-ui/core/FormLabel';
import CustomOutlinedInput from "../../../components/Inputs/CustomOutlinedInput";

const useStyles = makeStyles(theme => ({

    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),

    },
    formControl: {
        margin: theme.spacing(1),
    },

}));

const CreateUserForm = (props) => {
    const { handleCreateUserInputChange, createUserFormControls, attemptedCreateUserSubmit } = props;
    const classes = useStyles();
    let keys = Object.keys(createUserFormControls);
    
    return (
        <form onSubmit={() => alert("submitted")}>
            <Grid container >

                {keys.map(key => (
                    <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}} key={key}>
                        <CustomOutlinedInput
                            label={createUserFormControls[key].label}
                            id="component-error"
                            value={createUserFormControls[key].value}
                            name={key}
                            onChange={handleCreateUserInputChange}
                            aria-describedby="component-error-text"
                            fullWidth
                            inputComponent={createUserFormControls[key].textMask ? createUserFormControls[key].textMask : 'input'}
                            error={!createUserFormControls[key].valid && attemptedCreateUserSubmit}
                        />
                    </Grid>
                ))}
                
            </Grid>
        </form>
    )
}



export default (CreateUserForm)