import React from 'react'
import { Link } from 'react-router-dom';



import { makeStyles } from '@material-ui/core/styles'



const useStyles = makeStyles((theme) => ({
    link: {
        color: theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.primary.dark,
            textDecoration: 'underline',
        }

    },
}));

function LinkText(props) {
    const { projectId, facilityId, text, object } = props;

    const classes = useStyles();

    if (projectId && facilityId) {
        return (
            <Link className={classes.link} to={`/${projectId}/${facilityId}/overview`}>{text}</Link>
        )
    } else {
    return (
        <Link className={classes.link} to={`/${projectId}`} >{text}</Link>
)
}
    
}

export default LinkText;