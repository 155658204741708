import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { Page, Text, View, Document, StyleSheet, PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import html2canvas from 'html2canvas';

import PdfTest from "./PdfTest";
import HighchartForPDF from "./HighchartForPDF";
import Button from "@material-ui/core/Button";

const styles = theme => ({
    root: {
        width: '100%',
        overflowX: 'auto',
    },
    table: {
        marginLeft: 10,
        marginRight: 10
    }
});

class Profile extends Component {
    state = {
        image: null
    };

    onClick = () => {
        let image;
        html2canvas(document.getElementById('highchart')).then(function (canvas) {
            document.body.appendChild(canvas);
            let a = document.createElement('a');
            a.href = canvas.toDataURL("image/jpeg").replace("image/jpeg", "image/octet-stream");
            image = a;

            canvas.download = 'somefilename.png';
            a.click();
        });
        debugger;
        this.setState({ image: image });
    };

    render() {
        let userInfo = JSON.parse(window.sessionStorage.user);
        const { classes } = this.props;

        return (

            <Grid container>
                <Table className={classes.table} id={'testCanvas'}>
                    <TableRow>
                        <TableCell>
                            First Name
                        </TableCell>
                        <TableCell>
                            {userInfo.firstName}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            Last Name
                        </TableCell>
                        <TableCell>
                            {userInfo.lastName}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            Email
                        </TableCell>
                        <TableCell>
                            {userInfo.username}
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell>
                            Phone Number
                        </TableCell>
                        <TableCell>
                            {userInfo.phoneNumber}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            Organization
                        </TableCell>
                        <TableCell>
                            {userInfo.organizationName}
                        </TableCell>
                    </TableRow>
                </Table>
                {/*<Button onClick={this.onClick}>*/}
                {/*    Test html2Canvas*/}
                {/*</Button>*/}
                {/*<PdfTest/>*/}
                {/*<img src={"https://d17fnq9dkz9hgj.cloudfront.net/breed-uploads/2018/09/dog-landing-hero-lg.jpg?bust=1536935129&width=1080"} />*/}
            </Grid>

        )
    }
}

export default withStyles(styles)(Profile)