import {
    SET_SNACKBAR
} from "../../actions/HomeActions/home-actions";

const snackbar = (state = {message: null, open: false, variant: "success"}, action) => {
    switch (action.type) {
        case SET_SNACKBAR:
            debugger;
            return action.payload;
    }
    return state
};


export { snackbar }