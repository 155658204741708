import React, {Component} from 'react'
import AntTabs from "../../../components/AntTabs/AntTabs";
import AntTab from "../../../components/AntTabs/AntTab";
import {Link} from 'react-router-dom';

class NavigationTabs extends Component {
    render() {
        const {selectedFacility} = this.props;
        const {projectId, facilityId} = selectedFacility;
        
        let tabs = [
            {
                label: 'Overview',
                link: `/${projectId}/${facilityId}/overview`,
                name: 'overview'
            },
            {
                label: 'Charts',
                link: `/${projectId}/${facilityId}/charts/load-curve`,
                name: 'charts'
            },
            {
                label: 'Weather',
                link: `/${projectId}/${facilityId}/weather/degree-days`,
                name: 'weather'
            },
            {
                label: 'Emissions',
                link: `/${projectId}/${facilityId}/emissions/summary`,
                name: 'emissions'
            },
            // {
            //     label: 'Regression',
            //     link: `/${projectId}/${facilityId}/regression`,
            //     name: 'regression'
            // },
            {
                label: 'Notes',
                link: `/${projectId}/${facilityId}/notes`,
                name: 'notes'
            },
            {
                label: 'Data',
                link: `/${projectId}/${facilityId}/data`,
                name: 'data'
            },
            // {
            //     label: 'Settings',
            //     link: `/${projectId}/${facilityId}/settings/dates`,
            //     name: 'settings'
            // },

        ];

        let selectedIndex;
        tabs.forEach((x, index) => {
            if (x.name === this.props.selectedFacilityView) {
                selectedIndex = index;
            }
        });
        
        return (
            <AntTabs value={selectedIndex} style={{padding: 0}}>
                {tabs.map(tab => (
                    <Link to={tab.link} >
     
                            <AntTab label={tab.label} style={{padding: 0}} />  
              
                        
                    </Link>
                    
                ))}
            </AntTabs>
        )
    }
}

export default NavigationTabs