/* eslint-disable */
import React, { Component, Fragment } from "react";

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button';

import { HotTable } from '@handsontable/react';


import DataViewLoadingSpinnger from "../spinners/DataViewLoadingSpinner";

const TableView = (props) => {
    const { chartTableSettings, exportDataAsCsv } = props;

    return (
        <Grid container style={{ padding: 5, paddingTop: 15 }}>
            <Grid item xs={12}>
                <Button color="secondary" onClick={exportDataAsCsv}>
                    Export Data as CSV
                </Button>
            </Grid>
            
                    
                    <Grid item xs={12}>
                        {
                            chartTableSettings === null ? <DataViewLoadingSpinnger /> 
                            : <HotTable settings={chartTableSettings}/>

                        }


            </Grid>
        </Grid>
        )
}

export default TableView