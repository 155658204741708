import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import AddCircleIcon from "@material-ui/icons/AddCircle";

const useStyles = makeStyles(theme => ({
    button: {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.success.contrast,
        borderRadius: 20,
        '&:hover': {
            backgroundColor: theme.palette.success.dark,
        }
    }
}));

export default function AddNewButton(props) {
    const classes = useStyles();
    const {onClick, text} = props;

    return (
        <Button
            className={classes.button}
            onClick={onClick}
        >
            <div
                style={{padding: '0 5px', display: 'flex', alignItems: 'center'}}>
                <AddCircleIcon/>
                <span style={{paddingLeft: 5}}>{text}</span>
            </div>
        </Button>
    );
}