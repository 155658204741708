import React from 'react';

//Forms
import ImportDataComponent from '../../../ImportData/ImportDataComponent'
import CustomDialog from "../../../components/Dialogs/customDialog";


function ImportDataDialog(props) {
    const { open, toggleDialog, importDataFacility } = props;

    return (
        <div>
            {importDataFacility === null ? null :
                <CustomDialog
                    open={open}
                    onClose={toggleDialog}
                    subTitle="IMPORT DATA"
                    title={importDataFacility.name}
                    content={<ImportDataComponent facilityId={importDataFacility.facilityId} projectId={importDataFacility.projectId} />}
                />
            }
        </div>


    );
}

export default ImportDataDialog;