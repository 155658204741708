import moment from 'moment'
import 'moment-timezone';

// function formatVerticalManualData(data, startDate, endDate, duration, handsontableSettings) {
//     let array = [];
//     // let durationInt = duration
//     // let date = startDate.clone();
//     for (let i = 0; i < data.length; i++) {
//         array.push({ DateTime: moment.utc(handsontableSettings.rowHeaders[i]), Value: checkDataValueType(data[i].kw) })
//     }
//     return array
// }

function formatVerticalManualData(data, startDate, endDate, duration, handsontableSettings, timezone) {
    let array = [];
    let daylightSavingsFallBackArray = [];
    let daylightSavingsFallBackDays = [
        "2010-11-07",
        "2011-11-06",
        "2012-11-04",
        "2013-11-03",
        "2014-11-02",
        "2015-11-01",
        "2016-11-06",
        "2017-11-05",
        "2018-11-04",
        "2019-11-03",
        "2020-11-01",
        "2021-11-07",
    ];
    
    
    for (let i = 0; i < data.length; i++) {
        let t = moment(handsontableSettings.rowHeaders[i]).format('YYYY-MM-DD HH:mm');
        let time = moment.tz(t, timezone);
        if (time.month() === 10 && daylightSavingsFallBackDays.includes(time.format("YYYY-MM-DD"))) {
            
            // if (time.isSame(moment.tz("2018-11-04", timezone), 'day')) {
                if (time.hour() === 1) {
                    let isSecondHour = daylightSavingsFallBackArray.find(x => {
                        return x === time.format();
                    });
                    if (isSecondHour) {
                        let p = time.utcOffset();
                        p -= 60;
                        time.utcOffset(p).add(1, 'hour');
                    }
                    daylightSavingsFallBackArray.push(time.format());
                }
            // }
        }
        array.push({ DateTime: time.format(), Value: checkDataValueType(data[i].kw) })
    }
    debugger;
    return array
}

// function formatHorizontalManualData(data, startDate, endDate, duration, handsontableSettings) {
//     let array = [];
//     let durationInt = duration;
//     let date = moment.utc(startDate.format("MM/DD/YYYY")).hour(0).minutes(0).seconds(0).millisecond(0);
//     for (let i = 0; i < data.length; i++) {
//         let day = data[i];
//         for (let j = 1; j <= day.length; j++) {
//             array.push({ DateTime: moment.utc(date).add(durationInt*j, 'minutes'), Value: checkDataValueType(day[j])})
//         }
//         date.add(1, 'day')
//     }
//     return array
// }


function formatHorizontalManualData(data, startDate, endDate, duration, handsontableSettings, timezone) {
    let array = [];
    let durationInt = duration;
    let date = moment.tz(startDate.format('YYYY-MM-DD HH:mm'), timezone).hour(0).minutes(0).seconds(0).millisecond(0);
    for (let i = 0; i < data.length; i++) {
        let day = data[i];
        for (let j = 0; j < day.length; j++) {
            let time = handsontableSettings.colHeaders[j].split(':');
            let hours = parseInt(time[0]);
            let minutes = parseInt(time[1]);
            let finalDate = date.clone().hours(hours).minutes(minutes);
            if (hours === 0 && minutes === 0) {
                finalDate.add(1, 'day')
            }
            array.push({ DateTime: finalDate.format(), Value: checkDataValueType(day[j])})
        }
        date.add(1, 'day')
    }
    debugger;
    return array
}

function checkDataValueType(value) {
    if (typeof value === 'number') {
        return value;
    } else {
        return null
    }
}


export { formatHorizontalManualData, formatVerticalManualData }