import React, {Component} from 'react'
import {bindActionCreators} from "redux";
import {getDegreeDaysRedux, setDegreeDayChartToUpdate} from "../../../redux/actions/weather-actions";
import { withStyles} from "@material-ui/core";
import {connect} from "react-redux";
import {regressionRedux} from "../../../redux/reducers/weather-reducers";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import {HotTable} from "@handsontable/react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import exportDataAsCsv from "../../js/exportDataAsCsv";
import RegressionDetails from "./CreateRegression/components/RegressionDetails";
import RegressionSummary from "./CreateRegression/components/RegressionSummary";
import MeterSectionTitleBar from "../components/MeterSectionTitleBar";
import Schedule from "./CreateRegression/components/Schedule";
import CreateRegression from "./CreateRegression";


class Regression extends Component {

    state = {
        chartOptions: null,
        handsontableSettings: null,
    };
    
    componentDidMount = () => {
        // let request = getDefaultDegreeDayRequestObjectFromFacility();
        this.props.getDegreeDaysRedux(this.props.selectedFacility);
    };
    
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.regressionRedux !== prevProps.regressionRedux) {
            let chartOptions = this.props.regressionRedux.getSummaryChartOptions();
            let handsontableSettings = this.props.regressionRedux.generateHandontableSettings();
            this.setState({chartOptions, handsontableSettings})
        }
    }
    
    exportCsv = () => {
        exportDataAsCsv(this.state.handsontableSettings)
    };

    render() {
        if (this.state.chartOptions) {
            return (
                <div>
                    <MeterSectionTitleBar title={"Regression"}/>
                    <Grid container>
                        <Grid item xs={3}>
                            <Grid container>
                                <div style={{width: '100%'}}>
                                    <RegressionSummary regression={this.props.regressionRedux.regression}/>
                                </div>
                                
                            </Grid>
                            
                        </Grid>
                        <Grid item xs={9}>
                            <Grid container>
                                <RegressionDetails regression={this.props.regressionRedux.regression}/>
                            </Grid>
                            
                        </Grid>
                    </Grid>
                    
                    

                    <Button onClick={this.exportCsv}>Export as CSV</Button>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={this.state.chartOptions}
                    />
                    <HotTable settings={this.state.handsontableSettings}
                              licenseKey='non-commercial-and-evaluation' width='auto'/>
                              <CreateRegression selectedFacility={this.props.selectedFacility} />
                </div>
            )
        } else {
            return 'Loading'
        }
        
    }
}

function mapStateToProps(state) {
    return {
        selectedFacilityView: state.selectedFacilityView,
        intervalData: state.intervalData,
        selectedFacility: state.selectedFacility,
        chartDataFilterFormObject: state.chartDataFilterFormObject,
        degreeDays: state.degreeDays,
        degreeDayChartToUpdate: state.degreeDayChartToUpdate,
        degreeDaysHandsontableSettings: state.degreeDaysHandsontableSettings,
        regressionRedux: state.regressionRedux
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setDegreeDayChartToUpdate,
        getDegreeDaysRedux
    }, dispatch);
};

// let DegreeDays = withStyles(styles)(DegreeDaysTemp);

export default connect(mapStateToProps, mapDispatchToProps)(Regression);

