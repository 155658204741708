import React, {Component} from 'react'
import moment from "moment";

//Material UI
import CustomSlider from "../../../../../../components/Sliders/CustomSlider";
import Typography from '@material-ui/core/Typography';
import FormLabel from '@material-ui/core/FormLabel';
import {withStyles} from "@material-ui/core";
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import orange from '@material-ui/core/colors/orange';

const styles = theme => ({
    root: {
        width: '100%',
    },
    slider: {
        color: orange[800],
    }
});



function valueLabelComponent(event) {
    const popperRef = React.useRef(null);
    React.useEffect(() => {
        if (popperRef.current) {
            popperRef.current.update();
        }
    });


    // let label = event.value;
    let label = moment().hour(0).minutes(0).seconds(0).add(event.value*15, 'minutes').format('HH:mm');

    return (
        <Tooltip
            PopperProps={{
                popperRef,
            }}
            open={event.open}
            enterTouchDelay={0}
            placement="top"
            title={<Typography>{label}</Typography>}
        >
            {event.children}
        </Tooltip>
    );
}


class ScheduleSlider extends Component {

    handleChange = (event, newValue) => {
        const {weekdays, weekends} = this.props.groups;
        const {day} = this.props;
        let schedule = {...this.props.schedule};
        let startHours = Math.floor(newValue[0] / 4);
        let start15Min = newValue[0] % 4;
        let endHours = Math.floor(newValue[1] / 4);
        let end15Min = newValue[1] % 4;
        
        if ((day === 0 || day === 6 || day === 7) && weekends) {
            schedule[0].startTime = moment().hours(startHours).minutes(start15Min * 15).seconds(0).milliseconds(0);
            schedule[0].endTime = moment().hours(endHours).minutes(end15Min * 15).seconds(0).milliseconds(0);
            schedule[6].startTime = moment().hours(startHours).minutes(start15Min * 15).seconds(0).milliseconds(0);
            schedule[6].endTime = moment().hours(endHours).minutes(end15Min * 15).seconds(0).milliseconds(0);
            schedule[7].startTime = moment().hours(startHours).minutes(start15Min * 15).seconds(0).milliseconds(0);
            schedule[7].endTime = moment().hours(endHours).minutes(end15Min * 15).seconds(0).milliseconds(0);
        } else if ((day > 0  && day < 6) && weekdays) {
            for (let i = 1; i < 6; i++) {
                schedule[i].startTime = moment().hours(startHours).minutes(start15Min * 15).seconds(0).milliseconds(0);
                schedule[i].endTime = moment().hours(endHours).minutes(end15Min * 15).seconds(0).milliseconds(0);
            }
        } else {
            schedule[this.props.day].startTime = moment().hours(startHours).minutes(start15Min * 15).seconds(0).milliseconds(0);
            schedule[this.props.day].endTime = moment().hours(endHours).minutes(end15Min * 15).seconds(0).milliseconds(0);
        }
        
        
        
        this.props.changeSchedule(schedule);
    };
    
    toggleDisabled = () => {
        const {day} = this.props;
        const {weekdays, weekends} = this.props.groups;
        let schedule = {...this.props.schedule};
        let bool = !schedule[day].unoccupiedDay;
        if ((day === 0 || day === 6 || day === 7) && weekends) {
            schedule[0].unoccupiedDay = bool;
            schedule[6].unoccupiedDay = bool;
            schedule[7].unoccupiedDay = bool;
        } else if ((day > 0  && day < 6) && weekdays) {
            for (let i = 1; i < 6; i++) {
                schedule[i].unoccupiedDay = bool;
            }
        } else {
            schedule[day].unoccupiedDay = bool;
        }
        
        this.props.changeSchedule(schedule);
        
    }


    render() {
        const {classes, schedule, day} = this.props;
        let defaultStart = schedule[day].startTime.hours()*4 + schedule[day].startTime.minutes()/15;
        let defaultEnd = schedule[day].endTime.hours()*4 + schedule[day].endTime.minutes()/15;
        let defaultValue = [defaultStart, defaultEnd];

        return (
                <Grid container className={classes.root}>
                    <Grid item lg={3}>
                        <Typography>{schedule[day].label}</Typography>
                    </Grid>
                    <Grid item lg={3}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={schedule[day].unoccupiedDay}
                                    onChange={this.toggleDisabled}
                                    value="disabled"
                                    color="secondary"
                                />
                            }
                            label="Unoccupied Day"
                        />
                    </Grid>
                    <Grid item lg={3}>
                        {schedule[day].unoccupiedDay ?
                            <Typography>Unoccupied</Typography>
                        : <Typography>{schedule[day].startTime.format("HH:mm A")} - {schedule[day].endTime.format("HH:mm A")}</Typography>
                        }
                        
                    </Grid>
                    <Grid item lg={3}>
                        <CustomSlider
                            className={classes.slider}
                            value={defaultValue}
                            aria-labelledby="discrete-slider"
                            color={"secondary"}
                            valueLabelDisplay="auto"
                            ValueLabelComponent={valueLabelComponent}
                            onChange={this.handleChange}
                            step={1}
                            min={1}
                            max={96}
                            disabled={schedule[day].unoccupiedDay}
                        />
                    </Grid>
                
                
                
                </Grid>
        )
    }
}

export default withStyles(styles)(ScheduleSlider)