import createHandsontableSettingsFromData from "../createHandsontableSettingsFromData";
import React from 'react'
import regressionTest from "../../FacilityHome/components/Regression/regressionTest";

class DegreeDaysClass {
    constructor(data) {
        this.data = data;
        this.summaryChartData = null;
    };

    setSummaryChartData = () => {
        this.summaryChartData = 'the test worked!'
    };


    generateHandontableSettings = () => {
        return createHandsontableSettingsFromData(this.data, 'weather-degree-days')
    };
    
    getRegression = () => {
        const temp = this.data;
        let degreeDayData = this.data.degreeDays;
        let inputArray = [];
        let outputArray = [];
        degreeDayData.forEach(day => {
            if (day.heatingDegreeDays !== null && day.coolingDegreeDays !== null && day.sumKwh !== null) {
                inputArray.push([day.coolingDegreeDays, day.heatingDegreeDays]);
                outputArray.push([day.sumKwh])
            }
        });
        let regression = regressionTest(inputArray, outputArray);
        return regression
    };

    getChartOptions = (chartType) => {
        let height = chartType === 'column' ? '600px' : '1200px';
        // let data = this.props.degreeDays.degreeDays;
        const {heatingDegreeBase, coolingDegreeBase, degreeDays} = this.data;
        let dates = [];
        let cdd = [];
        let hdd = [];
        let demand = [];
        degreeDays.forEach(interval => {
            dates.push(interval.name);
            cdd.push(interval.coolingDegreeDays);
            hdd.push(interval.heatingDegreeDays);
            demand.push(interval['maxKw'] ? interval.maxKw : null)
        });

        return {
            chart: {
                type: chartType,
                zoomType: 'x',
                height: height,
                style: {
                    fontFamily: "Roboto"
                },
            },
            title: {
                // text: 'Degree Days'
                text: ''
            },
            subtitle: {
                // text: 'CDD Base: ' + coolingDegreeBase + '°F | HDD Base: ' + heatingDegreeBase + '°F'
                text: ''
            },
            xAxis: {
                categories: dates,
                crosshair: true
            },
            yAxis: [{
                // min: 0,
                title: {
                    text: 'Days'
                }
            },
                {
                    title: {
                        text: 'Peak Demand (kW)'
                    },
                    opposite: true
                }],


            tooltip: {
                // headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                // pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                //     '<td style="padding:0"><b>{point.y:.1f} days</b></td></tr>',
                // footerFormat: '</table>',
                formatter: function () {
                    let temp = this;
                    // debugger;
                    let points = this.points;
                    let header = points[0].x;
                    let rows = [];
                    points.forEach(point => {
                        rows.push('<br/>' + point.series.userOptions.name + ': ' + '<span style="color:' + point.series.color + '; font-weight: 600">' + point.y.toFixed(2) + point.series.userOptions.suffix + '</span>')
                    });
                    let final = header;
                    rows.forEach(row => {
                        final += row;
                    })
                  
                    return final

                },
                shared: true,
                useHTML: true
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            series: [{
                name: 'CDD',
                data: cdd,
                yAxis: 0,
                suffix: ' CDD'

            }, {
                name: 'HDD',
                data: hdd,
                color: '#EF5350',
                yAxis: 0,
                suffix: ' HDD'

            }, {
                type: 'line',
                name: 'Peak Demand',
                data: demand,
                color: '#4caf50',
                yAxis: 1,
                suffix: ' kW'

            }],
            credits: {
                enabled: false
            }
        };
    }

}

export default DegreeDaysClass;