import React, {Component} from "react";
import {bindActionCreators} from "redux";
import {selectFacility, selectFacilityView, toggleFacilitySideBar} from "../../../../../redux/actions/facility-actions";
import {addFacilitiesToState, selectProject} from "../../../../../redux/actions/project-actions";
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core";
import {getDegreeDaysRedux, setDegreeDayChartToUpdate, clearDegreeDaysRedux, setRawWeatherDataChartOptions} from "../../../../../redux/actions/weather-actions";
import {getFacilityWeather, getFacilityWeatherRequestObject} from "../../../../../services/intervalData.service";
import createHandsontableSettingsFromData from "../../../../../js/createHandsontableSettingsFromData";
import moment from "moment";
import Highcharts from "highcharts/highstock";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import InputLabel from '@material-ui/core/InputLabel';

import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from "@material-ui/core/OutlinedInput";
import {rawWeatherDataChartOptions} from "../../../../../redux/reducers/weather-reducers";


const styles = theme => ({

});


class RawWeatherDataSidebar extends Component {

    state = {
        handsontableSettings: null,
        selectedDataType: 'temperature',
        dataTypes: [
            {
                label: 'Temperature',
                value: 'temperature',
                unit: '°F',
                axisLabel: 'Temperature (°F)'
            },
            {
                label: 'Humidity',
                value: 'humidity',
                unit: '%',
                axisLabel: 'Humidity (%)'
            },
            {
                label: 'Dew Point',
                value: 'dewpoint',
                unit: '°F',
                axisLabel: 'Dew Point (°F)'
            },
            {
                label: 'Precipitation',
                value: 'precipitation',
                unit: ' mm',
                axisLabel: 'Precipitation (mm)'
            },
            {
                label: 'Wind Speed',
                value: 'windSpeed',
                unit: ' km/h',
                axisLabel: 'Wind Speed (km/h)'
            },
            {
                label: 'Pressure',
                value: 'pressure',
                unit: ' hPa',
                axisLabel: 'Pressure (hPa)'
            },

        ]
    };

    componentDidMount = () => {
        this.props.setRawWeatherDataChartOptions(this.state.selectedDataType)
    };

    changeDataType = (event) => {
        this.setState({selectedDataType: event.target.value}, () => {
            this.props.setRawWeatherDataChartOptions(this.state.selectedDataType)
        })
    }

    render() {

        const {classes} = this.props;
        return (
            <div style={{width: '100%', padding: 5, paddingTop: 15 }}>
                <div style={{padding: 5, width: '100%', margin: 5}}>
                    <FormControl variant="outlined" style={{width: '100%', paddingRight: 5}}>
                        <InputLabel id="demo-simple-select-outlined-label">
                            Data Type
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            input={<OutlinedInput labelWidth={80}/>}
                            value={this.state.selectedDataType}
                            // value={age}
                            onChange={this.changeDataType}
                        >
                            {this.state.dataTypes.map(x => (
                                <MenuItem value={x.value}>{x.label}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        rawWeatherDataChartOptions: state.rawWeatherDataChartOptions
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setRawWeatherDataChartOptions
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)((RawWeatherDataSidebar)));

