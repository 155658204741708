import React, { Component, Fragment } from 'react';

// Material UI
import { makeStyles, withStyles } from '@material-ui/core/styles';


import FacilitiesTable from '../containers/FacilitiesTable'

import {Grid, Typography} from "@material-ui/core";
import {green, orange} from '@material-ui/core/colors';




import PulseLoader from '../../components/Spinners/pulse-loader'
import ProjectInfo from './cards/ProjectInfo'
import FacilitiesChartTable from './charts/FacilitiesChartTable'
import FacilitiesSteamGraph from './charts/FacilitiesSteamGraph'
import AddNewButton from "../../components/CustomButtons/AddNewButton";


import CreateFacilityDialog from './dialogs/CreateFacilityDialog'
import EditFacilityDialog from './dialogs/EditFacilityDialog'
import ImportDataDialog from './dialogs/ImportDataDialog'
import DeleteFacilityDialog from './dialogs/DeleteFacilityDialog'
import CustomInfoLayout from "../../components/CustomInfoLayout/CustomInfoLayout";


import CustomSnackbar from '../../components/Snackbar/CustomSnackbar'
import EmptyGif from "../../components/EmptyGif/EmptyGif";
import MeterTestGif from '../../assets/gifs/meter3.gif'
import ProjectsTable from "../../Dashboard/containers/ProjectsTable";
import projectsGif from "../../assets/gifs/projects.gif";
import DataViewLoadingSpinner from "../../FacilityHome/components/spinners/DataViewLoadingSpinner";
import Icon from "@material-ui/core/Icon";
import GreenButton from "../../components/CustomButtons/GreenButton";



const styles = theme => ({

    paper: {
        margin: 20,
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        color: theme.palette.common.white,
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[600],
        }
    },
});



class ProjectHome extends Component {
    state = {
        tabs: [
            {
                label: "Table",
                value: "table",
            },
            {
                label: "Chart",
                value: 'chart',
            }
        ],
        currentTab: 0,
        currentComponent: <FacilitiesTable 
            importDataDialogOpen={ this.props.importDataDialogOpen }
            toggleImportDataDialog={this.props.toggleImportDataDialog }
            toggleEditFacilityDialog={this.props.toggleEditFacilityDialog }
            toggleDeleteFacilityDialog={this.props.toggleDeleteFacilityDialog }
            />
    }

    setTab = (value) => {
        let currentComponent;
        switch (value) {
            case 0:
                currentComponent = <FacilitiesTable 
                    importDataDialogOpen={this.props.importDataDialogOpen}
                    toggleImportDataDialog={this.props.toggleImportDataDialog}
                    toggleEditFacilityDialog={this.props.toggleEditFacilityDialog}
                    toggleDeleteFacilityDialog={this.props.toggleDeleteFacilityDialog}
                />
                break;
            case 1:
                currentComponent = <FacilitiesChartTable facilities={this.props.facilities} />
                break
        }

        this.setState({ currentComponent, currentTab: value });

    }

    render() {

    const {
         // Classes
        classes,

        // Project
        loading,
        project,
        facilities,
        toggleEditProjectDialog,
        fetchingFacilities,

        // Create Facility
        createFacilityDialogOpen,
        toggleCreateFacilityDialog,
        createFacilityFormControls,
        handleCreateFacilityInputChange,
        onCreateFacilitySubmit,
        attemptedCreateFacilitySubmit,

        //edit facility
        editFacilityDialogOpen,
        toggleEditFacilityDialog,
        editFacilityFormControls,
        handleEditFacilityInputChange,
        onEditFacilitySubmit,
        attemptedEdotFacilitySubmit,
        selectedFacilityForEdit,

        // Import Data
        importDataDialogOpen,
        toggleImportDataDialog,
        importDataFacility,

        // Delete Facility
        selectedFacilityForDelete,
        deleteFacilityDialogOpen,
        onDeleteFacilitySubmit,
        toggleDeleteFacilityDialog,

        // Snackbar
        snackbar,
        openSnackbar

    } = this.props;
    
    return (
        <div className={classes.root}>
            <CustomSnackbar
                snackbar={snackbar}
                openSnackbar={openSnackbar}
            />

            <Grid container >
                <Grid item xs={12} md={4}>
                    <CustomInfoLayout
                        title="Project Information"
                        topRightContent={null}
                        mainContent={<ProjectInfo project={project} toggleEditProjectDialog={toggleEditProjectDialog}/>}
                    />
                </Grid>
                <Grid item xs={12} md={8}>
                    {/*{!facilities || fetchingFacilities ?*/}
                    {/*    <Grid container*/}
                    {/*        spacing={0}*/}
                    {/*        direction="column"*/}
                    {/*        alignItems="center"*/}
                    {/*        justify="center"*/}
                    {/*        padding={20}>*/}
                    {/*        <Grid item>*/}
                    {/*            <PulseLoader />*/}
                    {/*        </Grid>*/}
                    {/*    </Grid>*/}
                    
                    {/*    : facilities.length === 0 && !fetchingFacilities ? */}
                    {/*        <EmptyGif*/}
                    {/*            gif={MeterTestGif}*/}
                    {/*            title={'No Meters Yet'}*/}
                    {/*            message={'No meters have been added yet.'}*/}
                    {/*            buttonText={'Add Meter'}*/}
                    {/*            onClick={toggleCreateFacilityDialog}*/}
                    {/*        /> :*/}
                    {/*    */}
                    {/*    <CustomInfoLayout */}
                    {/*        title="Meters" */}
                    {/*        topRightContent={<AddNewButton onClick={toggleCreateFacilityDialog} text={'Create New Meter'}/>}*/}
                    {/*        mainContent={<FacilitiesTable*/}
                    {/*            importDataDialogOpen={this.props.importDataDialogOpen}*/}
                    {/*            toggleImportDataDialog={this.props.toggleImportDataDialog}*/}
                    {/*            toggleEditFacilityDialog={this.props.toggleEditFacilityDialog}*/}
                    {/*            toggleDeleteFacilityDialog={this.props.toggleDeleteFacilityDialog}*/}
                    {/*        />}*/}
                    {/*    />*/}
                    
                    
                    {/*}*/}
                    {!fetchingFacilities && facilities.length !== 0 ?
                        <Grid item xs={12}>
                            <CustomInfoLayout
                                title="Meters"
                                topRightContent={<div>
                                    {/*<AddNewButton onClick={toggleCreateFacilityDialog} text={'Create New Meter'}/>*/}
                                    <GreenButton onClick={toggleCreateFacilityDialog}><Icon style={{marginRight: 5}}>add_circle</Icon>Add Meter</GreenButton>
                                </div>}
                                mainContent={<FacilitiesTable
                                    importDataDialogOpen={this.props.importDataDialogOpen}
                                    toggleImportDataDialog={this.props.toggleImportDataDialog}
                                    toggleEditFacilityDialog={this.props.toggleEditFacilityDialog}
                                    toggleDeleteFacilityDialog={this.props.toggleDeleteFacilityDialog}
                                />}
                            />

                        </Grid> : !fetchingFacilities && facilities.length === 0 ?
                            <Grid item xs={12}>
                                <Grid container>
                                    <EmptyGif
                                        gif={MeterTestGif}
                                        title={'No Meters Yet'}
                                        message={'No meters have been added yet.'}
                                        buttonText={'Add Meter'}
                                        onClick={toggleCreateFacilityDialog}
                                    />
                                </Grid>

                            </Grid>
                            :
                            <Grid item xs={12}>
                                <Grid container justify={"center"}>
                                    <DataViewLoadingSpinner message={'Fetching facilities...'}/>
                                </Grid>
                            </Grid>
                    }

                </Grid>


            </Grid>



            <CreateFacilityDialog
                loading={loading}
                createFacilityDialogOpen={createFacilityDialogOpen}
                toggleCreateFacilityDialog={toggleCreateFacilityDialog}
                createFacilityFormControls={createFacilityFormControls}
                handleCreateFacilityInputChange={handleCreateFacilityInputChange}
                onCreateFacilitySubmit={onCreateFacilitySubmit}
                attemptedCreateFacilitySubmit={attemptedCreateFacilitySubmit}
            />

            <EditFacilityDialog />

            <DeleteFacilityDialog
                selectedFacilityForDelete={selectedFacilityForDelete}
                deleteFacilityDialogOpen={deleteFacilityDialogOpen}
                onDeleteFacilitySubmit={onDeleteFacilitySubmit}
                toggleDeleteFacilityDialog={toggleDeleteFacilityDialog}
            />

            <ImportDataDialog open={importDataDialogOpen} toggleDialog={toggleImportDataDialog} importDataFacility={importDataFacility}/>


        </div>
        );
    }
}

export default withStyles(styles)(ProjectHome)