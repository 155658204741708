import React, {Component} from 'react'
import { HotTable } from '@handsontable/react';
import Handsontable from 'handsontable';
import RegularButton from "../../components/CustomButtons/Button";

class UploadHandsontable extends Component {
    constructor(props) {
        super(props);
        this.hotTable = React.createRef();
    }

    // componentDidMount = () => {
    //     this.hotTable.current.hotInstance.validateCells();
    // }
    
    test = () => {
        
        this.hotTable.current.hotInstance.validateCells(() => {
            debugger;
        });

    }

    render() {
        const {settings} = this.props;
        return (
            <div>
                <HotTable ref={this.hotTable} settings={settings}/>
            </div>
        )
    }
}

export default UploadHandsontable