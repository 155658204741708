import {getDegreeDaysRequestObject} from "../services/intervalData.service";
import moment from 'moment'
import 'moment-timezone'

const getDefaultDegreeDayRequestObjectFromFacility = (selectedFacility, cddBase, hddBase) => {
    let degreeDaysRequest = { ...getDegreeDaysRequestObject };
    degreeDaysRequest.FacilityId = selectedFacility.facilityId;
    degreeDaysRequest.StartDate = selectedFacility.intervalDataStartDate;
    // if the facility enddatetime is at 23:59:59.99999 it screws up the call, so just add a minute and set seconds to 0
    degreeDaysRequest.EndDate = moment.tz(selectedFacility.intervalDataEndDate, selectedFacility.timezone).seconds(0).add(1, 'minute').format();
    degreeDaysRequest.CoolingDegreeBase = cddBase;
    degreeDaysRequest.HeatingDegreeBase = hddBase;
    return degreeDaysRequest
}

export {getDefaultDegreeDayRequestObjectFromFacility}