import React, {Component} from 'react'
import Grid from '@material-ui/core/Grid'
import CustomInfoLayout from "../../../components/CustomInfoLayout/CustomInfoLayout";
import ChangePasswordForm from "./ChangePasswordForm";
import Profile from "../AccountSummary/Profile";

class PasswordManagement extends Component {
    render() {
        return (


            <Grid container style={{display: "flex", justifyContent: 'center'}}>
                <Grid item xs={12} style={{maxWidth: 500}}>
                    <CustomInfoLayout title="Change Password" mainContent={<ChangePasswordForm/>}/>
                </Grid>
            </Grid>


        )
    }
}

export default PasswordManagement