import React, {Component} from 'react'
import {Link} from "react-router-dom";
import AntTab from "../../../components/AntTabs/AntTab";
import AntTabs from "../../../components/AntTabs/AntTabs";

function AccountTabs(props) {


    let tabs = [
        {
            label: 'Home',
            link: `/account/home`,
            name: 'home'
        },
        {
            label: 'Password',
            link: `/account/password`,
            name: 'password'
        },
        {
            label: 'Subscription',
            link: `/account/subscription`,
            name: 'subscription'
        },
        {
            label: 'Team',
            link: `/account/team`,
            name: 'team'
        },
        
    ];

    let selectedIndex;
    tabs.forEach((x, index) => {
        if (x.name === props.currentComponent) {
            selectedIndex = index;
        }
    });

    return (
        <AntTabs value={selectedIndex} centered={true}>
            {tabs.map(tab => (
                <Link to={tab.link} style={{textDecoration: 'none'}}  >
                    <AntTab label={tab.label} style={{padding: 0}} />
                </Link>

            ))}
        </AntTabs>
    )

}

export default AccountTabs