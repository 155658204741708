import React, {Component, Fragment} from 'react'
import {
    getIntervalDataSummaryChartByFacilityId,
    intervalDataRequestObject
} from "../../../../../services/intervalData.service";
import moment from "moment";
import 'moment-timezone'
import {HotTable} from "@handsontable/react";
import Handsontable from 'handsontable';

// Material UI
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

class MonthlySetup extends Component {
    constructor(props) {
        super(props);
        this.hotTableComponent = React.createRef();
    }
    
    state = {
        billingDate: null,
        billingDateSelectArray: null,
        monthBlockTableSettings: null,
        dailyData: null,
        monthBlocks: null,
    };
    
    componentDidMount = () => {
        let billingDateSelectArray = this.getBillingDateSelectArray();
        this.setState({billingDateSelectArray});
        this.getDailySummaryData();
        Handsontable.hooks.add('afterChange', (event) => this.editMonthBlocks(event));
        
    };
    
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.dailyData === null && this.state.dailyData !== null) {
            this.buildDefaultMonthBlocks();
        }
    };
    
    editMonthBlocks = (event) => {
        // event = [row, column, oldValue, newValue]
        const {selectedFacility} = this.props;
        if (event !== null) {
            let array = event[0];
            let row = array[0];
            let column = array[1];
            let newValue = array[3];
            let monthBlocks = [...this.state.monthBlocks];
            if (column === 0) {
                monthBlocks[row].startDate = moment.tz(newValue, selectedFacility.timezone);
                if (row > 0)
                    monthBlocks[row - 1].endDate = moment.tz(newValue, selectedFacility.timezone).subtract(1, 'day');
            };
            if (column === 1) {
                monthBlocks[row].endDate = moment.tz(newValue, selectedFacility.timezone);
                monthBlocks[row + 1].startDate = moment.tz(newValue, selectedFacility.timezone).add(1, 'day');
            };
            this.buildTableWithMonthblocks(monthBlocks);
        }
        
    }

    handleBillingDateChange = (event) => {
        let billingDate = event.target.value;
        this.setState({billingDate}, () => this.buildDefaultMonthBlocks())
    };

    getBillingDateSelectArray = () => {
        let array = [];
        for (let i = 1; i < 29; i++) {
            array.push(i)
        };
        return array;
    }
    
    buildDefaultMonthBlocks = () => {
        const {selectedFacility} = this.props;
        const {intervalDataStartDate, intervalDataEndDate} = selectedFacility;
        let monthBlocks = [];
        let startDate = moment();
        let endDate = moment();
        if (this.state.billingDate === null) {
            let duration = moment.tz(intervalDataEndDate, selectedFacility.timezone).diff(moment.tz(intervalDataStartDate, selectedFacility.timezone), 'months') + 1;
            for (let i = 0; i < duration; i++ ) {
                    startDate = moment.tz(intervalDataStartDate, selectedFacility.timezone).add(i, 'months');
                if (startDate.clone().add(1, 'month') > moment.tz(intervalDataEndDate, selectedFacility.timezone)) {
                    endDate = moment.tz(intervalDataEndDate, selectedFacility.timezone);
                } else {
                    endDate = startDate.clone().add(1, 'month').subtract(1, 'day');
                };
                monthBlocks.push({
                    startDate,
                    endDate,
                });
            };

        } else {
            let duration = moment.tz(intervalDataEndDate, selectedFacility.timezone).diff(moment.tz(intervalDataStartDate, selectedFacility.timezone), 'months') + 1;
            let previousStartDate = moment();
            let previousEndDate = moment();
            let done = false;
            
            for (let j = 0; !done; j++) {
                if (j === 0) {
                    startDate = moment.tz(intervalDataStartDate, selectedFacility.timezone);
                    if (moment.tz(intervalDataStartDate, selectedFacility.timezone).date() <= this.state.billingDate) {
                        endDate = moment.tz(intervalDataStartDate, selectedFacility.timezone).date(this.state.billingDate);
                    } else {
                        endDate = moment.tz(intervalDataStartDate, selectedFacility.timezone).add(1, 'month').date(this.state.billingDate)
                    }
                } else if (previousEndDate.clone().add(1, 'month').isAfter(moment.tz(intervalDataEndDate, selectedFacility.timezone))) {
                    endDate = moment.tz(intervalDataEndDate, selectedFacility.timezone);
                    startDate = moment.tz(previousEndDate, selectedFacility.timezone).add(1, 'day');
                    done = true;
                } else {
                    startDate = moment.tz(previousEndDate, selectedFacility.timezone).add(1, 'day');
                    endDate = moment.tz(previousEndDate, selectedFacility.timezone).add(1, 'month');
                }

                monthBlocks.push({
                    startDate,
                    endDate,
                });
                previousStartDate = startDate;
                previousEndDate = endDate;
            };
        };
        this.buildTableWithMonthblocks(monthBlocks)
    };
    
    buildTableWithMonthblocks = (monthBlocks) => {
        this.setState({monthBlocks});
        monthBlocks = this.applyDataToMonth(monthBlocks);
        let monthBlockTableSettings = this.buildMonthTable(monthBlocks);
        this.setState({monthBlockTableSettings})
    };
    
    applyDataToMonth = (monthBlocks) => {
        const {selectedFacility} = this.props;
        monthBlocks.forEach(x => {
            let total = 0;
            this.state.dailyData.forEach(y => {
                // let startDate = x.startDate;
                // let endDate = x.endDate;
                // let intervalDay = y.moment.add(1, 'hour');
                if (moment.tz(y.moment, selectedFacility.timezone).add(1, 'hour') >= moment.tz(x.startDate, selectedFacility.timezone) && moment.tz(y.moment, selectedFacility.timezone) <= moment.tz(x.endDate, selectedFacility.timezone)) {
                    total += y.sumKwh;
                }
            });
            x['totalKwh'] = total;
        });
        return monthBlocks;
    };
    
    getDailySummaryData = () => {
        const {selectedFacility} = this.props;
        let request = { ...intervalDataRequestObject };

        request.ProjectId = selectedFacility.projectId;
        request.FacilityId = selectedFacility.facilityId;
        // request.ChartType = 'summary-chart';
        // request.DaysOfWeek = [1,2,3,4,5,6,7];
        request.Months = [1,2,3,4,5,6,7,8,9,10,11,12];
        request.SeriesType = ['Average'];
        request.DataType = 'kw';
        request.SeriesPer = 'day';
        request.StartDate = moment.tz(selectedFacility.intervalDataStartDate, selectedFacility.timezone);
        request.EndDate = moment.tz(selectedFacility.intervalDataEndDate, selectedFacility.timezone);
        

        getIntervalDataSummaryChartByFacilityId(request).then(data => {
            data.forEach(x => {
                x['moment'] = moment.tz(x.name, selectedFacility.timezone)
            });
            this.setState({dailyData: data})
        });
    };
    
    buildMonthTable = (data) => {
        const {selectedFacility} = this.props;
        let colHeaders = ['Start Date','End Date', 'Consumption (kWh)','# of Days'];
        let rowHeaders = null;
        let dataForTable = [];
        let columns = [];

        data.forEach(series => {
            // rowHeaders.push(series.name);
            dataForTable.push([series.startDate.format('MM/DD/YYYY'), series.endDate.format('MM/DD/YYYY'), series.totalKwh]);
        });
        
        // this.setState({dataForTable})

        // colHeaders.forEach((header, index) => columns.push({ data: index, type: 'numeric' }))

        columns = [
            {
                data: 0,
                type: 'date',
                correctFormat: true,
                dateFormat: 'MM/DD/YYYY',
                datePickerConfig: {
                    minDate: moment.tz(selectedFacility.intervalDataStartDate, selectedFacility.timezone).toDate()
                }
            },
            {
                data: 1,
                type: 'date',
                dateFormat: 'MM/DD/YYYY',
            },
            {
                data: 2,
                type: 'numeric',
                numericFormat: {
                    pattern: '0,0.00',
                },
            },
        ];

        return {
            colHeaders,
            rowHeaders,
            data: dataForTable,
            columns,
            width: 'auto',
            height: 500,
            rowHeaderWidth: 180,
            licenseKey: 'non-commercial-and-evaluation',
            stretchH: 'all',
        }
    }

    
    render() {
        return (
            <Grid container>
                <Grid item xs={12} lg={4}>
                    {this.state.billingDateSelectArray ? 
                        <Fragment>
                    <InputLabel htmlFor="billingDateSelector">Billing Date</InputLabel>
                    <Select
                        value={this.state.billingDate}
                        onChange={this.handleBillingDateChange}
                        displayEmpty
                        name="age"
                        // className={classes.selectEmpty}
                    >
                        {this.state.billingDateSelectArray.map(x => <MenuItem value={x}>{x}</MenuItem>)}
                    </Select>
                        </Fragment> : null}
                </Grid>
                <Grid item xs={12} lg={4}>
                    {this.state.monthBlockTableSettings ? <HotTable ref={this.hotTableComponent} settings={this.state.monthBlockTableSettings}/> : 'Loading'}
                </Grid>
            </Grid>
        )
    }
}

export default MonthlySetup