

import { EDIT_PROFILE_PROPERTY, GET_ORGANIZATION_USERS, SET_ACTIVE_USER} from '../actions/user-actions'
import {PROJECT_SELECTED} from "../actions/project-actions";

const editedUser = (state = {}, action) => {
    switch (action.type) {
        case EDIT_PROFILE_PROPERTY:
        const { key, value, userObject } = action.payload;
            let editedUser = { ...userObject };
            //editedUser[key] = value;
            if (key !== 'temp') {
                editedUser[key] = value;
            }
            
        return editedUser;
    }
    return state;
};

const usersOfOrganization = (state = null, action) => {
    switch (action.type) {
        case GET_ORGANIZATION_USERS:
            return action.payload;
    }
    return state
};

const activeUser = (state = null, action) => {
    
    switch (action.type) {
        case SET_ACTIVE_USER:
            return action.payload;
    }
    return state
}

export { editedUser, usersOfOrganization, activeUser }