import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';

// Handsontable
import { HotTable } from '@handsontable/react';

// Moment
import moment from 'moment'
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

const getImage = (duration, season) => {
    switch (duration) {
        case '15':
            if (season === 'spring') {
                return <img src={require('../../assets/img/FormatDataHelpers/Spring15min.png')} alt="Daylight Savings Spring" style={{width: '100%', padding: 20}} />
            } else {
                return <img src={require('../../assets/img/FormatDataHelpers/Fall15min.png')} alt="Daylight Savings Fall" style={{width: '100%', padding: 20}} />
            }
        case '30':
            if (season === 'spring') {
                return <img src={require('../../assets/img/FormatDataHelpers/Spring30min.png')} alt="Daylight Savings Spring" style={{width: '100%', padding: 20}} />
            } else {
                return <img src={require('../../assets/img/FormatDataHelpers/Fall30min.png')} alt="Daylight Savings Fall" style={{width: '100%', padding: 20}} />
            }
        case '60':
            if (season === 'spring') {
                return <img src={require('../../assets/img/FormatDataHelpers/Spring60min.png')} alt="Daylight Savings Spring" style={{width: '100%', padding: 20}} />
            } else {
                return <img src={require('../../assets/img/FormatDataHelpers/Fall60min.png')} alt="Daylight Savings Fall" style={{width: '100%', padding: 20}} />
            }
    }
}

class Step3Manual extends Component {

    render() {
        const {orientation, duration} = this.props;
        if (orientation === 'horizontal') {
            return (
                <Fragment>
                    <Typography>*Time Periods are Interval Ending</Typography>
                    <HotTable settings={this.props.handsontableSettings}/>
                </Fragment>
            );
        } else if (orientation === 'vertical') {
            return (
                <Grid container>
                    <Grid item md={6}>
                    <Typography>*Time Periods are Interval Ending</Typography>
                    <HotTable settings={this.props.handsontableSettings}/>
                    </Grid>
                    <Grid item md={6} style={{padding: 20, maxHeight: 500, overflow: 'auto'}}>
                        <div style={{textAlign: 'center'}}>
                            <Typography variant="h5">NOTE: Daylight Savings</Typography>
                        </div>
                        
                        <Typography style={{paddingTop: 10}}>
                            Daylight savings is handled differently in many vertical data files. Before copying and pasting your data in, check to make sure the format of your
                            data matches that of our table here. 
                        </Typography>
                        <Typography variant="h6" style={{paddingTop: 10, fontWeight: 700}}>
                            Spring
                        </Typography>
                        <Typography>
                            In the spring the time jumps forward 1 hour. This table will jump from the last interval in the 1:00am hour to the 3:00 hour.
                        </Typography>
                        {getImage(duration, 'spring')}
                        <Typography variant="h6" style={{paddingTop: 10, fontWeight: 700}}>
                            Fall
                        </Typography>
                        <Typography>
                            In the fall the time falls back 1 hour. That means there are (2) 1:00am - 2:00am hours. 
                        </Typography>
                        {getImage(duration, 'fall')}
                    </Grid>
                </Grid>
            );
        }
        
    }
}

export default Step3Manual