import React, {Component} from 'react'
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import Grid from "@material-ui/core/Grid";
import MeterSectionTitleBar from "../components/MeterSectionTitleBar";
import GhgMeter from "./components/Meter";
import EmissionsTabs from "./components/Tabs";
import {setEmissionsObject, toggleEmissionsIncludeLosses, handleEmissionsStateVsRegionChange} from "../../../redux/actions/EmissionsActions/emissions-actions";

class Emissions extends Component {
    
    componentDidMount = () => {
        const {selectedFacility} = this.props;
        if (selectedFacility.postalCode) {
            // let ghg = new GhgClass(selectedFacility.postalCode);
            this.props.setEmissionsObject()
        }
    };

    toggleIncludeLosses = () => {
        this.props.toggleEmissionsIncludeLosses(!this.props.emissionsIncludeLosses);
        this.props.setEmissionsObject()
    };

    handleStateVsRegionChange = (event) => {
        this.props.handleEmissionsStateVsRegionChange(event.target.value);
        this.props.setEmissionsObject()
    };
    
    render() {
        const {selectedFacility, selectedFacilitySubview} = this.props;
        return (
            <Grid container>
                <MeterSectionTitleBar title={"Emissions"}/>
                {/*<div style={{width: '100%'}}>*/}
                {/*    <EmissionsTabs selectedFacility={selectedFacility} selectedFacilitySubview={selectedFacilitySubview}/>*/}
                {/*</div>*/}
               
                <GhgMeter 
                    selectedFacility={selectedFacility} 
                    emissionsObject={this.props.emissionsObject}
                    toggleIncludeLosses={this.toggleIncludeLosses}
                    handleStateVsRegionChange={this.handleStateVsRegionChange}
                    emissionsStateVsRegion={this.props.emissionsStateVsRegion}
                    emissionsIncludeLosses={this.props.emissionsIncludeLosses}
                />
            </Grid>
        )
    }
}

function mapStateToProps(state) {
    return {
        selectedFacility: state.selectedFacility,
        selectedFacilitySubview: state.selectedFacilitySubview,
        emissionsObject: state.emissionsObject,
        emissionsIncludeLosses: state.emissionsIncludeLosses,
        emissionsStateVsRegion: state.emissionsStateVsRegion
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setEmissionsObject,
        toggleEmissionsIncludeLosses,
        handleEmissionsStateVsRegionChange
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Emissions);
