import React, {useState} from 'react';
import {fade, makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import Box from '@material-ui/core/Box';
import Fade from "@material-ui/core/Fade";
import Popper from "@material-ui/core/Popper";
import { Link } from 'react-router-dom';
import {logout} from '../../services/user.service'
import reactlogo from "../../assets/img/ReactLogo_NoShadow.svg"
import './TopNavbarCss.css';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
//Material Icons

import AccountCircle from '@material-ui/icons/AccountCircle';

//colors
import grey from '@material-ui/core/colors/grey';

// Breadcrumbs
import Breadcrumbs from '../Breadcrumbs/breadcrumbs'
import Popover from "@material-ui/core/Popover";
import blue from "@material-ui/core/colors/blue";
import InboxIcon from '@material-ui/icons/MoveToInbox';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PeopleIcon from '@material-ui/icons/People';
import ExitToApp from '@material-ui/icons/ExitToApp';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import ErrorIcon from '@material-ui/icons/Error';
import Tooltip from '@material-ui/core/Tooltip';

import moment from 'moment'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        textColor: theme.palette.primary.contrast
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 2,
        boxShadow: 'none',
    },
    link: {
        color: grey[800],
        '&:hover': {
            color: 'black',
        }
    },
    title: {
        borderStyle: 'solid',
        borderColor: theme.palette.grey[300],
        borderBottom: 0,
        borderTop: 0,
        borderLeft: 0,
        borderRightWidth: 1,
        paddingRight: 15
    },
    breadcrumbs: {
        flexGrow: 1,
        marginLeft: 20,
        alignContent: 'flex-end',
    },
    titleText: {
        color: theme.palette.appBar.contrast,
        display: 'inline-block',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(1),
    },
    toolbar: {
        backgroundColor: theme.palette.appBar.main,
        borderStyle: 'solid',
        borderColor: theme.palette.grey[200],
        borderBottomWidth: 1,
        borderTop: 0,
        borderLeft: 0,
        borderRight: 0,
        width: '100%'
    },
    accountMenu:{
        // color: 'white'
    },
    accountPopper: {
        // shadow: 'pink'
    },
    icon: {
        color: theme.palette.appBar.contrast,
    },
    errorIcon: {
      color: theme.palette.error.main  
    },
    circle: {
        display: "flex",
        height: 50,
        width: 50,
        backgroundColor: theme.palette.appBar.contrast,
        borderRadius: 50,
        justifyContent: 'center',
        alignItems: 'center',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    initials: {
        fontSize: '25px',
        color: theme.palette.appBar.main
    },
    message: {
        color: theme.palette.appBar.contrast,
    },
    nameAndCompanyBlock: {
        display: "inline-block",
        // backgroundColor: "red",
        height: "100%",
        paddingLeft: 10,
        verticalAlign: "middle"
    },
}));



function MenuAppBar(props) {
    const classes = useStyles();

    const {
        toggleFacilitySideBar, 
        facilitySidebarOpen, 
        selectedFacility,
        selectedProject,
        facilities,
        selectedFacilityView,
        activeUser,
        location,
        match,
        history
    } = props;

    const [anchorEl, setAnchorEl] = React.useState(null);

    function handleClick(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    let userInfo = JSON.parse(window.sessionStorage.user);
    let firstInitial = userInfo.firstName.charAt(0);
    let lastInitial = userInfo.lastName.charAt(0);

    return (
        <div className={classes.root}>
            <AppBar position="fixed" className={classes.appBar}>
                
                <Toolbar className={classes.toolbar}>
                    {/*{selectedFacility === null ? null :*/}
                    {/*    <IconButton onClick={() => toggleFacilitySideBar(facilitySidebarOpen)} edge="start" color="white" className={classes.icon} aria-label="Menu">*/}
                    {/*        <MenuIcon />*/}
                    {/*    </IconButton>}*/}
                    <img style={{width: 40, height: 40, paddingRight: 10}} src={reactlogo} alt="Logo" />
                    <Typography variant="h6" className={classes.title}>
                        <Link to={'/'}>
                            <Typography className={classes.titleText} variant="h6">React Energy </Typography>
                            <Typography className={classes.titleText} variant="subtitle1"
                                        style={{paddingLeft: 3}}>PORTAL</Typography>
                        </Link>
                    </Typography>
                    <Grid className={classes.breadcrumbs}>
                                <Breadcrumbs
                                    selectedFacilityView={selectedFacilityView}
                                    selectedFacility={selectedFacility} 
                                    selectedProject={selectedProject} 
                                    facilities={facilities}
                                    location={location}
                                    match={match}
                                    history={history}
                                />
                    </Grid>
                    {moment(activeUser.subscription.trialPeriodEndDate).isAfter(moment()) ? 
                    <div>
                        <Typography style={{fontWeight: 500}} className={classes.message}>Free trial ending {moment().to(moment(activeUser.subscription.trialPeriodEndDate))}!</Typography>
                    </div> : null }
                    <div style={{paddingLeft: 20}}>
                        {/*<IconButton onClick={handleClick} edge="start" color="white" className={classes.icon} aria-label="Menu">*/}
                        {/*    <AccountCircle />*/}
                        {/*</IconButton>*/}
                        <div className={classes.circle} onClick={handleClick}>
                            <div className={classes.initials}>{firstInitial}{lastInitial}</div>
                        </div>

                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}

                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <div >
                            <List >
                                <Link to={`/account/home`} onClick={handleClose} className={classes.link}>
                                    <ListItem button key={'Account'} style={{width: 250}}>
                                            <div className={classes.circle}>
                                                <div className={classes.initials}>{firstInitial}{lastInitial}</div>
                                            </div>
                                            <div className={classes.nameAndCompanyBlock}>
                                                <Typography style={{fontSize: "1.2em"}}>{userInfo.firstName} {userInfo.lastName}</Typography>
                                                <Typography style={{fontWeight: 300}}>View Profile</Typography>

                                                    
                                                
                                            </div>
                                    </ListItem>
                                </Link>
                                <Divider />
                                {/*<Link to={`/account/home`} onClick={handleClose} className={classes.link}>*/}
                                {/*    <ListItem button key={'Account'} style={{width: 250}}>*/}
                                {/*        <ListItemIcon><AccountCircleIcon /> </ListItemIcon>*/}
                                {/*        <ListItemText primary={'Account'} />*/}
                                {/*    </ListItem>*/}
                                {/*</Link>*/}
                                <Link to={{pathname: `/account/subscription`}} onClick={handleClose} className={classes.link}>
                                    <ListItem button key={'Subscription'}>
                                        <ListItemIcon><CreditCardIcon /> </ListItemIcon>
                                        <ListItemText primary={'Subscription'} />
                                        {/*<Tooltip title={'Subscription Cancelled'} ><ListItemIcon><ErrorIcon className={classes.errorIcon}/> </ListItemIcon></Tooltip>*/}
                                    </ListItem>
                                </Link>
                                <Link to={{pathname: `/account/team`}} onClick={handleClose} className={classes.link}>
                                    <ListItem button key={'Team'}>
                                        <ListItemIcon><PeopleIcon /> </ListItemIcon>
                                        <ListItemText primary={'Team'} />
                                    </ListItem>
                                </Link>
                                <Divider/>
                                <Link to={`/`} onClick={() => logout()} className={classes.link}>
                                    <ListItem button key={'Logout'}>
                                        <ListItemIcon><ExitToApp /> </ListItemIcon>
                                        <ListItemText primary={'Logout'} />
                                    </ListItem>
                                </Link>
                                
                            </List>
                            </div>
                        </Popover>
                    </div>
                </Toolbar>
                
            </AppBar>
            <AppBar marginTop={20}>
                <Breadcrumbs/>
            </AppBar>
        </div>
    );
}

export default MenuAppBar;