import React, {Component} from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { Link } from 'react-router-dom';

import { renderToString } from 'react-dom/server'

import LabelComponent from "./FacilityChartLabels";

// Material UI
import Grid from '@material-ui/core/Grid';

class FacilityChartTable extends Component {
    constructor(props) {
        super(props);
        //this.afterChartCreated = this.afterChartCreated.bind(this);
        this.state = {
            chartOptions: null
        };
    }

    //afterChartCreated(chart) {
    //    debugger;
    //    this.internalChart = chart;
    //    this.forceUpdate();
    //}

    componentDidUpdate() {
        //this.internalChart.getMargins(); // redraw
        //this.internalChart.reflow();
    }

    componentDidMount = () => {
        const { facilities } = this.props;
        let projectId = (facilities && facilities.count > 0) ? facilities[0].projectId : null;
        let seriesAndLists = getSeriesForChart(facilities);
        let chartOptions = getChartOptions(seriesAndLists, projectId);
        this.setState({ chartOptions });
    }

    render() {
        const chart = this.internalChart, customLabels = [];

        //if (chart && chart.xAxis[0]) {
        //    debugger;
        //    Highcharts.objectEach(chart.xAxis[0].ticks, function (tick) {
        //        customLabels.push(<LabelComponent tick={tick} />);
        //    });
        //}

        return (
            <Grid container>
                <Grid item md={12}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={this.state.chartOptions}

                    />
                    {customLabels}
            </Grid>
            
                
                </Grid>
            )
    }
}

export default FacilityChartTable;


const getChartOptions = (seriesAndLists, projectId) => {
    const { series, facilitiesList, facilityIds } = seriesAndLists;
    
    return (
        {
            chart: {
                type: 'bar',
                style: {
                    fontFamily: "Roboto"
                }
            },
            title: {
                text: 'Facilities'
            },
            subtitle: {
                text: ''
            },
            xAxis: {
                categories: facilitiesList,
                title: {
                    text: null
                },
                labels: {
                    formatter: function () {
                        let facility = facilitiesList[this.pos];
                        return facility
                    },
                    useHTML: true
                }
            },
            yAxis: [{
                min: 0,
                title: {
                    text: 'Peak Demand (kW)',
                    align: 'high'
                },
                labels: {
                    overflow: 'justify'
                },
                opposite: true
            },
            {
                min: 0,
                title: {
                    text: 'Annual Usage (kWh)',
                    align: 'high'
                },
                labels: {
                    overflow: 'justify'
                },

            },
            ],
            tooltip: {
                valueSuffix: ' kW'
            },
            plotOptions: {
                bar: {
                    dataLabels: {
                        enabled: false
                    }
                }
            },
            legend: {
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'top',
                x: -40,
                y: 80,
                floating: false,
                borderWidth: 1,
                backgroundColor: ((Highcharts.theme && Highcharts.theme.legendBackgroundColor) || '#FFFFFF'),
                shadow: true
            },
            credits: {
                enabled: false
            },
            series: series
        } 
        )
    
}

const getSeriesForChart = (facilities) => {
    let series = [
        {
            name: 'Peak Demand (kW)',
            data: [],
            yAxis: 0
        },
        {
            name: 'Annual Usage (kWh)',
            data: [],
            yAxis: 1
        }
    ];
    let facilitiesList = [];
    let facilityIds = [];

    facilities.forEach((facility, index) => {
        series[0].data.push(facility.maximumkWValue);
        series[1].data.push((facility.maximumkWValue * 1000));
        facilitiesList.push(facility.name);
        facilityIds.push(facility.facilityId)

    })
    return { series, facilitiesList, facilityIds}
};

//export default function FacilitiesChartTable({ facilities }) {
//    let projectId = facilities[0].projectId;
//    let seriesAndLists = getSeriesForChart(facilities);
//    let options = getChartOptions(seriesAndLists, projectId);

//    return (
//    <HighchartsReact
//    highcharts={Highcharts}
//            options={this.state.chartOptions}
//        />
//        )
//}