import React, {Component, Fragment} from 'react';
import {loadStripe} from '@stripe/stripe-js';
import {
    CardElement,
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
    Elements,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';

import CheckoutForm from "./CheckoutForm/index";
import CurrentPaymentMethod from "./CurrentPaymentMethod";
import CustomInfoLayout from "../../../../components/CustomInfoLayout/CustomInfoLayout";


// Material UI
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

import Typography from "@material-ui/core/Typography";
import {withStyles} from "@material-ui/core/styles";
import {getOrganizationPaymentMethods, removePaymentMethod} from "../../../../services/admin.service";
import CustomSnackbar from "../../../../components/Snackbar/CustomSnackbar";
import HashLoader from 'react-spinners/HashLoader';
import PulseLoader from 'react-spinners/PulseLoader';
import moment from 'moment';
import HorizontalDivider from "../../../../components/Dividers/HorizontalDivider";
import Icon from "@material-ui/core/Icon";
import {useTheme} from '@material-ui/core/styles';
import LoadingButton from "../../../../components/CustomButtons/LoadingButton";

const styles = theme => ({
    root: {
        padding: 15,
        width: '100%'
        // minHeight: 200
    },
    button: {
        padding: 0
    },
    buttonGroup: {
        padding: 10
    },
    icon: {
        margin: theme.spacing(0),
        color: '#4a4a4a',
        fontSize: 25,
        display: 'inline-block'
    },
    errorIcon: {
        color: theme.palette.error.main,
    },
});


class PaymentMethodRev2 extends Component {
    state = {
        uploadingNewPaymentMethod: false,
        removingPaymentMethod: false,
        showUpdateCreditCard: false,
        snackbar: {
            open: false,
            message: '',
            variant: ''
        },
    }


    handleDelete = async (event, name, email) => {
        const {organizationPaymentMethod, organizationPaymentMethodId, fetchingPaymentMethod, getPaymentMethod} = this.props;

        this.setState({removingPaymentMethod: true});
        await removePaymentMethod(organizationPaymentMethodId).then(x => {
            if (x.isSuccess) {
                let snackbar = {...this.state.snackbar};
                snackbar.message = "Successfully removed payment method.";
                snackbar.open = true;
                snackbar.variant = "success";
                this.setState({snackbar});
            } else {
                let snackbar = {...this.state.snackbar};
                snackbar.message = "Something went wrong. Please contact React Energy to resolve issue";
                snackbar.open = true;
                snackbar.variant = "error";
                this.setState({snackbar});
            }
            getPaymentMethod();
            this.setState({removingPaymentMethod: false, showUpdateCreditCard: false});
        });

        // this.getPaymentMethod();
    };

    toggleShowUpdateCreditCard = () => {
        this.setState({showUpdateCreditCard: !this.state.showUpdateCreditCard})
    }

    openSnackbar = (value) => {
        let snackbar = {...this.state.snackbar}
        snackbar.open = value;
        this.setState({snackbar})
    }

    render() {
        const {classes} = this.props;
        const {showUpdateCreditCard} = this.state;
        let userInfo = JSON.parse(window.sessionStorage.user);
        const {organizationPaymentMethod, organizationPaymentMethodId, fetchingPaymentMethod, getPaymentMethod} = this.props;

        return (
            <div style={{width: '100%'}}>
                <CustomSnackbar
                    snackbar={this.state.snackbar}
                    openSnackbar={this.openSnackbar}
                />
                <CustomInfoLayout
                    mainContent={
                        <div className={classes.root} >

                            {this.state.fetchingPaymentMethod ?
                                <div style={{height: '100%', width: '100%', display: 'flex', justifyContent: 'center'}}>
                                    <HashLoader/>
                                </div>
                                :
                                <Grid container>

                                    <Grid item md={8} lg={9} >

                                        <CurrentPaymentMethod
                                            organizationPaymentMethod={organizationPaymentMethod}
                                            fetchingPaymentMethod={fetchingPaymentMethod}
                                        />
                                        <div style={{padding: 10}}>
                                            {userInfo.subscription.currentPeriodEndDate && !userInfo.subscription.cancelAtPeriodEnd ?
                                                <div style={{padding: 10, display: 'flex', justifyItems: 'center'}}>
                                                    <Icon className={classes.icon}>
                                                        autorenew
                                                    </Icon>
                                                    <Typography>Your subscription is set for auto-renewal for
                                                        ${userInfo.subscription.plan.price} on {moment(userInfo.subscription.currentPeriodEndDate).format('MMMM D, YYYY')}</Typography>
                                                    
                                                </div>
                                                : <div style={{padding: 10, display: 'flex', justifyItems: 'center'}}>
                                                    <Icon className={classes.errorIcon}>
                                                        error
                                                    </Icon>
                                                    <Typography style={{paddingLeft: 5}}>Your subscription will end
                                                        on {moment(userInfo.subscription.currentPeriodEndDate).format('MMMM D, YYYY')}.
                                                        Please email support@reactenergy.io to renew.</Typography>
                                                </div>
                                            }
                                        </div>
                                    </Grid>
                                    <Grid item md={4} lg={3}>
                                        <div className={classes.buttonGroup}>
                                            <div className={classes.button}>
                                                <Button
                                                    onClick={this.toggleShowUpdateCreditCard}
                                                    variant={"contained"}
                                                    color={"primary"}
                                                    disabled={showUpdateCreditCard}
                                                >Update Payment Method</Button>
                                            </div>
                                            <div className={classes.button}>
                                                <LoadingButton
                                                    onClick={this.handleDelete}
                                                    message={'Remove Payment Method'}
                                                    loading={this.state.removingPaymentMethod}
                                                    color={'primary'}
                                                    disabled={!organizationPaymentMethod}
                                                />
                                            </div>
                                        </div>
                                    </Grid>


                                </Grid>
                            }
                            {showUpdateCreditCard ?
                                <Fragment>
                                    <HorizontalDivider verticalMargin={15}/>
                                    <CheckoutForm
                                        onClose={this.toggleShowUpdateCreditCard}
                                        onSubmit={getPaymentMethod}
                                        showCancel={true}
                                    />
                                </Fragment>

                                :
                                null

                            }
                        </div>

                    }
                    title={"Payment Method"}/>

            </div>
        )
    }
}

export default withStyles(styles)(PaymentMethodRev2)


