import {loadCurveOptions} from "../generateDefaultChartOptions";
import createHandsontableSettingsFromData from "../createHandsontableSettingsFromData";
import moment from "moment";

class LoadCurveData {
    constructor(data) {
        this.data = data;

    }
    
    generateChartOptions = (dataType, timezone) => {
        return loadCurveOptions(this.data, dataType, timezone)
    }
    
    generateSurfacePlotOptions = (dataType, intervalDuration) => {
        let y = [];
        let z = [];
        let intervalsPerDay = 1440 / intervalDuration;
        // The chart doesn't seem to work unless the first z array has the full correct number in the array, so shift() the first data if it doesn't match
        
        let formattedData = [...this.data];
        let firstIsGood = formattedData[0].data.length === intervalsPerDay;
        while (!firstIsGood) {
            formattedData.shift();
            firstIsGood = formattedData[0].data.length === intervalsPerDay;
        }
        formattedData.forEach(interval => {

            y.push(interval.name);
            let zData = [];
            interval.data.forEach(array => zData.push(array[1]));
            z.push(zData);

        });

        let start = moment().hour(0).minutes(0).seconds(0).millisecond(0);
        let x = [];
        
        for (let i = 1; i <= intervalsPerDay; i++) {
            x.push(start.clone().add(i * intervalDuration, 'minutes').format("HH:mm"))
        }


        let colorscale = [
            [0, '#003d99'],
            [0.2, '#66a3ff'],
            [0.4, '#63BE7B'],
            [0.6, '#fae052'],
            [0.8, '#f40b0f'],
            [1, '#b30000']
        ];


        // https://plot.ly/javascript/reference/#surface
        // https://plot.ly/javascript/configuration-options/

        let data = [{
            x: x,
            y: y,
            z: z,
            colorscale: colorscale,
            type: 'surface'
        }];
        let layout = {
            autosize: true,

            // width: 1300,
            height: 600,
            scene: {
                xaxis: {
                    title: 'Time',
                    name: 'Time'
                },
                yaxis: {
                    title: ' '
                },
                zaxis: {
                    title: dataType === 'kw' ? 'Demand (kW)' : 'Consumption (kWh)'
                },
                aspectmode: 'manual',
                aspectratio: {x: 4, y: 4, z: 2},
                domain: {
                    x: [0.00,  3],
                    y: [0, 3],
                    z: [0, 6]
                },
                camera: {
                    center: {
                        x: 0, y: 0, z: 0 },
                    eye: {
                        x: 2, y: 2, z: 2 },
                    up: {
                        x: 0, y: 0, z: 2 }
                },
            },
            margin: {
                l: 10,
                r: 10,
                b: 10,
                t: 15,
            }
        };
        let config = {
            responsive: true,
            displaylogo: false
        }
         return {data, layout, config}
    }

    generateHandsontableSettings = (intervalDuration) => {
        return createHandsontableSettingsFromData(this.data, 'load-curve', null, intervalDuration )
    }

}

export default LoadCurveData;


