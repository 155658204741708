import config from "../config";
import { authHeader } from "../helpers/auth-header";
import { REQUESTTYPES, fetchHelper, responseStatusHandler } from "./serviceHelper";

const ACCOUNT = config.account;

const ENDPOINTS = {
    account: {
        login: "Login",
        forgotPassword: "ForgotPassword",
        resetPassword: "ResetPassword",
        submitFeedback: "SubmitFeedback",
        changePassword: "ChangePassword",
        refreshToken: "RefreshToken"
    }
};

const login = ($email, $password) => {
    const response = fetchHelper(
        ACCOUNT,
        ENDPOINTS.account.login,
        {Email: $email, Password: $password},
        "post"
    ).then(response => {
        // login successful if there's a user in the response
        if (response.isSuccess) {
            // store the details and basic auth credentials in local storage
            // to keep user logged in between page refreshes
            sessionStorage.setItem("user", JSON.stringify(response.user));
            // Perform redirect to '/'
            //window.location.href = "/";
        } else {
            return response;
        }
    });
    
    return response;
};

const refreshToken = () => {
    let user = getUser();
    
    const response = fetchHelper(
        ACCOUNT,
        ENDPOINTS.account.refreshToken,
        {Token: user.token},
        "post"
    ).then(response => {
        // login successful if there's a user in the response
        if (response.isSuccess) {
            //update token with new token
            user.token = response.token;
            
            // update local storage with new
            sessionStorage.setItem("user", JSON.stringify(user));
        } else {
            return response;
        }
    });

    return response;
};

const forgotPassword = ($email) => {
    const response = fetchHelper(
        ACCOUNT,
        ENDPOINTS.account.forgotPassword,
        {Email: $email},
        "post"
    ).then(response => {
        if (!response.isSuccess) {
            return response;
        } else {
            return response;
        }
    });

    return response;
};

//this method is used to verify the token is valid on first call (leave newPassword blank/null)
//this method is then used to update the password by passing both the token and the new password
const resetPassword = (token, newPassword) => {
    const response = fetchHelper(
        ACCOUNT,
        ENDPOINTS.account.resetPassword,
        {Token: token, NewPassword: newPassword},
        "post"
    ).then(response => {
        // login successful if there's a user in the response
        if (!response.isSuccess) {
            return response
            //todo: do something here on failure
            //todo: most likely invalid token
            //possibly failure to update password
        } else {
            return response;
        }
    });

    return response;
};

const submitFeedback = (subject, message) => {
    const response = fetchHelper(
        ACCOUNT,
        ENDPOINTS.account.submitFeedback,
        {Subject: subject, Message: message},
        "post"
    ).then(response => {
        // login successful if there's a user in the response
        if (!response.isSuccess) {
            return response
            //todo: do something here on failure
            //todo: most likely invalid token
            //possibly failure to update password
        } else {
            return response;
        }
    });

    return response;
};

const changePassword = (oldPassword, newPassword) => {
    const response = fetchHelper(
        ACCOUNT,
        ENDPOINTS.account.changePassword,
        {OldPassword: oldPassword, NewPassword: newPassword},
        "post"
    ).then(response => {
        // login successful if there's a user in the response
        if (!response.isSuccess) {
            return response
            //todo: do something here on failure
            //todo: most likely invalid token
            //possibly failure to update password
            // return response;
        } else {
            return response;
        }
    });

    return response;
};


const logout = () => {
    sessionStorage.removeItem("user");
    //window.location.href = '/';
};

const getUser = () => {
    if (window.sessionStorage.user) {
        const user = JSON.parse(window.sessionStorage.user);
        return user;
    }
    return undefined;
};

export {
    login,
    refreshToken,
    logout,
    getUser,
    forgotPassword,
    resetPassword,
    submitFeedback,
    changePassword
};
