import React, {Component} from 'react'

// react Router
import { Link } from 'react-router-dom';

//Material UI
import Grid from "@material-ui/core/Grid";
import { withStyles } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';


const styles = theme => ({
    root: {
        paddingTop: 40,
        paddingBottom: 15
        //backgroundColor: grey[50]
    },
    link: {
        paddingLeft: 5,
        paddingRight: 5,
        color: theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.primary.dark,
            textDecoration: 'underline'
        }
    }

});

class Footer extends Component {
    render() {
        const {classes} = this.props;
        return (
            <Grid className={classes.root} container alignItems="center" justify="center">
                &copy; React Energy | <Link to={`/`} className={classes.link}> Home </Link> | <Link to={`/terms`}  className={classes.link}> Terms of Service</Link> | <Link to={`/privacy`}  className={classes.link}> Privacy Policy </Link>
            </Grid>
            )
        

    }
}
export default withStyles(styles)(Footer);

