//import { TOGGLE_STRIPE_DIALOG, ORGANIZATION_MOUNTED, PAYMENT_METHODS_MOUNTED} from '../actions/admin-actions'

const setContact = (state = {firstName: 'Bill'}, action) => {
    switch (action.type) {
        case 'Jimmy':
            return !action.payload;
    }
    return state;
};


export { setContact };