import React, {Component} from 'react'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const privacy = [
    {
        header: "",
        paragraphs: [
            <div>This Privacy Policy is incorporated by reference into the React Energy Terms of Service (the
                "Terms"). The term "React Energy," "we," and "us" includes React Energy and our affiliates and
                subsidiaries. The Privacy Policy explains React Energy may collect, use, and disclose information we
                obtain through the Services. All other terms not defined herein will have the meanings set forth in the
                Terms.</div>,

            <div>"<span
                style={{fontWeight: 600}}>Personal Information</span>" means information that alone or when in
                combination with other information may be used to readily identify, contact, or locate you or your
                customers or potential customers, such as: name, address, email address, or phone number. We do not
                consider Personal Information to include information that has been anonymized so that it does not allow
                a third party to easily identify a specific individual.</div>,
        ]
    },
    {
        header: 'THE SERVICES COLLECT YOUR INFORMATION',
        paragraphs: [
            <div>We collect Personal Information when you:

                <ul>
                    <li>register to use the Services;</li>
                    <li>use the Services; and</li>
                    <li>communicate with us.</li>
                </ul>
                We also collect information, such as anonymous usage statistics, by using cookies, server logs, and
                other similar technology as you use the Services.</div>,

            <div><span
                style={{fontWeight: 600}}>Personal Information Collection.</span> You must register to use the Services.
                To register, you may need to provide Personal Information, such as your name, phone number, and email
                address. You may also provide information about your company, your company’s address. To add users to
                your account, you will have to provide us with Personal Information about such users, including their
                names and email addresses. If you are adding users to your account, then you represent and warrant you
                have the right to provide those users’ Personal Information to us.</div>,

            <div><span
                style={{fontWeight: 600}}>Using the Service.</span> We collect information when you use the Services.
                For
                example, when you create a meter, we will collect the information you submit for inclusion in the
                meter, including any Personal Information.</div>,

            <div><span
                style={{fontWeight: 600}}>Making Payments.</span> When you make payments through the Services, you may
                need to provide Personal Information to our third-party service providers, such as your credit card
                number.</div>,
            <div><span
                style={{fontWeight: 600}}>Customer Support.</span> We may collect Personal Information through your
                communications with our customer-support team.</div>,

            <div><span
                style={{fontWeight: 600}}>Cookies, Automatic Data Collection, and Related Technologies.</span> The
                Services collect and stores information that is generated automatically as you use it, including your
                preferences and anonymous usage statistics.</div>,

            <div>When you visit the Services, we and our third-party service providers receive and record information on
                our server logs from your browser, including your IP address, and from cookies and similar technology.
                Cookies are small text files placed in visitors’ computer browsers to store their preferences. Most
                browsers allow you to block and delete cookies. However, if you do that, the Services may not work
                properly.</div>,

            <div>By using the Services, you are authorizing us to gather, parse, and retain data related to the
                provision of the Services.</div>,

        ]
    },
    {
        header: 'HOW REACT ENERGY USES YOUR INFORMATION',
        paragraphs: [
            <div>We use Personal Information to:

                <ul>
                    <li>facilitate and improve our services; and</li>
                    <li>communicate with you.</li>

                </ul>
                We may use aggregate information for any purpose, including for marketing purposes.</div>,

            <div><span
                style={{fontWeight: 600}}>Internal and Services-Related Usage.</span> We use information, including
                Personal Information, for internal and service-related purposes only and may provide it to third parties
                to allow us to facilitate the Services. We may use and retain any data we collect to provide and improve
                our services.</div>,

            <div><span
                style={{fontWeight: 600}}>Communication.</span> We may send email to the email address you provide to us
                to verify your account and for informational and operational purposes, such as account management,
                customer service, or system maintenance.</div>,

            <div><span
                style={{fontWeight: 600}}>Marketing.</span> We may use information, including Personal Information, to
                facilitate transmittal of information that may be useful, relevant, valuable or otherwise of interest to
                you.</div>,
            <div><span
                style={{fontWeight: 600}}>Aggregate Data.</span> We may anonymize and aggregate data collected through
                the Services and use it for any purpose.</div>,

        ]
    },
    {
        header: 'REACT ENERGY MAY DISCLOSE YOUR INFORMATION',
        paragraphs: [
            <div>We may share your information:

                <ul>
                    <li>with our third-party service providers;</li>
                    <li>to comply with legal obligations;</li>
                    <li>to protect and defend our rights and property; and</li>
                    <li>with your permission.</li>

                </ul>
                We do not rent, sell, or share Personal Information about you with other people or nonaffiliated
                companies for their direct marketing purposes, unless we have your permission.</div>,

            <div><span
                style={{fontWeight: 600}}>We Use Vendors and Service Providers.</span> We may share any information we
                receive with vendors and service providers retained in connection with the provision of the Services.
            </div>,

            <div><span
                style={{fontWeight: 600}}>Displaying to Other Users.</span> The content you post to the Services may be
                displayed on the Services. Other users associated with your account will be able to see your contact
                information and, if you are given editing permissions, any revisions, additions or edits you make to a
                proposal. We are not responsible for privacy practices of the other users who will view and use the
                posted information.</div>,

            <div><span
                style={{fontWeight: 600}}>Marketing.</span> We do not rent, sell, or share Personal Information about
                you with other people or nonaffiliated companies for their direct marketing purposes, unless we have
                your permission. We may allow access to other data collected by the Services to enable facilitate
                transmittal of information that may be useful, relevant, valuable or otherwise of interest to you.
            </div>,
            <div><span
                style={{fontWeight: 600}}>As Required By Law and Similar Disclosures.</span> We may access, preserve,
                and disclose your Personal Information, other account information, and content if we believe doing so is
                required or appropriate to: comply with law enforcement requests and legal process, such as a court
                order or subpoena; respond to your requests; or protect yours’, ours’ or others’ rights, property, or
                safety.</div>,

            <div><span
                style={{fontWeight: 600}}>Merger, Sale, or Other Asset Transfers.</span> If we are involved in a merger,
                acquisition, financing due diligence, reorganization, bankruptcy, receivership, sale of company assets,
                or transition of service to another provider, then your information may be sold or transferred as part
                of such a transaction as permitted by law and/or contract. The use of your Personal Information
                following any of the foregoing events should be governed by the provisions of this Privacy Policy in
                effect at the time such Personal Information was collected.</div>,

            <div>We may also disclose your Personal Information with your permission.</div>

        ]
    },
    {
        header: 'SECURITY OF YOUR INFORMATION',
        paragraphs: [
            <div>We take steps to ensure that your information is treated securely and in accordance with this Privacy
                Policy. Unfortunately, the Internet cannot be guaranteed to be 100% secure, and we cannot ensure or
                warrant the security of any information you provide to us. We do not accept liability for unintentional
                disclosure.</div>,

            <div>By using the Services or providing Personal Information to us, you agree that we may communicate with
                you electronically regarding security, privacy, and administrative issues relating to your use of the
                Services. If we learn of a security system’s breach, we may attempt to notify you electronically by
                posting a notice on the Services or sending an e-mail to you. You may have a legal right to receive this
                notice in writing. To receive free written notice of a security breach (or to withdraw your consent from
                receiving electronic notice), please notify us at contactus@reactenergy.io.
            </div>,
        ]
    },
    {
        header: 'CHILDREN’S PRIVACY',
        paragraphs: [
            <div>We do not knowingly collect, maintain, or use Personal Information from children under 13 years of age,
                and no part of the Services is directed to children under the age of 13. If you learn that your child
                has provided us with Personal Information without your consent, you may alert us at
                contactus@reactenergy.io. If we learn that we have collected any Personal Information from
                children under 13, we will promptly take steps to delete such information and terminate the child’s
                account.</div>,
        ],
    },
    {
        header: 'UPDATE YOUR INFORMATION OR POSE A QUESTION OR SUGGESTION',
        paragraphs: [

            <div>If you would like to update or correct any information that you have provided to us through your use of
                the Services or otherwise, or if you have suggestions for improving this Privacy Policy, please send an
                e-mail to contactus@reactenergy.io.</div>,

        ],
    },
    {
        header: 'CHANGES TO OUR PRIVACY POLICY AND PRACTICES',
        paragraphs: [

            <div><span
                style={{fontWeight: 600}}>Posting of Revised Privacy Policy.</span> We will post any adjustments to the
                Privacy Policy on this web page, and the revised version will be effective when it is posted. If you are
                concerned about how your information is used, bookmark this page and read this Privacy Policy
                periodically.</div>,

            <div><span
                style={{fontWeight: 600}}>New Uses of Personal Information.</span> From time to time, we may desire to
                use Personal Information for uses not previously disclosed in our Privacy Policy. If our practices
                change regarding previously collected Personal Information in a way that would be materially less
                restrictive than stated in the version of this Privacy Policy in effect at the time we collected the
                information, we will make reasonable efforts to provide notice and obtain consent to any such uses as
                may be required by law.</div>,

        ],
    },
];


class PrivacyContent extends Component {
    render() {
        return (

            <Grid container style={{padding: 20}}>
                <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                    <Typography variant={"h6"}>PRIVACY POLICY</Typography>
                </Grid>
                {privacy.map(section => (
                    <div>
                        <Typography variant="subtitle1" style={{marginBottom: 10}}>
                            {section.header}
                        </Typography>
                        {section.paragraphs.map(paragraph => (
                            <div style={{marginBottom: 10}}>
                                <Typography variant="body">
                                    {paragraph}
                                </Typography>
                            </div>

                        ))}
                    </div>

                ))}

            </Grid>
        )
    }
}

export default PrivacyContent