
function timeOfDayMatch(y, x) {
    // moment was giving me a hard time with the time zones and matching times. So this function uses the unix timestamp (in milliseconds) and 
    // tells you if two different values are the same time of day (does 12:15AM === 12:15AM). there are 86400000 milliseconds per day, so finding
    // the remainder of dividing by 86,400,000 will return the milliseconds from midnight, and you can compare those two to match the time of the day
    
    if (x % 86400000 === y % 86400000) {
        return y
    }
}

export {timeOfDayMatch}