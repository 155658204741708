import config from "../config";
import { authHeader } from "../helpers/auth-header";
import { getUser } from "../services/user.service";
import { REQUESTTYPES, fetchHelper, responseStatusHandler } from "./serviceHelper";

// Get api base URLs
const FACILITY = config.facility;

const facilityModel =
    {
        FacilityId: null,
        OrganizationId: null,
        Name: "",
        Description: "",
        AccountNumber: null,
        MeterNumber: null,
        Address1: "",
        Address2: "",
        City: "",
        State: "",
        PostalCode: "",
        Phone: "",
        Utility: "",
        Notes: ""
    };

const facilityNoteModel =
    {
        FacilityNoteId: null,
        FacilityId: null,
        Title: "",
        Notes: ""
    };

const ENDPOINTS = {
    facilities: {
        getFacilityById: "GetFacilityById",
        getFacilitiesByProjectId: "GetFacilitiesByProjectId",
        createOrUpdateFacility: "CreateOrUpdateFacility",
        deleteFacility: "DeleteFacility",
        getFacilityNoteById: "GetFacilityNoteById",
        getFacilityNotesByFacilityId: "GetFacilityNotesByFacilityId",
        createOrUpdateFacilityNote: "CreateOrUpdateFacilityNote",
        deleteFacilityNote: "DeleteFacilityNote",
    }
};


const getFacilityById = (facilityId) => {
    const apiResponse = fetchHelper(
        FACILITY,
        ENDPOINTS.facilities.getFacilityById,
        {facilityId: facilityId},
        "post"
    ).then(response => {
        // login successful if there's a user in the response
        if (!response.isSuccess) {
            //todo: do something on error
            return response;
        } else {
            return response.facility;
        }
    });

    return apiResponse;
};

const createOrUpdateFacility = (facilityModel) => {

    if (!facilityModel.OrganizationId){
        let user = getUser();
        facilityModel.OrganizationId = user.organizationId;
    }

    const apiResponse = fetchHelper(
        FACILITY,
        ENDPOINTS.facilities.createOrUpdateFacility,
        {
            facility: facilityModel
        },
        "post"
    ).then(response => {
        // login successful if there's a user in the response
        if (!response.isSuccess) {
            //todo: do something on error
            return response;
        } else {
            return response;
        }
    });

    return apiResponse;
};

const deleteFacility = (facilityId) => {
    const apiResponse = fetchHelper(
        FACILITY,
        ENDPOINTS.facilities.deleteFacility,
        {
            FacilityId: facilityId
        },
        "post"
    ).then(response => {
        // login successful if there's a user in the response
        if (!response.isSuccess) {
            //todo: do something on error
            return response;
        } else {
            return response;
        }
    });

    return apiResponse;
};

const getFacilitiesByProjectId = (projectId) => {
    const apiResponse = fetchHelper(
        FACILITY,
        ENDPOINTS.facilities.getFacilitiesByProjectId,
        {ProjectId: projectId},
        "post"
    ).then(response => {
        // login successful if there's a user in the response
        if (!response.isSuccess) {
            //todo: do something on error
        } else {
            return response.facilities;
        }
    });

    return apiResponse;
};

const getFacilityNotesByFacilityId = (facilityId) => {
    const apiResponse = fetchHelper(
        FACILITY,
        ENDPOINTS.facilities.getFacilityNotesByFacilityId,
        {FacilityId: facilityId},
        "post"
    ).then(response => {
        // login successful if there's a user in the response
        if (!response.isSuccess) {
            //todo: do something on error
        } else {
            return response;
        }
    });

    return apiResponse;
};

const getFacilityNoteById = (facilityNoteId) => {
    const apiResponse = fetchHelper(
        FACILITY,
        ENDPOINTS.facilities.getFacilityNoteById,
        {facilityNoteId: facilityNoteId},
        "post"
    ).then(response => {
        // login successful if there's a user in the response
        if (!response.isSuccess) {
            //todo: do something on error
        } else {
            return response;
        }
    });

    return apiResponse;
};

const createOrUpdateFacilityNote = (facilityNoteModel) => {

    const apiResponse = fetchHelper(
        FACILITY,
        ENDPOINTS.facilities.createOrUpdateFacilityNote,
        {
            FacilityNote: facilityNoteModel
        },
        "post"
    ).then(response => {
        // login successful if there's a user in the response
        if (!response.isSuccess) {
            //todo: do something on error
            return response;
        } else {
            return response;
        }
    });

    return apiResponse;
};

const deleteFacilityNote = (facilityNoteId) => {
    const apiResponse = fetchHelper(
        FACILITY,
        ENDPOINTS.facilities.deleteFacilityNote,
        {
            FacilityNoteId: facilityNoteId
        },
        "post"
    ).then(response => {
        // login successful if there's a user in the response
        if (!response.isSuccess) {
            //todo: do something on error
            return response;
        } else {
            return response;
        }
    });

    return apiResponse;
};


export {
    facilityModel,
    getFacilitiesByProjectId,
    getFacilityById,
    createOrUpdateFacility,
    deleteFacility,
    facilityNoteModel,
    getFacilityNotesByFacilityId,
    getFacilityNoteById,
    createOrUpdateFacilityNote,
    deleteFacilityNote
};
