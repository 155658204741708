import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
// import heatmap from "../../assets/img/macbookHeatmap.png"
import heatmap from '../../assets/img/chartPics/HeatmapForLogin.png'
import scatterplot from '../../assets/img/chartPics/ScatterForLogin.png'

// Colors
import blue from '@material-ui/core/colors/blue';

class DemoCarousel extends Component {
    render() {
        return (
            <Carousel showThumbs={false} infiniteLoop autoPlay width="400px">
                <div style={{backgroundColor: 'white', height: 500}}>
                    <img style={{width: 350, height: 350, margin:10}} src={heatmap} alt="Logo" />
                    {/*<p className="legend">Legend 1</p>*/}
                </div>
                <div style={{backgroundColor: 'white', height: 500}}>
                    <img style={{width: 350, height: 350, margin:10}} src={scatterplot} alt="Logo" />
                    {/*<p className="legend">Legend 1</p>*/}
                </div>
            </Carousel>
        );
    }
};

export default DemoCarousel