import React, {Component} from 'react'
import MaterialTable from 'material-table'

class MeterEmissionsTable extends Component {
    render() {
        const {locationEmissions, selectedFacility} = this.props;
        let emissions = locationEmissions.emissions;
        let annualConsumption = selectedFacility.annualKwhValue;
        emissions.forEach(x => {
            // divide by 1000 to convert MWh to kWh (the emissions values are per MWh)
            x.meterEmissions = (x.value/1000 * annualConsumption).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            x.valueAsString = x.value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        });
        
        return (
            <div style={{ maxWidth: '100%' }}>
                <MaterialTable
                    columns={[
                        { title: 'Gas', field: 'label' },
                        { title: 'Gas Name', field: 'name' },
                        { title: 'Meter Emissions (lbs)', field: 'meterEmissions' },
                        { title: 'Sub Region Rate (lbs/MWh)', field: 'valueAsString' },
                    ]}
                    data={locationEmissions.emissions}
                    title=""
                    options={{
                        search: false,
                        paging: false,
                        pageSize: emissions.length,
                        exportButton: true,
                        toolbar: true
                    }}
                />
            </div>
        )
    }
}

export default MeterEmissionsTable;