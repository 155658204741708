import React, {Component} from 'react'
import {withStyles} from '@material-ui/core/styles';

const styles = theme => ({
    root: {
        // padding: 15
        borderBottom: '1px solid #e8e8e8',
        marginLeft: 10,
        marginRight: 10
    },

});


class HorizontalDivider extends Component {
    render() {
        const {classes} = this.props;
        return (
            <div 
                className={classes.root} 
                style={{marginBottom: this.props.verticalMargin ? this.props.verticalMargin : 0, marginTop: this.props.verticalMargin ? this.props.verticalMargin : 0}}>
                
            </div>
        )
    }
}

export default withStyles(styles)(HorizontalDivider)