import React, {Component} from 'react'
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Link from '@material-ui/core/Link';

class EmissionsSidebar extends Component {
    render() {
        const {emissionsObject} = this.props;


        if (Object.keys(emissionsObject).length > 0) {
            return (
                <Grid container style={{padding: 10}}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant={"subtitle2"} style={{textTransform: 'none'}}>
                                Powered by eGRID
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{padding: 5}}>
                            <Typography variant={"body2"}>
                                Emissions are calculated through use of The Emissions & Generation Resource Integrated
                                Database (eGRID). More eGRID information can be found <Link target="_blank"
                                href={"https://www.epa.gov/energy/emissions-generation-resource-integrated-database-egrid"}>on their website</Link>.
                            </Typography>
                        </Grid>

                    </Grid>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant={"subtitle2"} style={{textTransform: 'none'}}>
                                eGRID Information
                            </Typography>
                        </Grid>
                        <Grid container style={{padding: 5}}>
                            <Grid item xs={12}>
                                <Typography variant={"body2"}>
                                    State: {emissionsObject.location.stateName}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant={"body2"}>
                                    Postal Code: {emissionsObject.location.postalCode}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant={"body2"}>
                                    Subregion Abbreviation: {emissionsObject.location.subRegion1Abbr}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant={"body2"}>
                                    Subregion Name: {emissionsObject.location.subRegion1Name}
                                </Typography>
                            </Grid>
                        </Grid>
                        
                    </Grid>


                </Grid>
            );
        } else {
            return 'Loading'
        }


    }
}

function mapStateToProps(state) {
    return {
        selectedFacility: state.selectedFacility,
        selectedFacilitySubview: state.selectedFacilitySubview,
        emissionsObject: state.emissionsObject,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(EmissionsSidebar);
