import React, {Component} from 'react'
import {bindActionCreators} from "redux";
import {setActiveUser} from "../../../redux/actions/user-actions";
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core/styles";
import PhoneNumberTextMaskInput from "../../../components/TextMasks/PhoneNumberTextMaskInput";
import {createOrUpdateUser, createOrUpdateUserRequestObject} from "../../../services/admin.service";
import validate from "../../../js/validate";
import EditUserDialog from "../../../OrganizationSettings/components/dialogs/EditUserDialog";

class EditAccountInformation extends Component {
    
    state = {
        editAccountFormIsValid: true,
        attemptedEditAccountSubmit: false,
        editAccountDialogOpen: true,
        editAccountFormControls: {
            email: {
                label: 'Email',
                value: this.props.activeUser.username,
                valid: true,
                touched: false,
                message: 'required',
                validationRules: {
                    isRequired: true
                },
                textMask: null,
            },
            firstName: {
                label: 'First Name',
                value: this.props.activeUser.firstName,
                valid: true,
                touched: false,
                message: '',
                validationRules: {},
                textMask: null,
            },
            lastName: {
                label: 'Last Name',
                value: this.props.activeUser.lastName,
                valid: true,
                touched: false,
                message: '',
                validationRules: {},
                textMask: null,
            },
            phoneNumber: {
                label: 'Phone Number',
                value: this.props.activeUser.phoneNumber,
                valid: true,
                touched: false,
                message: '',
                validationRules: {},
                textMask: PhoneNumberTextMaskInput
            },
        }
    };

    toggleEditAccountDialog = (user = this.props.activeUser) => {

        if (user !== null) {


            // set the state to the project to edit
            let editAccountFormControls = { ...this.state.editAccountFormControls }
            editAccountFormControls.email.value = user.email;
            editAccountFormControls.firstName.value = user.firstName;
            editAccountFormControls.lastName.value = user.lastName;
            editAccountFormControls.phoneNumber.value = user.phoneNumber;

            this.setState({ editAccountFormControls })
        }

        let editAccountDialogOpen = !this.state.editAccountDialogOpen;
        this.setState({ editAccountDialogOpen })
    }

    onEditAccountSubmit = () => {
        let submittedForm = { ...this.state.editAccountFormControls };
        let keys = Object.keys(submittedForm);
        let finalSubmission = {};
        keys.forEach(key => { finalSubmission[key] = submittedForm[key].value });

        if (this.state.editAccountFormIsValid) {
            let userRequest = {...createOrUpdateUserRequestObject};

            userRequest.UserId = this.props.activeUser.userId;
            userRequest.OrganizationId = this.props.activeUser.organizationId;
            userRequest.Email = finalSubmission.email;
            userRequest.FirstName = finalSubmission.firstName;
            userRequest.LastName = finalSubmission.lastName;
            userRequest.PhoneNumber = finalSubmission.phoneNumber;

            createOrUpdateUser(userRequest).then((response)=>{
                if (response.isSuccess){
                    this.toggleEditAccountDialog();
                    this.setState({ attemptedEditUserSubmit: false })

                    // set snackbar
                    let snackbar = { ...this.state.snackbar };
                    snackbar.message = response.successMessage ? response.successMessage : "Successfully Updated Account";
                    snackbar.open = true;
                    snackbar.variant = "success";
                    this.setState({ snackbar });
                }else{
                    // set snackbar
                    let snackbar = { ...this.state.snackbar };
                    snackbar.message = "Something went wrong. Please try again or contact React Energy Support.";
                    snackbar.open = true;
                    snackbar.variant = "error";
                    this.setState({ snackbar });
                }
            });

        } else {
            this.setState({ attemptedEditUserSubmit: true })
        }
    };

    handleEditAccountInputChange = (event) => {
        const value = event.target.value;
        const name = event.target.name;
        const updatedControls = { ...this.state.editAccountFormControls };

        const updatedFormElement = { ...updatedControls[name] };

        updatedFormElement.value = value;
        updatedFormElement.touched = true;
        updatedFormElement.valid = validate(value, updatedFormElement.validationRules);

        updatedControls[name] = updatedFormElement;


        // Check if form is valid
        let formIsValid = true;

        for (let inputIdentifier in updatedControls) {
            formIsValid = updatedControls[inputIdentifier].valid && formIsValid;
        }
        this.setState({
            editAccountFormControls: updatedControls,
            editAccountFormIsValid: formIsValid
        })
    }

    render() {

        
        return (
            <div>
                <EditUserDialog
                    editUserDialogOpen={this.state.editAccountDialogOpen}
                    toggleEditUserDialog={this.toggleEditAccountDialog}
                    editUserFormControls={this.state.editAccountFormControls}
                    handleEditUserInputChange={this.handleEditAccountInputChange}
                    onEditUserSubmit={this.onEditAccountSubmit}
                    attemptedEditUserSubmit={this.state.attemptedEditAccountSubmit}
                    selectedUserForEdit={this.props.activeUser}
                />
            </div>
            
        )
    }
}

function mapStateToProps(state) {
    return {
        activeUser: state.activeUser,
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(EditAccountInformation);