import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CountUp from "react-countup";


const useStyles = makeStyles({
    infoCard: {
        minHeight: 100,
    }
});

export default function MeterHomeTopBanner(props) {
    const classes = useStyles();
    const {selectedFacility, meterAnnualC02e, intervalDataLengthInDays} = props;
    
    let cards = [
        {
            label: 'Peak Demand',
            value: selectedFacility.maximumkWValue,
            suffix: ' kW',
            estimatedMessage: null
        },
        {
            label: 'Annual kWh',
            value: selectedFacility.annualKwhValue,
            suffix: ' kWh',
            estimatedMessage: intervalDataLengthInDays < 365 ? ('Estimated from ' + intervalDataLengthInDays + ' days of data') : null
        },
        {
            label: 'Annual Emissions (CO2 Equivalent)',
            value: meterAnnualC02e,
            suffix: ' lbs',
            estimatedMessage: intervalDataLengthInDays < 365 ? ('Estimated from ' + intervalDataLengthInDays + ' days of data') : null
        },
        
    ]

    return (
        <Grid item xs={12}>
            <Grid container className={classes.root}>
                {cards.map(x => (
                    <Grid item xs={12} md={6} lg={3} style={{padding: 5}}>
                        <Card className={classes.infoCard}>
                            <Grid container>
                                <Grid item xs={12}
                                      style={{display: 'flex', justifyContent: 'center', paddingTop: 5}}>
                                    <div>
                                        {x.label}
                                    </div>

                                </Grid>
                                <Grid item xs={12} style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    paddingTop: 10,
                                    fontSize: '2em',
                                    fontWeight: 600
                                }}>
                                    {x.value ?
                                        <CountUp end={x.value} duration={2} decimal="."
                                                 decimals={2} separator="," suffix={x.suffix}/> : null}
                                </Grid>
                                {x.estimatedMessage ?
                                <Grid item xs={12}
                                      style={{display: 'flex', justifyContent: 'center', paddingTop: 5, fontSize: '0.8em'}}>
                                    <div>
                                        {x.estimatedMessage}
                                    </div>

                                </Grid> : null }
                                
                            </Grid>


                        </Card>
                    </Grid>
                ))}
                <Grid item xs={12} md={6} lg={3} style={{padding: 5}}>
                    <Card className={classes.infoCard}>
                        <Grid container>
                            <Grid item xs={12}
                                  style={{display: 'flex', justifyContent: 'center', paddingTop: 5}}>
                                <div>
                                    Data Span
                                </div>

                            </Grid>
                            <Grid item xs={12} style={{
                                display: 'flex',
                                justifyContent: 'center',
                                paddingTop: 10,
                                fontSize: '2em',
                                fontWeight: 600
                            }}>
                                {intervalDataLengthInDays ?
                                    <CountUp end={intervalDataLengthInDays} duration={2} decimal="."
                                             decimals={0} separator="," suffix={' days'}/> : null}
                            </Grid>
                            

                        </Grid>


                    </Card>
                </Grid>
            </Grid>
        </Grid>
    );
}

