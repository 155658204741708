
const daysOfWeekGenerator = () => [
    {
        value: true,
        label: "S",
        number: 1
    },
    {
        value: false,
        label: "M",
        number: 2
    },
    {
        value: false,
        label: "T",
        number: 3
    },
    {
        value: false,
        label: "W",
        number: 4
    },
    {
        value: false,
        label: "T",
        number: 5
    },
    {
        value: false,
        label: "F",
        number: 6
    },
    {
        value: false,
        label: "S",
        number: 7
    },
];

const monthsGenerator = () => [
    {
        value: true,
        label: "Jan",
        number: 1
    },
    {
        value: true,
        label: "Feb",
        number: 2
    },
    {
        value: false,
        label: "Mar",
        number: 3
    },
    {
        value: false,
        label: "Apr",
        number: 4
    },
    {
        value: false,
        label: "May",
        number: 5
    },
    {
        value: false,
        label: "Jun",
        number: 6
    },
    {
        value: false,
        label: "Jul",
        number: 7
    },
    {
        value: false,
        label: "Aug",
        number: 8
    },
    {
        value: false,
        label: "Sep",
        number: 9
    },
    {
        value: false,
        label: "Oct",
        number: 10
    },
    {
        value: false,
        label: "Nov",
        number: 11
    },
    {
        value: false,
        label: "Dec",
        number: 12
    },
];

const seriesTypeGenerator = () => [
    {
        value: true,
        label: "Average",
        shortLabel: "Avg"
    },
    {
        value: false,
        label: "Maximum",
        shortLabel: "Max"
    },
    {
        value: false,
        label: "Minimum",
        shortLabel: "Min"
    },
];

const dataTypesGenerator = () => [
    {
        value: false,
        label: "Min kW",
        name: "minKw"
    },
    {
        value: false,
        label: "Avg kW",
        name: "avgKw"
    },
    {
        value: true,
        label: "Max kW",
        name: "maxKw"
    },
    {
        value: false,
        label: "Min kWh",
        name: "minKwh"
    },
    {
        value: false,
        label: "Avg kWh",
        name: "avgKwh"
    },
    {
        value: false,
        label: "Max kWh",
        name: "maxKwh"
    },
    
    
    {
        value: true,
        label: "Sum kWh",
        name: "sumKwh"
    },
    {
        value: true,
        label: "Load Factor",
        name: "loadFactor"
    },
];





export { daysOfWeekGenerator, monthsGenerator, seriesTypeGenerator, dataTypesGenerator}