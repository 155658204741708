import React, { Fragment } from 'react';


import { makeStyles } from '@material-ui/core/styles';


import UsersTable from '../containers/UsersTable'
//import CreateProjectDialogContainer from '../../containers/Dialogs/CreateProjectDialogContainer'


import Paper from '@material-ui/core/Paper';
import { Grid } from "@material-ui/core";

import { green } from '@material-ui/core/colors';




import PulseLoader from '../../components/Spinners/pulse-loader'
import OrganizationInfo from './cards/OrganizationInfo'
import AddNewButton from "../../components/CustomButtons/AddNewButton";


import CreateUserDialog from './dialogs/CreateUserDialog'
import EditUserDialog from './dialogs/EditUserDialog'
import DeleteUserDialog from './dialogs/DeleteUserDialog'

import CustomSnackbar from '../../components/Snackbar/CustomSnackbar'
import ProjectInfo from "../../ProjectHome/components/cards/ProjectInfo";
import CustomInfoLayout from "../../components/CustomInfoLayout/CustomInfoLayout";
import Button from "@material-ui/core/Button";
import BuyMoreUsersDialog from "./dialogs/BuyMoreUsersDialog";
import AddCircleIcon from "@material-ui/icons/AddCircle";


const useStyles = makeStyles(theme => ({
    paper: {
        // margin: 20,
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        color: theme.palette.common.white,
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[600],
        }
    },
    button: {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.success.contrast,
        borderRadius: 20,
        '&:hover': {
            backgroundColor: theme.palette.success.dark,
        }
    }
}));



export default function OrganizationSettingsHomeComponent(props) {
    const classes = useStyles();
    const {
        // Organization
        users,
        organization,
        
        //Buy more users
        toggleBuyMoreUsersDialog,
        buyMoreUsersDialog,

        // Create User
        createUserDialogOpen,
        toggleCreateUserDialog,
        createUserFormControls,
        handleCreateUserInputChange,
        onCreateUserSubmit,
        attemptedCreateUserSubmit,

        //edit User
        editUserDialogOpen,
        toggleEditUserDialog,
        editUserFormControls,
        handleEditUserInputChange,
        onEditUserSubmit,
        attemptedEditUserSubmit,
        selectedUserForEdit,

        // Delete User
        selectedUserForDelete,
        deleteUserDialogOpen,
        onDeleteUserSubmit,
        toggleDeleteUserDialog,

        // Snackbar
        // snackbar,
        // openSnackbar

    } = props;


    return (
        <div className={classes.root}>
            <Grid container >
                <Grid item xs={12}>
                    {!users ?
                        <Grid container
                            spacing={0}
                            direction="column"
                            alignItems="center"
                            justify="center"
                            padding={20}>
                            <Grid item>
                                <PulseLoader />
                            </Grid>
                        </Grid>

                        :
                        <Fragment>
                            <CustomInfoLayout
                                title="Team Members"
                                topRightContent={<AddNewButton text={'Invite New User'} onClick={toggleCreateUserDialog}/>}
                                mainContent={<UsersTable
                                    toggleEditUserDialog={toggleEditUserDialog}
                                    toggleDeleteUserDialog={toggleDeleteUserDialog}
                                />}
                            />




                        </Fragment>

                    }

                </Grid>
            </Grid>



            <CreateUserDialog
                createUserDialogOpen={createUserDialogOpen}
                toggleCreateUserDialog={toggleCreateUserDialog}
                createUserFormControls={createUserFormControls}
                handleCreateUserInputChange={handleCreateUserInputChange}
                onCreateUserSubmit={onCreateUserSubmit}
                attemptedCreateUserSubmit={attemptedCreateUserSubmit}
            />

            <BuyMoreUsersDialog
                buyMoreUsersDialog={buyMoreUsersDialog}
                toggleBuyMoreUsersDialog={toggleBuyMoreUsersDialog}
            />

            <EditUserDialog
                editUserDialogOpen={editUserDialogOpen}
                toggleEditUserDialog={toggleEditUserDialog}
                editUserFormControls={editUserFormControls}
                handleEditUserInputChange={handleEditUserInputChange}
                onEditUserSubmit={onEditUserSubmit}
                attemptedEditUserSubmit={attemptedEditUserSubmit}
                selectedUserForEdit={selectedUserForEdit}
            />

            <DeleteUserDialog
                selectedUserForDelete={selectedUserForDelete}
                deleteUserDialogOpen={deleteUserDialogOpen}
                onDeleteUserSubmit={onDeleteUserSubmit}
                toggleDeleteUserDialog={toggleDeleteUserDialog}
            />

        </div>
    );
}
