import React, {Component} from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import {bindActionCreators} from "redux";
import {selectFacility, selectFacilityView, toggleFacilitySideBar} from "../../../../redux/actions/facility-actions";
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core";
import ChartWrapper from "../../../../components/ChartWrapper/ChartWrapper";
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import BarChartIcon from '@material-ui/icons/BarChart';

import CustomTabsPaper from "../../../../components/tabs/CustomTabsPaper";
import {getDegreeDaysRedux, setDegreeDayChartToUpdate} from "../../../../redux/actions/weather-actions";
import {getDefaultDegreeDayRequestObjectFromFacility} from "../../../../js/degreeDayFunctions";
import {HotTable} from '@handsontable/react';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import DataViewLoadingSpinner from "../../spinners/DataViewLoadingSpinner";
import exportDataAsCsv from "../../../js/exportDataAsCsv";

const styles = theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
});

class DegreeDaysTemp extends Component {

    state = {
        cdd: [],
        hdd: [],
        dates: [],
        chartType: 'column',
        chartOptions: null,
        cddBaseInput: 65,
        hddBaseInput: 65,
        cddBaseCurrent: null,
        hddBaseCurrent: null,
        chartCanUpdate: false,
        tabs: [
            {
                label: "Chart",
                value: "chart",
            },
            {
                label: "Table",
                value: 'table',
            }
        ],
        currentTab: 0,
        currentComponent: null
    }

    componentDidMount = () => {
        // let request = getDefaultDegreeDayRequestObjectFromFacility();
        this.props.getDegreeDaysRedux(this.props.selectedFacility);
    };

    changeChartType = (event, newValue) => {
        if (this.state.chartType !== newValue) {
            this.setState({chartType: newValue, chartCanUpdate: true})
        }
    };

    setChartOptions = () => {
        return new Promise((resolve, reject) => {
            let chartOptions = this.props.degreeDays.getChartOptions(this.state.chartType);
            this.setState({chartOptions, chartCanUpdate: false});
            resolve('Success')
        })

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.degreeDayChartToUpdate || (this.state.chartType !== prevState.chartType)) {
            this.setChartOptions().then(x => {
                // this.setCurrentComponent()
            });
            this.props.setDegreeDayChartToUpdate(false)
        }
    }

    getCurrentComponent = () => {
        const {currentTab} = this.state;
        if (currentTab === 0) {
            const {classes, degreeDays} = this.props;

            return (
                <div style={{width: '100%', padding: 5, paddingTop: 15}}>
                    {degreeDays ? 
                    <ChartWrapper
                        title={'Degree Days'}
                        message={'HDD Base: ' + degreeDays.data.heatingDegreeBase + ' | CDD Base: ' + degreeDays.data.coolingDegreeBase}
                        chartArea={this.state.chartOptions ? <HighchartsReact
                            highcharts={Highcharts}
                            options={this.state.chartOptions}
                        /> : <DataViewLoadingSpinner message={'Calculating Degree Days'}/>}
                        titleBarRight={<div style={{display: 'inline-block'}}>
                            <ToggleButtonGroup size="small" value={this.state.chartType} exclusive
                                               onChange={this.changeChartType}>
                                <ToggleButton key={1} value="column">
                                    <BarChartIcon/>
                                </ToggleButton>
                                <ToggleButton key={2} value="bar">
                                    <BarChartIcon style={{transform: 'rotate(90deg)'}}/>
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </div>}
                    /> : <DataViewLoadingSpinner message={'Calculating Degree Days'}/>}
                    {/*TODO: put loading spinner*/}


                </div>
            )
        } else if (currentTab === 1) {
            const {degreeDaysHandsontableSettings} = this.props;
            if (degreeDaysHandsontableSettings) {
                return (

                    <Grid container style={{padding: 5, paddingTop: 15}}>

                        <Grid item xs={12}>
                            <Button
                                color="secondary"
                                onClick={() => exportDataAsCsv(degreeDaysHandsontableSettings)}
                            >
                                Export Data as CSV
                            </Button>
                        </Grid>


                        <Grid item xs={12}>
                            {
                                degreeDaysHandsontableSettings === null ? <DataViewLoadingSpinner/>
                                    : <HotTable
                                        // colHeaders={degreeDaysHandsontableSettings.colHeaders}
                                        // rowHeaders={degreeDaysHandsontableSettings.rowHeaders}
                                        // data={degreeDaysHandsontableSettings.data}
                                        // columns={degreeDaysHandsontableSettings.columns}
                                        // width={degreeDaysHandsontableSettings.width}
                                        // height={degreeDaysHandsontableSettings.height}
                                        // rowHeaderWidth={degreeDaysHandsontableSettings.rowHeaderWidth}
                                        // licenseKey={degreeDaysHandsontableSettings.licenseKey}
                                        // stretchH={degreeDaysHandsontableSettings.stretchH}
                                        settings={degreeDaysHandsontableSettings}
                                    />

                            }


                        </Grid>
                    </Grid>
                )
            } else {
                return <DataViewLoadingSpinner message={'Calculating Degree Days'}/>
            }
            

        }

    };

    setTab = (value) => {
        this.setState({currentTab: value});
    }

    render() {


        return (
            <div style={{width: '100%'}}>

                {this.state.chartOptions ?
                    <CustomTabsPaper
                        tabs={this.state.tabs}
                        currentComponent={this.getCurrentComponent()}
                        setTab={this.setTab}
                        currentTab={this.state.currentTab}
                    />

                    : <DataViewLoadingSpinner message={'Calculating Degree Days'}/>}

            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        selectedFacilityView: state.selectedFacilityView,
        // intervalData: state.intervalData,
        // facilitySidebarOpen: state.facilitySidebarOpen,
        selectedFacility: state.selectedFacility,
        // facilities: state.facilities,
        // intervalDataForLoadCurve: state.intervalDataForLoadCurve,
        // intervalDataForZoomable: state.intervalDataForZoomable,
        // chartDataFilterFormObject: state.chartDataFilterFormObject,
        degreeDays: state.degreeDays,
        degreeDayChartToUpdate: state.degreeDayChartToUpdate,
        degreeDaysHandsontableSettings: state.degreeDaysHandsontableSettings
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        // selectFacilityView,
        // selectFacility,
        // toggleFacilitySideBar,
        setDegreeDayChartToUpdate,
        getDegreeDaysRedux
    }, dispatch);
};

let DegreeDays = withStyles(styles)(DegreeDaysTemp);

export default connect(mapStateToProps, mapDispatchToProps)(DegreeDays);

