import React, {Component} from 'react'

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import {withStyles} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormLabel from '@material-ui/core/FormLabel';
import CustomOutlinedInput from "../../../components/Inputs/CustomOutlinedInput";
import AntTabs from "../../../components/AntTabs/AntTabs";
import AntTab from "../../../components/AntTabs/AntTab";

const styles = theme => ({
    textField: {
        margin: theme.spacing(1),
        width: '100%'

    },
    root: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
    row: {
        paddingTop: 0,
        paddingBottom: 0
    }
});

class CreateFacilityForm extends Component {
    state = {
        currentTab: 0,
        tabs: [
            {
                label: 'Description',
            },
            {
                label: 'Utility'
            },
            {
                label: 'Address'
            }
        ],
    };

    changeTab = (index) => {
        this.setState({currentTab: index})
    };

    getComponent = () => {
        const {currentTab} = this.state;
        const {handleInputChange, formControls, attemptedSubmit} = this.props;
        switch (currentTab) {
            case 0:
                return <CustomOutlinedInput
                    label={formControls.description.label}
                    value={formControls.description.value}
                    onChange={handleInputChange}
                    name={formControls.description.name}
                    multiline={true}
                />;
            case 1:
                return <div>
                    <CustomOutlinedInput
                        label={formControls.utility.label}
                        value={formControls.utility.value}
                        onChange={handleInputChange}
                        name={formControls.utility.name}
                    />
                    <CustomOutlinedInput
                        label={formControls.meterNumber.label}
                        value={formControls.meterNumber.value}
                        onChange={handleInputChange}
                        name={formControls.meterNumber.name}
                    />
                    <CustomOutlinedInput
                        label={formControls.accountNumber.label}
                        value={formControls.accountNumber.value}
                        onChange={handleInputChange}
                        name={formControls.accountNumber.name}
                    />
                </div>;
            case 2:
                return <Grid>
                    <CustomOutlinedInput
                        label={formControls.address1.label}
                        value={formControls.address1.value}
                        onChange={handleInputChange}
                        name={formControls.address1.name}
                    />
                    <CustomOutlinedInput
                        label={formControls.address2.label}
                        value={formControls.address2.value}
                        onChange={handleInputChange}
                        name={formControls.address2.name}
                    />
                    <CustomOutlinedInput
                        label={formControls.city.label}
                        value={formControls.city.value}
                        onChange={handleInputChange}
                        name={formControls.city.name}
                    />
                    <CustomOutlinedInput
                        label={formControls.state.label}
                        value={formControls.state.value}
                        onChange={handleInputChange}
                        name={formControls.state.name}
                    />
                </Grid>;
            default:
                return <CustomOutlinedInput
                    label={formControls.description.label}
                    value={formControls.description.value}
                    onChange={handleInputChange}
                    name={formControls.description.name}
                    multiline={true}
                />;
        }
    }

    render() {
        const {handleInputChange, formControls, attemptedSubmit, classes} = this.props;

        return (
            <form onSubmit={() => alert("submitted")}>
                <Grid container spacing={0}>
                    <Grid item xs={12} className={classes.row}>
                        <CustomOutlinedInput
                            label={formControls.name.label}
                            value={formControls.name.value}
                            name={formControls.name.name}
                            onChange={handleInputChange}
                            error={!formControls.name.valid && attemptedSubmit}
                            required={formControls.name.validationRules.isRequired}
                        />
                        <CustomOutlinedInput
                            label={formControls.postalCode.label}
                            value={formControls.postalCode.value}
                            onChange={handleInputChange}
                            name={formControls.postalCode.name}
                            required={formControls.postalCode.validationRules.isRequired}
                            error={!formControls.postalCode.valid && attemptedSubmit}
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.row}>
                        <AntTabs value={this.state.currentTab}>
                            {this.state.tabs.map((tab, index) => (
                                <AntTab label={tab.label} onClick={() => this.changeTab(index)}/>
                            ))}
                        </AntTabs>
                    </Grid>
                    <Grid item xs={12} className={classes.row}>
                        {this.getComponent()}
                    </Grid>
                </Grid>
            </form>
        )
    }


}


export default withStyles(styles)(CreateFacilityForm)