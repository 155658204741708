import React, {Component, Fragment} from "react";

import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {selectProject} from '../../redux/actions/project-actions';
import {toggleEditMeterDialogOpen, setSelectedMeterForEdit} from "../../redux/actions/MeterActions/EditMeterActions/edit-meter-actions";

//Material UI
import MaterialTable from 'material-table'


// Components
import LinkText from '../../components/Link/LinkText';
import EditDeleteAddIcons from '../components/buttons/FacilityEditDeleteAddIcons'


import Button from '@material-ui/core/Button';
import Tab from '@material-ui/core/Tab';
import Typography from "@material-ui/core/Typography";
import ProjectTableDetailPanel from "../components/tables/ProjectTableDetailPanel";

import CountUp from "react-countup";

class FacilitiesTable extends Component {

    render() {

        const {importDataDialogOpen, toggleImportDataDialog, toggleEditFacilityDialog, toggleDeleteFacilityDialog} = this.props;


        return (

            <div style={{width: '100%'}}>

                <MaterialTable
                    columns={[
                        {
                            title: 'Meter',
                            field: 'name',
                            render: rowData =>
                                <LinkText text={rowData.name} projectId={rowData.projectId}
                                          facilityId={rowData.facilityId} object={rowData}/>
                        },
                        {title: 'Peak Demand', field: 'maximumkWValue', render: (rowData) => {
                                if (rowData.maximumkWValue) {
                                    return <CountUp
                                        start={0}
                                        end={rowData.maximumkWValue ? rowData.maximumkWValue : null}
                                        duration={1}
                                        separator=","
                                        suffix={" kW"}
                                        decimal="."
                                        decimals={2}
                                        useEasing={true}
                                    />
                                } else {
                                    return null
                                }

                            }},
                        {
                            title: 'Annual kWh', field: 'annualKwhValue', render: (rowData) => {
                                if (rowData.annualKwhValue) {
                                    return <CountUp
                                        start={0}
                                        end={rowData.annualKwhValue ? rowData.annualKwhValue : null}
                                        duration={1}
                                        separator=","
                                        suffix={" kWh"}
                                        decimal="."
                                        decimals={2}
                                        useEasing={true}
                                    />
                                } else {
                                    return null
                                }

                            }
                        },
                        {
                            title: 'Edit',
                            render: rowData => <EditDeleteAddIcons importDataDialogOpen={importDataDialogOpen}
                                                                   toggleImportDataDialog={toggleImportDataDialog}
                                                                   facilityObject={rowData}
                                                                   toggleEditFacilityDialog={this.props.toggleEditMeterDialogOpen}
                                                                   setSelectedMeterForEdit={this.props.setSelectedMeterForEdit}
                                                                   toggleDeleteFacilityDialog={this.props.toggleDeleteFacilityDialog}/>
                        },


                    ]}
                    detailPanel={rowData => {
                        return (
                            <ProjectTableDetailPanel facility={rowData}/>
                        )
                    }}
                    data={this.props.facilities}
                    title={null}
                    options={{
                        search: true,
                        pageSize: 10,
                        padding: 'dense',
                        // selection: true,

                    }}
                    style={{boxShadow: 'none'}}
                />

            </div>


        );

    }
}

function mapStateToProps(state) {
    return {
        facilities: state.facilities,
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        selectProject,
        toggleEditMeterDialogOpen,
        setSelectedMeterForEdit
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(FacilitiesTable);

