import {summaryChartOptions} from "../generateDefaultChartOptions";
import createHandsontableSettingsFromData from "../createHandsontableSettingsFromData";

class SummaryChartData {
    constructor(data) {
        this.data = addLoadFactor(data);

    }
    
    getDataForDegreeDays = () => {
        
    };
    
    generateChartOptions = (requestedDataPoints, height = null, singleOrMultiple = 'single') => {
        let { categories, series } = formatDataForSummaryChart(this.data, requestedDataPoints);
        if (singleOrMultiple === 'single') {
            return summaryChartOptions(series, categories, height)
        } else if (singleOrMultiple === 'multiple') {
            let charts = [];
            series.forEach(x => {
                let temp = summaryChartOptions([x], categories, height)
                charts.push(temp)
                
            });
            return charts
        }
    }

    generateHandsontableSettings = () => {
        return createHandsontableSettingsFromData(this.data, 'summary-chart')
    }

}

export default SummaryChartData;


function addLoadFactor(data) {
    data.forEach((series, index) => {data[index].loadFactor = Number((series.avgKwh / series.maxKwh).toFixed(2)) });
    return data
};

function formatDataForSummaryChart(data, requestedDataPoints = null) {

    // Make sure Load Factor data has been added
    if (!data[0].loadFactor) {
        data.forEach((series, index) => {data[index].loadFactor = (series.avgKwh / series.maxKwh) });
    }

    let categories = [];
    let tempSeries = getTempSeries(requestedDataPoints);

    for (let i = 0; i < data.length; i++) {
        categories.push(data[i].name);
        for (let j = 0; j < tempSeries.length; j++) {
            tempSeries[j].data.push(data[i][tempSeries[j].value]);
        }

    }

    let keys = Object.keys(tempSeries);
    let series = [];
    keys.forEach(key => series.push(tempSeries[key]))

    return { categories, series }

}


function getTempSeries(requestedDataPoints) {
    let allSeries = {
        maxKw: {
            name: 'Peak kW',
            value: 'maxKw',
            data: [],
        },
        avgKw: {
            name: 'Avg kW',
            value: 'avgKw',
            data: [],
        },
        minKw: {
            name: 'Min kW',
            value: 'minKw',
            data: [],
        },
        maxKwh: {
            name: 'Max kWh',
            value: 'maxKwh',
            data: [],
            yAxis: 1,
        },
        minKwh: {
            name: 'Min kWh',
            value: 'minKwh',
            data: [],
            yAxis: 1,
        },
        avgKwh: {
            name: 'Average kWh',
            value: 'avgKwh',
            data: [],
            yAxis: 1,
        },
        sumKwh: {
            name: 'Sum kWh',
            value: 'sumKwh',
            data: [],
            yAxis: 2,
        },
        loadFactor: {
            name: 'Load Factor',
            value: 'loadFactor',
            data: [],
            yAxis: 3,
        },

    };

    let tempSeries = [];
    requestedDataPoints.forEach(dataPoint => {
        tempSeries.push(allSeries[dataPoint])
    });
    return tempSeries
};
