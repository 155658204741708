import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
    progress: {
        margin: theme.spacing(2),
    },
}));

export default function DataViewLoadingSpinner(props) {
    const classes = useStyles();
    const [progress, setProgress] = React.useState(0);
    
    let message;
    
    props['message'] ? message = props.message : message = 'Fetching data...';

    React.useEffect(() => {
        function tick() {
            // reset when reaching 100%
            setProgress(oldProgress => (oldProgress >= 100 ? 0 : oldProgress + 1));
        }

        const timer = setInterval(tick, 20);
        return () => {
            clearInterval(timer);
        };
    }, []);

    return (
        <div>
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
                style={{height: '300px'}}
            >

                <CircularProgress className={classes.progress} variant="determinate" value={progress} />
                <Typography>{message}</Typography>
            </Grid>
        </div>
    );
}