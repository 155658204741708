import React, {Component} from 'react'
import moment from "moment";

//Material UI
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import {withStyles} from "@material-ui/core";
import Input from '@material-ui/core/Input';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
    root: {
        flexGrow: 1
    },
});



class TemperatureSettings extends Component {
    
    state = {
        monthly: {
            cddActive: true,
            hddActive: false,
            cddTemp: 65,
            hddTemp: 65,
        }
    };
    
    toggleActiveCddOrHdd = (type) => {
        let monthly = {...this.state.monthly};
        monthly[type] = !monthly[type];
        this.setState({monthly})
    };
    
    changeTemperature = (event, type) => {
        let monthly = {...this.state.monthly};
        if (type === 'cdd') {
            monthly.cddTemp = event.target.value;
        } else {
            monthly.hddTemp = event.target.value;
        }
        this.setState({monthly})
    }
    
    
    render() {
        const {selectedFacility, classes} = this.props;
        let duration = moment(selectedFacility.intervalDataEndDate).diff(moment(selectedFacility.intervalDataStartDate), 'days');
        return (
            <Grid container className={classes.root} justify={"center"}>
                <Typography>Temperature Settings</Typography>
                <Grid item xs={12} container justify={"center"}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.state.monthly.cddActive}
                                onChange={() => this.toggleActiveCddOrHdd('cddActive')}
                                value="cdd"
                                color="secondary"
                            />
                        }
                        label="CDD (Cooling Degree Days)"
                    />
                    <FormControlLabel
                        control={
                            <Input
                                // className={classes.input}
                                value={this.state.monthly.cddTemp}
                                margin="dense"
                                onChange={(event) => this.changeTemperature(event, 'cdd')}
                                // onBlur={handleBlur}
                                inputProps={{
                                    step: 1,
                                    min: 50,
                                    max: 75,
                                    type: 'number',
                                    'aria-labelledby': 'input-slider',
                                }}
                            />
                        }
                        label={<Typography>CDD Reference Temperature <span>&#176;</span>F</Typography>}
                    />
                </Grid>
                <Grid item xs={12} container justify={"center"}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.state.monthly.hddActive}
                                onChange={() => this.toggleActiveCddOrHdd('hddActive')}
                                value="cdd"
                                color="secondary"
                            />
                        }
                        label="HDD (Heating Degree Days)"
                    />
                    <FormControlLabel
                        control={
                            <Input
                                // className={classes.input}
                                value={this.state.monthly.hddTemp}
                                margin="dense"
                                onChange={(event) => this.changeTemperature(event, 'hdd')}
                                // onBlur={handleBlur}
                                inputProps={{
                                    step: 1,
                                    min: 50,
                                    max: 75,
                                    type: 'number',
                                    'aria-labelledby': 'input-slider',
                                }}
                            />
                        }
                        label=<Typography>HDD Reference Temperature <span>&#176;</span>F</Typography>
                    />
                </Grid>
                
            </Grid>
        )
    }
}

export default withStyles(styles)(TemperatureSettings)