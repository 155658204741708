import React from 'react';

// Material UI
import {makeStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

//Containers
import ProjectsTable from '../containers/ProjectsTable'
//import CreateProjectDialogContainer from '../../containers/Dialogs/CreateProjectDialogContainer'

//Material UI
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import {Grid, Typography} from "@material-ui/core";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import {green, orange} from '@material-ui/core/colors';


// Components
import DashboardHelp from './DashboardHelp'
import CreateProjectDialog from './CreateProjectDialog'
import EditProjectDialog from './EditProject/EditProjectDialog'
import DeleteProjectDialog from './DeleteProjectDialog'
import AddNewButton from "../../components/CustomButtons/AddNewButton";


import CustomSnackbar from '../../components/Snackbar/CustomSnackbar'
import FacilitiesTable from "../../ProjectHome/containers/FacilitiesTable";
import CustomInfoLayout from "../../components/CustomInfoLayout/CustomInfoLayout";

import AddCircleIcon from '@material-ui/icons/AddCircle';
import EmptyGif from "../../components/EmptyGif/EmptyGif";

import projectsGif from '../../assets/gifs/projects.gif'
import {PulseLoader} from "react-spinners";
import CustomPulseLoader from "../../components/Spinners/pulse-loader";
import DataViewLoadingSpinner from "../../FacilityHome/components/spinners/DataViewLoadingSpinner";
import GreenButton from "../../components/CustomButtons/GreenButton";
import Icon from "@material-ui/core/Icon";


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    title: {
        flexGrow: 1,
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        color: theme.palette.common.white,
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[600],
        }
    },
    button: {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.success.contrast,
        borderRadius: 20,
        '&:hover': {
            backgroundColor: theme.palette.success.dark,
        }
    }
}));


export default function MainDashboard(props) {
    const classes = useStyles();
    const {
        loading,
        fetchingProjects,
        projects,
        //create project
        createProjectDialogOpen,
        toggleCreateProjectDialog,
        createProjectFormControls,
        handleCreateProjectInputChange,
        onCreateProjectSubmit,
        attemptedCreateProjectSubmit,

        //edit project
        editProjectDialogOpen,
        toggleEditProjectDialog,
        editProjectFormControls,
        handleEditProjectInputChange,
        onEditProjectSubmit,
        attemptedEditProjectSubmit,
        selectedProjectForEdit,

        // delete project
        toggleDeleteProjectDialog,
        deleteProjectDialogOpen,
        selectedProjectForDelete,
        onDeleteProjectSubmit,

        //snackbar
        snackbar,
        openSnackbar
    } = props;


    return (
        <div className={classes.root}>
            <CustomSnackbar
                snackbar={snackbar}
                openSnackbar={openSnackbar}
            />
            

            <Grid container>
                {/*{!fetchingProjects ? <CustomPulseLoader/> :*/}
                {/*    projects.length !== 0 ?*/}
                {/*        <EmptyGif*/}
                {/*            title={"Not Projects Yet"}*/}
                {/*            message={"No projects have been added yet"}*/}
                {/*            buttonText={'Add Project'}*/}
                {/*            gif={projectsGif}*/}
                {/*            onClick={toggleCreateProjectDialog}*/}
                {/*        />*/}
                {/*        : <Grid item xs={12}>*/}
                {/*            <CustomInfoLayout*/}
                {/*                title="Projects"*/}
                {/*                topRightContent={<AddNewButton onClick={toggleCreateProjectDialog}*/}
                {/*                                               text={'Create New Project'}/>}*/}
                {/*                mainContent={*/}
                {/*                    <ProjectsTable*/}
                {/*                        toggleEditProjectDialog={toggleEditProjectDialog}*/}
                {/*                        toggleDeleteProjectDialog={toggleDeleteProjectDialog}*/}
                {/*                        toggleCreateProjectDialog={toggleCreateProjectDialog}*/}
                {/*                    />}*/}
                {/*            />*/}
                
                {/*        </Grid>}*/}
                
                {!fetchingProjects && projects.length !== 0 ?
                    <Grid item xs={12}>
                        <CustomInfoLayout
                            title="Projects"
                            topRightContent={<GreenButton onClick={toggleCreateProjectDialog}><Icon style={{marginRight: 5}}>add_circle</Icon>Add Project</GreenButton>}
                            mainContent={
                                <ProjectsTable
                                    toggleEditProjectDialog={toggleEditProjectDialog}
                                    toggleDeleteProjectDialog={toggleDeleteProjectDialog}
                                    toggleCreateProjectDialog={toggleCreateProjectDialog}
                                />}
                        />

                    </Grid> : !fetchingProjects && projects.length === 0 ?
                        <Grid item xs={12}>
                            <Grid container>
                                <EmptyGif
                                    title={"Not Projects Yet"}
                                    message={"No projects have been added yet"}
                                    buttonText={'Add Project'}
                                    gif={projectsGif}
                                    onClick={toggleCreateProjectDialog}
                                />
                            </Grid>
                            
                        </Grid>
                         :
                        <Grid item xs={12}>
                            <Grid container justify={"center"}>
                                <DataViewLoadingSpinner message={'Fetching your projects...'}/>
                            </Grid>
                        </Grid>
                }

         
            </Grid>
            <CreateProjectDialog
                createProjectDialogOpen={createProjectDialogOpen}
                toggleCreateProjectDialog={toggleCreateProjectDialog}
                createProjectFormControls={createProjectFormControls}
                handleCreateProjectInputChange={handleCreateProjectInputChange}
                onCreateProjectSubmit={onCreateProjectSubmit}
                attemptedCreateProjectSubmit={attemptedCreateProjectSubmit}
                loading={loading}
            />


            <DeleteProjectDialog
                toggleDeleteProjectDialog={toggleDeleteProjectDialog}
                deleteProjectDialogOpen={deleteProjectDialogOpen}
                selectedProjectForDelete={selectedProjectForDelete}
                onDeleteProjectSubmit={onDeleteProjectSubmit}
            />


        </div>
    );
}
