import {
    TOGGLE_DELETE_PROJECT_DIALOG,
    TOGGLE_EDIT_PROJECT_DIALOG,
    PROJECT_SELECTED,
    GET_PROJECT_FACILITIES,
    EDIT_PROJECT_PROPERTY,
    SET_FETCHING_FACILITIES
} from '../actions/project-actions'

const selectedProject = (state = null, action) => {
    switch (action.type) {
        case PROJECT_SELECTED:
            return action.payload;
    }
    return state
}

const facilitiesOfProject = (state = [], action) => {
    switch (action.type) {
        case GET_PROJECT_FACILITIES:
            return action.payload;
    }
    return state
};

const toggleDeleteProjectDialog = (state = false, action) => {
    switch (action.type) {
        case TOGGLE_DELETE_PROJECT_DIALOG:
            return action.payload
    }
    return { open: false, project: null };
};

const toggleEditProjectDialog = (state = false, action) => {
    switch (action.type) {
        case TOGGLE_EDIT_PROJECT_DIALOG:
            return action.payload;
        case EDIT_PROJECT_PROPERTY:
            const { event, project } = action.payload;
            let editedProject = { ...project };
            editedProject[event.currentTarget.name] = event.currentTarget.value
            return { open: true, project: editedProject }
    }
    return { open: false, project: null };
};

const fetchingFacilities = (state = false, action) => {
    switch (action.type) {
        case SET_FETCHING_FACILITIES:
            return action.payload
    }
    return state
};


export { selectedProject, facilitiesOfProject, toggleDeleteProjectDialog, toggleEditProjectDialog, fetchingFacilities }