import React, {Component} from 'react'
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {makeStyles} from "@material-ui/core/styles";
import CustomDialog from "../../../../../components/Dialogs/customDialog";
import moment from "moment";

const useStyles = makeStyles(theme => ({
    // button: {
    //     color: 'red'
    // }
}));

function EditSubscriptionDialog(props) {


    const {open, handleClose, onSubmit, userInfo} = props;
    const classes = useStyles();

    return (
        <CustomDialog
            open={open}
            onClose={handleClose}
            subTitle={'Edit Subscription?'}
            content={<Typography align={"center"}>The ability to edit your subscription online is coming soon. Until then,
                please reach out to us at support@reactenergy.io to request subscription changes.</Typography>}
        />
    )

}

export default EditSubscriptionDialog