import Highcharts from 'highcharts';
import moment from 'moment';
import 'moment-timezone';
import blue from '@material-ui/core/colors/blue';

let colors = [blue[100], blue[200], blue[300], blue[400], blue[500], blue[600], blue[700], blue[800], blue[900]];

const loadCurveOptions = (series, dataType, timezone) => {
    let fixedSeries = [];
    series.forEach(x => {
        let temp = {
            name: x.name,
            data: []
        };
        
        

        x.data.forEach(y => {
            // debugger;
            let minutes = y[0] % 60;
            let hours = Math.floor(y[0] / 60);
            let time = moment().hours(hours).minutes(minutes).second(0).millisecond(0);

            temp.data.push([time.valueOf(), y[1]]);
            // temp.data.push([moment().hours(hours).minutes(minutes).valueOf(), y[1]]);
        });
        fixedSeries.push(temp);
        
    });

    let axisTitle;
    let tooltipSuffix;
    switch (dataType) {
        case 'kw':
            axisTitle = 'Demand (kW)';
            tooltipSuffix = 'kW';
            break;
        case 'kwh':
            axisTitle = 'Usage (kWh)';
            tooltipSuffix = 'kWh';
            break;
    }
    
    let legendEnabled = true;
    if (fixedSeries.length > 30) {
        legendEnabled = false
    }

    return {
        // colors: colors,
        chart: {
            type: 'line',
            height: null,
            style: {
                fontFamily: "Roboto"
            }
        },
        time: {
            timezone: timezone
        }, 
        legend: {
            enabled: legendEnabled
        },
        title: {
            text: ''
        },
        subtitle: {
            text: ''
        },
        xAxis: {
            type: 'datetime',
            labels: {
                formatter: function () {
                    let time = moment(this.value).format('H:mm');
                    return '<a>' +
                        time + '</a>';
                }
            }
        },
        yAxis: {
            // min: 0,
            title: {
                text: axisTitle
            }
        },
        tooltip: {
            // headerFormat: '<span style="font-size:2em; text-align: center; width: 100%">{point.key}</span><table>',
            // pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            //     '<td style="padding:0"><b>{point.y:.2f} {tooltipSuffix}</b></td></tr>',
            // footerFormat: '</table>',
            
            // use this if shared is true
            // formatter: function() {
            //     let temp = this;
            //     debugger;
            //     let time = moment(this.x).format('H:mm');
            //     let array = [];
            //     this.points.forEach(point => {
            //         array.push({
            //             name: point.series.name,
            //             value: point.y.toFixed(2)
            //         })
            //     });
            //
            //     let final = '<a>' + time +'</a>';
            //
            //     array.forEach(x => {
            //         final += '<br/><a>' + x.name +':</a>' + '<a>' + x.value + '</a>'
            //     })
            //
            //
            //     return final
            // },
            formatter: function() {
                // let temp = this;
                // debugger;
                let time = moment(this.x).format('H:mm');
                let name = this.series.name;
                let value = this.y.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                let color = this.color;
                let line = '<svg height="20" width="30"><line x1="0" y1="13" x2="30" y2="13" style="stroke:' + color +';stroke-width:2" /></svg> ';
                
                let final = '<div font-size: 18px"><center>' + time +'</center></div>' + '<div font-size: 18px"><center>' + name +'</center></div><div style="font-weight: 800; font-size: 20px" >' + line + value + ' ' + tooltipSuffix + ' ' + line + '</div>';

                return final
            },
            shared: false,
            useHTML: true,
            backgroundColor: '#fafafa',
            xDateFormat: '%H:%M',
            // pointFormatter: function () {
            //     console.log(this);
            //     // let dateTime = moment.utc(this.x).add(this.y, 'minutes').format("ddd M/D/YY HH:mm");
            //     // let line = '<svg height="20" width="30"><line x1="0" y1="13" x2="30" y2="13" style="stroke:rgb(255,0,0);stroke-width:2" /></svg> '
            //
            //     return this.y
            //
            // }
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0
            },
            series: {
                marker: {
                    enabled: false
                }
            }
        },
        series: fixedSeries,
        credits: {
            enabled: false
        },
        exporting: {
            enabled: true
        }
    }
}

const summaryChartOptions = (series, categories, height = 500) => {
    return {
        // colors: [blue[300], orange[300], green[300], grey[300], red[300]],
        chart: {
            type: 'column',
            height: height,
            style: {
                fontFamily: "Roboto"
            },
            zoomType: 'x'
        },
        title: {
            text: ''
        },
        subtitle: {
            text: ''
        },
        xAxis: {
            categories: categories,
            //crosshair: true,
            events: {
                setExtremes: null
            },
        },
        yAxis: [
            {
                //min: 0,
                title: {
                    text: 'Peak Demand (kW)'
                },
                showEmpty: false
            },
            {
                //min: 0,
                title: {
                    text: 'Consumption (kWh)'
                },
                opposite: true,
                showEmpty: false
            },
            {
                //min: 0,
                title: {
                    text: 'Total Consumption (kWh)'
                },
                opposite: true,
                showEmpty: false
            },
            {
                //min: 0,
                title: {
                    text: 'Load Factor'
                },
                showEmpty: false
            }
        ],
        tooltip: {
            headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
            pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                '<td style="padding:0"><b>{point.y:.2f}</b></td></tr>',
            footerFormat: '</table>',
            //shared: true,
            useHTML: true,
            //backgroundColor: '#fafafa'
        },
        plotOptions: {
            // column: {
            //     grouping: false,
            // }
            column: {
                pointPadding: 0.2
            }
        },
        series: series,
        credits: {
            enabled: false
        },

    }
}

const heatMapOptions = (data, durationView, intervalDuration, dataType) => {
    let chartFont = 'Roboto';
    let tooltipSuffix;
    switch (dataType) {
        case 'kw':
            tooltipSuffix = 'kW';
            break;
        case 'kwh':
            tooltipSuffix = 'kWh';
            break;
    }
    
    let height;
    let tickPositions;
    let tickInterval;
    let xAxisLabels;
    let marginLeft;
    let tickLength;
    let exporting = {
        sourceWidth: 3000,
        sourceHeight: 1500,
    };
    if (durationView === 'week') {
        data.forEach(x => {
            let time = moment.utc(x[0]).add(x[1], 'minutes');
            x[0] = time.clone().day(0).hour(0).minutes(0).seconds(0).millisecond(0).valueOf();
            x[1] = time.day() * 24 * 60 + time.hours() * 60;
        });
        height = data.length / 20 + 400;
        tickPositions = [1440, 2880, 4320, 5760, 7200, 8640, 10080];
        tickInterval = 24 * 3600 * 1000 * 7;
        xAxisLabels = {
            align: 'right',
            style: {
                fontFamily: chartFont,
                fontSize: '15px',
            },
            x: -20,
            y: 5,
            rotation: 0,
            format: '{value:%b %e, %y}' // long month
        };
        marginLeft = 100;
        tickLength = 0;
    } else {
        height = data.length / 5 + 400;
        tickPositions = [180, 360, 540, 720, 900, 1080, 1260];
        tickInterval = 24 * 3600 * 1000 * 30;
        xAxisLabels = {
            align: 'right',
            style: {
                fontFamily: chartFont,
                fontSize: '15px',
            },
            x: -20,
            y: 10,
            rotation: -90,
            format: '{value:%b %y}' // long month
        };
        marginLeft = 50;
        tickLength = 51;
        exporting = {
            sourceWidth: 2000,
            sourceHeight: 3000,
        };
    }
        

    return {
        legend: {
            layout: 'horizontal',
            verticalAlign: 'top',
            y: 0,
            symbolHeight: 40,
            symbolWidth: 600,
            // width: '100%',
            // padding: 20,
            // borderRadius: 5,
            // shadow: true
        },

        chart: {
            type: 'heatmap',
            marginTop: 150,
            marginBottom: 20,
            height: height,
            marginLeft: marginLeft,
            marginRight: 50,
            inverted: true,
            plotBorderWidth: 1,
            borderRadius: 20,
            style: {
                fontFamily: chartFont
            }
        },

        boost: {
            useGPUTranslations: true
        },

        title: {
            text: null,
            align: 'left',
            x: 40
        },

        subtitle: {
            text: null,
            align: 'left',
            x: 40
        },

        xAxis: {
            type: 'datetime',
            //startOnTick: true,
            labels: xAxisLabels,
            showLastLabel: false,
            tickLength: tickLength,
            startOnTick: false,
            endOnTick: false,
            tickmarkPlacement: 'on',
            //datetime axes are based on milliseconds, so for example an interval of one day is expressed as 24 * 3600 * 1000
            tickInterval: tickInterval,
            //tickColor: 'black',
        },

        yAxis: {
            title: {
                text: null
            },
            type: 'datetime',
            //labels: {
            //    format: '{value}'
            //},
            labels: {
                formatter: function () {
                    if (durationView === 'day') {
                        if (intervalDuration === 15) {
                            let hours = Math.floor((this.value) / 60);
                            let tempMinutes = this.value % 60;
                            let minutes;
                            if (tempMinutes === 0) {
                                minutes = '00'
                            } else {
                                minutes = tempMinutes
                            }
                            return hours + ':' + minutes;
                        } else if (intervalDuration === 60) {
                            let hours = Math.floor((this.value) / 60);
                            let tempMinutes = this.value % 60;
                            let minutes;
                            if (tempMinutes === 0) {
                                minutes = '00'
                            } else {
                                minutes = tempMinutes
                            }
                            return hours + ':' + minutes;
                        }
                    } else {
                        return moment().day(this.value / 1440).format('ddd');
                    }
                    

                },
                style: {
                    fontFamily: chartFont,
                    fontSize: '15px',
                    //color: 'black'

                }
            },
            startOnTick: false,
            endOnTick: false,
            tickWidth: 1,
            reversed: false,
            opposite: true,
            //tickPositions: [360, 720, 1080],
            tickPositioner: function () {
                return tickPositions
            }
        },

        colorAxis: {
            stops: [
                [0, '#003d99'],
                [0.2, '#66a3ff'],
                [0.4, '#63BE7B'],
                [0.6, '#fae052'],
                [0.8, '#f40b0f'],
                [1, '#b30000']
            ],
            startOnTick: false,
            endOnTick: false,
            labels: {
                format: '{value} ' + tooltipSuffix
            },
        },


        plotOptions: {
            series: {
                //pointWidth: 5,
                pointPadding: 0,
                groupPadding: 0,
                shadow: false,
            },
            borderColor: 'black',
        },

        tooltip: {
            backgroundColor: {
                linearGradient: [0, 0, 0, 60],
                stops: [
                    [0, '#FFFFFF'],
                    [1, '#E0E0E0']
                ]
            },
            borderWidth: 1,
            borderColor: '#AAA',
            style: {
                color: 'black',
                fontSize: '15px'
            }
        },

        series: [
            {
                data: data,
                boostThreshold: 100,
                // rowsize: 14,
                rowsize: 60, // how many minutes does the cell cover 
                borderWidth: .1,
                borderColor: 'black',
                nullColor: '#EFEFEF',
                // colsize: 24 * 36e5, // one day in milliseconds
                colsize: 24 * 36e5 * 7, // one day in milliseconds
                tooltip: {
                    headerFormat: '',
                    useHTML: true,

                    //pointFormat: '{point.x:%e %b, %Y} {point.y}:00: <b>{point.value}</b>'

                    pointFormatter: function () {
                        let dateTime = moment.utc(this.x).add(this.y, 'minutes').format("ddd M/D/YY HH:mm");
                        let line = '<svg height="20" width="30"><line x1="0" y1="13" x2="30" y2="13" style="stroke:rgb(255,0,0);stroke-width:2" /></svg> '

                        return '<div font-size: 15px"><center>' + dateTime + '</center></div><br/><div style="color: ' + this.color + '; font-weight: 800; font-size: 20px" >' + line + this.value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' ' + tooltipSuffix + ' ' + line + '</div>';

                    }
                },
                turboThreshold: 0 // #3404, remove after 4.0.5 release
            }
        ],
        credits: {
            enabled: false
        },

        exporting: exporting
    }
}

const scatterPlotOptions = (data, dataType, durationView, timezone, intervalDuraton) => {
    let chartFont = 'Roboto';
    let tooltipSuffix;
    let yAxisTitle;
    switch (dataType) {
        case 'kw':
            tooltipSuffix = 'kW';
            yAxisTitle = "Demand (kW)";
            break;
        case 'kwh':
            tooltipSuffix = 'kWh';
            yAxisTitle = "Consumption (kWh)";
            break;
    };
    let ceiling = -1 * (intervalDuraton / 2);
    let floor = intervalDuraton / 2;
    
    let meterSeries = [];
    let min = Math.ceil(ceiling);
    let max = Math.floor(floor);

    let tickInterval;
    if (durationView === "week") {
        tickInterval = 60 * 12;
        data.forEach(x => {
            // let timeStamp = 
            meterSeries.push({
                x: moment.tz(x[0], timezone).day() * (24*60) + moment.tz(x[0], timezone).minutes() + moment.tz(x[0], timezone).hour() * 60 + (Math.floor(Math.random() * (max - min)) + min),
                y: x[1],
                // color: "#FF0000",
                date: moment.tz(x[0], timezone).format("MMM D, YYYY"),
                day: moment.tz(x[0], timezone).format("dddd"),
                time: moment.tz(x[0], timezone).format("H:mm"),
            });
            // let temp = series;
            // debugger;
        });
    } else {
        tickInterval = 60;
        data.forEach(x => {
            meterSeries.push({
                x: moment.tz(x[0], timezone).minutes() + moment.tz(x[0], timezone).hour() * 60 + (Math.floor(Math.random() * (max - min)) + min),
                y: x[1],
                date: moment.tz(x[0], timezone).format("MMM D, YYYY"),
                day: moment.tz(x[0], timezone).format("dddd"),
                time: moment.tz(x[0], timezone).format("H:mm")
            })
        });
    };
    
    
    
   
    return {
        chart: {
            // type: 'scatter',
            zoomType: 'xy',
            style: {
                fontFamily: chartFont
            }
        },
        time: {
            // useUTC: true
            timezone: timezone
        },
        title: {
            text: ''
        },
        subtitle: {
            text: ''
        },
        boost: {
            useGPUTranslations: true,
            usePreAllocated: true
        },
        xAxis: {
            // type: 'datetime',
            tickInterval: tickInterval,
            labels: {
                formatter: function () {
                    let label;
                    // let value = this.value;
                    // debugger;
                    
                    if (durationView === "day") {
                        let hours = this.value/60;
                        label = moment().hour(hours).minutes(0).format('H:mm');
                    } else {
                        let days = Math.floor(this.value/1440);
                        let minutes = this.value % 1440;
                        let hours = minutes / 60;
                        
                        label = moment().day(days).hour(hours).minutes(0).format('ddd H:mm');
                    }
                    
                    return '<a>' +
                        label + '</a>';
                }
            }
        },
        yAxis: {
            title: {
                text: yAxisTitle
            }
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            scatter: {
                marker: {
                    radius: 1.5,
                    states: {
                        hover: {
                            enabled: true,
                            lineColor: 'rgb(100,100,100)'
                        }
                    }
                },
                states: {
                    hover: {
                        marker: {
                            enabled: false
                        }
                    }
                },
                tooltip: {
                    useHTML: true,
                    headerFormat: '',
                    pointFormatter: function () {
                        console.log(this);
                        return '<div font-size: 15px> Date: ' + this.date + '</div>' + '<br/><div font-size: 15px>Day: ' + this.day + '</div>' + '<br/><div font-size: 15px>Time: ' + this.time + '</div>' +'<br/><div font-size: 15px>' + tooltipSuffix + ': ' + this.y.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") +'</div>';

                    }
                }
            }
        },
        series: [{
            name: 'Series',
            type: 'scatter',
            turboThreshold: 0,
            // setting boostthreshold to 0 will allow custom colors per point. by default if the series is longer than 2000 highcharts
            // will enter boost mode where boostmode where it only accepts an x and y parameter, not color
            // boostThreshold: 0,
            color: 'rgba(33, 150, 243, .5)',
            data: meterSeries,
        }], 
        credits: {
            enabled: false
        }
    }
};

const cbecsZoomableOptions = (data, dataType) => {
    let axisTitle;
    let tooltipSuffix;
    
    let cbecs = {
        spaceHeating: 0.02011263,
        cooling: .14883347,
        ventilation: .15768302,
        waterHeating: .00482703,
        lighting: 0.17055511,
        cooking: 0.02172164,
        refrigeration: 0.15768302,
        officeEquipment: 0.04022526,
        computing: 0.09573612,
        other: 0.18101386
        
    };

    switch (dataType) {
        case 'kw':
            axisTitle = 'Demand (kW)';
            tooltipSuffix = 'kW';
            break;
        case 'kwh':
            axisTitle = 'Usage (kWh)';
            tooltipSuffix = 'kWh';
            break;
    };
    
    let spaceHeating = [];
    let cooling = [];
    let ventilation = [];
    let waterHeating = [];
    let lighting = [];
    let cooking = [];
    let refrigeration = [];
    let officeEquipment = [];
    let computing = [];
    let other = [];
    
    data.forEach(x => {
        let dateTimeStamp = x[0];
        let value = x[1];
        spaceHeating.push([dateTimeStamp, value*cbecs.spaceHeating]);
        cooling.push([dateTimeStamp, value*cbecs.cooling]);
        ventilation.push([dateTimeStamp, value*cbecs.ventilation]);
        waterHeating.push([dateTimeStamp, value*cbecs.waterHeating]);
        lighting.push([dateTimeStamp, value*cbecs.lighting]);
        cooking.push([dateTimeStamp, value*cbecs.cooking]);
        refrigeration.push([dateTimeStamp, value*cbecs.refrigeration]);
        officeEquipment.push([dateTimeStamp, value*cbecs.officeEquipment]);
        computing.push([dateTimeStamp, value*cbecs.computing]);
        other.push([dateTimeStamp, value*cbecs.other]);
        

    });
    
    let series = [{
        // type: 'area',
        name: 'Space Heating',
        data: spaceHeating,
        // fillColor: "#FF0000",
    }, {
        // type: 'area',
        name: 'Cooling',
        data: cooling,
        // fillColor: '#0800FF',
    }, {
        // type: 'area',
        name: 'Ventilation',
        data: ventilation,
        // fillColor: '#0800FF',
    }, 
        {
        // type: 'area',
        name: 'Water Heating',
        data: waterHeating,
        // fillColor: '#0800FF',
    }, 
        {
        // type: 'area',
        name: 'Lighting',
        data: lighting,
        // fillColor: '#0800FF',
    }, 
        {
        // type: 'area',
        name: 'Cooking',
        data: cooking,
        // fillColor: '#0800FF',
    }, 
        {
        // type: 'area',
        name: 'Refrigeration',
        data: refrigeration,
        // fillColor: '#0800FF',
    }, {
        // type: 'area',
        name: 'Office Equipment',
        data: officeEquipment,
        // fillColor: '#0800FF',
    }, {
        // type: 'area',
        name: 'Computing',
        data: computing,
        // fillColor: '#0800FF',
    }, {
        // type: 'area',
        name: 'Other',
        data: other,
        // fillColor: '#0800FF',
    }
    ];

    let options = {
        chart: {
            zoomType: 'x',
            height: 500,
            style: {
                fontFamily: "Roboto"
            },
        },
        time: {
            useUTC: true
        },
        title: {
            text: ''
        },
        subtitle: {
            text: document.ontouchstart === undefined ?
                '' : ''
        },
        xAxis: {
            type: 'datetime'
        },
        yAxis: {
            title: {
                text: axisTitle
            },
            opposite: true
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            area: {
                stacking: 'normal',
                // fillColor: {
                //     linearGradient: {
                //         x1: 0,
                //         y1: 0,
                //         x2: 0,
                //         y2: 1
                //     },
                //     stops: [
                //         [0, Highcharts.getOptions().colors[0]],
                //         [1, Highcharts.Color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
                //     ]
                // },

                marker: {
                    radius: 2
                },
                lineWidth: 1,
                states: {
                    hover: {
                        lineWidth: 1
                    }
                },
                // threshold: null
            }
        },
        tooltip: {
            formatter: function () {
                console.log(this);
                //debugger;
                console.log("Time range: ", this.points[0].series.closestPointRange);
                let timeRange = this.points[0].series.closestPointRange;
                let startDateTime = moment.utc(this.x).subtract(timeRange, 'milliseconds')
                //.format("ddd M/D/YY HH:mm");
                let endDateTime = moment.utc(this.x)
                let diffDays = endDateTime.date() !== startDateTime.date();
                let timeString;
                if (diffDays === true) {
                    timeString = '<div font-size: 18px"><center>' + startDateTime.format("ddd M/D/YY HH:mm") + '<br/> to ' + endDateTime.format("ddd M/D/YY HH:mm") + '</center></div>'
                } else {
                    timeString = '<div font-size: 18px"><center>' + endDateTime.format("ddd M/D/YY") + ' <br /> ' + startDateTime.format("HH:mm") + ' to ' + endDateTime.format("HH:mm") + '</center></div>'
                }
                let color;
                if (this.y >= 0) {
                    color = this.points[0].series.color;
                } else {
                    color = 'rgba(76, 175, 80, 1)'
                }

                let line = '<svg height="20" width="30"><line x1="0" y1="13" x2="30" y2="13" style="stroke:' + color +';stroke-width:2" /></svg> '
                //return '<div font-size: 15px"><center>' + startDateTime + '<br/> to ' + endDateTime + '</center></div><br/><div style="color: ' + color + '; font-weight: 800; font-size: 20px" >' + line + this.y.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' kW ' + line + '</div>';
                return timeString + '<div style="color: ' + color + '; font-weight: 800; font-size: 20px" >' + line + this.y.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' ' + tooltipSuffix + ' ' + line + '</div>';
            },
            useHTML: true,
            backgroundColor: '#fafafa'
        },
        series: series,

        credits: {
            enabled: false
        }
    };

    return options
};


const zoomableOptions = (data, dataType, timezone, temperatureData = null, plotLines = null, plotBands = null) => {
   let axisTitle;
   let tooltipSuffix;
   
    switch (dataType) {
       case 'kw':
           axisTitle = 'Demand (kW)';
           tooltipSuffix = 'kW';
           break;
        case 'kwh':
            axisTitle = 'Usage (kWh)';
            tooltipSuffix = 'kWh';
            break;
   };

    let series = [{
        type: 'area',
        name: axisTitle,
        data: data,
        negativeColor: 'rgba(76, 175, 80, 1)',
        negativeFillColor: {
            linearGradient: {
                x1: 0,
                y1: 1,
                x2: 0,
                y2: 0
            },
            stops: [
                [0, 'rgba(76, 175, 80, 1)'],
                [1, 'rgba(76, 175, 80, 0)']
            ]
        },
        fillColor: {
            linearGradient: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 1
            },
            stops: [
                [0, Highcharts.getOptions().colors[0]],
                [1, Highcharts.Color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
            ]
        },
    }];
    
    let yAxis = [{
        title: {
            text: axisTitle
        },
        // opposite: true
    }, {
        title: {
            text: ''
        },
    }]
    
    if (temperatureData) {
        series.push({
            type: 'line',
            name: 'Temperature',
            data: temperatureData,
            yAxis: 1,
            color: 'rgb(255, 0, 0, 0.5)',
            dashStyle: 'ShortDot'
        });
        
        //for some reason pushing the whole object doesn't work quite as expected so this was an easy work around
        yAxis[1].title.text = 'Temperature'
    };

    const tooltipFunction = (point) => {
        // debugger;
        let timeRange = point.points[0].series.closestPointRange;
        let startDateTime = moment.tz(point.x, timezone).subtract(timeRange, 'milliseconds')
        let endDateTime = moment.tz(point.x, timezone)
        let diffDays = endDateTime.date() !== startDateTime.date();
        let timeString;
        if (diffDays === true) {
            timeString = '<div font-size: 18px"><center>' + startDateTime.format("ddd M/D/YY HH:mm") + '<br/> to ' + endDateTime.format("ddd M/D/YY HH:mm") + '</center></div>'
        } else {
            timeString = '<div font-size: 18px"><center>' + endDateTime.format("ddd M/D/YY") + ' <br /> ' + startDateTime.format("HH:mm") + ' to ' + endDateTime.format("HH:mm") + '</center></div>'
        }
        let color;
        if (point.y >= 0) {
            color = point.points[0].series.color;
        } else {
            color = 'rgba(76, 175, 80, 1)'
        }
        let color2 = 'rgb(255, 0, 0, 0.5)';
        let line = '<svg height="20" width="30"><line x1="0" y1="13" x2="30" y2="13" style="stroke:' + color +';stroke-width:2" /></svg> ';
        let tempLine = '<svg height="20" width="30"><line x1="0" y1="13" x2="30" y2="13" style="stroke:' + color2 +';stroke-width:2" /></svg> ';

        let final = timeString +
            '<div style="color: ' + color + '; font-weight: 800; font-size: 20px" >' +  line + point.y.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' ' + tooltipSuffix + ' ' + line + '</div>';
        if (temperatureData !== null && point.points.length > 1) {
            final += '<div style="color: ' + color2 + '; font-weight: 800; font-size: 20px" ><center>' +  tempLine + point.points[1].y.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '&degF' + ' ' + tempLine + '<center/></div>'
        }
        // return timeString +
        //     '<div style="color: ' + color + '; font-weight: 800; font-size: 20px" >' +  line + point.y.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' ' + tooltipSuffix + ' ' + line + '</div>'
        //     + '<div style="color: ' + color2 + '; font-weight: 800; font-size: 20px" >' +  tempLine + point.points[1].y.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '&degF' + ' ' + tempLine + '</div>'
        //     ;

        return final
    };

    return {
        chart: {
            zoomType: 'x',
            height: 500,
            style: {
                fontFamily: "Roboto"
            },
        },
        time: {
            // useUTC: true
            timezone: timezone
        },
        title: {
            text: ''
        },
        subtitle: {
            text: document.ontouchstart === undefined ?
                '' : ''
        },
        xAxis: {
            type: 'datetime',
            
            plotBands: plotBands,
            plotLines: plotLines,
        },
        yAxis: yAxis,
        legend: {
            enabled: false
        },
        plotOptions: {
             area: {
                 // stacking: 'normal',
                fillColor: {
                    linearGradient: {
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 1
                    },
                    stops: [
                        [0, Highcharts.getOptions().colors[0]],
                        [1, Highcharts.Color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
                    ]
                },

                marker: {
                    radius: 2
                },
                lineWidth: 1,
                states: {
                    hover: {
                        lineWidth: 1
                    }
                },
                // threshold: null
            }
        },
        tooltip: {
            formatter: function () {
                return tooltipFunction(this);
            },
            useHTML: true,
            backgroundColor: '#fafafa'
        },
        series: series,

        credits: {
            enabled: false
        }
    }
}

const demandDurationOptions = (data, dateTimeArray) => {

    //let data = intervalDataForDemandDuration.data;

    return {
        chart: {
            zoomType: 'x',
            height: 500,
            style: {
                fontFamily: "Roboto"
            }
        },
        title: {
            text: ''
        },
        subtitle: {
            text: document.ontouchstart === undefined ?
                '' : ''
        },
        xAxis: {
            labels: {
                formatter: function () {
                    let totalDifference = data[data.length - 1][0] - data[0][0];
                    let difference = this.value - data[0][0];
                    let percent = (difference / totalDifference) * 100;

                    return percent.toFixed(2) + '%'
                }
            }
        },
        navigator: {
            xAxis: {
                labels: {
                    formatter: function () {
                        let totalDifference = data[data.length - 1][0] - data[0][0];
                        let difference = this.value - data[0][0];
                        let percent = (difference / totalDifference) * 100;

                        return percent.toFixed(2) + '%'
                    }
                }
            },
        },
        tooltip: {
            formatter: function () {
                let totalDifference = data[data.length - 1][0] - data[0][0];
                let difference = this.x - data[0][0];
                let percent = (difference / totalDifference) * 100;
                let index = this.points[0].point.index;
                let dateStamp = dateTimeArray[index].intervalDateTime;

                return 'kW: ' + this.y.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '<br /> Percent: ' + percent.toFixed(2) + '%' + '<br /> ' + moment.utc(dateStamp).format('MMMM Do YYYY, h:mm a')
            },
        },
        yAxis: {
            title: {
                text: 'Demand (kW)'
            }
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            area: {
                fillColor: {
                    linearGradient: {
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 1
                    },
                    stops: [
                        [0, Highcharts.getOptions().colors[0]],
                        [1, Highcharts.Color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
                    ]
                },
                marker: {
                    radius: 2
                },
                lineWidth: 1,
                states: {
                    hover: {
                        lineWidth: 1
                    }
                },
                threshold: null
            }
        },
        rangeSelector: {
            enabled: false
        },
        series: [{
            type: 'area',
            name: 'Demand (kW)',
            data: data,
            fillColor: {
                linearGradient: {
                    x1: 0,
                    y1: 0,
                    x2: 1,
                    y2: 0
                },
                stops: [
                    [0, Highcharts.getOptions().colors[0]],
                    [1, Highcharts.Color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
                ]
            },
        }],
        credits: {
            enabled: false
        }
    }
}

export {loadCurveOptions, summaryChartOptions, heatMapOptions, zoomableOptions, cbecsZoomableOptions, demandDurationOptions, scatterPlotOptions}