import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import highchartsExporting from "highcharts/modules/exporting";
import {timeOfDayMatch} from "./jsFunctions";
import moment from 'moment'
import 'moment-timezone'

window.moment = moment;

highchartsExporting(Highcharts);

const getOptions = (highchartSeries, peakDemand, showPeakDemand, timezone) => {

    if (!showPeakDemand) {
        peakDemand = null;
    }
    
    const getTempTitle = () => {
        return '<div>Temperature</div>';
    }

    return {
        chart: {
            // type: 'area',
            // height: 100,
            style: {
                fontFamily: "Roboto"
            }
        },
        time: {
          timezone: timezone  
        },
        title: {
            text: ''
        },
        xAxis: {
            crosshair: true,
            dateTimeLabelFormats: {
                hour: "%H:%M"
            },
            type: 'datetime',
            labels: {
                enabled: true
            }
        },
        yAxis: [{
            // min: 0,
            max: peakDemand,
            title: {
                text: 'Demand (kW)'
            },
            // labels: {
            //     enabled: true
            // },
            plotLines: [{
                color: '#74cc79',
                width: 2,
                value: peakDemand,
                dashStyle: 'dash',
                label: {
                    formatter: function() {
                        debugger;
                        return 'Plot line value: ';
                    }
                }
            }]
        }, {
            title: {
                text: getTempTitle()
            },
            opposite: true
        }],
        legend: {
            enabled: false
        },
        tooltip: {
            // headerFormat: '<table><tr><td>Time: </td><td>{point.key: %b %e, %H:%M}</td></tr>',
            // pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            //     '<td style="padding:0">{point.y:.2f} kW</td></tr>',
            // footerFormat: '</table>',
            formatter: function() {
                let point = this;
                let dateTimeMoment = moment.tz(this.x, timezone)
                let dayOfWeek = dateTimeMoment.format('dddd');
                let date = dateTimeMoment.format('MMM D, YYYY');
                let time = dateTimeMoment.format('h:mm A')
                let color;
                let timeString = 'TIME';
                if (point.y >= 0) {
                    color = point.points[0].series.color;
                } else {
                    color = 'rgba(76, 175, 80, 1)'
                }
                let color2 = 'rgb(255, 0, 0, 0.5)';
                let line = '<svg height="20" width="30"><line x1="0" y1="13" x2="30" y2="13" style="stroke:' + color +';stroke-width:2" /></svg> ';
                let tempLine = '<svg height="20" width="30"><line x1="0" y1="13" x2="30" y2="13" style="stroke:' + color2 +';stroke-width:2" /></svg> ';

                let final = '<div><center>' + dayOfWeek +'</center></div>' + '<div><center>' + date +'</center></div>' + '<div><center>' + time +'</center></div>' +
                    '<div style="color: ' + color + '; font-weight: 800; font-size: 20px" >' +  line + point.y.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' ' + 'kW' + ' ' + line + '</div>';
                if (highchartSeries.filter(x => x.name === 'Temperature').length !== 0) {
                    if (point.points.length > 1) {
                        final += '<div style="color: ' + color2 + '; font-weight: 800; font-size: 20px" ><center>' +  tempLine + point.points[1].y.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '&degF' + ' ' + tempLine + '<center/></div>'
                    }
                    
                }  
                return final
            },
            shared: true,
            useHTML: true,
            outside: false
        },
        plotOptions: {
            column: {
                pointPadding: 0.1,
                borderWidth: 0
            },
            area: {
                // lineWidth: 1,
                states: {
                    hover: {
                        lineWidth: 1
                    }
                },
                // threshold: null
            },
            series: {
                marker: {
                    enabled: false
                },
            }
        },
        exporting: {
            enabled: true,
        },
        series: highchartSeries,
        credits: {
            enabled: false
        }
    }

}

export default function HighchartForCalendarChartDayView(props) {
    const { series, peakDemand, showPeakDemand, comparisonLoadCurveData, showComparisonLoadCurve, selectedFacility, temperatureView } = props;
    let data = [];
    let data2 = [];
    let tempData= [];
    // let comparisonArray = [];
    series.forEach(x => {
        data.push([x.dateTimestamp, x.kW]);
        tempData.push([x.dateTimestamp, x.temperature]);
        if (showComparisonLoadCurve) {
            // let temp = comparisonLoadCurveData.filter(y => moment(x.dateTimestamp).minutes() + moment(x.dateTimestamp).hours()*60 === moment.utc(y[0]).minutes() + moment.utc(y[0]).hours()*60);
            let temp = comparisonLoadCurveData.filter(y => timeOfDayMatch(y[0], x.dateTimestamp));
            if (temp.length !== 0) {
                data2.push([x.dateTimestamp, temp[0][1]])
            }
        }     
    });
    
    
    
    let highchartSeries = [
        {
            name: 'Demand',
            data: data,
            type: 'area',
            negativeColor: true,
            negativeFillColor: {
                linearGradient: {
                    x1: 0,
                    y1: 1,
                    x2: 0,
                    y2: 0
                },
                stops: [
                    [0, 'rgba(76, 175, 80, 1)'],
                    [1, 'rgba(76, 175, 80, 0)']
                ]
            },
            fillColor: {
                linearGradient: {
                    x1: 0,
                    y1: 0,
                    x2: 0,
                    y2: 1
                },
                stops: [
                    [0, Highcharts.getOptions().colors[0]],
                    [1, Highcharts.Color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
                ]
            },
        }, {
            name: 'Average',
            data: data2,
            // fillColor: {
            //     linearGradient: {
            //         x1: 0,
            //         y1: 0,
            //         x2: 0,
            //         y2: 0
            //     },
            //     stops: [
            //         [0, Highcharts.getOptions().colors[0]],
            //         [1, Highcharts.Color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
            //     ]
            // },
        }, 
        
    ];
    
    if (temperatureView) {
        highchartSeries.push({
            name: 'Temperature',
            data: tempData,
            yAxis: 1,
            color: 'rgb(255, 0, 0, 0.5)',
            dashStyle: 'ShortDot'
        })
    }
    let options = getOptions(highchartSeries, peakDemand, showPeakDemand, selectedFacility.timezone);

    return (<HighchartsReact highcharts={Highcharts} options={options} />)

}



