import React, {Component} from 'react'
import HomeIcon from "@material-ui/icons/Home";
import LockIcon from "@material-ui/icons/Lock";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import PeopleIcon from "@material-ui/icons/People";
import SettingsIcon from "@material-ui/icons/Settings";
import DashboardIcon from "@material-ui/icons/Dashboard";
import List from "@material-ui/core/List";
import {Link} from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import { makeStyles } from '@material-ui/core/styles';
import grey from "@material-ui/core/colors/grey";
import blue from "@material-ui/core/colors/blue";
import { fade } from '@material-ui/core/styles/colorManipulator';

const linkWidth = 280;
let drawerWidth = 300;

const useStyles = makeStyles(theme => ({

    drawer: {
        width: 300,
        flexShrink: 0,
        // border: 'none',
        // whiteSpace: 'nowrap',
        // borderRight: '1px solid red'
    },
    drawerPaper: {
        width: 300,
        // borderRight: '1px solid red',
        border: 'none',
        whiteSpace: 'nowrap',
        backgroundColor: theme.palette.background.default
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(2),
    },
    toolbar: theme.mixins.toolbar,
    link: {
        color: theme.palette.grey[700],
        width: linkWidth,
        borderRadius: '0 30px 30px 0',
        //backgroundColor: grey[200],
        '&:hover': {
            color: '#000000',
        }
    },
    selectedLink: {
        color: theme.palette.primary.dark,
        width: linkWidth,
        backgroundColor: fade(theme.palette.primary.light, 0.2),
        borderRadius: '0 30px 30px 0',
    },
    
    selectedIcon: {
        color: theme.palette.primary.dark,
    }
}));

function AccountNavbar(props) {
    
    const tabs = [  {
        label: 'Account Home',
        icon: <HomeIcon />,
        link: `/account/home`
    }, {
        label: 'Password',
        icon: <LockIcon />,
        link: `/account/password`
    }, {
        label: 'Subscription',
        icon: <CreditCardIcon />,
        link: `/account/subscription`
    },{
        label: 'Team',
        icon: <PeopleIcon />,
        link: `/account/team`
    }, 
    //     {
    //     label: 'Settings',
    //     icon: <SettingsIcon />,
    //     link: `/account/settings`
    // }
    ];
    const tabs2 = [
        {
            label: 'Back to Dashboard',
            icon: <DashboardIcon/>,
            link: `/`
        },
    ];
    
    const {currentPath} = props;
    
    const classes = useStyles();
    return (
        <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
                paper: classes.drawerPaper,
            }}
        >
            <div className={classes.toolbar} />

            <List>
                {tabs.map((item, index) => (
                    <Link to={item.link} >
                        <ListItem button key={item.label} className={currentPath === item.link ? classes.selectedLink : classes.link} >
                            <ListItemIcon className={currentPath === item.link ? classes.selectedIcon : null}>{item.icon}</ListItemIcon>
                            <ListItemText primary={item.label} />
                        </ListItem>
                    </Link>
                ))}
            </List>
            <Divider />
            <List>
                {tabs2.map((item, index) => (
                    <Link to={`/`} >
                        <ListItem button className={classes.link} key={item.label} >
                            <ListItemIcon>{item.icon}</ListItemIcon>
                            <ListItemText primary={item.label} />
                        </ListItem>
                    </Link>
                ))}
            </List>
        </Drawer>
    )
}

export default AccountNavbar