import React, {Component} from 'react'
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {withStyles} from '@material-ui/core/styles';
import CardTest from "./CardTest";
import GoogleLayout from "./GoogleLayout";

const styles = theme => ({
    root: {
        width: '100%',
        height: '100%',
        minHeight: '100vh',
        backgroundColor: 'white'
    },
    container: {
        display: 'flex',
        justifyContent: 'center'
    },
    

});

class TestLayout extends Component {
    render() {
        const {classes} = this.props;
        return (
            <Grid container className={classes.container}>
                <div style={{margin: 20}}>
                    <GoogleLayout />
                </div>
               
            </Grid>
            
        )
    }
}

export default withStyles(styles)(TestLayout)