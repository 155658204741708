import React, {Component} from 'react'
import WeatherFilterForm from './WeatherFilterForm/index'

function WeatherSidebar(props) {
    const {selectedFacilitySubview} = props;
    switch (selectedFacilitySubview) {
        case 'location': 
            return <div>Location</div>;
        case 'degree-days':
            return <WeatherFilterForm />;
        case 'raw-data':
            return <div>Raw Data</div>
    }

}

export default WeatherSidebar