import moment from 'moment'

const getDataFilterMessage = (chartDataFilterForm) => {
    let summary = '';
   
    switch (chartDataFilterForm.chartType) {
        case 'load-curve':
            summary = getLoadCurveMessage(chartDataFilterForm);
            break;
        case 'summary-chart':
        case 'scatter-plot':
            summary = 'Highlight area to zoom in';
            break;
        case 'zoomable-chart':
            summary = 'Values averaged over time period shown';
            break;
        case 'heat-map':
            summary = 'Values averaged over time period shown';
            break;
    }
    
    return {
        summary,
        details: ''
    }
    
}

function getLoadCurveMessage(chartDataFilterForm) {
    let summary = '';
    let seriesTypeObject = chartDataFilterForm.seriesTypes.filter(x => x.value === true);
    let seriesTypeLabel = seriesTypeObject[0].label;
    let seriesPer = '';
    switch (chartDataFilterForm.seriesPer) {
        case 'day':
            seriesPer = 'Day';
            break;
        case 'month':
            seriesPer = 'Month';
            break;
        case 'dayOfWeek':
            seriesPer = 'Day of Week';
            break;
        case 'week':
            seriesPer = 'Week';
            break;
        case 'year':
            seriesPer = 'Year';
            break;
        case 'allData':
            seriesPer = 'All Data';
            break;
        default:
            seriesPer = '';
            
    }
    
    let dataType;
    switch (chartDataFilterForm.dataType) {
        case 'kw':
            dataType = 'Demand (kW)';
            break;
        case 'kwh':
            dataType = 'Usage (kWh)';
            break;
        default:
            dataType = '';

    }
    
    summary = seriesTypeLabel + ' ' + dataType + ' by ' +  seriesPer;
    return summary
    
}

export default getDataFilterMessage