import React from 'react';
import { connect } from 'react-redux'

//Material UI
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

//Redux Form
import { submit } from 'redux-form'



const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    paper: {
        padding: 10
    }
}));


const ImportDataStepper = ({ activeStep, steps }) => {
    const classes = useStyles();
    return (
    <div className={classes.root}>

        <Stepper activeStep={activeStep} alternativeLabel >
        {steps.map(label => (
            <Step key={label}>
        <StepLabel>{label}</StepLabel>
        </Step>
))}
</Stepper>

    </div>
);
};

export default ImportDataStepper