import React, { Component, Fragment } from "react";
// Redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { selectProject } from '../../redux/actions/project-actions';
import { toggleDeleteProjectDialog, toggleEditProjectDialog } from '../../redux/actions/project-actions'

// Material UI
import MaterialTable from 'material-table'

//Components
import LinkText from '../../components/Link/LinkText';
import EditAndDeleteIcons from '../components/ProjectEditAndDeleteIcons'

//Loader
import PulseLoader from '../../components/Spinners/pulse-loader'

import Button from '@material-ui/core/Button';

//moment
import moment from 'moment'
import AddCircleIcon from "@material-ui/icons/AddCircle";
import {withStyles} from "@material-ui/core/styles";
import {green} from "@material-ui/core/colors";

class ProjectsTable extends Component {

    render() {
        const { projects, selectProject, toggleEditProjectDialog, toggleDeleteProjectDialog, toggleCreateProjectDialog, classes } = this.props;
        if (!projects) {
            return (
                <PulseLoader />
            )
        } else {
            return (
                <div style={{ width: '100%'}}>
                        <MaterialTable
                            columns={[
                                {
                                    title: 'Project Name',
                                    field: 'name',
                                    render: rowData =>
                                        <LinkText text={rowData.name} projectId={rowData.projectId} onClick={() => selectProject(rowData)} object={rowData} />
                                },
                                { title: 'Utility', field: 'utility' },
                                { title: 'Last Modified', field: 'modifiedDate', render: rowData => <div onClick={() => selectProject(rowData)}>{moment(rowData.modifiedDate).format("MMMM D, YYYY")}</div> },
                                //{
                                //    title: 'Edit', render: rowData => (
                                //        <Fragment>
                                //            <EditAndDeleteFabsRedux deleteOpen={deleteProjectDialogOpen} deleteToggle={toggleDeleteProjectDialog} project={rowData} importFabNeeded={false} editOpen={editProjectDialogOpen} editToggle={toggleEditProjectDialog}/>

                                //            </Fragment>
                                //        ) 

                                //},
                                {
                                    title: 'Edit', render: rowData => (
                                        <Fragment>
                                            <EditAndDeleteIcons toggleEditProjectDialog={toggleEditProjectDialog} project={rowData} toggleDeleteProjectDialog={toggleDeleteProjectDialog}/>

                                        </Fragment>
                                    )

                                },


                            ]}
                            data={this.props.projects}
                            title={''}
                            options={{
                                search: true,
                                pageSize: 10,
                                padding: 'dense',
                                // searchFieldAlignment: 'left'
                                
                            }}
                            // component={{
                            //     Container: props => <div {...props}/>
                            // }}
                            //localization={{
                            //    pagination: {
                            //        labelRowsSelect: 10
                            //}
                            //}}
                        />
                </div>




            );
        }

    }
}

function mapStateToProps(state) {
    return {
        projects: state.projects,
        selectedProject: state.selectedProject,

    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ selectProject }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsTable)

