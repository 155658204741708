import React from 'react'

// Material UI
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormLabel from '@material-ui/core/FormLabel';

const useStyles = makeStyles(theme => ({

    textField: {
        margin: theme.spacing(1),
        width: '100%'

    },

}));

const CreateProjectForm = (props) => {
    const { handleCreateProjectInputChange, createProjectFormControls, attemptedCreateProjectSubmit } = props;
    const classes = useStyles();

    return (
        <form onSubmit={() => alert("submitted")}>
            <Grid container spacing={3}>
                <Grid item md={6} xs={12} >
                    <FormControl className={classes.textField} error={!createProjectFormControls.name.valid && attemptedCreateProjectSubmit}>
                        
                        <InputLabel htmlFor="component-error">{createProjectFormControls.name.label}*</InputLabel>
                        <Input
                            id="component-error"
                            value={createProjectFormControls.name.value}
                            name="name"
                            onChange={handleCreateProjectInputChange}
                            aria-describedby="component-error-text"
                        />
                        {/*<FormHelperText id="component-error-text">{createProjectFormControls.name.message}</FormHelperText>*/}
                    </FormControl>
                    <TextField
                        id="standard-name"
                        label={createProjectFormControls.companyName.label}
                        className={classes.textField}
                        value={createProjectFormControls.companyName.value}
                        name="companyName"
                        onChange={handleCreateProjectInputChange}
                        margin="normal"
                    />
                    <TextField
                        id="standard-name"
                        label={createProjectFormControls.utility.label}
                        className={classes.textField}
                        value={createProjectFormControls.utility.value}
                        name="utility"
                        onChange={handleCreateProjectInputChange}
                        margin="normal"
                    />
                </Grid>
                <Grid item md={6} xs={12} >
                    <TextField
                        id="standard-name"
                        label={createProjectFormControls.contactName.label}
                        className={classes.textField}
                        value={createProjectFormControls.contactName.value}
                        name="contactName"
                        onChange={handleCreateProjectInputChange}
                        margin="normal"
                    />
                    <TextField
                        id="standard-name"
                        label={createProjectFormControls.contactJobTitle.label}
                        className={classes.textField}
                        value={createProjectFormControls.contactJobTitle.value}
                        name="contactJobTitle"
                        onChange={handleCreateProjectInputChange}
                        margin="normal"
                    />
                    {/*<TextField*/}
                    {/*    id="standard-name"*/}
                    {/*    label={createProjectFormControls.contactPhoneNumber.label}*/}
                    {/*    className={classes.textField}*/}
                    {/*    value={createProjectFormControls.contactPhoneNumber.value}*/}
                    {/*    name="contactPhoneNumber"*/}
                    {/*    onChange={handleCreateProjectInputChange}*/}
                    {/*    margin="normal"*/}
                    {/*    */}
                    {/*/>*/}
                    <FormControl className={classes.textField}>
                    <InputLabel htmlFor="component-error">{createProjectFormControls.contactPhoneNumber.label}</InputLabel>
                    <Input
                        id="component-error"
                        value={createProjectFormControls.contactPhoneNumber.value}
                        name={'contactPhoneNumber'}
                        onChange={handleCreateProjectInputChange}
                        aria-describedby="component-error-text"
                        fullWidth
                        inputComponent={createProjectFormControls.contactPhoneNumber.textMask}
                    />
                    </FormControl>
                    <TextField
                        id="standard-name"
                        label={createProjectFormControls.contactEmail.label}
                        className={classes.textField}
                        value={createProjectFormControls.contactEmail.value}
                        name="contactEmail"
                        onChange={handleCreateProjectInputChange}
                        margin="normal"
                    />
                </Grid>

            </Grid>


        </form>
    )

}



export default (CreateProjectForm)