import React, {Component} from 'react'

import {
    getIntervalDataSummaryChartByFacilityId,
    intervalDataRequestObject,
} from '../../../../../services/intervalData.service'
import moment from "moment";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import DataViewLoadingSpinner from "../../../spinners/DataViewLoadingSpinner";

class MeterMonthlyEmissionsChart extends Component {
    state = {
        chartOptions: null,
    };
    
    componentDidMount() {
        this.setChartOptions();
    };
    
    setChartOptions = () => {
        const { locationEmissions, selectedFacility} = this.props;

        let emissionConstants = {};
        locationEmissions.emissions.forEach(x => {
            // divide by 1000: lbs/MWh converted to lbs/kWh
            emissionConstants[x.abbr] = x.value / 1000
        });

        let request = intervalDataRequestObject;
        request.ProjectId = selectedFacility.projectId;
        request.FacilityId = selectedFacility.facilityId;
        request.Months = [1,2,3,4,5,6,7,8,9,10,11,12];
        request.SeriesType = ['Average'];
        request.DataType = 'kwh';
        request.SeriesPer = 'month';
        request.StartDate = moment.utc(selectedFacility.intervalDataStartDate);
        request.EndDate = moment.utc(selectedFacility.intervalDataEndDate);

        let array = [];
        let categories = [];
        let series = [{
            name: 'CO2 Equivalent',
            data: []
        }];
        getIntervalDataSummaryChartByFacilityId(request).then(response => {
            response.forEach(x => {
                categories.push(x.name);
                series[0].data.push(x.sumKwh * emissionConstants.co2e);
                array.push({
                    month: x.name,
                    sumKwh: x.sumKwh,
                    co2: x.sumKwh * emissionConstants.co2,
                    ch4: x.sumKwh * emissionConstants.ch4,
                    n2o: x.sumKwh * emissionConstants.n2o,
                    co2e: x.sumKwh * emissionConstants.co2e,
                    annualNox: x.sumKwh * emissionConstants.annualNox,
                    ozoneNox: x.sumKwh * emissionConstants.ozoneNox,
                    so2: x.sumKwh * emissionConstants.so2,
                });
            });

            let chartOptions = this.getChartOptions(series, categories);
            this.setState({chartOptions})
        });
    };
    
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.locationEmissions.emissions.filter(x => x.abbr === 'co2e')[0] !== this.props.locationEmissions.emissions.filter(x => x.abbr === 'co2e')[0]) {
            this.setChartOptions()
        }
    }

    getChartOptions = (series, categories) => {
        return {
            chart: {
                type: 'column',
                height: 500,
                style: {
                    fontFamily: "Roboto"
                },
                zoomType: 'x'
            },
            title: {
                text: ''
            },
            subtitle: {
                text: ''
            },
            xAxis: {
                categories: categories,
                //crosshair: true
            },
            yAxis: {
                title: {
                    text: 'CO2 Equivalent'
                }
            },
            tooltip: {
                headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                    '<td style="padding:0"><b>{point.y:.2f}</b></td></tr>',
                footerFormat: '</table>',
                //shared: true,
                useHTML: true,
                //backgroundColor: '#fafafa'
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2
                }
            },
            series: series,
            credits: {
                enabled: false
            },
        }
    };

    render() {
        const {locationEmissions, selectedFacility} = this.props;
        let emissions = locationEmissions.emissions;
        let annualConsumption = selectedFacility.annualKwhValue;
        emissions.forEach(x => {
            // divide by 1000 to convert MWh to kWh (the emissions values are per MWh)
            x.meterEmissions = (x.value/1000 * annualConsumption).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            x.valueAsString = x.value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' lbs';
        });

        return (
            <div style={{ maxWidth: '100%', height: '100%' }}>
                {this.state.chartOptions ? <HighchartsReact
                    highcharts={Highcharts}
                    options={this.state.chartOptions}
                /> : <DataViewLoadingSpinner/>}
            </div>
        )
    }
}

export default MeterMonthlyEmissionsChart;