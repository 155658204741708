import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Typography from "@material-ui/core/Typography";

const AnyReactComponent = ({ weatherStationName }) => {
    return (
        <div style={{display: 'flex', alignItems: 'center', width: 300}}>
            <LocationOnIcon style={{ color: '#ea4335', fontSize: '3em' }}/>
            <Typography style={{ color: '#ea4335', fontSize: '1em' }}>{weatherStationName}</Typography>
        </div>
        
        )
    
};

class WeatherStationMap extends Component {

    render() {
        const {selectedFacility} = this.props;
        let center = {
            lat: selectedFacility.weatherStation.latitude,
            lng: selectedFacility.weatherStation.longitude
        }
        let zoom = 11;
        
        return (
            // Important! Always set the container height explicitly
            <div style={{ minHeight: 500, width: '100%' }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: 'AIzaSyAvkhcAgPKUCBXAu_8RfxDdXeypny3_5VI' }}
                    defaultCenter={center}
                    defaultZoom={zoom}
                >
                    <AnyReactComponent
                        lat={center.lat}
                        lng={center.lng}
                        weatherStationName={selectedFacility.weatherStation.name}
                    />
                </GoogleMapReact>
            </div>
        );
    }
}

export default WeatherStationMap;