
const ORGANIZATION_MOUNTED = 'ORGANIZATION_MOUNTED';
const PAYMENT_METHODS_MOUNTED = 'PAYMENT_METHODS_MOUNTED';
const TOGGLE_STRIPE_DIALOG = 'TOGGLE_STRIPE_DIALOG';
const ADD_ORGANIZATION_SUBSCRIPTION = 'ADD_ORGANIZATION_SUBSCRIPTION';
const CHANGE_THEME = 'CHANGE_THEME';

const addOrganizationToState = (organization) => {
    return {
        type: ORGANIZATION_MOUNTED,
        payload: organization
    };
};

const addPaymentMethodsToState = (paymentMethods) => {
    return {
        type: PAYMENT_METHODS_MOUNTED,
        payload: paymentMethods
    };
};

const toggleStripeCreditCardDialog = (currentState) => {
    return {
        type: TOGGLE_STRIPE_DIALOG,
        payload: currentState
    };
};

const addOrganizationSubscription = (subscription) => {
    return {
        type: ADD_ORGANIZATION_SUBSCRIPTION,
        payload: subscription
    };
};

const changeTheme = (primaryColor) => {
    return {
        type: CHANGE_THEME,
        payload: primaryColor
    };
};

export {
    addOrganizationToState,
    addPaymentMethodsToState,
    toggleStripeCreditCardDialog,
    addOrganizationSubscription,
    changeTheme,
    ORGANIZATION_MOUNTED,
    PAYMENT_METHODS_MOUNTED,
    TOGGLE_STRIPE_DIALOG,
    ADD_ORGANIZATION_SUBSCRIPTION,
    CHANGE_THEME
}