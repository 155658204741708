import React, {Component} from 'react'

// Material UI
import {withStyles} from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

//Components
import PeriodLength from "./components/PeriodLength";
import MonthlySetup from "./components/MonthlySetup";
import DateRanges from "./components/DateRanges";
import TemperatureSettings from "./components/TemperatureSettings";
import Holidays from "./components/Holidays";
import Schedule from "./components/Schedule/index";
import Divider from "@material-ui/core/Divider";

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    content: {
        margin: theme.spacing(1),
    }
});

class CreateRegression extends Component {
    state = {
        steps: null,
        activeStep: 0,
        periodLength: 'hour',
        
    }
    
    componentDidMount() {
        this.getSteps();
    }

    nextStep = () => {
        let activeStep = this.state.activeStep + 1;
        this.setState({activeStep})
    }
    
    previousStep = () => {
        let activeStep = this.state.activeStep - 1;
        this.setState({activeStep})
    }
    
    getStepContent = () => {
        const {steps, activeStep} = this.state;
        switch (steps[activeStep].name) {
            case 'periodLength':
                return <PeriodLength
                    setPeriodLength={this.setPeriodLength}
                    periodLength={this.state.periodLength}
                    selectedFacility={this.props.selectedFacility}
                />;
            case 'monthSetup':
                return <MonthlySetup
                    selectedFacility={this.props.selectedFacility}
                />
            case 'dateRanges':
                return <DateRanges
                    selectedFacility={this.props.selectedFacility}
                />
            case 'tempSettings':
                return <TemperatureSettings selectedFacility={this.props.selectedFacility} />
            case 'holidays':
                return <Holidays selectedFacility={this.props.selectedFacility} />
            case 'scheduling':
                return <Schedule
                selectedFacility={this.props.selectedFacility}
            />
        };
    }
    
    setPeriodLength = (periodLength) => {
        this.setState({periodLength}, () => this.getSteps());
    }
    
    getSteps = () => {
        let steps = null;
        if (this.state.periodLength === 'month') {
            steps = [
                {
                    label: 'Period Length',
                    name: 'periodLength' 
                },
                {
                    label: 'Monthly Setup',
                    name: 'monthSetup'
                },
                {
                    label: 'Date Ranges',
                    name: 'dateRanges'
                },
                {
                    label: 'Temperature Settings',
                    name: 'tempSettings'
                },
                {
                    label: 'Holidays',
                    name: 'holidays'
                },
                {
                    label: 'Other',
                    name: 'other'
                },

            ]
        } else {
            steps = [
                {
                    label: 'Period Length',
                    name: 'periodLength'
                },
                {
                    label: 'Date Ranges',
                    name: 'dateRanges'
                },
                {
                    label: 'Temperature Settings',
                    name: 'tempSettings'
                },
                {
                    label: 'Scheduling',
                    name: 'scheduling'
                },
                {
                    label: 'Holidays',
                    name: 'holidays'
                },
                {
                    label: 'Other',
                    name: 'other'
                },

            ]
        }
        this.setState({steps});
    }

    render() {
        const {classes} = this.props;
        if (this.state.steps !== null) {
            return(
                <div className={classes.root}>
                    <Paper>
                        <Grid container>
                            <Grid item xs={12}>
                                <Stepper activeStep={this.state.activeStep} alternativeLabel>
                                    {this.state.steps.map((step, index) => {
                                        return (
                                            <Step key={step.label} >
                                                <StepLabel >{step.label}</StepLabel>
                                            </Step>
                                        );
                                    })}
                                </Stepper>
                            </Grid>
                            <Grid item xs={12} className={classes.content} container justify={"center"}>
                                {this.getStepContent()}
                            </Grid>
                            <Grid item xs={12} className={classes.content} >
                                <Button onClick={this.previousStep} disabled={this.state.activeStep < 1}>Prev</Button>
                                <Button onClick={this.nextStep}>Next</Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </div>
            )
        } else {
            return 'Loading'
        }
        
    }
}

export default withStyles(styles)(CreateRegression)
