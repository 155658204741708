import React, {Component, Fragment} from 'react'

//Material UI
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import CustomInfoLayout from "../../../../components/CustomInfoLayout/CustomInfoLayout";
import moment from "moment";
import {cancelOrganizationSubscription, getOrganizationSubscription} from "../../../../services/admin.service";
import CancelSubscriptionDialog from "./Components/CancelSubscriptionDialog";
import CustomSnackbar from "../../../../components/Snackbar/CustomSnackbar";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton';
import EditSubscriptionDialog from "./Components/EditSubscriptionDialog";

const styles = theme => ({
    cancel: {
        color: theme.palette.primary.main,
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline',
        }
    },
    icon: {
        margin: theme.spacing(0),
        color: '#4a4a4a',
        fontSize: 30,
        display: 'inline-block'
    },
    iconTable: {
        margin: theme.spacing(0),
        color: '#4a4a4a',
        fontSize: 30
    },
    margin: {
        margin: theme.spacing(0),
    },

});

class CurrentSubscription extends Component {

    state = {
        chosenSubscription: null,
        cancelSubscriptionDialogOpen: false,
        editSubscriptionDialogOpen: false,
        snackbar: {
            open: false,
            message: '',
            variant: ''
        },
    };

    componentDidMount() {
        // let userInfo = JSON.parse(window.sessionStorage.user);

        const {activeUser, resetActiveUser} = this.props;
        const {trialPeriodEndDate} = activeUser.subscription;
        let chosenSubscription = {
            title: 'Basic',
            pricePerLicense: '$40.00',
            description: ['Per User License', 'Unlimited Projects', 'Email support'],
            trialEnd: null,

        };
        this.setState({chosenSubscription})
    }

    openSnackbar = (value) => {
        let snackbar = {...this.state.snackbar};
        snackbar.open = value;
        this.setState({snackbar})
    };

    cancelSubscription = () => {
        // alert('Cancel Subscription');
        cancelOrganizationSubscription().then(x => {
            let snackbar = {...this.state.snackbar};
            if (x.isSuccess) {
                snackbar.message = 'Succesfully Cancelled Subscription';
                snackbar.variant = 'success';
                snackbar.open = true;
                getOrganizationSubscription().then(x => {
                    this.props.resetActiveUser();
                })

            } else {
                snackbar.message = 'Something went wrong cancelling your account. Please reach out to support@reactenergy,com.';
                snackbar.variant = 'error';
                snackbar.open = true;
            }
            this.setState({snackbar: snackbar});
            this.toggleCancelSubscriptionDialog();
        });

    };

    componentDidUpdate = (prevProps, prevState, snapshot) => {
        let temp = this.props;

        if (prevProps.activeUser.subscription.cancelAtPeriodEnd !== this.props.activeUser.subscription.cancelAtPeriodEnd) {

        }
    }


    toggleCancelSubscriptionDialog = () => {
        this.setState({cancelSubscriptionDialogOpen: !this.state.cancelSubscriptionDialogOpen})
    };
    toggleEditSubscriptionDialog = () => {
        this.setState({editSubscriptionDialogOpen: !this.state.editSubscriptionDialogOpen})
    };
    

    render() {
        // let userInfo = JSON.parse(window.sessionStorage.user);
        const {classes} = this.props;
        const {activeUser, resetActiveUser} = this.props;


        const {chosenSubscription} = this.state;


        let data = [{
            index: 0,
            column1: 'Subscription Type',
            column2: <div>
                {moment(activeUser.subscription.trialPeriodEndDate).isAfter(moment()) ?
                    <Typography style={{fontWeight: 500}}>Free Trial
                        Ending {moment().to(moment(activeUser.subscription.trialPeriodEndDate))}! </Typography>
                    : <div>{moment(activeUser.subscription.currentPeriodEndDate).isAfter(moment()) ?
                        <Typography>{activeUser.subscription.plan.name}</Typography> : ''}</div>


                }
            </div>
        }, {
            index: 1,
            column1: 'Quantity',
            column2: <Typography>{activeUser.subscription.quantity} </Typography>
        }];

        let subscriptionDetails = [
            {
                label: 'Product',
                value: activeUser.subscription.plan.name
            },
            
            {
                label: 'Quantity',
                value: activeUser.subscription.quantity
            },
            {
                label: 'Price',
                value: '$' + activeUser.subscription.plan.price
            },
            {
                label: 'Billed',
                value: activeUser.subscription.plan.interval === 'month' ? 'Monthly' : 'Annually'
            },
            {
                label: 'Edit',
                value: <IconButton aria-label="Edit" className={classes.margin} size="small" onClick={this.toggleEditSubscriptionDialog} ><EditIcon className={classes.icon}/></IconButton>
            },
        ]

        return (
            <div style={{width: '100%'}}>
                <CancelSubscriptionDialog
                    open={this.state.cancelSubscriptionDialogOpen}
                    handleClose={this.toggleCancelSubscriptionDialog}
                    onSubmit={this.cancelSubscription}
                    userInfo={activeUser}
                    resetActiveUser={resetActiveUser}
                />
                <EditSubscriptionDialog
                    open={this.state.editSubscriptionDialogOpen}
                    handleClose={this.toggleEditSubscriptionDialog}
                />
                <CustomSnackbar snackbar={this.state.snackbar} openSnackbar={this.openSnackbar}/>
                <CustomInfoLayout
                    title={"Current Subscription"}
                    mainContent={chosenSubscription ?
            
                        <Grid container style={{padding: 20,}}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {subscriptionDetails.map(x => (
                                            <TableCell>{x.label}</TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        {subscriptionDetails.map(x => (
                                            <TableCell style={{fontWeight: 600, fonstSize: '1.2em'}}>{x.value}</TableCell>
                                        ))}
                                    </TableRow>
                                </TableBody>
                            </Table>
                            {activeUser.subscription.cancelAtPeriodEnd ? null :
                                <Grid item xs={12}
                                      style={{justifyContent: 'flex-end', display: "flex", paddingTop: 10}}>
                                    <Typography className={classes.cancel}
                                                onClick={this.toggleCancelSubscriptionDialog}>Cancel
                                        Subscription</Typography>
                                </Grid>}


                        </Grid> : null}/>


            </div>

        )
    }
}

export default withStyles(styles)(CurrentSubscription)