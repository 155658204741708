import React, {Component} from 'react'
import moment from 'moment'

import Grid from '@material-ui/core/Grid'
import {FormGroup, withStyles} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
    root: {
        padding: theme.spacing(2),
    },
});

// function getDayOfMonth(years, dataStartDate, dataEndDate, month, index, dayOfWeek, label) {
//     // index is which one of that particular day. So if you want the 2nd Monday, index would be 2 and dayOfWeek should be 1
//     let temp = {
//         label: label,
//         dates: []
//     };
//    
//     years.forEach(year => {
//         let startDate = moment.utc().year(year).month(month).date(1).hour(0).minute(0).second(0);
//         let startOfMonth = moment.utc(startDate).startOf('month').startOf('isoweek').hour(0).minute(0).second(0);
//         let date = moment.utc(startDate).startOf('month').startOf('isoweek').add(index, 'w').add(dayOfWeek, 'day').hour(0).minute(0).second(0);
//         debugger;
//         if (date.month() == startOfMonth.month()) {
//             // debugger;
//             date = date.subtract(1, 'w');
//         }
//        
//         if (date.isAfter(dataStartDate) && date.isBefore(dataEndDate)) {
//             // using the valueOf() below is a jank way to get moment to format the UTC time instead of formatting it as your local computer's time
//             temp.dates.push(moment(date.valueOf()).format("MM/DD/YYYY"));
//         }
//     });
//    
//    
//     return temp;
// }

function getDayOfMonth(years, dataStartDate, dataEndDate, month, index, dayOfWeek, label) {
    // index is which one of that particular day. So if you want the 2nd Monday, index would be 2 and dayOfWeek should be 1
    let temp = [];

    years.forEach(year => {
        let startDate = moment.utc().year(year).month(month).date(1).hour(0).minute(0).second(0);
        let startOfMonth = moment.utc(startDate).startOf('month').startOf('isoweek').hour(0).minute(0).second(0);
        let date = startOfMonth.clone().add(index, 'w').add(dayOfWeek, 'day').hour(0).minute(0).second(0);

        if (date.month() == startOfMonth.month() || dayOfWeek >= startDate.day()) {
            // debugger;
            date = date.subtract(1, 'w');
        }

        if (date.isAfter(dataStartDate) && date.isBefore(dataEndDate)) {
            // using the valueOf() below is a jank way to get moment to format the UTC time instead of formatting it as your local computer's time
            temp.push({date: moment(date.valueOf()).format("MM/DD/YYYY"), label: label});
        }
    });


    return temp;
}

function getExactDates(years, dataStartDate, dataEndDate, month, date, label) {
    let temp = [];
    years.forEach(year => {
        let dateAsMoment = moment().year(year).month(month).date(date);
        if (dateAsMoment.isAfter(dataStartDate) && dateAsMoment.isBefore(dataEndDate)) {
            temp.push({date: dateAsMoment.format("MM/DD/YYYY"), label: label});
        }

    });
    return temp
}

class Holidays extends Component {
    
    
    state = {
        holidays: [
            {
                label: 'New Year\'s Day',
                getDates: function(years, dataStartDate, dataEndDate) {
                    return getExactDates(years, dataStartDate, dataEndDate, 0, 1, 'New Year\'s Day')
                },
                value: false
            },{
                label: 'Martin Luther King Jr. Day',
                getDates: function(years, dataStartDate, dataEndDate) {
                    return getDayOfMonth(years, dataStartDate, dataEndDate, 0,3, 1, 'Martin Luther King Jr. Day')
                },
                value: false,
            },
            {
                label: 'President\'s Day',
                getDates: function(years, dataStartDate, dataEndDate) {
                    return getDayOfMonth(years, dataStartDate, dataEndDate, 1,3, 1, 'President\'s Day')
                },
                value: false
            },
            // {
            //     label: 'Memorial Day',
            //     onSpecificDate: true,
            //     value: false
            // },
            {
                label: 'Independence Day',
                getDates: function(years, dataStartDate, dataEndDate) {
                    return getExactDates(years, dataStartDate, dataEndDate, 6, 4, 'Independence Day')
                },
                value: false
            },
            {
                label: 'Labor Day',
                getDates: function(years, dataStartDate, dataEndDate) {
                    return getDayOfMonth(years, dataStartDate, dataEndDate, 8,1, 1, 'Labor Day')
                },
                value: false
            },
            {
                label: 'Columbus Day',
                getDates: function(years, dataStartDate, dataEndDate) {
                    return getDayOfMonth(years, dataStartDate, dataEndDate, 9,2, 1, 'Columbus Day')
                },
                value: false
            },
            // {
            //     label: 'Veterans Day',
            //     getDates: function(years, dataStartDate, dataEndDate) {
            //         return getExactDates(years, dataStartDate, dataEndDate, 10, 11, 'Veterans Day')
            //     },
            //     value: false
            // },
            {
                label: 'Thanksgiving Day',
                getDates: function(years, dataStartDate, dataEndDate) {
                    return getDayOfMonth(years, dataStartDate, dataEndDate, 10,4, 4, 'Thanksgiving Day')
                },
                value: false
            },
            {
                label: 'Christmas Eve',
                getDates: function(years, dataStartDate, dataEndDate) {
                    return getExactDates(years, dataStartDate, dataEndDate, 11, 24, 'Christmas Eve')
                },
                value: false
            },
            {
                label: 'Christmas Day',
                getDates: function(years, dataStartDate, dataEndDate) {
                    return getExactDates(years, dataStartDate, dataEndDate, 11, 25, 'Christmas Day')
                },
                value: false
            },
        ]
    };
    
    toggleHoliday = (event, index) => {
        let holidays = [...this.state.holidays];
        holidays[index].value = !holidays[index].value;
        this.setState({holidays});
    };
    
    render() {
        const {classes, selectedFacility} = this.props;
        const {intervalDataStartDate, intervalDataEndDate} = selectedFacility;
        const {holidays} = this.state;
        let dataStartDate = moment(intervalDataStartDate);
        let dataEndDate = moment(intervalDataEndDate);
        let temp = [];
        let years = [];
        let year1 = moment(intervalDataStartDate).year();
        let year2 = moment(intervalDataEndDate).year();
        for (let i = 0; year1 + i <= year2; i++) {
            years.push(year1 + i);
        }
        holidays.forEach(x => {
            if (x.value) {
                let result = x.getDates(years, dataStartDate, dataEndDate);
                result.forEach(y => {
                    temp.push(y)
                })
                
            }
            
        });
        
        temp.sort(function(a, b) {
            return moment(a.date).valueOf() - moment(b.date).valueOf()
        });
       
        return (
            <Grid container className={classes.root}>
                <Grid item md={6}>
                    <FormGroup >
                    {holidays.map((holiday, index) => {
                        return (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={holiday.value}
                                        onChange={(event) => this.toggleHoliday(event, index)}
                                        value={holiday.value}
                                        color="secondary"
                                    />
                                }
                                label={holiday.label}
                            />
                        )
                    })}
                    </FormGroup>
                </Grid>
                <Grid item md={6}>
                    Selected List
                    {temp.map(holiday => {
                        return (
                            <div>
                                <Typography>{holiday.date} - {holiday.label}</Typography>
                            </div>
                            
                        )
                    })}
                </Grid>
            </Grid>
        )
    }
}

export default withStyles(styles)(Holidays)