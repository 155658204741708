import {
    GET_DEGREE_DAYS, 
    DEGREE_DAY_CHART_TO_UPDATE,
    SET_WEATHER_HANDSONTABLE_SETTINGS_REDUX,
    CLEAR_DEGREE_DAYS_REDUX,
    SET_REGRESSION,
    SET_RAW_WEATHER_DATA_CHART_OPTIONS
} from "../actions/weather-actions";

const degreeDayChartToUpdate = (state = null, action) => {
    switch (action.type) {

        case DEGREE_DAY_CHART_TO_UPDATE:
            return action.payload;
    }
    return state
}
const rawWeatherDataChartOptions = (state = null, action) => {
    switch (action.type) {

        case SET_RAW_WEATHER_DATA_CHART_OPTIONS:
            return action.payload;
    }
    return state
}

const degreeDays = (state = null, action) => {
    switch (action.type) {
        case GET_DEGREE_DAYS:
            return action.payload;
        case CLEAR_DEGREE_DAYS_REDUX:
            return action.payload;
    }
    return state
}

const weatherHandsontableSettings = (state = null, action) => {
    switch (action.type) {
        case SET_WEATHER_HANDSONTABLE_SETTINGS_REDUX:
            return action.payload;
        case CLEAR_DEGREE_DAYS_REDUX:
            return action.payload;
    }
    return state
}

const regressionRedux = (state = null, action) => {
    switch (action.type) {
        case SET_REGRESSION:
            return action.payload;
    }
    return state
}


export { 
    degreeDays, 
    degreeDayChartToUpdate,
    weatherHandsontableSettings,
    regressionRedux,
    rawWeatherDataChartOptions
}