import React, { Fragment } from 'react';

// Material UI
import Button from '@material-ui/core/Button';

// Components
import EditProjectForm from './EditProjectForm';
import CustomDialog from "../../../components/Dialogs/customDialog";

function EditProjectDialog(props) {
    const { editProjectDialogOpen,
        toggleEditProjectDialog,
        editProjectFormControls,
        handleEditProjectInputChange,
        onEditProjectSubmit,
        attemptedEditProjectSubmit,
        selectedProjectForEdit } = props;

    return (
        <Fragment>
            {selectedProjectForEdit === null ? null :
                <CustomDialog
                    open={editProjectDialogOpen}
                    onClose={toggleEditProjectDialog}
                    subTitle="Edit Project"
                    // title={selectedProjectForEdit.name}
                    content={<EditProjectForm 
                        editProjectFormControls={editProjectFormControls} 
                        handleEditProjectInputChange={handleEditProjectInputChange} 
                        attemptedEditProjectSubmit={attemptedEditProjectSubmit} 
                    />
                    }
                    actions={<Button type="button" onClick={() => onEditProjectSubmit()}>Submit</Button>}
                />
            }
        </Fragment>


    );
}

export default EditProjectDialog;