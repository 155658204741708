import moment from 'moment'
import 'moment-timezone';


// the moment isDST() function returns true for ALL values in the DST time frame. For example, Texas participates in DST and the summer time 
// is the daylight savings time (winter is the standard time). So ANY time period during that summer will return true. So to find the dates
// where the time actually changes you can check when that isDST() value goes from false to true

let getDSTDays = (startDate, endDate) => {
    // let start = moment().tz('Australia/Sydney').month(2).date(10).year(2017).hour(0).minute(0).second(0).millisecond(0);
    let fallBackDays = [];
    let springForwardDays = [];

    let day = startDate.clone();
    let prevIsDST = startDate.isDST();
    for (let i = 0; day.isBefore(endDate); i++) {
        if (prevIsDST !== day.isDST()) {
            if (prevIsDST) {
                fallBackDays.push(day.format("YYYY-MM-DD"))
            } else {
                springForwardDays.push(day.format("YYYY-MM-DD"))
            }

        }
        // days.push(day.format())
        prevIsDST = day.isDST();
        day.add(1, "hour");

    }
    return {fallBackDays, springForwardDays};

}

export default getDSTDays