import React, {Component} from 'react'
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {makeStyles} from "@material-ui/core/styles";
import CustomDialog from "../../../../../components/Dialogs/customDialog";
import moment from "moment";

const useStyles = makeStyles(theme => ({
    button: {
        color: 'red'
    }
}));

function CancelSubscriptionDialog(props) {


    const {open, handleClose, onSubmit, userInfo} = props;
    const classes = useStyles();

    return (
        <CustomDialog
            open={open}
            onClose={handleClose}
            subTitle={'Cancel Subscription?'}
            content={<Typography align={"center"}>Are you sure you want to cancel your React Energy subscription for you and your entire
                team? You will continue to have access to the portal through {moment(userInfo.subscription.currentPeriodEndDate).format('MMMM D, YYYY')}.</Typography>}
            actions={<Button onClick={onSubmit} className={classes.button}>Cancel Subscription</Button>}
        />
    )

}

export default CancelSubscriptionDialog