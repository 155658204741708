import { 
    SET_EDIT_METER_FORM_IS_VALID,
    SET_ATTEMPTED_EDIT_METER_SUBMIT,
    TOGGLE_EDIT_METER_DIALOG_OPEN,
    SET_SELECTED_METER_FOR_EDIT,
    SET_EDIT_METER_FORM_CONTROLS,
    SET_FETCHING_NEW_WEATHER_DATA,
    SET_EDIT_METER_SNACKBAR
} from "../../../actions/MeterActions/EditMeterActions/edit-meter-actions";

const editMeterFormIsValid = (state = false, action) => {
    switch (action.type) {
        case SET_EDIT_METER_FORM_IS_VALID:
            return action.payload;
    }
    return state;
};

const attemptedEditMeterSubmit = (state = false, action) => {
    switch (action.type) {
        case SET_ATTEMPTED_EDIT_METER_SUBMIT:
            return action.payload;
    }
    return state;
};
const fetchingNewWeatherData = (state = false, action) => {
    switch (action.type) {
        case SET_FETCHING_NEW_WEATHER_DATA:
            return action.payload;
    }
    return state;
};

const editMeterDialogOpen = (state = false, action) => {
    switch (action.type) {
        case TOGGLE_EDIT_METER_DIALOG_OPEN:
            return action.payload;
    }
    return state;
};

const selectedMeterForEdit = (state = {}, action) => {
    switch (action.type) {
        case SET_SELECTED_METER_FOR_EDIT:
            return action.payload;
    }
    return state;
};

let editMeterFormControlsDefault = {
    name: {
        label: 'Name',
        name: 'name',
        value: null,
        valid: true,
        touched: false,
        message: 'required',
        validationRules: {
            isRequired: true
        }
    },
    description: {
        label: 'Description (Optional)',
        name: 'description',
        value: null,
        valid: true,
        touched: false,
        message: 'required',
        validationRules: {
            isRequired: true
        }
    },
    meterNumber: {
        label: 'Meter Number',
        name: 'meterNumber',
        value: null,
        valid: true,
        touched: false,
        validationRules: {

        }
    },
    accountNumber: {
        label: 'Account Number',
        name: 'accountNumber',
        value: null,
        valid: true,
        touched: false,
        validationRules: {

        }
    },
    utility: {
        label: 'Utility',
        name: 'utility',
        value: null,
        valid: true,
        touched: false,
        validationRules: {

        }
    },
    address1: {
        label: 'Address 1',
        name: 'address1',
        value: null,
        valid: true,
        touched: false,
        validationRules: {

        }
    },
    address2: {
        label: 'Address 2',
        name: 'address2',
        value: null,
        valid: true,
        touched: false,
        validationRules: {

        }
    },
    city: {
        label: 'City',
        name: 'city',
        value: null,
        valid: true,
        touched: false,
        validationRules: {
            // isRequired: false
        }
    },
    state: {
        label: 'State',
        name: 'state',
        value: null,
        valid: true,
        touched: false,
        validationRules: {

        }
    },
    postalCode: {
        label: 'Zip Code',
        name: 'postalCode',
        value: null,
        valid: true,
        touched: false,
        validationRules: {
            isRequired: true,
            //isZipcode: true
        }
    },

}

const editMeterFormControls = (state = {...editMeterFormControlsDefault}, action) => {
    switch (action.type) {
        case SET_EDIT_METER_FORM_CONTROLS:
            return action.payload;
    }
    return state;
};



export { 
    editMeterFormIsValid,
    attemptedEditMeterSubmit,
    editMeterDialogOpen,
    selectedMeterForEdit,
    editMeterFormControls,
    fetchingNewWeatherData,
};