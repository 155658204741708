import React, {Component} from 'react'
import notebooksGif from '../../assets/gifs/notebooks.gif'
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import GreenButton from "../../components/CustomButtons/GreenButton";
import BigGreenButton from "../../components/CustomButtons/BigGreenButton";


class EmptyGif extends Component {

    render() {
        const {gif, title, message, buttonText, onClick} = this.props;
        return (
            <div style={{width: '100%'}}>
                <Grid container style={{display: 'flex', justifyItems: 'center'}}>
                    <Grid item xs={12}>
                        <Grid container style={{display: 'flex', justifyContent: 'center'}}>
                            <img src={gif} alt={'time gif'}/>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container style={{display: 'flex', justifyContent: 'center'}}>
                            <Typography variant={"h5"}>{title}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container style={{display: 'flex', justifyContent: 'center'}}>
                            <Typography variant={"subtitle1"} style={{textTransform: 'none'}}>{message}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container style={{display: 'flex', justifyContent: 'center', padding: 10}}>
                            <BigGreenButton variant={"contained"} color={"secondary"} onClick={onClick}>{buttonText}</BigGreenButton>
                        </Grid>
                    </Grid>


                </Grid>
            </div>

        )
    }
}

export default EmptyGif