import React from 'react'
import CustomDialog from "../../../../components/Dialogs/customDialog";
import Button from "@material-ui/core/Button";
import NoteForm from "../NoteForm";
import GreenButton from "../../../../components/CustomButtons/GreenButton";

function EditNoteDialog(props) {
    const {
        open, 
        onClose,
        editNoteFormControls,
        handleEditNoteChange,
        onCreateOrEditNoteSubmit,
        attemptedEditNoteSubmit
    } = props;
    
    return (
        <CustomDialog
            open={open}
            subTitle="Edit Note"
            onClose={onClose}
            actions={<GreenButton color="secondary" onClick={() => onCreateOrEditNoteSubmit('edit')}>Update Note</GreenButton>}
            content={<NoteForm formControls={editNoteFormControls} handleChange={handleEditNoteChange} attemptedSubmit={attemptedEditNoteSubmit}/>}
            fullWidth={true}
        />
    )
}

export default EditNoteDialog