import {getFacilitiesByProjectId} from "../../services/facility.service";
import {SET_FETCHING_NEW_WEATHER_DATA} from "./MeterActions/EditMeterActions/edit-meter-actions";

const TOGGLE_DELETE_PROJECT_DIALOG = 'TOGGLE_DELETE_PROJECT_DIALOG';
const TOGGLE_EDIT_PROJECT_DIALOG = 'TOGGLE_EDIT_PROJECT_DIALOG';
const EDIT_PROJECT_PROPERTY = 'EDIT_PROJECT_PROPERTY';
const PROJECT_SELECTED = 'PROJECT_SELECTED';
const GET_PROJECT_FACILITIES = 'GET_PROJECT_FACILITIES';
const SET_FETCHING_FACILITIES = 'SET_FETCHING_FACILITIES';



const toggleDeleteProjectDialog = (currentState, project) => {
    return {
        type: TOGGLE_DELETE_PROJECT_DIALOG,
        payload: { open: !currentState, project: project }
    };
};

const toggleEditProjectDialog = (currentState, project = {}) => {
    return {
        type: TOGGLE_EDIT_PROJECT_DIALOG,
        payload: { open: !currentState, project: project }
    };
};

const editProjectProperty = (event, project = {}) => {
    return {
        type: EDIT_PROJECT_PROPERTY,
        payload: { event: event, project: project }
    };
};


const selectProject = (project) => {
    return {
        type: PROJECT_SELECTED,
        payload: project
    };
};

const setFetchingFacilities = (boolean) => {
    return {
        type: SET_FETCHING_FACILITIES,
        payload: boolean
    };
}

const getProjectsFacilities = () => {
    
    return (dispatch, getState) => {
        const {
            selectedProject,
        } = getState();
        dispatch(setFetchingFacilities(true));
        getFacilitiesByProjectId(selectedProject.projectId)
            .then(facilities => {
                dispatch({
                    type: GET_PROJECT_FACILITIES,
                    payload: facilities
                });
                dispatch(setFetchingFacilities(false));
            })
    }
};



export {
    toggleDeleteProjectDialog,
    toggleEditProjectDialog,
    selectProject,
    getProjectsFacilities,
    editProjectProperty,
    setFetchingFacilities,
    TOGGLE_DELETE_PROJECT_DIALOG,
    PROJECT_SELECTED,
    GET_PROJECT_FACILITIES,
    TOGGLE_EDIT_PROJECT_DIALOG,
    EDIT_PROJECT_PROPERTY,
    SET_FETCHING_FACILITIES
};

