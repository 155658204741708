import React, {Fragment} from 'react';

//Material UI
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },

    card: {
        maxWidth: 345,
    },
    projectProperty: {
        color: theme.palette.primary.main,
        display: 'inline-block',
        paddingLeft: theme.spacing(1),
        '&:hover': {
            color: theme.palette.primary.dark,
            textDecoration: 'underline',
            cursor: 'pointer'
        }
    }
}));

const projectProperties = [
    {
        name: 'name',
        label: 'Project'
    },
    {
        name: 'companyName',
        label: 'Company Name'
    },
    {
        name: 'contactName',
        label: 'Contact Name',
    },
    {
        name: 'contactJobTitle',
        label: 'Contact Job Title'
    },
    {
        name: 'contactPhoneNumber',
        label: 'Contact Phone Number',
    },
    {
        name: 'contactEmail',
        label: 'Contact Email'
    },
];

function getProjectInfo(project, classes, toggleEditProjectDialog) {

    return (
        <Table>
            
            {projectProperties.map(property => {
                // if (project[property.name]) {
                return (
                    <Fragment>
                        <Typography color="inherit" style={{display: 'inline-block'}}>
                            {property.label}:
                        </Typography>
                        <Typography color="inherit" className={classes.projectProperty} onClick={toggleEditProjectDialog}>
                            {project[property.name]}
                        </Typography>
                        <br/>
                    </Fragment>

                )
                // } else {
                //     return null
                // }                   

            })}

        </Table>
    )
}

export default function SimpleAppBar(props) {
    const classes = useStyles();
    const {project, toggleEditProjectDialog} = props;

    return (
        <div className={classes.root}>
            <Card>

                <CardContent>
                    {project === null ? null : getProjectInfo(project, classes, toggleEditProjectDialog)}
                </CardContent>

            </Card>


        </div>
    );
}