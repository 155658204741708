import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        // justifyContent: 'center',
        alignItems: 'flex-end',
    },
    iconDelete: {
        margin: theme.spacing(0),
        color: '#4a4a4a',
    },
    iconEdit: {
        margin: theme.spacing(0),
        color: '#4a4a4a',
    },
    margin: {
        margin: theme.spacing(0),
    },
}));

export default function ProjectEditAndDeleteIcons(props) {
    const classes = useStyles();
    const { toggleEditProjectDialog, project, toggleDeleteProjectDialog } = props;

    return (
        <div className={classes.root}>
            <IconButton aria-label="Delete" className={classes.margin} size="small" onClick={() => toggleEditProjectDialog(project)}>
                <Icon className={classes.iconEdit} color="primary" style={{ fontSize: 30 }}>
                    edit_outline
                </Icon>
            </IconButton>
            <IconButton aria-label="Delete" className={classes.margin} size="small" onClick={() => toggleDeleteProjectDialog(project)}>
                <Icon className={classes.iconDelete} color="primary" style={{ fontSize: 30 }}>
                    delete_outline
                </Icon>
            </IconButton>

            
        </div>
    );
}