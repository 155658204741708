import React from 'react'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import {makeStyles} from "@material-ui/core/styles";
import SubscriptionCounter from "../../../../../components/Counter/SubscriptionCounter";

const useStyles = makeStyles(theme => ({
    icon: {
        margin: theme.spacing(0),
        color: '#4a4a4a',
        fontSize: 30
    },
    margin: {
        margin: theme.spacing(2),
    },


}));


function AccountActivationStep1(props) {

    const {quantity, handleUserCountChange} = props;

    const onAddUser = (event) => {
        handleUserCountChange(quantity + 1)
    }

    const onRemoveUser = (event) => {
        handleUserCountChange(quantity - 1)
    }

    const classes = useStyles();

    return (
        <Grid container style={{display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
            <Grid container style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                <Typography>Select Number of Users</Typography>
            </Grid>
            <Grid container style={{display: 'flex', justifyContent: 'center', paddingTop: 20}}>
                <SubscriptionCounter quantity={quantity} handleQuantityChange={handleUserCountChange}/>
            </Grid>
        </Grid>

    )
}

export default AccountActivationStep1