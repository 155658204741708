import MomentUtils from '@date-io/moment';

import React, { useState } from "react";

import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

export default function ({ group, handleDateChange}) {
    const [selectedDate, handleLocalChange] = useState(group.value);

    function changeDate(event, name) {
        handleDateChange(event, name);
        handleLocalChange(event)
    }

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <DatePicker variant="inline" value={selectedDate} onChange={e => changeDate(e, group.name)} format="MM/DD/YYYY" views={["year", "month", "date"]}/>
        </MuiPickersUtilsProvider>
    );
}