import {heatMapOptions} from "../generateDefaultChartOptions";
import createHandsontableSettingsFromData from "../createHandsontableSettingsFromData";

const _formatDataForHeatMap = new WeakMap();

class HeatMapData {
    constructor(data) {
        this.data = data;

        _formatDataForHeatMap.set(this, (dataType) => {
            // let temp = this.data;
            
            let data = [];
            if (dataType === 'kwh') {
                this.data.forEach(interval => {
                    data.push([interval.dayTimestamp, interval.minutes, interval.kWh])
                });
            } else {
                this.data.forEach(interval => {
                    data.push([interval.dayTimestamp, interval.minutes, interval.kW])
                });
            }
            // debugger;
            return data;
        })
    }
    
    generateChartOptions = (dataType = "kw", durationView, intervalDuration = 15) => {
        let data = _formatDataForHeatMap.get(this)(dataType);
        // debugger;
        return heatMapOptions(data, durationView, intervalDuration, dataType);
    };

    generateHandsontableSettings = (dataType) => {
        let data = _formatDataForHeatMap.get(this)(dataType);
        return createHandsontableSettingsFromData(data, 'heat-map', dataType)
    }

}

export default HeatMapData;

