import React from "react";
import {Route, Redirect, Switch} from "react-router-dom";

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={prop =>
            sessionStorage.getItem("user") ? (
                <Component {...prop} />
            ) : (
                <Redirect
                    to={{ pathname: '/login', state: { from: prop.location } }}
                />
            )
        }
    />
);

export default PrivateRoute;
