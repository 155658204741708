import moment from 'moment';
import {
    INTERVAL_DATA_RECEIVED,
    TIMESTAMP_DATA_RECEIVED,
    HEATMAP_DATA_RECEIVED,
    DEMAND_DURATION_DATA_RECEIVED,
    CALENDAR_CHART_DATA_RECEIVED,
    SURFACE_PLOT_DATA_RECEIVED,
    LOAD_CURVE_DATA_RECIEVED,
    CLEAR_INTERVAL_DATA,
    SUMMARY_CHART_DATA_RECIEVED,
} from '../actions/interval-data-actions'


const rawIntervalData = (state = null, action) => {
    switch (action.type) {
        case INTERVAL_DATA_RECEIVED:
        return action.payload;
    }
    return state
};

const intervalDataForZoomable = (state = null, action) => {
    switch (action.type) {
        case TIMESTAMP_DATA_RECEIVED:
            let newArray = [];
            let data = action.payload;
            //data.forEach(interval => {
            //    newArray.push([interval.dateTimestamp, interval.kW])
            //});
            //console.timeLog("test1");
            return data;
    }
    return state
} 

const intervalDataForDemandDuration = (state = null, action) => {
    
    switch (action.type) {
        case DEMAND_DURATION_DATA_RECEIVED:
            let newArray = [];
            let dateTimeArray = [];
            let data = action.payload;
            let returnData = {};
            data.forEach(interval => {
                newArray.push([interval.fakeTimestamp, interval.kW])
                dateTimeArray.push({intervalDateTime: interval.dateTimestamp})
            });
    
            returnData.data = newArray;
            returnData.dateTimeArray = dateTimeArray;
            
            console.timeLog("test1");
            return returnData;
    }
    return state
} 

const intervalDataForHeatMap = (state = null, action) => {
    switch (action.type) {
        case HEATMAP_DATA_RECEIVED:
        // let newArray = [];
        // let data = action.payload;
        // debugger;
        // data.forEach(interval => {
        //     newArray.push([interval.dayTimestamp, interval.minutes, interval.kW])
        // });
        // return newArray;
        let data = action.payload;
        return data
    }
    return state
} 

const intervalDataForCalendarChart = (state = null, action) => {
    switch (action.type) {
        case CALENDAR_CHART_DATA_RECEIVED:
        return action.payload;
    }
    return state
}


const intervalDataForSurfacePlot = (state = null, action) => {
    switch (action.type) {
        case SURFACE_PLOT_DATA_RECEIVED:
        return action.payload;
    }
    return state
}

const intervalDataForLoadCurve = (state = null, action) => {
    switch (action.type) {
        case LOAD_CURVE_DATA_RECIEVED:
            return action.payload;
        case CLEAR_INTERVAL_DATA:
            if (action.payload === 'load-curve')
                return null;           
    }
    return state
}

const intervalDataForSummaryChart = (state = null, action) => {
    switch (action.type) {
        case SUMMARY_CHART_DATA_RECIEVED:
            if (action.payload != null) {
                return action.payload;
            }
            else {
                return null
            }
        case CLEAR_INTERVAL_DATA:
            if (action.payload === 'summary-chart')
                return null;
    }
    return state
}


const intervalDataForExport = (state = null, action) => {
    switch (action.type) {
    case INTERVAL_DATA_RECEIVED:
        let newArray = [];
        let data = action.payload;
        //intervalDataForHorizontalHandsontable(data)
        return newArray;
    }
    return state
} 

export {
    rawIntervalData,
    intervalDataForZoomable,
    intervalDataForHeatMap,
    intervalDataForExport,
    intervalDataForDemandDuration,
    intervalDataForCalendarChart,
    intervalDataForSurfacePlot,
    intervalDataForLoadCurve,
    intervalDataForSummaryChart
    };