/* eslint-disable */
import React, { Component, Fragment } from "react";


import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';

import thermometer from '../../../assets/img/svg/thermometer.svg'
import ReactSVG from "react-svg";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';


import DataViewLoadingSpinnger from "../spinners/DataViewLoadingSpinner";
import highchartsExporting from "highcharts/modules/exporting";
import highchartsMore from "highcharts/modules/heatmap";
import ChartWrapper from "../../../components/ChartWrapper/ChartWrapper";

import moment from 'moment'
import 'moment-timezone'

window.moment = moment;

highchartsMore(Highcharts);
highchartsExporting(Highcharts);

let borderColor = grey[300];

const styles = theme => ({
    content: {
        flexGrow: 1,
        border: 'solid',
        borderColor: borderColor,
        borderWidth: 1,
        borderTop: 'none'
    },
    subtitle: {
        flexGrow: 1
    },
    title: {
        backgroundColor: grey[200],
        padding: 5,
        paddingLeft: 20,
        paddingRight: 20,
        align: 'center',
        border: 'solid',
        //backgroundColor: grey[200],
        borderColor: borderColor,
        borderWidth: 1,
        borderTop: 'none',
        borderBottom: 'none',
        borderLeft: 'none',
    },
    chartTitleBar: {
        border: 'solid',
        borderColor: borderColor,
        borderWidth: 1,
    },
    chartSection: {
        border: 'solid',
        borderColor: borderColor,
        borderWidth: 1,
        borderTop: 'none',
        paddingTop: 10
    },
    chartTypeTab: {
        border: 'solid',
        borderColor: borderColor,
        borderWidth: 1,
        borderTop: 'none',
        borderBottom: 'none',
        borderRight: 'none',
        paddingLeft: 10,
        paddingRight: 10,
        height: '100%',
        color: grey[700],
        backgroundColor: grey[200],
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: grey[300],
        }
    },
    toggleContainer: {
        //margin: theme.spacing(2, 0),
    },
    heatmapLegend: {
        backgroundImage: 'linear-gradient(to right, red , yellow, green, blue)',
    },
    button: {
        marginBottom: theme.spacing(1)
    },
    button2: {
        marginBottom: theme.spacing(1),
        backgroundColor: grey[300],
    },
    heatMapGradient: {
        backgroundImage: 'linear-gradient(to right, #003d99 0%, #66a3ff 20%, #63BE7B 40%, #fae052 60%, #f40b0f 80%, #b30000 100%)',
        height: 20,
        width: 300
    }
});

class ChartsView extends Component {

    getTitleText = (selectedFacilityView) => {
        let text;
        switch (selectedFacilityView) {
            case 'load-curve':
                text = 'Load Curve'
                break;
            case 'summary-chart':
                text = 'Summary Chart'
                break;
            case 'zoomable-chart':
                text = 'Zoomable Chart'
                break;
            case 'demand-duration':
                text = 'Demand Duration'
                break;
            case 'heat-map':
                text = "Heat Map"
                break;
            case 'scatter-plot':
                text = "Scatter Plot"
                break;
        }
        return text
    }
    
    getChart = (chartOptions, view, timezone) => {
        if (!timezone) {
            alert('no timezone returned for facility')
        }
        if (view !== 'load-curve') {
            Highcharts.setOptions({
                time: {
                    timezone: timezone
                }
            });
        }
        
        switch (view) {
            case 'load-curve':
            case 'summary-chart':
            case 'heat-map':
            case 'scatter-plot':
                return (
                    <Fragment>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={chartOptions}
                            ref="chartComponent"
                        />
                    </Fragment>
                )
            case 'demand-duration':
            case 'zoomable-chart':
                return (
                    <HighchartsReact
                        highcharts={Highcharts}
                        constructorType={'stockChart'}
                        options={chartOptions}
                        ref="chartComponent"
                    />
                )
        }
    };
    
    toggleTemperature = () => {
        const chart = this.refs.chartComponent.chart;
        let refs = this.refs;
        debugger;
        chart.reflow();
        this.props.toggleTemperatureView();
    };
    
    
    
    render() {
        const { 
            chartOptions, 
            selectedFacilityView,
            selectedFacilitySubview,
            selectedFacility, 
            handleChartOptionChange, 
            dataFilterMessage, 
            toggleDurationView,
            toggleTemperatureView,
            durationView, 
            temperatureView,
            showMeterDatesAndRanges,
            toggleShowDatesAndRangesView,
            classes 
        } = this.props;
        
        // stuff for heat map gradient calculation
        // let delta = selectedFacility.maximumkWValue - selectedFacility.minimumkWValue;
        // let divider = delta / 5;
        // let rounded = Math.ceil((24 + 1)/25) * 25
        //
        // debugger;

        return (
            <Grid container style={{ padding: 5, paddingTop: 15 }}>
                <Grid container>
                    {selectedFacilitySubview === 'zoomable-chart' ?
                        <div>
                            <Button
                                variant={"outlined"}
                                className={temperatureView ? classes.button2 : classes.button}
                                // startIcon={<ReactSVG src={thermometer} />}
                                startIcon={<CloudUploadIcon />}
                                onClick={() => this.toggleTemperature()}
                            >
                                <Grid container alignItems={"center"} >
                                    <ReactSVG src={thermometer}/>
                                    Temperature
                                </Grid>
                            </Button>
                            {/*<Button*/}
                            {/*    variant={"outlined"}*/}
                            {/*    className={showMeterDatesAndRanges ? classes.button2 : classes.button}*/}
                            {/*    // startIcon={<ReactSVG src={thermometer} />}*/}
                            {/*    startIcon={<CloudUploadIcon />}*/}
                            {/*    onClick={toggleShowDatesAndRangesView}*/}
                            {/*>*/}
                            {/*    <Grid container alignItems={"center"} >*/}
                            {/*        <ReactSVG src={thermometer}/>*/}
                            {/*        Shows Dates*/}
                            {/*    </Grid>*/}
                            {/*</Button>*/}
                        </div>
                        
                        : null}
                        <ChartWrapper 
                            title={this.getTitleText(selectedFacilitySubview)} 
                            message={dataFilterMessage ? dataFilterMessage.summary : null}
                            chartArea={
                                chartOptions !== null ?
                                    this.getChart(chartOptions, selectedFacilitySubview, selectedFacility.timezone)
                                    : <DataViewLoadingSpinnger />

                            }
                            titleBarRight={selectedFacilitySubview === "scatter-plot" || selectedFacilitySubview === "heat-map"?
                                <RadioGroup row aria-label="durationView" name="durationView" value={durationView} onChange={() => toggleDurationView()}>
                                    <FormControlLabel value="day" control={<Radio />} label="Day" />
                                    <FormControlLabel value="week" control={<Radio />} label="Week"/>
                                </RadioGroup>
                                : null}
                            
                        />

                </Grid>
            </Grid>
        )
    }
    
}

export default withStyles(styles)(ChartsView)