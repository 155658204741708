/* eslint-disable */
import React, { Component, Fragment } from "react";
import validate from '../../../js/validate'
import EditProjectDialog from './EditProjectDialog'
import CustomSnackbar from '../../../components/Snackbar/CustomSnackbar'
import {createOrUpdateProject, projectModel} from "../../../services/project.service";
import PhoneNumberTextMaskInput from "../../../components/TextMasks/PhoneNumberTextMaskInput";
import CustomDialog from "../../../components/Dialogs/customDialog";
import EditProjectForm from "./EditProjectForm";
import Button from "@material-ui/core/Button";
import GreenButton from "../../../components/CustomButtons/GreenButton";


class EditProject extends Component {

    state = {
        prevProjectForEditName: null,
        editProjectDialogOpen: false,
        selectedProjectForEdit: null,
        attemptedEditProjectSubmit: false,
        editProjectFormIsValid: true,
        
        editProjectFormControls: {
            name: {
                label: 'Project Name',
                value: null,
                valid: true,
                name: 'name',
                startAdornment: null,
                touched: false,
                validationRules: {
                    isRequired: true
                }
            },
            description: {
                label: 'Project Description (Optional)',
                value: null,
                valid: true,
                name: 'description',
                startAdornment: null,
                touched: false,
                validationRules: {
                    
                }
            },
            companyName: {
                label: 'Company Name',
                value: null,
                valid: true,
                touched: false,
                startAdornment: null,
                name: 'companyName',
                validationRules: {

                }
            },
            utility: {
                label: 'Utility',
                value: null,
                valid: true,
                touched: false,
                startAdornment: null,
                name: 'utility',
                validationRules: {

                }
            },
            contactName: {
                label: 'Contact Name',
                value: null,
                valid: true,
                touched: false,
                startAdornment: 'person',
                name: 'contactName',
                validationRules: {

                }
            },
            contactJobTitle: {
                label: 'Contact Job Title',
                value: null,
                valid: true,
                touched: false,
                startAdornment: 'work_outline',
                name: 'contactJobTitle',
                validationRules: {

                }
            },
            contactPhoneNumber: {
                label: 'Contact Phone Number',
                value: null,
                valid: true,
                touched: false,
                startAdornment: 'phone',
                name: 'contactPhoneNumber',
                validationRules: {

                },
                textMask: PhoneNumberTextMaskInput
            },
            contactEmail: {
                label: 'Contact Email',
                value: null,
                valid: true,
                touched: false,
                startAdornment: 'email',
                name: 'contactEmail',
                validationRules: {
                    isEmail: true
                }
            },
        },
        
        snackbar: {
            open: false,
            message: '',
            varient: ''
        },

        
    };

    toggleEditProjectDialog = (project = null) => {
        let editProjectDialogOpen = !this.state.editProjectDialogOpen;
        this.setState({ editProjectDialogOpen, selectedProjectForEdit: project })
    }

    handleEditProjectInputChange = (event, form) => {

        const value = event.target.value;
        const name = event.target.name;

        const updatedControls = { ...this.state.editProjectFormControls };
        const updatedFormElement = { ...updatedControls[name] }

        updatedFormElement.value = value;
        updatedFormElement.touched = true;
        updatedFormElement.valid = validate(value, updatedFormElement.validationRules);

        updatedControls[name] = updatedFormElement;

        // Check if form is valid
        let formIsValid = true;
        for (let inputIdentifier in updatedControls) {
            formIsValid = updatedControls[inputIdentifier].valid && formIsValid;
        }
        this.setState({
            editProjectFormControls: updatedControls,
            editProjectFormIsValid: formIsValid
        })
    }

    onEditProjectSubmit = () => {
        let submittedForm = { ...this.state.editProjectFormControls };
        let keys = Object.keys(submittedForm);
        let finalSubmission = {};
        keys.forEach(key => { finalSubmission[key] = submittedForm[key].value });

        if (this.state.editProjectFormIsValid) {
            console.log(finalSubmission);
            // TODO Edit Project. ProjectId = this.state.selectedProjectForEdit.projectId. finalSubmission is the new object that should have what you need

            let projectRequest = {...projectModel};

            projectRequest.ProjectId = this.props.selectedProjectForEdit.projectId;
            projectRequest.CompanyName = finalSubmission.companyName;
            projectRequest.ContactName = finalSubmission.contactName;
            projectRequest.ContactEmail = finalSubmission.contactEmail;
            projectRequest.ContactJobTitle = finalSubmission.contactJobTitle;
            projectRequest.ContactPhoneNumber = finalSubmission.contactPhoneNumber;
            projectRequest.Description = finalSubmission.description;
            projectRequest.Name = finalSubmission.name;
            projectRequest.Utility = finalSubmission.utility;

            createOrUpdateProject(projectRequest).then((response)=>{
                if (response.isSuccess){
                    this.toggleEditProjectDialog();
                    this.setState({ attemptedEditProjectSubmit: false })

                    // set snackbar
                    let snackbar = { ...this.state.snackbar };
                    snackbar.message = response.successMessage ? response.successMessage : "Successfully Updated Project";
                    snackbar.open = true;
                    snackbar.variant = "success";
                    this.setState({ snackbar });

                    this.props.onSubmit();
                    this.props.toggleEditProjectDialog();
                }else{

                    //default error message
                    let errorMessage = "Something went wrong. Please try again or contact React Energy Support.";

                    //check if response returned a better error message
                    if (response.errors && response.errors.length > 0){
                        errorMessage = response.errors[0].message;
                    }
                    
                    // set snackbar
                    let snackbar = { ...this.state.snackbar };
                    snackbar.message = errorMessage;
                    snackbar.open = true;
                    snackbar.variant = "error";
                    this.setState({ snackbar });
                }
            });

        } else {
            this.setState({ attemptedEditProjectSubmit: true })
        }


    }

    openSnackbar = (value) => {
        let snackbar = { ...this.state.snackbar }
        snackbar.open = value;
        this.setState({ snackbar })
    }

    componentDidUpdate = (prevProps) => {
        let project = { ...this.props.selectedProjectForEdit };
        // Make sure it's a new project and that the new one is different
        if (this.props.editProjectDialogOpen && project.name !== this.state.prevProjectForEditName) {
            // set the state to the project to edit
            let editProjectFormControls = { ...this.state.editProjectFormControls }
            editProjectFormControls.name.value = project.name;
            editProjectFormControls.companyName.value = project.companyName;
            editProjectFormControls.utility.value = project.utility;
            editProjectFormControls.description.value = project.description;
            editProjectFormControls.contactName.value = project.contactName;
            editProjectFormControls.contactJobTitle.value = project.contactJobTitle;
            editProjectFormControls.contactPhoneNumber.value = project.contactPhoneNumber;
            editProjectFormControls.contactEmail.value = project.contactEmail;
            this.setState({ editProjectFormControls, prevProjectForEditName: project.name })
        }
    }

    render() {

        return (
            <div>
                <CustomSnackbar
                    snackbar={this.state.snackbar}
                    openSnackbar={this.openSnackbar}
                />
                {this.props.selectedProjectForEdit === null ? null :
                    <CustomDialog
                        open={this.props.editProjectDialogOpen}
                        onClose={this.props.toggleEditProjectDialog}
                        subTitle="Edit Project"
                        // title={selectedProjectForEdit.name}
                        content={<EditProjectForm
                            formControls={this.state.editProjectFormControls}
                            handleInputChange={this.handleEditProjectInputChange}
                            attemptedSubmit={this.state.attemptedEditProjectSubmit}
                        />
                        }
                        actions={<GreenButton type="button" onClick={() => this.onEditProjectSubmit()}>Update Project</GreenButton>}
                    />
                }
               



            </div>
        )




    }
}


export default EditProject;

