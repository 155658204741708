import React, {Component} from 'react'
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import ReactSVG from 'react-svg'

import EquivalentCard from "./EquivalentCard";

// SVGs
import homeSVG from '../../../../../../assets/img/ghgComparisonSvgs/home.svg'
import burningCoal from '../../../../../../assets/img/ghgComparisonSvgs/burningCoal.svg'
import gasStation from '../../../../../../assets/img/ghgComparisonSvgs/gasStation.svg'
import car from '../../../../../../assets/img/ghgComparisonSvgs/car.svg'
import oilBarrel from '../../../../../../assets/img/ghgComparisonSvgs/oilBarrel.svg'
import coalRailcar from '../../../../../../assets/img/ghgComparisonSvgs/coalRailcar.svg'

class EmissionsComparisons extends Component {
    render() {
        const {carbonEquivalentPounds} = this.props;
        // 0.0004536 metric tons per pound
        let metricTonsCo2e = carbonEquivalentPounds * 0.0004536;
        //Constants from eGRID in metric Tons CO2 per x (https://www.epa.gov/energy/greenhouse-gases-equivalencies-calculator-calculations-and-references)
        let constants = {
            gallonsOfGasoline: .008887,
            gallonsOfDiesel: .010180,
            passengerVehiclesPerYear: 4.71,
            milesAveragePassengerVehicle: .000409,
            thermsOfNaturalGas: .0053,
            mcfOfNaturalGas: .0551,
            barrelsOfOilConsumed: .43,
            tankerTrucksFilledWithGasoline: 75.54,
            numberOfIncandescentBulbsSwitchedToLed: .0263,
            homeElectricityUse: 5.734,
            homeEnergyUse: 8.35,
            urbanTreeSeedlingsTenYears: .060,
            acresOfForestOneYear: -.085,
            acresOfForestPreserved: -123.22,
            railcarsOfCoalBurned: 183.29,
            poundsOfCoalBurned: .000915,
            tonsOfWasteRecycledInsteadOfLandfilled: 2.87,
            numberOfGarbageTrucksWasteRecycledInsteadOfLandfilled: 20.07,
            trashBagsOfWasteRecycledInsteadOfLandfilled: .0029,
            coalFiredPowerPlantOneYear: 3893003.27,
            numberOfWindTurbinesOneYear: 4719,
            numberOfSmartphonesCharged: 0.00000784,


        };
        let metricTonsRatios = [
            {
                name: 'gallonsOfGasoline',
                description: 'Gallons of Gasoline',
                metricTonsPer: constants.gallonsOfGasoline,
                equivalent: metricTonsCo2e / constants.gallonsOfGasoline,
                image: gasStation
            },
            {
                name: 'homeEnergyUse',
                description: 'homes energy use for one year',
                metricTonsPer: constants.homeEnergyUse,
                equivalent:  metricTonsCo2e / constants.homeEnergyUse,
                image: homeSVG
            },
            {
                name: 'poundsOfCoalBurned',
                description: 'pounds of coal burned',
                metricTonsPer: constants.poundsOfCoalBurned,
                equivalent:  metricTonsCo2e / constants.poundsOfCoalBurned,
                image: burningCoal
            },
            {
                name: 'railcarsOfCoal',
                description: 'railcars worth of coal burned',
                metricTonsPer: constants.railcarsOfCoalBurned,
                equivalent:  metricTonsCo2e / constants.railcarsOfCoalBurned,
                image: coalRailcar
            },
            {
                name: 'milesAveragePassengerVehicle',
                description: 'miles driven by an average passenger vehicle',
                metricTonsPer: constants.milesAveragePassengerVehicle,
                equivalent:  metricTonsCo2e / constants.milesAveragePassengerVehicle,
                image: car
            },
            {
                name: 'barrelsOfOil',
                description: 'barrels of oil consumed',
                metricTonsPer: constants.barrelsOfOilConsumed,
                equivalent:  metricTonsCo2e / constants.barrelsOfOilConsumed,
                image: oilBarrel
            },
        ]
        return (
            <Grid container >
                {metricTonsRatios.map(x => {
                    return (

                            <Grid item md={3} lg={3}>

                                <EquivalentCard description={x.description} value={x.equivalent} image={x.image}/>

                            </Grid>

                    )

                })}
            </Grid>

        )
    }
}

export default EmissionsComparisons