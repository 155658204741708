import { TOGGLE_CREATE_PROJECT_DIALOG, PROJECTS_MOUNTED} from '../actions/dashboard-actions'
import moment from 'moment'

const toggleCreateProjectDialog = (state = false, action) => {
    switch (action.type) {
        case TOGGLE_CREATE_PROJECT_DIALOG:
            return !action.payload;
    }
    return state;
};

const projects = (state = [], action) => {
    switch (action.type) {
        case PROJECTS_MOUNTED:
            let projects = action.payload;
            projects.sort(function(a, b) {
                if (moment(a.modifiedDate).isBefore(moment(b.modifiedDate))) {
                    return 1
                } else {
                    return -1
                }
            });
            return action.payload;
    }
    return state
} 


export { toggleCreateProjectDialog, projects };