import React, {Component} from 'react'

import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import {Typography} from "@material-ui/core";

import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import clsx from 'clsx';

import {changePassword} from '../../../services/user.service';

import validate from "../../../js/validate";
import CustomSnackbar from "../../../components/Snackbar/CustomSnackbar";

const styles = theme => ({
    container: {
        // display: 'flex',
        // flexWrap: 'wrap',
        // alignItems: "center",
        // justify: "center"
        // padding: 10
    },
    textField: {
        margin: theme.spacing(3),
        width: '80%',
        // color: 'red'
    },
    button: {
        margin: 15
    },
})

class ChangePasswordForm extends Component {
    state = {
        changePasswordFormIsValid: false,
        submitError: {
            value: false,
            mesasge: ''
        },
        snackbar: {
            open: false,
            message: '',
            varient: ''
        },
        changePasswordForm: {
            currentPassword: {
                label: "Current Password",
                value: null,
                showPassword: false,
                valid: false,
                touched: false,
                message: 'required',
                validationRules: {
                    isRequired: true,
                    // shouldn't have to check the existing password
                    // isPassword: true
                }
            },
            newPassword: {
                label: "New Password",
                value: null,
                showPassword: false,
                valid: false,
                touched: false,
                message: 'required',
                validationRules: {
                    isRequired: true,
                    isPassword: true
                }
            },
            confirmPassword: {
                label: "Confirm Password",
                value: null,
                showPassword: false,
                valid: false,
                touched: false,
                message: 'required',
                validationRules: {
                    isRequired: true,
                    isPassword: true
                }
            }
        }

    };

    handleChange = event => {

        let value = event.target.value;
        let name = event.target.name;
        let updatedControls = {...this.state.changePasswordForm};

        const updatedFormElement = {...updatedControls[name]};

        updatedFormElement.value = value;
        updatedFormElement.touched = true;
        updatedFormElement.valid = validate(value, updatedFormElement.validationRules);

        updatedControls[name] = updatedFormElement;


        // Check if form is valid
        let formIsValid = true;
        for (let inputIdentifier in updatedControls) {
            formIsValid = updatedControls[inputIdentifier].valid && formIsValid;
        }
        this.setState({
            changePasswordForm: updatedControls,
            changePasswordFormIsValid: formIsValid
        })

    }

    handleShowPassword = (name) => {
        let changePasswordForm = {...this.state.changePasswordForm};
        changePasswordForm[name].showPassword = !changePasswordForm[name].showPassword;
        this.setState({changePasswordForm})

    }

    onSubmit = () => {
        let changePasswordForm = {...this.state.changePasswordForm}
        const {currentPassword, newPassword, confirmPassword} = changePasswordForm;

        let match = (newPassword.value === confirmPassword.value);
        if (!match) {
            this.setState({submitError: {value: true, message: 'Passwords did not match'}})
        } else {
            this.setState({submitError: {value: false, message: ''}})
            
            changePassword(currentPassword.value, newPassword.value).then(response => {
                if (response.isSuccess) {
                    let snackbar = { ...this.state.snackbar };
                    snackbar.message = `Successfully Changed Password`;
                    snackbar.open = true;
                    snackbar.variant = "success";
                    this.setState({ snackbar });
                } else {
                    let snackbar = { ...this.state.snackbar };
                    snackbar.message = `Password Change Unsuccessful`;
                    snackbar.open = true;
                    snackbar.variant = "error";
                    this.setState({ snackbar });
                }
                
            })
        }
        return

    }

    render() {
        const {classes} = this.props;
        const {changePasswordForm} = this.state;

        let changePasswordFormKeys = Object.keys(changePasswordForm);
        return (
            <form className={classes.container}>


                    {/*I have absolutely no idea why this doesn't work*/}
                    {changePasswordFormKeys.map(key => {
                        return <Grid item xs={12} id={key}>
                            <FormControl className={clsx(classes.margin, classes.textField)}>
                                <InputLabel htmlFor="adornment-password">{changePasswordForm[key].label}</InputLabel>
                                <Input
                                    id="adornment-password"
                                    type={changePasswordForm[key].showPassword ? 'text' : 'password'}
                                    value={changePasswordForm[key].value}
                                    name={key}
                                    className={classes.input}
                                    error={changePasswordForm[key].touched && !changePasswordForm[key].valid}
                                    onChange={this.handleChange}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => this.handleShowPassword(key)}
                                            >
                                                {changePasswordForm[key].showPassword ? <Visibility/> :
                                                    <VisibilityOff/>}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                {key === 'newPassword' ?
                                    <FormHelperText >New
                                        Password must have a capital letter, a lowercase letter, a number, a special
                                        character, and a minimum length of 8.</FormHelperText> : null}
                            </FormControl>
                        </Grid>
                    })}
                    <Grid item xs={12}>

                        <Button
                            className={classes.button}
                            color="secondary"
                            variant="contained"
                            disabled={!this.state.changePasswordFormIsValid}
                            onClick={this.onSubmit}
                    
                        >
                            Submit
                        </Button>
                    </Grid>
                    <Grid item xs={12}>

                        {this.state.submitError.value ? <Typography style={{color: 'red'}}>{this.state.submitError.message}</Typography> : null}

                    </Grid>
                    

                <CustomSnackbar
                    snackbar={this.state.snackbar}
                    openSnackbar={this.openSnackbar}
                />
            </form>
        )
    }
}

export default withStyles(styles)(ChangePasswordForm)