import React, {Component} from 'react'
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { withStyles } from '@material-ui/core/styles';
import NotesTable from "./NotesTable";
import moment from 'moment'

// Services
import {createOrUpdateFacilityNote, facilityNoteModel, getFacilityNoteById, getFacilityNotesByFacilityId, deleteFacilityNote} from "../../../services/facility.service";

// Components
import CreateNoteDialog from "./Dialogs/CreateNoteDialog";
import EditNoteDialog from "./Dialogs/EditNoteDialog";
import DeleteNoteDialog from "./Dialogs/DeleteNoteDialog";
import EmptyGif from "../../../components/EmptyGif/EmptyGif";
import notebooksGif from '../../../assets/gifs/notebooks.gif'
import CustomPulseLoader from "../../../components/Spinners/pulse-loader";
import {handleTextInputChangeFormControl} from "../../../js/FormValidation/formValidationHelpers";
import GreenButton from "../../../components/CustomButtons/GreenButton";
import {Icon} from "@material-ui/core";

const styles = theme => ({
    root: {
        display: 'flex',
        width: '100%',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(0),

    },
    toolbar: theme.mixins.toolbar,

});

class FacilityNotes extends Component {
    state = {
        notes: [],
        attemptedCreateNoteSubmit: false,
        createNoteFormIsValid: false,
        createNoteFormControls: {
            title: {
                label: 'Name',
                placeHolder: 'Equipment behind the meter...',
                name: 'title',
                value: null,
                valid: false,
                touched: false,
                validationRules: {
                    isRequired: true
                },
            },
            notes: {
                label: 'Notes',
                placeHolder: '2 chillers, 4 air handlers, 30 VAVs, etc...',
                name: 'notes',
                value: null,
                valid: false,
                touched: false,
                validationRules: {
                    isRequired: true
                },
            },
        },
        deleteNote: null,
        deleteNoteDialogOpen: false,
        attemptedEditNoteSubmit: false,
        editNoteFormControls: null,
        editNoteFormIsValid: true,
        editNoteId: null,
        editNoteDialogOpen: false,
        createNoteDialogOpen: false,
        finishedFetchingNotes: false
    };
    
    handleCreateNoteChange = (event) => {
        const {isValid, updatedControls} = handleTextInputChangeFormControl(event, {...this.state.createNoteFormControls});
        this.setState({createNoteFormIsValid: isValid, createNoteFormControls: updatedControls})
    };

    handleEditNoteChange = (event) => {
        const {isValid, updatedControls} = handleTextInputChangeFormControl(event, {...this.state.editNoteFormControls});
        this.setState({editNoteFormIsValid: isValid, editNoteFormControls: updatedControls})
    };

    toggleCreateNoteDialog = () => {
        this.setState({createNoteDialogOpen: !this.state.createNoteDialogOpen})
    }
    
    toggleDeleteNoteDialog = (note = null) => {
        
        this.setState({deleteNoteDialogOpen: !this.state.deleteNoteDialogOpen, deleteNote: note})
    }

    toggleEditNoteDialog = (note = null) => {
        let editNoteFormControls = {
            title: {
                label: 'Name',
                placeHolder: 'Equipment behind the meter...',
                name: 'title',
                value: note.title,
                valid: true,
                touched: false,
                validationRules: {
                    isRequired: true
                },
            },
            notes: {
                label: 'Notes',
                placeHolder: '2 chillers, 4 air handlers, 30 VAVs, etc...',
                name: 'notes',
                value: note.notes,
                valid: true,
                touched: false,
                validationRules: {
                    isRequired: true
                },
            },
        };
        
        let editNoteId = note.facilityNoteId;
        this.setState({editNoteDialogOpen: !this.state.editNoteDialogOpen, editNoteFormControls, editNoteId})
    }
    
    onCreateOrEditNoteSubmit = (type) => {
        
        let request = facilityNoteModel;
        request.FacilityId = this.props.selectedFacility.facilityId;
        
        
        switch (type) {
            case 'create':
                if (this.state.createNoteFormIsValid) {
                    request.Notes = this.state.createNoteFormControls.notes.value;
                    request.Title = this.state.createNoteFormControls.title.value;
                    request.FacilityNoteId = null;
                    this.submitNote(request);
                    this.setState({createNoteDialogOpen: false});
                } else {
                    this.setState({attemptedCreateNoteSubmit: true})
                }

                break;
            case 'edit':
                if (this.state.editNoteFormIsValid) {
                    request.Notes = this.state.editNoteFormControls.notes.value;
                    request.Title = this.state.editNoteFormControls.title.value;
                    request.FacilityNoteId = this.state.editNoteId;
                    this.submitNote(request);
                    this.setState({editNoteDialogOpen: false});
                } else {
                    this.setState({attemptedCreateNoteSubmit: true})
                }

                break;
                // request.Notes = this.state.editNote.notes;
                // request.Title = this.state.editNote.title;
                // request.FacilityNoteId = this.state.editNote.facilityNoteId;
                // let temp = this.state.editNote;
                // this.setState({editNoteDialogOpen: false});
                // break;
        }
        
        
    };
    
    submitNote = (request) => {
        createOrUpdateFacilityNote(request).then(x => {
            getFacilityNotesByFacilityId(this.props.selectedFacility.facilityId).then(response => {
                let notes = response.facilityNotes;
                this.setState({notes})
            })
        });
    }
    
    onDeleteNoteSubmit = () => {
        let id = this.state.deleteNote;
        deleteFacilityNote(this.state.deleteNote.facilityNoteId).then(x => {
            getFacilityNotesByFacilityId(this.props.selectedFacility.facilityId).then(response => {
                let notes = response.facilityNotes;
                this.setState({notes})
            })
        });;
        this.toggleDeleteNoteDialog()
    }

    componentDidMount = () => {
        const {selectedFacility} = this.props;
        if (selectedFacility !== null) {
            getFacilityNotesByFacilityId(this.props.selectedFacility.facilityId).then(response => {
                let notes = response.facilityNotes;
                this.setState({notes, finishedFetchingNotes: true})
            })
        }
        
    }

    componentDidUpdate = (prevProps) => {
        const {selectedFacility} = this.props;
        if (selectedFacility !== null && prevProps.selectedFacility.facilityId !== selectedFacility.facilityId) {
            getFacilityNotesByFacilityId(this.props.selectedFacility.facilityId).then(response => {
                let notes = response.facilityNotes;
                this.setState({notes})
            })
        }
    }
    
    render() {
        const { classes, selectedFacility } = this.props;
        return (
            <Grid container className={classes.root}>
                {this.state.notes.length && this.state.finishedFetchingNotes ?
                <Grid item xs={12}>
                    <Grid container justify="flex-end" style={{padding: 10}}>
                        <GreenButton variant="contained" color="secondary" onClick={this.toggleCreateNoteDialog}><Icon style={{marginRight: 5}}>add_circle</Icon>Add Note</GreenButton>
                    </Grid>
                </Grid> : null }
                <Grid item xs={12}>
                    {this.state.notes.length !== 0 ?
                    <NotesTable 
                        notes={this.state.notes}
                        toggleDeleteNoteDialog={this.toggleDeleteNoteDialog}
                        toggleEditNoteDialog={this.toggleEditNoteDialog}
                    /> : this.state.finishedFetchingNotes ?
                        <EmptyGif
                            gif={notebooksGif}
                            title={'No Notes Yet'}
                            message={'No notes have been added yet.'}
                            buttonText={'Add Note'}
                            onClick={this.toggleCreateNoteDialog}
                        /> : <Grid container justify={"center"}>
                                <CustomPulseLoader />
                            </Grid>
                    }
                </Grid>
                <CreateNoteDialog 
                    open={this.state.createNoteDialogOpen} 
                    onClose={this.toggleCreateNoteDialog}
                    createNoteFormControls={this.state.createNoteFormControls}
                    attemptedCreateNoteSubmit={this.state.attemptedCreateNoteSubmit}
                    handleCreateNoteChange={this.handleCreateNoteChange}
                    onCreateOrEditNoteSubmit={this.onCreateOrEditNoteSubmit}
                
                />
                <EditNoteDialog
                    open={this.state.editNoteDialogOpen}
                    onClose={this.toggleEditNoteDialog}
                    editNoteFormControls={this.state.editNoteFormControls}
                    attemptedEditNoteSubmit={this.state.attemptedEditNoteSubmit}
                    handleEditNoteChange={this.handleEditNoteChange}
                    onCreateOrEditNoteSubmit={this.onCreateOrEditNoteSubmit}

                />
                <DeleteNoteDialog
                    open={this.state.deleteNoteDialogOpen}
                    onClose={this.toggleDeleteNoteDialog}
                    deleteNote={this.state.deleteNote}
                    onDeleteNoteSubmit={this.onDeleteNoteSubmit}
                />
            </Grid>
            
        )
    }
}

export default withStyles(styles)(FacilityNotes)