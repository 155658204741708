import React, { Fragment, Component, useState } from 'react'
import { connect } from 'react-redux'
import { Field, FieldArray, reduxForm, SubmissionError, FormSection } from 'redux-form'
import getDSTdays from '../js/DstDays'


// Material UI
import Grid from '@material-ui/core/Grid';


// Cards
import ExcelCard from './cards/MicrosoftExcelCard'
import CsvCard from './cards/CsvCard'
import HandsontableCard from './cards/HandsontableCard'


//Moment
import moment from 'moment'

class ContactForm extends Component {

        // componentDidMount() {
        //
        //     let temp = moment.tz.zone('Australia/Sydney');
        //     let offsets = [...new Set(temp.offsets)];
        //     debugger;
        // }

    render() {
        const { handleSubmit, reset, handleClick } = this.props;
        const gridItemStyle = {
            alignItems: 'center',
            boxShadow: 'none',
            borderStyle: 'solid',
            borderColor: '#c2c2c2',
            borderWidth: 'thin',
            borderRadius: 10,
            height: "100%"
        };

        let minDate = moment().year(2019).month(0).date(1);
        let maxDate = moment().year(2020).month(0).date(1);

        let importMethods = [
            {
                title: 'Microsoft Excel',
                description: 'for .xlsx files',
                component: function() {
                    return <ExcelCard title={this.title} description={this.description} handleClick={handleClick} />
                }
            },
            {
                title: 'CSV',
                description: 'for .csv files',
                component: function () {
                    return <CsvCard title={this.title} description={this.description} handleClick={handleClick} />
                }
            },
            {
                title: 'Paste Data',
                description: 'Paste Data into Spreadsheet',
                component: function () {
                    return <HandsontableCard title={this.title} description={this.description} handleClick={handleClick} />
                }
            },

            ]

        return (

                <form onSubmit={handleSubmit}>

                    <Grid container style={{ padding: 10, display: 'flex', justifyItems: 'center' }} >
                            {importMethods.map(method => (
                            <Grid item xs={12} md={4} style={{ padding: 10, }}>
                                <Grid container style={{ display: 'flex', justifyContent: 'center'}}>
                                    {method.component()}
                                </Grid>
                                       

                                    

                                </Grid>

                            ))}
                        </Grid>

                </form>

        )
    }


}

ContactForm = reduxForm({
    // a unique name for the form
    form: 'selectImportMethodForm',
    enableReinitialize: true,
})(ContactForm)

export default (ContactForm)