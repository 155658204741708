import React, {Component} from 'react'

import './style.css'
// import LoadingHighchart from "./highchart-loading";

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';




let array = [147.6,139,141,143,142,147,141,139,133,140,142,142,141,141,137,141,144,144,136,135,167,186,177,180,204,203,246,267,371,400,434,441,448,453,454,456,457,452,453,454,450,451,458,465,459,456,453,460,464,453,441,443,446,441,440,443,449,450,454,448,451,442,458,445,440,420,400,363,260,232,223,215,211,209,180,173,174,175,180,181,170,164,167,171,165,164,163,165,157,154,156,154,154,154,153,150];
let count = 0;

let options = {
    chart: {
        type: 'line',
        animation: {
            duration: 6000
        }, // don't animate in old IE
        backgroundColor: 'transparent',
        marginRight: 10,
    },
    events: {
        addSeries: function () {
            debugger;
            var label = this.renderer.label('A series was added, about to redraw chart', 100, 120)
                .attr({
                    fill: Highcharts.getOptions().colors[0],
                    padding: 10,
                    r: 5,
                    zIndex: 8
                })
                .css({
                    color: '#FFFFFF'
                })
                .add();

            setTimeout(function () {
                label.fadeOut();
            }, 1000);
        }
    },

    time: {
        useUTC: false
    },

    title: {
        text: 'Live random data'
    },

    accessibility: {
        announceNewData: {
            enabled: true,
            minAnnounceInterval: 15000,
            announcementFormatter: function (allSeries, newSeries, newPoint) {
                if (newPoint) {
                    return 'New point added. Value: ' + newPoint.y;
                }
                return false;
            }
        }
    },

    xAxis: {
        type: 'datetime',
        tickPixelInterval: 150,
        gridLineColor: 'transparent',
                lineWidth: 0,
                minorGridLineWidth: 0,
                labels: {
                    enabled: false
                },
                minorTickLength: 0,
                tickLength: 0
    },

    yAxis: {
        title: {
            text: ''
        },
        gridLineColor: 'transparent',
        lineWidth: 0,
        minorGridLineWidth: 0,
        labels: {
            enabled: false
        },
        minorTickLength: 0,
        tickLength: 0
    },

    tooltip: {
        headerFormat: '<b>{series.name}</b><br/>',
        pointFormat: '{point.x:%Y-%m-%d %H:%M:%S}<br/>{point.y:.2f}'
    },
    plotOptions: {
        series: {
            animation: {
                duration: 6000
            },
        }
    },

    legend: {
        enabled: false
    },

    exporting: {
        enabled: false
    },

    series: [{
        name: 'series',
        data: array,
        color: '#ffffff'
    }]
}

class LoadingWeatherHighchart extends Component {
    constructor(props) {
        super(props);
        this.chart = React.createRef();
        this.options = {
            chart: {
                type: 'line',
                animation: {
                    duration: 6000
                }, // don't animate in old IE
                backgroundColor: 'transparent',
                marginRight: 10,
                // events: {
                //     addSeries: function () {
                //         this.redraw();
                //         var label = this.renderer.label('A series was added, about to redraw chart', 100, 120)
                //             .attr({
                //                 fill: Highcharts.getOptions().colors[0],
                //                 padding: 10,
                //                 r: 5,
                //                 zIndex: 8
                //             })
                //             .css({
                //                 color: '#FFFFFF'
                //             })
                //             .add();
                //
                //         setTimeout(function () {
                //             label.fadeOut();
                //         }, 1000);
                //        
                //     }
                // },
            },
            

            time: {
                useUTC: false
            },

            title: {
                text: 'Live random data'
            },

            accessibility: {
                announceNewData: {
                    enabled: true,
                    minAnnounceInterval: 15000,
                    announcementFormatter: function (allSeries, newSeries, newPoint) {
                        if (newPoint) {
                            return 'New point added. Value: ' + newPoint.y;
                        }
                        return false;
                    }
                }
            },

            xAxis: {
                type: 'datetime',
                tickPixelInterval: 150,
                gridLineColor: 'transparent',
                lineWidth: 0,
                minorGridLineWidth: 0,
                labels: {
                    enabled: false
                },
                minorTickLength: 0,
                tickLength: 0
            },

            yAxis: {
                title: {
                    text: ''
                },
                gridLineColor: 'transparent',
                lineWidth: 0,
                minorGridLineWidth: 0,
                labels: {
                    enabled: false
                },
                minorTickLength: 0,
                tickLength: 0
            },

            tooltip: {
                headerFormat: '<b>{series.name}</b><br/>',
                pointFormat: '{point.x:%Y-%m-%d %H:%M:%S}<br/>{point.y:.2f}'
            },
            plotOptions: {
                series: {
                    animation: {
                        duration: 6000
                    },
                }
            },

            legend: {
                enabled: false
            },

            exporting: {
                enabled: false
            },

            series: [{
                name: 'series',
                data: array,
                color: '#ffffff'
            }]
        }
    }
    

    
    // afterChartCreated = (chart) => {
    //     this.internalChart = chart;
    // };

    componentDidMount = () => {
        let chart = this.chart.current.chart;
        


        //chart.series[0].addPoint({x: 10, y: 12});

        // set up the updating of the chart each second
        var series = chart.series[0];
        
        
        setInterval(function () {
            // chart.destroy();
            let newArray = [];
            chart.series[0].remove();
            array.forEach((x, index) => {
                let positive = Math.random() < 0.5 ? -1 : 1;
                let y = Math.floor(Math.random() * 20)*positive;
                newArray.push(array[index] + y)
            });
            chart.addSeries({
                name: 'series',
                color: '#FFFFFF',
                data: newArray
            });
            
            // chart.redraw()
        }, 6000);

    }


    render() {
        // this.yourFunction();

        return (
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
                className={"chart"}
                // callback={ this.afterChartCreated }
                ref={this.chart}
            />
        )
    }


}

export default LoadingWeatherHighchart