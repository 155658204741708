import React, {Component} from 'react';
import Plot from 'react-plotly.js';
import {
    getIntervalDataLoadCurveByFacilityId,
    getIntervalDataTimestampByFacilityId
} from "../../../services/intervalData.service";
import moment from "moment";
import 'moment-timezone'
import CustomTabsPaper from "../../../components/tabs/CustomTabsPaper";
import ChartWrapper from "../../../components/ChartWrapper/ChartWrapper";
import TableView from "./TableView";
import DataViewLoadingSpinner from "../spinners/DataViewLoadingSpinner";

class SurfacePlot extends Component {
    state = {
        layout: null,
        data: null,

        tabs: [
            {
                label: "Chart",
                value: "chart",
            },
            {
                label: "Table",
                value: 'table',
            }
        ],
        currentTab: 0,

    };
    
    getCurrentComponent = () => {
        if (this.state.currentTab === 0) {
            return (
            <div style={{padding: '15px 5px 5px 5px', width: '100%'}}>
                <ChartWrapper
                    chartArea={this.props.plotlyChartOptions ? <Plot
                        data={this.props.plotlyChartOptions.data}
                        layout={this.props.plotlyChartOptions.layout}
                        style={{width: '100%'}}
                    /> : <DataViewLoadingSpinner />}
                    title={"Surface Plot"}
                />
            </div>
            )
        } else if (this.state.currentTab === 1) {
            return <TableView chartTableSettings={this.props.chartTableSettings} exportDataAsCsv={this.props.exportDataAsCsv}/>
        }
    };

    setTab = (value) => {

        this.setState({ currentTab: value });

    }

    render() {


        return (
            <CustomTabsPaper
                tabs={this.state.tabs}
                currentComponent={this.getCurrentComponent()}
                setTab={this.setTab}
                currentTab={this.state.currentTab}
            /> )

    }
}

export default SurfacePlot