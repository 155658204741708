import React, {Component} from 'react'
import Dialog from '@material-ui/core/Dialog'
import { withStyles } from '@material-ui/core/styles';
import {ScaleLoader} from "react-spinners";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import blue from '@material-ui/core/colors/blue';
import thermometer from '../../../assets/img/svg/thermometer.svg'
import ReactSVG from "react-svg";
import {Translate} from "@material-ui/icons";
import './style.css'

const styles = theme => ({
    root: {
        // backgroundColor: "transparent",
    },

    paper: {
        backgroundColor: "transparent",
        boxShadow: "none",
        overflow: "hidden"
    },
    text: {
        color: '#fff'
    }
});

class UploadingDataDialog extends Component {

    render() {
        const {classes, message} = this.props;
        return (
            <Dialog
                open={this.props.open}
                aria-labelledby="simple-dialog-title"
                BackdropProps={{
                    classes: {
                        root: classes.root
                    }
                }
                }
                PaperProps ={{
                    classes: {
                        root: classes.paper
                    }
                }}
            >
                <Grid container alignItems="center" justify="center">
                    <Grid item xs={12}>
                        <Grid container alignItems="center" justify="center">
                            
                                <ScaleLoader color={'#fff'}/>
                            
                            
                        </Grid>
                        <Grid container alignItems="center" justify="center">
                            <Typography className={classes.text}>{message}</Typography>


                        </Grid>
                    </Grid>
                    {/*<Grid item xs={12}>*/}
                    {/*    <Grid container alignItems="center" justify="center">*/}
                    {/*        <Typography >Loading Data</Typography>*/}
                    {/*    </Grid>*/}
                    {/*</Grid>*/}

                </Grid>

            </Dialog>
        )
    }
    
}

export default withStyles(styles)(UploadingDataDialog)