/* eslint-disable */
import React, { Component, Fragment } from "react";

// Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {toggleCalendarChartShowPeakDemand, toggleCalendarChartShowTemperature} from "../../../../redux/actions/DataChartActions/calendar-chart-actions";
import { addCalendarChartDataToState } from '../../../../redux/actions/interval-data-actions';


//import { getCalendarChartData } from "../../services/intervalData.service";
import moment from "moment";

//Material UI
import Paper from '@material-ui/core/Paper';
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import FormControlLabel from '@material-ui/core/FormControlLabel';

// Components
import CalendarChartTable from './CalendarChartTable'
import DataViewLoadingSpinner from "../../../components/spinners/DataViewLoadingSpinner";
import ChartDataFilterFormObject from "../../../components/DataFilterForm/ChartDataFilterFormObject";
import {getIntervalDataLoadCurveByFacilityId} from "../../../../services/intervalData.service";
import CustomSnackbar from "../../../../components/Snackbar/CustomSnackbar";




class CalendarChart extends Component {
    state = {
        currentMoment: moment.tz(this.props.selectedFacility.intervalDataStartDate, this.props.selectedFacility.timezone),
        currentView: 'month',
        dataStartDate: moment.tz(this.props.selectedFacility.intervalDataStartDate, this.props.selectedFacility.timezone),
        dataEndDate: moment.tz(this.props.selectedFacility.intervalDataEndDate, this.props.selectedFacility.timezone),
        currentMonthData: null,
        currentDayData: null,
        showPeakDemand: false,
        comparisonLoadCurveData: null,
        showComparisonLoadCurve: false,
        snackbar: {
            open: false
        }
    }

    openSnackbar = (value) => {
        let snackbar = { ...this.state.snackbar }
        snackbar.open = value;
        this.setState({ snackbar })
    }

    
    
    componentDidMount() {
        const {selectedFacility} = this.props;
        let loadCurveForm = new ChartDataFilterFormObject(selectedFacility).getDefaultLoadCurveFormForCalendarChart();
        let request = new ChartDataFilterFormObject(selectedFacility).getRequestFromForm(loadCurveForm, this.props.selectedFacility);
        getIntervalDataLoadCurveByFacilityId(request).then(response => {
            this.setState({comparisonLoadCurveData: response[0].data})
        })
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { chartCanUpdateData, setChartCanUpdateData, intervalDataForCalendarChart } = this.props;
        
        if (chartCanUpdateData && intervalDataForCalendarChart !== null || prevProps.intervalDataForCalendarChart !== this.props.intervalDataForCalendarChart) {
            this.handleChange();
            setChartCanUpdateData(false)
        }
        if (prevState.currentMoment.valueOf() !== this.state.currentMoment.valueOf()) {
            let currentDayData = this.state.currentMonthData.dayData.filter(day => day.date === this.state.currentMoment.date())[0];

            this.setState({currentDayData})
        }
    }

    handleChange = () => {
        const  {timezone} = this.props.selectedFacility;
        // let currentMonth = moment.tz(this.state.currentMoment, timezone).date(1).hours(0).minutes(0).seconds(0).milliseconds(0).valueOf();
        let currentMonth = moment.tz(this.state.currentMoment, timezone);
        // debugger;
        let currentMonthData = this.props.intervalDataForCalendarChart.filter(month => moment.tz(month.month, timezone).isSame(currentMonth, 'month'));
        let currentDayData = currentMonthData[0].dayData.filter(day => day.date === this.state.currentMoment.date())[0];
        this.setState({ currentMonthData: currentMonthData[0], currentDayData })
    }

    handleNext = () => {
        const  {timezone} = this.props.selectedFacility;
        let currentMoment = moment.tz(this.state.currentMoment, timezone)
        if (this.state.currentView === 'month') {
            currentMoment.add(1, 'month').date(1);
            if (currentMoment.isAfter(this.state.dataEndDate)) {
                let snackbar = { ...this.state.snackbar };
                snackbar.message = "No data after this point";
                snackbar.open = true;
                snackbar.variant = "error";
                this.setState({ snackbar });
            } else {
                this.setState({ currentMoment }, () => this.handleChange())
            }
            
        } else {
            currentMoment.add(1, 'day');
            if (currentMoment.isAfter(this.state.dataEndDate)) {
                let snackbar = { ...this.state.snackbar };
                snackbar.message = "No data after this point";
                snackbar.open = true;
                snackbar.variant = "error";
                this.setState({ snackbar });
            } else {
                this.setState({ currentMoment }, () => this.handleChange())
            }
        }
        
    }

    

    handlePrevious = () => {
        const  {timezone} = this.props.selectedFacility;
        let currentMoment = moment.tz(this.state.currentMoment, timezone);
        if (this.state.currentView === 'month') {
            currentMoment.date(0);
            if (currentMoment.isBefore(this.state.dataStartDate)) {
                let snackbar = { ...this.state.snackbar };
                snackbar.message = "No data before this point";
                snackbar.open = true;
                snackbar.variant = "error";
                this.setState({ snackbar });

            } else {
                this.setState({ currentMoment }, () => this.handleChange())
            }
        } else {
            currentMoment.subtract(1, 'day');
            if (currentMoment.isBefore(this.state.dataStartDate)) {
                let snackbar = { ...this.state.snackbar };
                snackbar.message = "No data before this point";
                snackbar.open = true;
                snackbar.variant = "error";
                this.setState({ snackbar });
            } else {
                this.setState({ currentMoment }, () => this.handleChange())
            }
        }
        
    }

    handleDateChange = (e) => {
        
        if (e.isBefore(this.state.dataStartDate)) {
            this.setState({ currentMoment: this.state.dataStartDate })
        } else if (e.isAfter(this.state.dataEndDate)) {
            this.setState({ currentMoment: this.state.dataEndDate })
        } else {
            this.setState({ currentMoment: e }, () => this.handleChange())
        }
        
    }

    changeViewToDay = () => {
        this.setState({currentView: 'day'})
    }
    
    selectDate = (e, day) => {
        const  {timezone} = this.props.selectedFacility;
        let date = day.date;
        let series = day.series;
        this.setState({currentView: 'day'});
        let currentMoment = moment.tz(this.state.currentMoment, timezone).date(date);
        currentMoment.date(date);
        this.setState({currentMoment});
        
        
    }

    changeViewToMonth = () => {
        this.setState({ currentView: 'month' })
    }
    
    toggleShowPeakDemand = () => {
        // let showPeakDemand = this.state.showPeakDemand;
        // this.setState({showPeakDemand: !showPeakDemand});
        this.props.toggleCalendarChartShowPeakDemand(!this.props.calendarChartShowPeakDemand)
    };

    toggleShowComparisonLoadCurve = () => {
        let showComparisonLoadCurve = this.state.showComparisonLoadCurve;
        this.setState({showComparisonLoadCurve: !showComparisonLoadCurve});
    };

    checkKey = (event) => {
        if (event.keyCode === 37) {
            this.handlePrevious()
        } else if (event.keyCode === 39) {
            this.handleNext()
        } else if (event.keyCode === 38) {
            this.props.toggleCalendarChartShowPeakDemand(!this.props.calendarChartShowPeakDemand)
            
        }
        
    };

    render() {
        //const { intervalDataForDemandDuration } = this.props;
        const { currentMoment, currentView, dataStartDate, dataEndDate, currentMonthData } = this.state;
        const peakDemand = this.props.selectedFacility.maximumkWValue;
        
        document.onkeydown = this.checkKey;
        console.log('rendering calendar chart')
       
        return (
            <div style={{paddingTop: 5}}>
                <CustomSnackbar snackbar={this.state.snackbar} openSnackbar={this.openSnackbar}/>
                {currentMonthData ?
                    <div>
                       
                        <Paper>


                            <CalendarChartTable
                                currentMoment={currentMoment}

                                handleNext={this.handleNext}
                                handlePrevious={this.handlePrevious}
                                handleDateChange={this.handleDateChange}
                                changeViewToDay={this.changeViewToDay}
                                changeViewToMonth={this.changeViewToMonth}
                                currentView={currentView}
                                dataStartDate={dataStartDate}
                                dataEndDate={dataEndDate}
                                currentMonthData={currentMonthData}
                                peakDemand={peakDemand}
                                selectDate={this.selectDate}
                                currentDayData={this.state.currentDayData}
                                showPeakDemand={this.props.calendarChartShowPeakDemand}
                                comparisonLoadCurveData={this.state.comparisonLoadCurveData}
                                showComparisonLoadCurve={this.state.showComparisonLoadCurve}
                                selectedFacility={this.props.selectedFacility}
                                temperatureView={this.props.calendarChartShowTemperature}
                            /></Paper>
                    </div>
                     : <DataViewLoadingSpinner />}
                
                </div>
        )
    }

};

function mapStateToProps(state) {
    return {
        selectedFacility: state.selectedFacility,
        intervalDataForCalendarChart: state.intervalDataForCalendarChart,
        calendarChartShowTemperature: state.calendarChartShowTemperature,
        calendarChartShowPeakDemand: state.calendarChartShowPeakDemand
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ 
        addCalendarChartDataToState,
        toggleCalendarChartShowTemperature,
        toggleCalendarChartShowPeakDemand
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarChart);



