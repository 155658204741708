import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});



export default function RegressionDetails(props) {
    const classes = useStyles();
    const {regression} = props;
    // debugger;
    const rows = [
        {
            name: 'CDD', 
            coefficient: regression.weights[0][0],
            stdError: regression.stdErrors[0],
            tStat: regression.tStats[0],
            pValue: regression.pValues[0],
            
        },
        {
            name: 'HDD', 
            coefficient: regression.weights[1][0],
            stdError: regression.stdErrors[1],
            tStat: regression.tStats[1],
            pValue: regression.pValues[1],
        },
        {
            name: 'Intercept', 
            coefficient: regression.weights[2][0],
            stdError: regression.stdErrors[2],
            tStat: regression.tStats[2],
            pValue: regression.pValues[2],
        },
    ];

    return (
        <Paper>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell align="right">Coefficient</TableCell>
                        <TableCell align="right">Standard Error</TableCell>
                        <TableCell align="right">tStat</TableCell>
                        <TableCell align="right">p Value</TableCell>
                        {/*<TableCell align="right">Protein&nbsp;(g)</TableCell>*/}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map(row => (
                        <TableRow key={row.name}>
                            <TableCell component="th" scope="row">
                                {row.name}
                            </TableCell>
                            <TableCell align="right">{row.coefficient.toFixed(2)}</TableCell>
                            <TableCell align="right">{row.stdError.toFixed(2)}</TableCell>
                            <TableCell align="right">{row.tStat.toFixed(2)}</TableCell>
                            <TableCell align="right">{row.pValue}</TableCell>
                            {/*<TableCell align="right">{row.protein}</TableCell>*/}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Paper>
    );
}