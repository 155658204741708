import React, {Component, Fragment} from 'react';

// Material UI
import Button from '@material-ui/core/Button';

// Components
import EditFacilityForm from '../forms/EditFacilityForm';
import CustomDialog from "../../../components/Dialogs/customDialog";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {
    toggleEditMeterDialogOpen,
    onEditMeterSubmit,
    handleEditMeterInputChange,
} from "../../../redux/actions/MeterActions/EditMeterActions/edit-meter-actions";
import CreateFacilityForm from "../forms/CreateFacilityForm";
import GreenButton from "../../../components/CustomButtons/GreenButton";


class EditFacilityDialog extends Component {
    componentDidUpdate(prevProps, prevState, snapshot) {
        // if (this.props.editFacilityDialogOpen !== prevProps.editFacilityDialogOpen) {
        //     debugger;
        // }
    }

    render() {
        const {
            //parent
            onEditComplete,
            
            //redux variables
            attemptedEditMeterSubmit,
            editMeterDialogOpen,
            selectedMeterForEdit,
            editMeterFormControls,

            //redux actions
            toggleEditMeterDialogOpen,
            onEditMeterSubmit,
            handleEditMeterInputChange
        } = this.props;


        return (
            <Fragment>
                {selectedMeterForEdit === null ? null :

                        <CustomDialog
                            open={editMeterDialogOpen}
                            onClose={toggleEditMeterDialogOpen}
                            subTitle="Update Meter"
                            // title={selectedMeterForEdit.name}
                            content={<CreateFacilityForm
                                formControls={editMeterFormControls}
                                handleInputChange={handleEditMeterInputChange}
                                attemptedSubmit={attemptedEditMeterSubmit}
                            />}
                            actions={<GreenButton type="button" onClick={() => {
                                onEditMeterSubmit(true);
                            }}>Update Meter</GreenButton>}
                        />

                }
            </Fragment>

        );
    }
};

function mapStateToProps(state) {
    return {
        editMeterFormIsValid: state.editMeterFormIsValid,
        attemptedEditMeterSubmit: state.attemptedEditMeterSubmit,
        editMeterDialogOpen: state.editMeterDialogOpen,
        selectedMeterForEdit: state.selectedMeterForEdit,
        editMeterFormControls: state.editMeterFormControls,

    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        toggleEditMeterDialogOpen,
        onEditMeterSubmit,
        handleEditMeterInputChange,
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(EditFacilityDialog);
