import validate from "../../../../js/validate";
import {createOrUpdateFacility, facilityModel, getFacilityById} from "../../../../services/facility.service";
import {getErrorMessage} from "../../../../services/serviceHelper";
import {getProjectsFacilities} from "../../project-actions";
import {SET_SNACKBAR} from "../../HomeActions/home-actions";
import {selectFacility} from "../../facility-actions";

const SET_EDIT_METER_FORM_IS_VALID = 'SET_EDIT_METER_FORM_IS_VALID';
const SET_ATTEMPTED_EDIT_METER_SUBMIT = 'SET_ATTEMPTED_EDIT_METER_SUBMIT';
const TOGGLE_EDIT_METER_DIALOG_OPEN = 'TOGGLE_EDIT_METER_DIALOG_OPEN';
const SET_SELECTED_METER_FOR_EDIT = 'SET_SELECTED_METER_FOR_EDIT';
const SET_EDIT_METER_FORM_CONTROLS = 'SET_EDIT_METER_FORM_CONTROLS';
const SET_FETCHING_NEW_WEATHER_DATA = 'SET_FETCHING_NEW_WEATHER_DATA';
const SET_EDIT_METER_SNACKBAR = 'SET_EDIT_METER_SNACKBAR';


const setEditMeterFormIsValid = (boolean) => {
    return {
        type: SET_EDIT_METER_FORM_IS_VALID,
        payload: boolean
    };
};

const setAttemptedEditFacilitySubmit = (boolean) => {
    return {
        type: SET_ATTEMPTED_EDIT_METER_SUBMIT,
        payload: boolean
    };
};

const setFetchingNewWeatherData = (boolean) => {
    return {
        type: SET_FETCHING_NEW_WEATHER_DATA,
        payload: boolean
    };
};

const toggleEditMeterDialogOpen = (boolean) => {
    return (dispatch, getState) => {
        const {
            editMeterDialogOpen
        } = getState();
        dispatch({
            type: TOGGLE_EDIT_METER_DIALOG_OPEN,
            payload: !editMeterDialogOpen
        });
    }


};

const setSelectedMeterForEdit = (meter) => {
    return (dispatch, getState) => {
        const {
            selectedMeterForEdit,
            editMeterFormControls
        } = getState();

        let editFacilityFormControls = {...editMeterFormControls};
        if (selectedMeterForEdit !== null) {


            // set the state to the facility to edit

            editFacilityFormControls.name.value = meter.name;
            editFacilityFormControls.description.value = meter.description;
            editFacilityFormControls.meterNumber.value = meter.meterNumber;
            editFacilityFormControls.accountNumber.value = meter.accountNumber;
            editFacilityFormControls.utility.value = meter.utility;
            editFacilityFormControls.address1.value = meter.address1;
            editFacilityFormControls.address2.value = meter.address2;
            editFacilityFormControls.city.value = meter.city;
            editFacilityFormControls.state.value = meter.state;
            editFacilityFormControls.postalCode.value = meter.postalCode;

        }

        dispatch({
            type: SET_EDIT_METER_FORM_CONTROLS,
            payload: editFacilityFormControls
        });

        dispatch({
            type: SET_SELECTED_METER_FOR_EDIT,
            payload: meter
        });

        // let editFacilityDialogOpen = !this.state.editFacilityDialogOpen;
        // this.setState({ editFacilityDialogOpen, selectedFacilityForEdit: facility })

    }

};

const setEditMeterFormControls = (meter) => {
    return {
        type: SET_EDIT_METER_FORM_CONTROLS,
        payload: meter
    };
};

const onEditMeterSubmit = (setAsSelectedMeter = false) => {
    return (dispatch, getState) => {
        const {
            editMeterFormControls,
            selectedMeterForEdit,
            editMeterFormIsValid,
            editMeterDialogOpen
        } = getState();

        let submittedForm = {...editMeterFormControls};
        let keys = Object.keys(submittedForm);
        let finalSubmission = {};
        keys.forEach(key => {
            finalSubmission[key] = submittedForm[key].value
        });


        if (editMeterFormIsValid) {

            let facilityRequest = {...facilityModel};

            facilityRequest.FacilityId = selectedMeterForEdit.facilityId;
            facilityRequest.ProjectId = selectedMeterForEdit.projectId;
            facilityRequest.Name = finalSubmission.name;
            facilityRequest.Description = finalSubmission.description;
            facilityRequest.AccountNumber = finalSubmission.accountNumber;
            facilityRequest.MeterNumber = finalSubmission.meterNumber;
            facilityRequest.Utility = finalSubmission.utility;
            facilityRequest.Address1 = finalSubmission.address1;
            facilityRequest.Address2 = finalSubmission.address2;
            facilityRequest.City = finalSubmission.city;
            facilityRequest.State = finalSubmission.state;
            facilityRequest.PostalCode = finalSubmission.postalCode;

            if (submittedForm.postalCode.value !== selectedMeterForEdit.postalCode) {
                dispatch({
                    type: SET_FETCHING_NEW_WEATHER_DATA,
                    payload: true
                });
            }

            createOrUpdateFacility(facilityRequest).then((response) => {
                dispatch({
                    type: SET_FETCHING_NEW_WEATHER_DATA,
                    payload: false
                });
                if (response.isSuccess) {

                    dispatch({
                        type: TOGGLE_EDIT_METER_DIALOG_OPEN,
                        payload: !editMeterDialogOpen
                    });

                    dispatch({
                        type: SET_ATTEMPTED_EDIT_METER_SUBMIT,
                        payload: false
                    });
                    let snackbar = {
                        message: response.successMessage ? response.successMessage : "Successfully Updated Facility",
                        open: true,
                        variant: 'success'
                    };
                    dispatch({
                        type: SET_SNACKBAR,
                        payload: snackbar
                    });
                    dispatch(getProjectsFacilities());
                    
                    if (setAsSelectedMeter) {
                        getFacilityById(selectedMeterForEdit.facilityId).then(x => {
                            dispatch(selectFacility(x));
                        })
                    };

                } else {
                    let snackbar = {
                        message: "There was an error updating the meter.",
                        open: true,
                        variant: 'error'
                    };
                    dispatch({
                        type: SET_SNACKBAR,
                        payload: snackbar
                    });
                    this.setState({snackbar});
                }
            });

        } else {
            dispatch({
                type: SET_ATTEMPTED_EDIT_METER_SUBMIT,
                payload: true
            });
        }
    }
};

const handleEditMeterInputChange = (event) => {
    return (dispatch, getState) => {
        const {
            editMeterFormControls,
        } = getState();

        const value = event.target.value;
        const name = event.target.name;

        const updatedControls = {...editMeterFormControls};

        const updatedFormElement = {...updatedControls[name]}

        updatedFormElement.value = value;
        updatedFormElement.touched = true;
        updatedFormElement.valid = validate(value, updatedFormElement.validationRules);

        updatedControls[name] = updatedFormElement;


        // Check if form is valid
        let formIsValid = true;
        for (let inputIdentifier in updatedControls) {
            formIsValid = updatedControls[inputIdentifier].valid && formIsValid;
        }

        dispatch({
            type: SET_EDIT_METER_FORM_CONTROLS,
            payload: updatedControls
        });

        dispatch({
            type: SET_EDIT_METER_FORM_IS_VALID,
            payload: formIsValid
        });
    }
};


export {
    setEditMeterFormIsValid,
    setAttemptedEditFacilitySubmit,
    toggleEditMeterDialogOpen,
    setSelectedMeterForEdit,
    setEditMeterFormControls,
    onEditMeterSubmit,
    handleEditMeterInputChange,
    SET_EDIT_METER_FORM_IS_VALID,
    SET_ATTEMPTED_EDIT_METER_SUBMIT,
    TOGGLE_EDIT_METER_DIALOG_OPEN,
    SET_SELECTED_METER_FOR_EDIT,
    SET_EDIT_METER_FORM_CONTROLS,
    SET_FETCHING_NEW_WEATHER_DATA,
    SET_EDIT_METER_SNACKBAR
}