import React, {Component} from 'react'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const content = [
    {
        header: "Website Terms of Use",
        paragraphs: [
            `These Terms of Use, (“Terms”) govern your use of the website reactenergy.io (the “Website”) and shall include all pages within the Website which is owned by React Energy. These Terms apply to your use of the Website and by using the Website, you expressly accept all terms and conditions contained herein in full. If you object to any of the Terms you must stop using the Website by closing or otherwise navigating away from the Website.`,
            `Your use of any React Energy mobile software or the React Energy Portal (portal.reactenergy.io) is governed by further terms and conditions provided separately when accessing the React Energy software or React Energy Portal.`,
            `This Website is not for use by any minors (defined as those who are not at least 18 years of age), and you must not use this Website if you a minor.`

        ]
    },
    {
        header: 'Intellectual Property Rights',
        paragraphs: [
            `Unless otherwise specified, React Energy owns all rights to the intellectual property and material
            contained in this Website, and all such rights are reserved. You are granted a limited license
            only, subject to the restrictions provided in these Terms, for purposes of viewing the material
            contained on this Website.`,

        ]
    },
    {
        header: 'Restrictions',
        paragraphs: [
            `Unless otherwise agreed in writing, you are expressly restricted from any and all of the following:`
        ],
        list: [
            `Copying or reproducing any of the Website material;`,
            `Publishing any Website material in any media;`,
            `Selling, sublicensing and/or otherwise commercializing any Website material;`,
            `publicly performing and/or showing any Website material;`,
            `Using this Website in any way that is, or may be, damaging to this Website or React Energy;`,
            `Using this Website in any way that impacts user access to this Website;`,
            `Using this Website contrary to applicable laws and regulations, or in a way that causes, or
                may cause, harm to the Website, or to any person or business entity;`,
            `Engaging in any data mining, data harvesting, data extracting or any other similar activity in
                relation to this Website, or while using this Website;`,
            `Using this Website to engage in any advertising or marketing;`
        ]
    },
    {
        header: 'No Warranties',
        paragraphs: [
            `This Website is provided “as is,” with all faults, and React Energy makes no express or implied
            representations or warranties, of any kind related to this Website or the materials contained on
            this Website. Additionally, nothing contained on this Website shall be construed as providing
            consultation or advice to you. Any price information provided on this site is informational only
            and is not binding on any party unless and until agreed in writing by React Energy.`
        ]
    },
    {
        header: 'Limitation of Liability',
        paragraphs: [
            `In no event shall React Energy, nor any of its officers, directors, employees, consultants and advisors,
            be liable to you for anything arising out of or in any way connected with your use of the
            Website, whether such liability is under contract, tort or otherwise. Further, React Energy, including
            its officers, directors and employees shall not be liable for any indirect, consequential or special
            liability arising out of or in any way related to your use of this Website.`
        ]
    },
    {
        header: 'Indemnification',
        paragraphs: [
            `You hereby indemnify to the fullest extent React Energy from and against any and all liabilities, costs,
            demands, causes of action, damages and expenses (including reasonable attorney’s fees)
            arising out of or in any way related to your breach of any of the provisions of these Terms.`
        ]
    },
    {
        header: `Severability`,
        paragraphs: [
            `If any provision of these Terms is found to be unenforceable or invalid under any applicable
            law, such unenforceability or invalidity shall not render these Terms unenforceable or invalid as
            a whole, and such provisions shall be deleted without affecting the remaining provisions herein.`
        ]
    },
    {
        header: `Updates to Terms`,
        paragraphs: [
            `React Energy may revise these Terms at any time and by using this Website you are expected to
            review such Terms on a regular basis to ensure you understand all terms and conditions
            governing use of this Website.`
        ]
    },
    {
        header: 'Assignment',
        paragraphs: [
            `React Energy shall be permitted to assign, transfer, and subcontract its rights and/or obligations
            under these Terms without any notification or consent required. However, you shall not be
            permitted to assign, transfer, or subcontract any of your rights and/or obligations under these
            Terms.`
        ]
    },
    {
        header: 'Entire Agreement',
        paragraphs: [
            `Unless otherwise agreed in writing, these Terms, including any legal notices and disclaimers
            contained on this Website, constitute the entire agreement between you and React Energy in
            relation to your use of this Website, and supersede all prior agreements and understandings
            with respect to the same.`
        ]
    },
    {
        header: 'Governing Law & Jurisdiction',
        paragraphs: [
            `These Terms will be governed by and construed in accordance with the laws of the State of
            Texas, and you submit to the exclusive jurisdiction of the state and federal courts located in
            Tarrant County, Texas for the resolution of any disputes.`
        ]
    }

]

class TermsOfUseContent extends Component {
    render() {
        return (

                        <Grid container style={{padding: 20}}>
                            {content.map(section => (
                                <div>
                                    <Typography variant="h6" style={{marginBottom: 10}}>
                                        {section.header}
                                    </Typography>
                                    {section.paragraphs.map(paragraph => (
                                        <div style={{marginBottom: 10}}>
                                            <Typography variant="body" >
                                                {paragraph}
                                            </Typography>
                                        </div>

                                    ))}
                                    {section.list ?
                                        <ul>
                                            {section.list.map(item => (
                                                <li>{item}</li>
                                            ))}

                                        </ul>
                                        : null }
                                </div>

                            ))}

                        </Grid>
        )
    }
}

export default TermsOfUseContent