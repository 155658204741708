import React, { Component, Fragment } from 'react'


// Material UI
import { withStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FilterList from '@material-ui/icons/FilterList';

//Components
import PulseLoader from '../../../components/Spinners/pulse-loader'

// Form Sections
import {
    DataTypeDropdown,
    SummaryLevelDropdown,
    DaysSection,
    MonthsSection,
    YearsSection,
    SeriesTypeSection,
    DataTypesSection,
    DateRangeSection,
    TimeRangeSection,
    SeriesTypeDropdown
} from './data-filter-form-sections'

import moment from "moment";
import GreenButton from "../../../components/CustomButtons/GreenButton";

const styles = theme => ({
    paper: {
        margin: 20,
    },
    weekTable: {
        backgroundColor: '#fcfcfc',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#e6e6e6',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#ededed'
        }

    },
    button: {
      textTransform: 'none',
      // color: theme.palette.secondary.contrast  
    },
    selectedWeekTable: {
        color: 'white',
        backgroundColor: theme.palette.primary.light,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#e6e6e6',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#00c936'
        }
    },
    gridSection: {
        paddingLeft: 5,
        paddingRight: 5,

    },
    hr: {
        border: 0,
        clear: 'both',
        display: 'block',
        width: '100%',
        backgroundColor: '#e6e6e6',
        height: '1px',
        marginTop: 10,
        marginBottom: 10
    },
    formSectionTitle: {
        variant: 'overline'
    },
    formSubmitToolbar: {
        position: 'sticky',
        paddingTop: 10,
        paddingBottom: 10,
        // backgroundColor: 'white',
    },
});




class ChartDataFilterForm extends Component {
    constructor(props) {
        super(props)
    }

    state = {
        currentFormOptions: {
            dataType: false,
            summaryLevel: false,
            seriesTypeDropdown: false,
            seriesTypeSection: false,
            days: false,
            months: false,
            dataRange: false,
            timeRange: false
        },
        chartDataFilterFormHasChanged: false,
    };

    setChartOptions = () => {
        const { chartDataFilterForm } = this.props;

        let options = {
            dataType: false,
            summaryLevel: false,
            seriesTypesDropdown: false,
            seriesTypesSection: false,
            dataTypesSection: false,
            days: false,
            months: false,
            years: false,
            dateRange: false,
            timeRange: false
        };
        
        switch (chartDataFilterForm.chartType) {
            case 'load-curve':
                options.dataType = true;
                options.summaryLevel = true;
                options.seriesTypesDropdown = true;
                options.days = true;
                options.months = true;
                options.years = true;
                // options.dateRange = true;
                this.setState({ currentFormOptions: options });
                break;
            case 'summary-chart':
                options.dataType = false;
                options.dataTypesSection = true;
                options.summaryLevel = true;
                options.seriesTypesDropdownSelection = true;
                options.days = true;
                options.months = true;
                options.years = true;
                // options.dateRange = true;
                // options.timeRange = true;

                this.setState({ currentFormOptions: options });
                break;
            case 'heat-map':
                options.dataType = true;
                options.years = true;
                // options.dateRange = true;
                this.setState({ currentFormOptions: options });
                break;
            case 'zoomable-chart':
                options.dataType = true;
                // options.dateRange = true;
                this.setState({ currentFormOptions: options });
                break;
            case 'demand-duration':
                // options.dataType = true;
                // options.dateRange = true;
                // options.days = true;
                options.months = true;
                options.years = true;
                this.setState({ currentFormOptions: options });
                break;
            case 'calendar-chart':
                options.dataType = true;
                this.setState({ currentFormOptions: options });
                break;
            case 'scatter-plot':
                options.dataType = true;
                options.days = true;
                options.months = true;
                options.years = true;
                // options.dateRange = true;
                this.setState({ currentFormOptions: options });
                break;
            case 'surface-plot':
                options.dataType = true;
                options.days = true;
                options.months = true;
                options.years = true;
                // options.dateRange = true;
                this.setState({ currentFormOptions: options });
                break;
        }
    }

    componentDidMount = () => {
        this.setChartOptions();
    };
    
    componentDidUpdate(prevProps, prevState, snapshot) {
        // debugger;
        if (!prevProps.chartDataFilterForm) {
            this.setChartOptions();
        } else if (this.props.chartDataFilterForm) {
            if (prevProps.chartDataFilterForm.chartType !== this.props.chartDataFilterForm.chartType) {
                this.setChartOptions();
            }
        }
        
    }

    handleAggregateDataFilterFormInputs = (value, key) => {
        const {updateChartDataFilterForm} = this.props;
        let chartDataFilterForm = {...this.props.chartDataFilterForm};
        switch (value) {
            case 'all':
                chartDataFilterForm[key].forEach(x => x.value = true);
                break;
            case 'years':
                chartDataFilterForm[key].forEach(x => x.value = true);
                break;
            case 'weekdays':
                chartDataFilterForm[key].forEach(x => {
                    if (x.number !== 1 && x.number !== 7)
                        x.value = true
                    else
                        x.value = false

                });
                break;
            case 'weekends':
                chartDataFilterForm[key].forEach(x => {
                    if (x.number === 1 || x.number === 7)
                        x.value = true
                    else
                        x.value = false

                });
                break;
            case 'clear':
                chartDataFilterForm[key].forEach(x => x.value = false);
                break;
        }
        updateChartDataFilterForm(chartDataFilterForm);
        this.setState({chartDataFilterFormHasChanged: true})
    };

    handleDataFilterFormDateAndTimeChange = (event, key) => {
        const {updateChartDataFilterForm} = this.props;
        let chartDataFilterForm = {...this.props.chartDataFilterForm};
        chartDataFilterForm[key] = event;
        if (key === 'startTime') {
            chartDataFilterForm['endTime'] = moment(parseInt(event)).add(30, 'minutes').valueOf().toString();
        }
        updateChartDataFilterForm(chartDataFilterForm);
        this.setState({chartDataFilterFormHasChanged: true})
    };

    handleDataFilterFormSelectChange = (event) => {
        const {updateChartDataFilterForm} = this.props;
        let value = event.target.value;
        let name = event.target.name;
        let chartDataFilterForm = {...this.props.chartDataFilterForm};
        chartDataFilterForm[name] = value;
        updateChartDataFilterForm(chartDataFilterForm);
        this.setState({chartDataFilterFormHasChanged: true})
    };
    
    // this one is different because for load curves you can only select one but for summary charts it can be mulitple. So needs to be submitted as an array
    handleSeriesTypeDropdownChange = (event) => {
        const {updateChartDataFilterForm} = this.props;
        let value = event.target.value;
        let name = event.target.name;
        let chartDataFilterForm = {...this.props.chartDataFilterForm};
        chartDataFilterForm[name].forEach(seriesType => {
            if (seriesType.label === value)
                seriesType.value = true;
            else
                seriesType.value = false
        });
        updateChartDataFilterForm(chartDataFilterForm);
        this.setState({chartDataFilterFormHasChanged: true})
    };

    handleDataFilterFormCheckboxChange = (key, index) => {
        const {updateChartDataFilterForm} = this.props;
        let chartDataFilterForm = {...this.props.chartDataFilterForm}
        chartDataFilterForm[key][index].value = !chartDataFilterForm[key][index].value;
        updateChartDataFilterForm(chartDataFilterForm);
        this.setState({chartDataFilterFormHasChanged: true})
    };
    
    onSubmit = () => {
        this.setState({chartDataFilterFormHasChanged: false});
        this.props.onDataFilterFormSubmit();
    }
    
    render() {
        const {
            chartDataFilterForm,
            classes,
            onDataFilterFormSubmit,
            selectedFacility
        } = this.props;
        
        const {chartDataFilterFormHasChanged} = this.state;

        const { currentFormOptions } = this.state;
        return (

            <form>
                {chartDataFilterForm === null ? <PulseLoader /> :
                    <Grid container style={{ marginTop: 10 }}>
                        <Grid container
                            className={classes.formSubmitToolbar}
                            direction="column"
                            alignItems="center"
                            justify="center"
                        >
                            <Button
                                // className={classes.formSubmitButton}
                                className={classes.button}
                                color="secondary"
                                onClick={this.onSubmit}
                                disabled={!chartDataFilterFormHasChanged}
                                variant="contained"
                            >
                                    <FilterList style={{paddingRight: 5}}/> 
                                    <Typography >
                                        Apply Filters
                                    </Typography>
                                    
                                </Button>

                        </Grid>
                        {/*<hr className={classes.hr} />*/}
                        {currentFormOptions.dataType ?
                            <Fragment>
                                <DataTypeDropdown
                                    chartDataFilterForm={chartDataFilterForm}
                                    handleDataFilterFormSelectChange={this.handleDataFilterFormSelectChange}
                                />
                                {/*<hr className={classes.hr} />*/}
                            </Fragment>
                            : null}
                            
                        {/*<hr className={classes.hr} />*/}
                        {currentFormOptions.dataTypesSection ?
                            <Fragment>
                                <DataTypesSection
                                    chartDataFilterForm={chartDataFilterForm}
                                    handleAggregateDataFilterFormInputs={this.handleAggregateDataFilterFormInputs}
                                    handleDataFilterFormCheckboxChange={this.handleDataFilterFormCheckboxChange}
                                />
                                {/*<hr className={classes.hr} />*/}
                            </Fragment>
                            : null}


                        {currentFormOptions.summaryLevel ?
                            <Fragment>
                            <SummaryLevelDropdown
                                chartDataFilterForm={chartDataFilterForm}
                                handleDataFilterFormSelectChange={this.handleDataFilterFormSelectChange}
                                />
                                {/*<hr className={classes.hr} />*/}
                                </Fragment>
                                : null}

                        

                        {currentFormOptions.seriesTypesDropdown ?
                            <Fragment>
                            <SeriesTypeDropdown
                                chartDataFilterForm={chartDataFilterForm}
                                handleSeriesTypeDropdownChange={this.handleSeriesTypeDropdownChange}
                                />
                                {/*<hr className={classes.hr} />*/}
                            </Fragment>
                                : null}

                        

                        {currentFormOptions.days ?
                            <Fragment>
                            <DaysSection
                                chartDataFilterForm={chartDataFilterForm}
                                handleAggregateDataFilterFormInputs={this.handleAggregateDataFilterFormInputs}
                                handleDataFilterFormCheckboxChange={this.handleDataFilterFormCheckboxChange}
                                />
                                {/*<hr className={classes.hr} />*/}
                            </Fragment>
                            : null}

                        {currentFormOptions.months ?
                            <Fragment>
                            <MonthsSection
                                chartDataFilterForm={chartDataFilterForm}
                                handleAggregateDataFilterFormInputs={this.handleAggregateDataFilterFormInputs}
                                handleDataFilterFormCheckboxChange={this.handleDataFilterFormCheckboxChange}
                                /> 
                                {/*<hr className={classes.hr} />*/}
                            </Fragment>
                                
                                : null}
                        {currentFormOptions.years ?
                            <Fragment>
                                <YearsSection
                                    chartDataFilterForm={chartDataFilterForm}
                                    handleAggregateDataFilterFormInputs={this.handleAggregateDataFilterFormInputs}
                                    handleDataFilterFormCheckboxChange={this.handleDataFilterFormCheckboxChange}
                                    selectedFacility={selectedFacility}
                                />
                                {/*<hr className={classes.hr} />*/}
                            </Fragment>

                            : null}

                        {currentFormOptions.seriesTypesSection ?
                            <Fragment>
                            <SeriesTypeSection
                                chartDataFilterForm={chartDataFilterForm}
                                handleAggregateDataFilterFormInputs={this.handleAggregateDataFilterFormInputs}
                                handleDataFilterFormCheckboxChange={this.handleDataFilterFormCheckboxChange}
                                />
                                {/*<hr className={classes.hr} />*/}
                            </Fragment>
                            : null}


                        {currentFormOptions.dateRange ?
                            <Fragment>
                            <DateRangeSection
                                chartDataFilterForm={chartDataFilterForm}
                                handleDataFilterFormDateAndTimeChange={this.handleDataFilterFormDateAndTimeChange}
                                selectedFacility={selectedFacility}
                                />
                                {/*<hr className={classes.hr} />*/}
                            </Fragment>
                                : null}

                        {currentFormOptions.timeRange ?
                            <Fragment>
                            <TimeRangeSection
                                chartDataFilterForm={chartDataFilterForm}
                                handleDataFilterFormDateAndTimeChange={this.handleDataFilterFormDateAndTimeChange}
                                />
                                {/*<hr className={classes.hr} />*/}
                            </Fragment>
                            : null}

                        
                    </Grid>
                }
            </form>
        )
    }


}

export default withStyles(styles)(ChartDataFilterForm)