const INTERVAL_DATA_RECEIVED = 'INTERVAL_DATA_RECEIVED'
const TIMESTAMP_DATA_RECEIVED = 'TIMESTAMP_DATA_RECEIVED'
const HEATMAP_DATA_RECEIVED = 'HEATMAP_DATA_RECEIVED'
const CLEAR_INTERVAL_DATA = "CLEAR_INTERVAL_DATA"
const DEMAND_DURATION_DATA_RECEIVED = 'DEMAND_DURATION_DATA_RECEIVED'
const CALENDAR_CHART_DATA_RECEIVED = 'CALENDAR_CHART_DATA_RECEIVED'
const SURFACE_PLOT_DATA_RECEIVED = "SURFACE_PLOT_DATA_RECEIVED"
const LOAD_CURVE_DATA_RECIEVED = "LOAD_CURVE_DATA_RECIEVED"
const SUMMARY_CHART_DATA_RECIEVED = "SUMMARY_CHART_DATA_RECIEVED"

const addIntervalDataToState = (data) => {
    return {
        type: INTERVAL_DATA_RECEIVED,
        payload: data
    };
};

const addTimeStampDataToState = (data) => {
    return {
        type: TIMESTAMP_DATA_RECEIVED,
        payload: data
    };
};

const addDemandDurationDataToState = (data) => {
    return {
        type: DEMAND_DURATION_DATA_RECEIVED,
        payload: data
    };
};

const addHeatmapDataToState = (data) => {
    debugger;
    return {
        type: HEATMAP_DATA_RECEIVED,
        payload: data
    };
};

const addCalendarChartDataToState = (data) => {

    return {
        type: CALENDAR_CHART_DATA_RECEIVED,
        payload: data
    };
};

const addLoadCurveDataToState = (data) => {

    return {
        type: LOAD_CURVE_DATA_RECIEVED,
        payload: data
    };
};

const addSummaryChartDataToState = (data) => {

    return {
        type: SUMMARY_CHART_DATA_RECIEVED,
        payload: data
    };
};

const clearIntervalData = (chartType) => {
    return {
        type: CLEAR_INTERVAL_DATA,
        payload: chartType
    };
};

const addSurfacePlotDataToState = (data) => {

    return {
        type: SURFACE_PLOT_DATA_RECEIVED,
        payload: data
    };
};


export {
    addIntervalDataToState,
    addTimeStampDataToState,
    addHeatmapDataToState,
    clearIntervalData,
    addDemandDurationDataToState,
    addCalendarChartDataToState,
    addSurfacePlotDataToState,
    addLoadCurveDataToState,
    addSummaryChartDataToState,
    INTERVAL_DATA_RECEIVED,
    TIMESTAMP_DATA_RECEIVED,
    HEATMAP_DATA_RECEIVED,
    CLEAR_INTERVAL_DATA,
    DEMAND_DURATION_DATA_RECEIVED,
    CALENDAR_CHART_DATA_RECEIVED,
    SURFACE_PLOT_DATA_RECEIVED,
    LOAD_CURVE_DATA_RECIEVED,
    SUMMARY_CHART_DATA_RECIEVED
}