import getDataFilterMessage from "../../FacilityHome/components/DataFilterForm/getDataFilterMessage";

import {
    getIntervalDataCalendarChartByFacilityId,
    getIntervalDataHeatmapByFacilityId,
    getIntervalDataLoadCurveByFacilityId,
    getIntervalDataSummaryChartByFacilityId, getIntervalDataTimestampByFacilityId
} from "../../services/intervalData.service";

import ChartDataFilterFormObject from "../../FacilityHome/components/DataFilterForm/ChartDataFilterFormObject";
import LoadCurveData from "../../js/CustomDataClasses/LoadCurveData";
import SummaryChartData from "../../js/CustomDataClasses/SummaryChartData";
import HeatMapData from "../../js/CustomDataClasses/HeatMapData";
import TimeSeriesData from "../../js/CustomDataClasses/TimeSeriesData";

import {
    CALENDAR_CHART_DATA_RECEIVED, 
    TIMESTAMP_DATA_RECEIVED,
    HEATMAP_DATA_RECEIVED
} from "./interval-data-actions";

const SET_CHART_DATA_FILTER_FORM_OBJECT = 'SET_CHART_DATA_FILTER_FORM_OBJECT';
const SET_CHART_OPTIONS = 'SET_CHART_OPTIONS';
const SET_PLOTLY_CHART_OPTIONS = 'SET_PLOTLY_CHART_OPTIONS';
const SET_CHART_TABLE_SETTINGS = 'SET_CHART_TABLE_SETTINGS';
const SET_DATA_FILTER_MESSAGE = 'SET_DATA_FILTER_MESSAGE';
const SET_CHART_CAN_UPDATE_DATA = 'SET_CHART_CAN_UPDATE_DATA';
const ON_DATA_FILTER_FORM_SUBMIT = 'ON_DATA_FILTER_FORM_SUBMIT';
const SET_DURATION_VIEW = 'SET_DURATION_VIEW';
const SET_TEMPERATURE_VIEW = 'SET_TEMPERATURE_VIEW';
const SET_SHOW_METER_DATES_AND_RANGES = 'SET_SHOW_METER_DATES_AND_RANGES';


const setChartDataFilterFormObject = (form) => {
    return {
        type: SET_CHART_DATA_FILTER_FORM_OBJECT,
        payload: form
    };
};

const setChartOptions = (chartOptions) => {
    return {
        type: SET_CHART_OPTIONS,
        payload: chartOptions
    };
};

const setChartTableSettings = (settings) => {
    return {
        type: SET_CHART_TABLE_SETTINGS,
        payload: settings
    };
};

const setDataFilterMessage = (message) => {
    return {
        type: SET_DATA_FILTER_MESSAGE,
        payload: message
    };
};

const setChartCanUpdateData = (boolean) => {
    return {
        type: SET_CHART_CAN_UPDATE_DATA,
        payload: boolean
    };
};

const setDurationView = (view) => {
    return {
        type: SET_DURATION_VIEW,
        payload: view
    };
};

const setTemperatureView = (boolean) => {
    return {
        type: SET_TEMPERATURE_VIEW,
        payload: boolean
    };
};

const setShowMeterDatesAndRanges = (boolean) => {
    return {
        type: SET_SHOW_METER_DATES_AND_RANGES,
        payload: boolean
    };
};



const onDataFilterFormSubmit = () => {
    return (dispatch, getState) => {
        const {
            facilities, 
            selectedFacility, 
            chartDataFilterFormObject,
            selectedChartView,
            selectedFacilitySubview,
            durationView
        } = getState();

        // clear chart options
        dispatch({
            type: SET_CHART_OPTIONS,
            payload: null
        });
        dispatch({
            type: SET_PLOTLY_CHART_OPTIONS,
            payload: null
        });
        
        let chartDataFilterForm;
        if (selectedFacilitySubview === chartDataFilterFormObject.chartType) {
            chartDataFilterForm = {...chartDataFilterFormObject};
        } else {
            // let temp = selectedFacility;
            let form = new ChartDataFilterFormObject(selectedFacility);
            chartDataFilterForm = form.getDefaultFormByChartType(selectedFacilitySubview)
        }
        
        dispatch({
            type: SET_CHART_DATA_FILTER_FORM_OBJECT,
            payload: chartDataFilterForm
        });

        
        // if (chartDataFilterForm !== null) {
        //     this.props.clearIntervalData(chartDataFilterForm.chartType);
        //     this.setState({chartOptions: null})
        // }


        let dataFilterMessage = getDataFilterMessage(chartDataFilterForm);
        
        dispatch({
            type: SET_DATA_FILTER_MESSAGE,
            payload: dataFilterMessage
        });

        let request = new ChartDataFilterFormObject(selectedFacility).getRequestFromForm(chartDataFilterForm, selectedFacility);

        // this.setState({chartDataFilterFormHasChanged: false, chartCanUpdateData: true});

        let chartOptions;
        let chartTableSettings;
        switch (chartDataFilterForm.chartType) {
            case 'load-curve':
                getIntervalDataLoadCurveByFacilityId(request).then(data => {
                    // that that user still hasn't changed the view before response came back
                    if (selectedFacilitySubview === 'load-curve' && data.length > 0) {
                        // this.props.addLoadCurveDataToState(data);
                        let loadCurveData = new LoadCurveData(data);
                        chartOptions = loadCurveData.generateChartOptions(chartDataFilterForm.dataType, selectedFacility.timezone);
                        chartTableSettings = loadCurveData.generateHandsontableSettings(selectedFacility.intervalDuration);
                        dispatch({
                            type: SET_CHART_OPTIONS,
                            payload: chartOptions
                        });
                        dispatch({
                            type: SET_CHART_TABLE_SETTINGS,
                            payload: chartTableSettings
                        });
                    }

                });
                break;
                case 'surface-plot':
                getIntervalDataLoadCurveByFacilityId(request).then(data => {
                    // that that user still hasn't changed the view before response came back
                    if (selectedFacilitySubview === 'surface-plot' && data.length > 0) {
                        // this.props.addLoadCurveDataToState(data);
                        let loadCurveData = new LoadCurveData(data);
                        chartOptions = loadCurveData.generateSurfacePlotOptions(chartDataFilterForm.dataType, selectedFacility.intervalDuration);
                        chartTableSettings = loadCurveData.generateHandsontableSettings(selectedFacility.intervalDuration);

                        dispatch({
                            type: SET_PLOTLY_CHART_OPTIONS,
                            payload: chartOptions
                        });
                        dispatch({
                            type: SET_CHART_TABLE_SETTINGS,
                            payload: chartTableSettings
                        });
                    }

                });
                break;
            case 'summary-chart':
                getIntervalDataSummaryChartByFacilityId(request).then(data => {
                    if (selectedFacilitySubview === 'summary-chart') {
                        // this.props.addSummaryChartDataToState(data);
                        let summaryChartData = new SummaryChartData(data);

                        let requestedDataPointsTemp = chartDataFilterForm.dataTypeOptions.filter(x => x.value === true);
                        let requestedDataPoints = [];
                        requestedDataPointsTemp.forEach(x => requestedDataPoints.push(x.name));

                        chartOptions = summaryChartData.generateChartOptions(requestedDataPoints);
                        chartTableSettings = summaryChartData.generateHandsontableSettings();
                        // this.setState({ chartOptions, chartTableSettings });
                        dispatch({
                            type: SET_CHART_OPTIONS,
                            payload: chartOptions
                        });
                        dispatch({
                            type: SET_CHART_TABLE_SETTINGS,
                            payload: chartTableSettings
                        });
                    }

                });
                break;
            case 'heat-map':
                //todo: add to formdata, make optional
                request.IntervalDuration = 60;
                getIntervalDataHeatmapByFacilityId(request).then(response => {
                    if (selectedFacilitySubview === 'heat-map') {
                        // this.props.addHeatmapDataToState(response);
                        let heatMapData = new HeatMapData(response);
                        dispatch({
                            type: HEATMAP_DATA_RECEIVED,
                            payload: heatMapData
                        });
                        chartOptions = heatMapData.generateChartOptions(chartDataFilterForm.dataType, durationView);
                        chartTableSettings = heatMapData.generateHandsontableSettings(chartDataFilterForm.dataType);
                        dispatch({
                            type: SET_CHART_OPTIONS,
                            payload: chartOptions
                        });
                        dispatch({
                            type: SET_CHART_TABLE_SETTINGS,
                            payload: chartTableSettings
                        });
                    }
                });
                break;
            case 'calendar-chart':
                getIntervalDataCalendarChartByFacilityId(request).then(data => {
                    dispatch({
                        type: CALENDAR_CHART_DATA_RECEIVED,
                        payload: data
                    });
                });
                break;
            case 'zoomable-chart':
                request.IncludePercent = false;
                getIntervalDataTimestampByFacilityId(request).then(data => {
                    if (selectedFacilitySubview === 'zoomable-chart') {
                        let timezone = selectedFacility.timezone;
                        let timeSeriesData = new TimeSeriesData(data, timezone);
                        dispatch({
                            type: TIMESTAMP_DATA_RECEIVED,
                            payload: timeSeriesData
                        });
                        
                        // this.props.addTimeStampDataToState(timeSeriesData);
                        chartOptions = timeSeriesData.generateZoomableChartOptions(chartDataFilterForm.dataType, timezone);
                        chartTableSettings = timeSeriesData.generateTimeSeriesHandsontableSettings(timezone);
                        // this.setState({ chartOptions, chartTableSettings });
                        dispatch({
                            type: SET_CHART_OPTIONS,
                            payload: chartOptions
                        });
                        dispatch({
                            type: SET_CHART_TABLE_SETTINGS,
                            payload: chartTableSettings
                        });
                    }
                });
                break;
            case 'demand-duration':
                request.IncludePercent = true;
                getIntervalDataTimestampByFacilityId(request).then(response => {
                    if (selectedFacilitySubview === 'demand-duration') {
                        let timezone = selectedFacility.timezone;
                        let timeSeriesData = new TimeSeriesData(response, timezone);
                        // this.props.addDemandDurationDataToState(response);
                        chartOptions = timeSeriesData.generateDemandDurationChartOptions();
                        chartTableSettings = timeSeriesData.generateDemandDurationHandsontableSettings();
                        // this.setState({ chartOptions, chartTableSettings });
                        dispatch({
                            type: SET_CHART_OPTIONS,
                            payload: chartOptions
                        });
                        dispatch({
                            type: SET_CHART_TABLE_SETTINGS,
                            payload: chartTableSettings
                        });
                    }
                });
                break;
            case 'scatter-plot':
                request.IncludePercent = false;
                getIntervalDataTimestampByFacilityId(request).then(data => {
                    if (selectedFacilitySubview === 'scatter-plot') {
                        let timezone = selectedFacility.timezone;
                        let timeSeriesData = new TimeSeriesData(data, timezone);
                        dispatch({
                            type: TIMESTAMP_DATA_RECEIVED,
                            payload: timeSeriesData
                        });
                        // this.props.addTimeStampDataToState(timeSeriesData);
                        chartOptions = timeSeriesData.generateScatterPlotChartOptions(chartDataFilterForm.dataType, durationView, selectedFacility.timezone, selectedFacility.intervalDuration);
                        chartTableSettings = timeSeriesData.generateTimeSeriesHandsontableSettings(selectedFacility.timezone);
                        // this.setState({ chartOptions, chartTableSettings });
                        dispatch({
                            type: SET_CHART_OPTIONS,
                            payload: chartOptions
                        });
                        dispatch({
                            type: SET_CHART_TABLE_SETTINGS,
                            payload: chartTableSettings
                        });
                    }

                });
                break;
        }
    }
};


export {
    setChartDataFilterFormObject,
    setChartOptions,
    setChartTableSettings,
    setDataFilterMessage,
    setChartCanUpdateData,
    onDataFilterFormSubmit,
    setDurationView,
    setTemperatureView,
    setShowMeterDatesAndRanges,
    SET_CHART_DATA_FILTER_FORM_OBJECT,
    SET_CHART_OPTIONS,
    SET_PLOTLY_CHART_OPTIONS,
    SET_CHART_TABLE_SETTINGS,
    SET_DATA_FILTER_MESSAGE,
    SET_CHART_CAN_UPDATE_DATA,
    ON_DATA_FILTER_FORM_SUBMIT,
    SET_DURATION_VIEW,
    SET_TEMPERATURE_VIEW,
    SET_SHOW_METER_DATES_AND_RANGES
};

