import React from 'react'
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Icon from "@material-ui/core/Icon";

function OverviewSidebarUtilitySection(props) {
    const {selectedFacility, toggleEditMeterDialogOpen} = props;
    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant={"subtitle2"} style={{textTransform: 'none'}}>
                    Utility
                </Typography>
            </Grid>
            <Grid container style={{padding: 5}}>

                    <Grid item xs={12}>
                        {selectedFacility.utility ? selectedFacility.utility : <Link onClick={toggleEditMeterDialogOpen}>Add Utility</Link>}
                    </Grid> 

            </Grid>
        </Grid>
    )
}

export default OverviewSidebarUtilitySection