/**
 * Auth header is a helper function that returns an HTTP Authorization header
 * containing the basic authentication credentials (base64 username and password)
 * of the currently logged in user from local storage. If the user isn't logged
 * in an empty object is returned.
 *
 * The auth header is used to make authenticated HTTP requests to the server
 * api using basic authentication.
 */

export function authHeader(multiPartFormData) {
    let user = JSON.parse(window.sessionStorage.getItem("user"));

    if (user && user.token) {
        if (multiPartFormData){
            return {
                Authorization: "Bearer " + user.token
            }
        }
        return {
            Authorization: "Bearer " + user.token,
            "Content-Type": "application/json; charset=utf-8"
        };
    } else {
        if (multiPartFormData){
            return {
            }
        }
        return {
            "Content-Type": "application/json; charset=utf-8"
        };
    }
}
