import React, { useCallback, useMemo, useState, Fragment } from 'react';
import { useDropzone } from 'react-dropzone';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

// Handsontable
import { HotTable } from '@handsontable/react';

// Moment
import moment from 'moment'
// XLSX 
import XLSX from 'xlsx';
import CustomGridSpinner from "../../components/Spinners/grid-loader";
import UploadHandsontable from "./Handsontable";

// CSV
//import csv from 'csv';
const csv = require('csvtojson');



const useStyles = makeStyles(theme => ({

    uploadIcon: {
        margin: theme.spacing(0),
        color: '#bdbdbd',
        fontSize: 100

    },

}));




export default function ImportDataFile(props) {
    const classes = useStyles();

    const [reading, setReading] = useState(false);
    
    const { 
        formatDataFromCSV, 
        toggleDialog, 
        csvHandsontableSettings,
        clearFile 

    } = props;
    
    // debugger;
    const baseStyle = {
        flex: 1,
        height: 200,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: 3,
        borderRadius: 5,
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out'
    };

    const activeStyle = {
        borderColor: '#2196f3'
    };

    const acceptStyle = {
        borderColor: '#00e676'
    };

    const rejectStyle = {
        borderColor: '#ff1744'
    };

    const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
        setReading(true);
        acceptedFiles.forEach(file => {
            console.log("Accepted Files: ", acceptedFiles);
            switch (file.type) {
                case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                    readXlsx(file);
                    break;
                case 'application/vnd.ms-excel':
                case 'text/plain': 
                case 'text/csv':
                    readCsv(file);
                    break;
            }

        });

    }, []);
    
    const readXlsx = (file) => {
        const reader = new FileReader();

        reader.onload = file => {
            
            var data = new Uint8Array(file.target.result);
            var workbook = XLSX.read(data, {type: 'array'});
            let sheets = workbook.Sheets;
            let sheetNames = Object.keys(sheets)
            let sheet1 = sheets[sheetNames[0]];
            let sheetAsCsvString = XLSX.utils.sheet_to_csv(sheet1);
            formatCsvString(sheetAsCsvString)
        }
        
        let temp = reader.readAsArrayBuffer(file);

    };
    
    const readCsv = (file) => {
        //FOR CSV
        const reader = new FileReader();
        reader.onload = () => {
            const fileAsBinaryString = reader.result;
            formatCsvString(fileAsBinaryString);
         };

        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');

        reader.readAsBinaryString(file);
    }
    
    const formatCsvString = (fileAsBinaryString) => {
        
        csv({
            noheader: true,
            output: "json"
        })
            .fromString(fileAsBinaryString)
            .then((csvRows) => {
                const toJson = []
                csvRows.forEach((aCsvRow, i) => {

                    if (i !== 0) {
                        const builtObject = {}

                        Object.keys(aCsvRow).forEach((aKey) => {
                            const valueToAddInBuiltObject = aCsvRow[aKey];
                            const keyToAddInBuiltObject = csvRows[0][aKey];
                            builtObject[keyToAddInBuiltObject] = valueToAddInBuiltObject;
                        })

                        toJson.push(builtObject)
                    }


                });
                formatDataFromCSV(toJson);
                setReading(false);
            })
    }
    
    let acceptableFileTypes = "application/vnd.ms-excel, text/plain, text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const { acceptedFiles, getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({ onDrop, accept: acceptableFileTypes });
    
    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject
    ]);

    const files = acceptedFiles.map(file => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ));

    //const classes = useStyles();


    return (
        <section className="container">
            {csvHandsontableSettings ? 
                <div>
                    <Button color="secondary" variant="contained" style={{margin: 10}} onClick={clearFile}>Clear File</Button>
                    {/*<HotTable settings={csvHandsontableSettings}/>*/}
                    <UploadHandsontable settings={csvHandsontableSettings}/>
                </div>
                
                
                :
                <div>
                    
                
                <Button color="secondary" variant="contained" style={{margin: 10}} onClick={toggleDialog}>How to format your data</Button>
            <div {...getRootProps({ className: 'dropzone', style })}>
                
                <input {...getInputProps()} />
                <Grid container>
                    
                    
                    <Grid item xs={12}>
                        
                        
                            <Grid container alignItems="center" justify="center" >
                                {reading ?
                                    <Grid container alignItems="center" justify="center" >
                                        <div style={{marginBottom: 10}}>
                                            <Typography>Reading file...</Typography>
                                        </div>
                                        <Grid item xs={12}>
                                            <CustomGridSpinner color={"#bdbdbd"}/>
                                        </Grid>
                                    </Grid>
                                    
                                :
                                    <Fragment>
                                        <Grid item xs={12}>
                                            <Grid container alignItems="center" justify="center">
                                                <Typography varient="h4">Drop Interval Data File</Typography>
                                            </Grid>
                                        </Grid>
                                        <CloudUploadIcon className={classes.uploadIcon}/>
                                    </Fragment>
                                     }
                            </Grid>
                        
                    </Grid>
                    {/*<Grid>*/}
                    {/*    {files.map(file => <Typography>{file}</Typography>)}*/}
                    {/*</Grid>*/}
                    {/*{csvHandsontableSettings ? <HotTable settings={csvHandsontableSettings}/> : null}*/}
                    
                </Grid>
                
            </div>
                </div>
             }
            {/* <PulseLoader />*/}
            {/*{reading ?*/}
            {/*    <Typography>Reading - {reading}</Typography>*/}
            {/*    : null*/}
            {/*}*/}
             
        </section>
    );
}
