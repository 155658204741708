import { daysOfWeekGenerator, monthsGenerator, kwAndKwh, seriesTypeGenerator, dataTypesGenerator } from '../../../js/time-constants';
import {intervalDataRequestObject} from "../../../services/intervalData.service";
import moment from "moment";
import "moment-timezone"

const masterFormObject = {
    chartType: null,
    days: null,
    months: null,
    // years: null,
    seriesTypes: null,
    dataTypeOptions: null,
    dataType: null,
    seriesPer: null,
    startDate: null,
    endDate: null,
    startTime: null,
    endTime: null
}

class ChartDataFilterFormObject {

    constructor(selectedFacility) {
        this.facility = selectedFacility;
    }
    
    getDefaultFormByChartType = (chartType) => {
        switch (chartType) {
            case 'load-curve':
            case 'summary-chart':
            case 'heat-map':
            case 'demand-duration':
            case 'zoomable-chart':
            case 'calendar-chart':
            case 'scatter-plot':
            case 'surface-plot':
                
                return getDefaultForm(chartType, this.facility);
                
        }
        return null
    };
    
    getDefaultLoadCurveFormForCalendarChart = () => {
        return loadCurveForCalendarDefaultForm(this.facility);
    };
    
    getDefaultSummaryChartForm = () => {
        return summaryChartDefaultForm();
    };
    
    getDefaultHeatMapForm = () => {
        return heatmapDefaultForm();
    };
    
    getDefaultZoomableChartForm = () => {
        return zoomableChartDefaultForm();
    };
    
    getDefaultDemandDurationForm = () => {
        return demandDurationChartDefaultForm();
    };
    
    getDefaultCalendarChartForm = () => {
        return calendarChartDefaultForm();
    };
    
    getDefaultScatterPlotForm = () => {
        return scatterPlotDefaultForm();
    };
    
    getRequestFromForm = (form, selectedFacility) => {
        return getIntervalDataRequestFromForm(form, selectedFacility)
    }
}

export default ChartDataFilterFormObject;

function getIntervalDataRequestFromForm(form, selectedFacility) {
    // Get the days the user selected
    let selectedDaysNumbers = [];
    if (form.days) {
        let selectedDays = form.days.filter(day => day.value === true);
        selectedDays.forEach(day => selectedDaysNumbers.push(day.number))
    } else {
        selectedDaysNumbers = null
    }
    // Get the months the user selected
    let selectedMonthNumbers = [];
    if (form.months) {
        let selectedMonths = form.months.filter(month => month.value === true);
        selectedMonths.forEach(month => selectedMonthNumbers.push(month.number))
    } else {
        selectedMonthNumbers = null;
    }
// Get the years the user selected
    let selectedYearNumbers = [];
    if (form.years) {
        let selectedYears = form.years.filter(year => year.value === true);
        selectedYears.forEach(year => selectedYearNumbers.push(year.number))
    } else {
        selectedYearNumbers = null;
    }
    // Get the series types the user selected
    let seriesTypes = [];
    if (form.seriesTypes) {

        form.seriesTypes.forEach(series => {
            if (series.value === true)
                seriesTypes.push(series.label)
        });
    } else {
        seriesTypes = null;
    }

    let request = intervalDataRequestObject;
    
    switch (form.chartType) {
        case 'zoomable-chart':
            request.IncludePercent = false;
            break;
        case 'demand-duration':
            request.IncludePercent = true;
            break;
        case 'heat-map':
            request.IntervalDuration = 60;
            break;
    }
    
    
    request.ProjectId = selectedFacility.projectId;
    request.FacilityId = selectedFacility.facilityId;
    request.DataType = form.dataType;
    request.SeriesPer = form.seriesPer;
    request.StartDate = form.startDate;
    request.EndDate = form.endDate;
    request.StartTime = form.startTime;
    request.EndTime = form.endTime;
    request.DaysOfWeek = selectedDaysNumbers;
    request.Months = selectedMonthNumbers;
    request.Years = selectedYearNumbers;
    request.SeriesType = seriesTypes;
    return request;
}

function getDefaultForm(chartType, facility) {
    switch (chartType) {
        case 'load-curve':
            return loadCurveDefaultForm(facility);
        case 'summary-chart':
            return summaryChartDefaultForm(facility);
        case 'heat-map':
            return heatmapDefaultForm(facility);
        case 'zoomable-chart':
            return zoomableChartDefaultForm(facility);
        case 'demand-duration':
            return demandDurationChartDefaultForm(facility);
        case 'calendar-chart':
            return calendarChartDefaultForm(facility);
        case 'scatter-plot':
            return scatterPlotDefaultForm(facility);
        case 'surface-plot':
            return surfacePlotDefaultForm(facility)
    }
}

const loadCurveForCalendarDefaultForm = (facility) => {
    let form = loadCurveDefaultForm(facility);
    form.seriesPer = 'allData';
    return form;
}

const loadCurveDefaultForm = (facility) => {
    let form = { ...masterFormObject };

    let days = daysOfWeekGenerator();
    for (let i = 0; i < days.length; i++) {
        days[i].value = true;
    }
    let months = monthsGenerator();
    for (let i = 0; i < months.length; i++) {
        months[i].value = true;
    }

    let timezone = facility.timezone;
    let startYear = moment.tz(facility.intervalDataStartDate, timezone).year();
    let endYear = moment.tz(facility.intervalDataEndDate, timezone).year();
    let years = [];
    for (let i = startYear; i <= endYear; i++) {
        years.push({value: true, label: i.toString(), number: i});
    }
 
    
    form.chartType = 'load-curve';
    form.days = days;
    form.months = months;
    form.years = years;
    form.seriesTypes = seriesTypeGenerator();
    form.dataType = 'kw';
    form.seriesPer = 'month';

    return form
}

const summaryChartDefaultForm = (facility) => {
    let form = { ...masterFormObject };

    let days = daysOfWeekGenerator();
    for (let i = 0; i < days.length; i++) {
        days[i].value = true;
    }
    let months = monthsGenerator();
    for (let i = 0; i < months.length; i++) {
        months[i].value = true;
    }

    let timezone = facility.timezone;
    let startYear = moment.tz(facility.intervalDataStartDate, timezone).year();
    let endYear = moment.tz(facility.intervalDataEndDate, timezone).year();
    let years = [];
    for (let i = startYear; i <= endYear; i++) {
        years.push({value: true, label: i.toString(), number: i});
    }

    form.chartType = 'summary-chart';
    form.days = days;
    form.months = months;
    form.years = years;
    form.seriesTypes = seriesTypeGenerator();
    form.dataTypeOptions = dataTypesGenerator();
    form.dataType = 'kwh'; // this doesn't matter, backend doesn't look it
    form.seriesPer = 'month';
    return form;
}

const heatmapDefaultForm = (facility) => {
    let form = { ...masterFormObject };

    let timezone = facility.timezone;
    let startYear = moment.tz(facility.intervalDataStartDate, timezone).year();
    let endYear = moment.tz(facility.intervalDataEndDate, timezone).year();
    let years = [];
    for (let i = startYear; i <= endYear; i++) {
        years.push({value: true, label: i.toString(), number: i});
    }
    
    form.years = years;
    form.chartType = 'heat-map';
    form.dataType = 'kw';

    return form
}

const zoomableChartDefaultForm = (facility) => {
    let form = { ...masterFormObject };

    form.chartType = 'zoomable-chart';
    form.dataType = 'kw';

    return form
}

const demandDurationChartDefaultForm = (facility) => {
    let form = { ...masterFormObject };

    let months = monthsGenerator();
    for (let i = 0; i < months.length; i++) {
        months[i].value = true;
    }
    let timezone = facility.timezone;
    let startYear = moment.tz(facility.intervalDataStartDate, timezone).year();
    let endYear = moment.tz(facility.intervalDataEndDate, timezone).year();
    let years = [];
    for (let i = startYear; i <= endYear; i++) {
        years.push({value: true, label: i.toString(), number: i});
    }

    
    form.chartType = 'demand-duration';
    form.dataType = 'kw';
    form.months = months;
    form.years = years;
    return form
}

const calendarChartDefaultForm = (facility) => {
    let form = { ...masterFormObject };

    form.chartType = 'calendar-chart';
    form.dataType = 'kw';

    return form
}


const scatterPlotDefaultForm = (facility) => {
    let form = { ...masterFormObject };

    let days = daysOfWeekGenerator();
    for (let i = 0; i < days.length; i++) {
        days[i].value = true;
    }
    let months = monthsGenerator();
    for (let i = 0; i < months.length; i++) {
        months[i].value = true;
    }

    let timezone = facility.timezone;
    let startYear = moment.tz(facility.intervalDataStartDate, timezone).year();
    let endYear = moment.tz(facility.intervalDataEndDate, timezone).year();
    let years = [];
    for (let i = startYear; i <= endYear; i++) {
        years.push({value: true, label: i.toString(), number: i});
    }
    
    form.chartType = 'scatter-plot';
    form.dataType = 'kw';
    form.days = days;
    form.months = months;
    form.years = years;
    return form
}

const surfacePlotDefaultForm = (facility) => {
    let form = { ...masterFormObject };

    let days = daysOfWeekGenerator();
    for (let i = 0; i < days.length; i++) {
        days[i].value = true;
    }
    let months = monthsGenerator();
    for (let i = 0; i < months.length; i++) {
        months[i].value = true;
    }

    let timezone = facility.timezone;
    let startYear = moment.tz(facility.intervalDataStartDate, timezone).year();
    let endYear = moment.tz(facility.intervalDataEndDate, timezone).year();
    let years = [];
    for (let i = startYear; i <= endYear; i++) {
        years.push({value: true, label: i.toString(), number: i});
    }


    form.chartType = 'surface-plot';
    form.days = days;
    form.months = months;
    form.years = years;
    form.seriesTypes = seriesTypeGenerator();
    form.dataType = 'kw';
    form.seriesPer = 'day';

    return form
}

