import allReducers from './redux/reducers'
import { devToolsEnhancer } from 'redux-devtools-extension';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from "redux-thunk";

// const store = createStore(allReducers, applyMiddleware(thunk), devToolsEnhancer(
//     // Specify custom devTools options
//     {
//         trace: true
//     }
// )
// );

const store = createStore(allReducers, compose(
    applyMiddleware(thunk),
    devToolsEnhancer(
//     // Specify custom devTools options
    {
        trace: true
    }
)
));

export default store