import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Material UI
import withStyles from '@material-ui/core/styles/withStyles'

// Services
import { login, forgotPassword } from "../services/user.service";

// Carousel
import LogIn from '../Login/index'

const styles = theme => ({
    main: {
        // width: 'auto',
        // display: 'block', // Fix IE 11 issue.
        // maxWidth: 400,
        // marginLeft: theme.spacing.unit * 3,
        // marginRight: theme.spacing.unit * 3,
        // [theme.breakpoints.up(800 + theme.spacing.unit * 3 * 2)]: {
        //     width: 800,
        //     // marginLeft: 'auto',
        //     // marginRight: 'auto',
        // },
    },
    paper: {
        marginTop: theme.spacing.unit * 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
        minHeight: 500,
        maxWidth: 400
    },
    carousel: {
        marginTop: theme.spacing.unit * 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 0}px ${theme.spacing.unit * 0}px ${theme.spacing.unit * 0}px`,
        minHeight: 500,
        maxWidth: 400
    },
    avatar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing.unit,
    },
    submit: {
        marginTop: theme.spacing.unit * 3,
    },
    hidden:{
        display: 'none'
    },
    red:{
        color: 'red'
    }
});

class SignIn extends Component {
    state = {
        form: {
            email: '',
            password: '',
            error: ''
        },
        currentView: 'logIn',
        submitting: false,
    }
    
    toggleView = (value) => {
        this.setState({currentView: value})
    }
    
    handleChange = (event) => {
        if (event) {
            let value = event.target.value;
            let name = event.target.name;

            let form = {...this.state.form};
            form[name] = value;
            this.setState({form}) 
        }
        
    }
    
    setError = (value) => {
        let form = {...this.state.form}
        form.error = value;
        this.setState({form})
    }

    onFormSubmit = e => {
        this.setState({submitting: true});
        e.preventDefault();
        const response = login(
            this.state.form.email,
            this.state.form.password
        );
        response.then(response => {
            if (response == null){
                let temp = this.props;

                let redirectUrl = "/";
                
                if (this.props.location && this.props.location.state){
                    redirectUrl = this.props.location.state.from.pathname;
                    if (redirectUrl === undefined || redirectUrl === null || redirectUrl === "" || redirectUrl === "/"){
                        redirectUrl = "/";
                    }
                }
                
                window.location.href = redirectUrl;
            }

            if (response != null && !response.success && response.errors && response.errors.length > 0){
                this.setError(response.errors[0].message)
            }
            this.setState({submitting: false});
        });
    };
    
    onNewPasswordSubmit = e => {
        e.preventDefault();
        this.setState({submitting: true});
        //TODO: send the user a new password. Email can be accessed at this.state.form.email
        let email = this.state.form.email;
        forgotPassword(this.state.form.email).then(response => {
            if (response.isSuccess) {
                this.setState({currentView: 'emailSent'})
            }
            this.setState({submitting: false});
        }
            
            
        );
        
    }
    
    render() {
        return (
            <LogIn
                onFormSubmit={this.onFormSubmit}
                form={this.state.form}
                handleChange={this.handleChange}
                currentView={this.state.currentView}
                toggleView={this.toggleView}
                onNewPasswordSubmit={this.onNewPasswordSubmit}
                submitting={this.state.submitting}
            />
        );
    }
    
}

export default withStyles(styles)(SignIn);