import React, {Component} from 'react'

// Redux
import {bindActionCreators} from "redux";
import {
    setChartDataFilterFormObject,
    onDataFilterFormSubmit
} from "../../../redux/actions/meter-charts-actions";

import {connect} from "react-redux";

// Components
import ChartDataFilterForm from "../DataFilterForm";
import ChartDataFilterFormObject from "../DataFilterForm/ChartDataFilterFormObject";

class MeterDataFilterFormContainer extends Component {
    
    componentDidMount() {
        const {selectedFacility, selectedFacilitySubview} = this.props;
        if (selectedFacility) {
            let temp = new ChartDataFilterFormObject(this.props.selectedFacility);
            let chartDataFilterForm = temp.getDefaultFormByChartType(selectedFacilitySubview);
            this.props.setChartDataFilterFormObject(chartDataFilterForm);
            this.props.onDataFilterFormSubmit();
        }
        
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {selectedFacility, selectedFacilitySubview} = this.props;
        if (selectedFacility && (selectedFacilitySubview !== prevProps.selectedFacilitySubview) || (selectedFacility !== prevProps.selectedFacility)) {
            // get new filter settings
            let temp = new ChartDataFilterFormObject(this.props.selectedFacility);
            let chartDataFilterForm = temp.getDefaultFormByChartType(selectedFacilitySubview);
            this.props.setChartDataFilterFormObject(chartDataFilterForm);
            this.props.onDataFilterFormSubmit();
        }
    }

    updateChartDataFilterForm = (chartDataFilterForm) => {
        // this.setState({chartDataFilterForm, chartDataFilterFormHasChanged: true});
        this.props.setChartDataFilterFormObject(chartDataFilterForm)
    };

    onDataFilterFormSubmit = () => {
        this.props.onDataFilterFormSubmit();
    };
    
    render() {
        const {
            //redux
            chartDataFilterFormObject,
            selectedFacility,
            
        } = this.props;
            if (chartDataFilterFormObject) {
                if (chartDataFilterFormObject.chartType === this.props.selectedFacilitySubview) {
                    return (
                        <ChartDataFilterForm

                            chartDataFilterForm={chartDataFilterFormObject}
                            updateChartDataFilterForm={this.updateChartDataFilterForm}
                            onDataFilterFormSubmit={this.onDataFilterFormSubmit}
                            selectedFacility={selectedFacility}
                        />
                    )
                } else {
                    return 'Loading'
                }

            } else {
                return 'Loading'
            }
        }
}

function mapStateToProps(state) {
    return {
        chartDataFilterFormObject: state.chartDataFilterFormObject,
        selectedFacility: state.selectedFacility,
        selectedFacilitySubview: state.selectedFacilitySubview
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        
        // chart options
        setChartDataFilterFormObject,
        onDataFilterFormSubmit,
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(MeterDataFilterFormContainer)