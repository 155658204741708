import React, {Component} from 'react'
import {getFacilityWeather, getFacilityWeatherRequestObject} from "../../../../services/intervalData.service";
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official'
import highchartsExporting from "highcharts/modules/exporting";
import moment from 'moment'
import CustomTabsPaper from "../../../../components/tabs/CustomTabsPaper";
import ChartWrapper from "../../../../components/ChartWrapper/ChartWrapper";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import BarChartIcon from "@material-ui/icons/BarChart";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {withStyles} from "@material-ui/core";
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from "@material-ui/core/OutlinedInput";
import WeatherStationMap from "../WeatherStation";
import {HotTable} from '@handsontable/react';
import createHandsontableSettingsFromData from "../../../../js/createHandsontableSettingsFromData";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import exportDataAsCsv from "../../../js/exportDataAsCsv";
import DataViewLoadingSpinner from "../../spinners/DataViewLoadingSpinner";
import {bindActionCreators} from "redux";
import {setRawWeatherDataChartOptions} from "../../../../redux/actions/weather-actions";
import {connect} from "react-redux";

const styles = theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: 200
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
});

class RawWeatherData extends Component {

    state = {
        selectedDataType: 'temperature',
        dataTypes: [
            {
                label: 'Temperature',
                value: 'temperature',
                unit: '°F',
                axisLabel: 'Temperature (°F)'
            },
            {
                label: 'Humidity',
                value: 'humidity',
                unit: '%',
                axisLabel: 'Humidity (%)'
            },
            {
                label: 'Dew Point',
                value: 'dewpoint',
                unit: '°F',
                axisLabel: 'Dew Point (°F)'
            },
            {
                label: 'Precipitation',
                value: 'precipitation',
                unit: ' mm',
                axisLabel: 'Precipitation (mm)'
            },
            {
                label: 'Wind Speed',
                value: 'windSpeed',
                unit: ' km/h',
                axisLabel: 'Wind Speed (km/h)'
            },
            {
                label: 'Pressure',
                value: 'pressure',
                unit: ' hPa',
                axisLabel: 'Pressure (hPa)'
            },

        ]
    }

    render() {
        return (


            <div style={{width: '100%', padding: 5, paddingTop: 15}}>

                <ChartWrapper
                    title={'Weather Data'}
                    message={'Highlight area to zoom in'}
                    chartArea={this.props.rawWeatherDataChartOptions ? <HighchartsReact
                        highcharts={Highcharts}
                        constructorType={'stockChart'}
                        options={this.props.rawWeatherDataChartOptions}
                    /> : <DataViewLoadingSpinner />}
                />
            </div>

        )
    }
}

function mapStateToProps(state) {
    return {
        rawWeatherDataChartOptions: state.rawWeatherDataChartOptions
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setRawWeatherDataChartOptions
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)((RawWeatherData)));
