/* eslint-disable */
import React, { Component, Fragment } from "react";

// Redux

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Highcharts from 'highcharts/highstock';
//import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import highchartsExporting from "highcharts/modules/exporting";
import highchartsMore from "highcharts/modules/heatmap";

//Material UI
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import Typography from '@material-ui/core/Typography';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

//Colors 
import grey from '@material-ui/core/colors/grey';
import blue from '@material-ui/core/colors/blue';

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartLine, faChartArea, faChartBar } from '@fortawesome/free-solid-svg-icons'
import AntTabs from "../AntTabs/AntTabs";
import AntTab from "../AntTabs/AntTab";

highchartsMore(Highcharts);
highchartsExporting(Highcharts);

let borderColor = grey[300];

const useStyles = makeStyles(theme => ({
    content: {
        flexGrow: 1,
        // border: 'solid',
        // borderColor: borderColor,
        // borderWidth: 1,
        // borderTop: 'none'
    },
    title: {
        backgroundColor: grey[200],
        padding: 5,
        paddingLeft: 20,
        paddingRight: 20,
        align: 'center',
        border: 'solid',
        //backgroundColor: grey[200],
        borderColor: borderColor,
        borderWidth: 1,
        borderTop: 'none',
        borderBottom: 'none',
        borderLeft: 'none',
    },
    chartTitleBar: {
        border: 'solid',
        borderColor: borderColor,
        borderWidth: 1,
    },
    chartSection: {
        border: 'solid',
        borderColor: borderColor,
        borderWidth: 1,
        borderTop: 'none',
        paddingTop: 10,
        
    },
    tabsContainer: {
        border: 'solid',
        //backgroundColor: grey[200],
        borderColor: borderColor,
        borderWidth: 1,
        borderTop: 'none',
        borderRight: 'none',
        borderLeft: 'none',
    },
    tabSelected: {
        backgroundColor: 'white',
        height: 40,
        border: 'solid',
        borderColor: borderColor,
        borderWidth: 1,
        borderBottom: 'none',
        borderTopRightRadius: 5,
        borderTopLeftRadius: 5,
        //'&:hover': {
        //    cursor: 'pointer'
        //}
    },
    tab: {
        height: 40,
        border: 'solid',
        borderColor: borderColor,
        borderWidth: 1,
        borderTop: 'none',
        borderRight: 'none',
        borderLeft: 'none',
        color: blue[500],
        fontWeight: 800,
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline'
        }
    },
    tabsExtraSpace: {
        border: 'solid',
        borderColor: borderColor,
        borderWidth: 1,
        borderTop: 'none',
        borderRight: 'none',
        borderLeft: 'none',
        height: '100%'
    },
    chartTypeTab: {
        border: 'solid',
        borderColor: borderColor,
        borderWidth: 1,
        borderTop: 'none',
        borderBottom: 'none',
        borderRight: 'none',
        paddingLeft: 10,
        paddingRight: 10,
        height: '100%',
        color: grey[700],
        backgroundColor: grey[200],
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: grey[300],
        }
    },
    toggleContainer: {
        //margin: theme.spacing(2, 0),
    },
}));

function MainChartContainer(props) {
    const { tabs, currentComponent, setTab, currentTab } = props;
    const classes = useStyles();

    const [tabValue, setTabValue] = React.useState(0);
    const [component, setComponent] = React.useState(tabs[0].component);

 


    function handleChange(tab) {
        setTabValue(tab.value);
        setComponent(tab.component);
    }

    return (
        <Fragment>



            <Grid container>
                {/*<Grid container>*/}
                {/*    {tabs.map((tab, index) => (*/}
                {/*        <AntTabs value={0}>*/}
                {/*            <AntTab label={tab.label} onClick={() => setTab(index)}/>*/}
                {/*        </AntTabs>*/}
                {/*    ))}*/}
                {/*    <Grid item md={12 - tabs.length * 2} xl={12 - tabs.length} xs={12-tabs.length*4}>*/}
                {/*        <Grid container className={classes.tabsExtraSpace}>*/}
                
                {/*        </Grid>*/}
                {/*    </Grid>*/}
                {/*</Grid>*/}

                <div 
                    className={classes.content} 
                    // style={{ backgroundColor: 'white' }} 
                >
                    <Grid container style={{minHeight: 300}}>
                        {currentComponent}
                    </Grid>

                </div>
            </Grid>




        </Fragment>


    )





}

export default MainChartContainer



