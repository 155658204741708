import React from 'react'

// Material UI
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormLabel from '@material-ui/core/FormLabel';

const useStyles = makeStyles(theme => ({

    textField: {
        margin: theme.spacing(1),
        width: '100%'

    },


}));

const EditFacilityForm = (props) => {
    const { handleEditFacilityInputChange, editFacilityFormControls, attemptedEditFacilitySubmit } = props;
    const classes = useStyles();

    return (
        <form onSubmit={() => alert("submitted")}>
            <Grid container spacing={3}>
                <Grid item md={6} xs={12} >
                    <FormControl className={classes.textField} error={!editFacilityFormControls.name.valid && attemptedEditFacilitySubmit}>
                        
                        <InputLabel htmlFor="component-error">{editFacilityFormControls.name.label}*</InputLabel>
                        <Input
                            id="component-error"
                            value={editFacilityFormControls.name.value}
                            name="name"
                            onChange={handleEditFacilityInputChange}
                            aria-describedby="component-error-text"
                            autoFocus
                        />
                        {/*<FormHelperText id="component-error-text">{editFacilityFormControls.name.message}</FormHelperText>*/}
                    </FormControl>
                    <TextField
                        id="standard-name"
                        label={editFacilityFormControls.meterNumber.label}
                        className={classes.textField}
                        value={editFacilityFormControls.meterNumber.value}
                        name="meterNumber"
                        onChange={handleEditFacilityInputChange}
                        margin="normal"
                    />
                    <TextField
                        id="standard-name"
                        label={editFacilityFormControls.accountNumber.label}
                        className={classes.textField}
                        value={editFacilityFormControls.accountNumber.value}
                        name="accountNumber"
                        onChange={handleEditFacilityInputChange}
                        margin="normal"
                    />
                    <TextField
                        id="standard-name"
                        label={editFacilityFormControls.utility.label}
                        className={classes.textField}
                        value={editFacilityFormControls.utility.value}
                        name="utility"
                        onChange={handleEditFacilityInputChange}
                        margin="normal"
                    />
                </Grid>
                <Grid item md={6} xs={12} >
                    
                    <TextField
                        id="standard-name"
                        label={editFacilityFormControls.address1.label}
                        className={classes.textField}
                        value={editFacilityFormControls.address1.value}
                        name="address1"
                        onChange={handleEditFacilityInputChange}
                        margin="normal"
                    />
                    <TextField
                        id="standard-name"
                        label={editFacilityFormControls.address2.label}
                        className={classes.textField}
                        value={editFacilityFormControls.address2.value}
                        name="address2"
                        onChange={handleEditFacilityInputChange}
                        margin="normal"
                    />
                    <TextField
                        id="standard-name"
                        label={editFacilityFormControls.city.label}
                        className={classes.textField}
                        value={editFacilityFormControls.city.value}
                        name="city"
                        onChange={handleEditFacilityInputChange}
                        margin="normal"
                    />
                    <TextField
                        id="standard-name"
                        label={editFacilityFormControls.state.label}
                        className={classes.textField}
                        value={editFacilityFormControls.state.value}
                        name="state"
                        onChange={handleEditFacilityInputChange}
                        margin="normal"
                    />
                    <FormControl className={classes.textField} error={!editFacilityFormControls.postalCode.valid}>

                        <InputLabel htmlFor="component-error">{editFacilityFormControls.postalCode.label}*</InputLabel>
                        <Input
                            id="component-error"
                            value={editFacilityFormControls.postalCode.value}
                            name="postalCode"
                            onChange={handleEditFacilityInputChange}
                            aria-describedby="component-error-text"
                            required={true}
                        />
                        {/*<FormHelperText id="component-error-text">{createFacilityFormControls.name.message}</FormHelperText>*/}
                    </FormControl>
                </Grid>

            </Grid>


        </form>
    )

}



export default (EditFacilityForm)