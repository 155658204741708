import React, {Component} from 'react'
import Grid from "@material-ui/core/Grid";
import CustomInfoLayout from "../../../components/CustomInfoLayout/CustomInfoLayout";
import UsageGauge from './Stats/UsageGauge'
import SubscriptionCard from "./Subscription/SubscriptionSummary";
import Profile from "./Profile";
import EditAccountInformation from "../EditAccount";

class AccountSummary extends Component {

    render() {
        return (
            <Grid container style={{display: "flex", justifyContent: 'center'}}>
                <Grid item xs={12} style={{maxWidth: 800}}>
                    <CustomInfoLayout title="Profile" mainContent={<Profile />}/>
                    {/*<EditAccountInformation />*/}
                </Grid>
            </Grid>

        )
    }
}

export default AccountSummary