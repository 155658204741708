import React, {Component, Fragment} from 'react'

//Material UI
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { withStyles } from '@material-ui/core/styles';
import CustomInfoLayout from "../../../../components/CustomInfoLayout/CustomInfoLayout";
import HorizontalDivider from "../../../../components/Dividers/HorizontalDivider";
import CheckoutForm from "../PaymentMethodRev2/CheckoutForm";
import AccountActivationStepper from "./Components/AccountActivationStepper";


const styles = theme => ({
    cancel: {
        color: 'red',
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline',
        }
    },
    icon: {
        margin: theme.spacing(0),
        color: '#4a4a4a',
        fontSize: 25,
        display: 'inline-block'
    },

});

class ActivateSubscription extends Component {

    state = {
        showPaymentMethod: false,
    };

    componentDidMount = () => {
        let userInfo = JSON.parse(window.sessionStorage.user);

    };
    
    toggleShowPaymentMethod = (value) => {
        this.setState({showPaymentMethod: value})
    }


    render() {
        
        const {activeUser, resetUserInfo} = this.props;
        
        return (
            <Grid container style={{display: 'flex', justifyContent: 'center', padding: 15}}>

                {this.state.showPaymentMethod ?
                    <Grid item xs={12}>
                        <AccountActivationStepper resetUserInfo={resetUserInfo}/>
                    </Grid> :
                    <div style={{height: '90vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <Grid container style={{display: 'flex', justifyContent: 'center'}}>
                            <div style={{maxWidth: 500}}>
                                <Typography align={'center'}>
                                    Oops! {activeUser.firstName}, looks like your subscription is no longer active. Activate your account and you will have access to all of the projects you have previously uploaded.
                                </Typography>
                            </div>
                                

                        <Grid item xs={12}>
                            <Grid container style={{display: 'flex', justifyContent: 'center', paddingTop: 10}}>
                                <Button
                                    variant={'contained'}
                                    color={'primary'}
                                    onClick={() => this.toggleShowPaymentMethod(true)}
                                    disabled={this.state.showPaymentMethod}
                                >
                                    Activate Subscription
                                </Button>
                            </Grid>

                        </Grid>
                        </Grid>
                    </div>
                }


            </Grid>

        )
    }
}

export default withStyles(styles)(ActivateSubscription)