import React from 'react'
import CustomDialog from "../../../../components/Dialogs/customDialog";
import Button from "@material-ui/core/Button";
import NoteForm from "../NoteForm";
import GreenButton from "../../../../components/CustomButtons/GreenButton";

function CreateNoteDialog(props) {
    const {
        open, 
        onClose,
        createNoteFormControls,
        handleCreateNoteChange,
        onCreateOrEditNoteSubmit,
        attemptedCreateNoteSubmit
    } = props;
    
    return (
        <CustomDialog
            open={open}
            subTitle="Add Note"
            onClose={onClose}
            actions={<GreenButton color="secondary" onClick={() => onCreateOrEditNoteSubmit('create')}>Add Note</GreenButton>}
            content={<NoteForm formControls={createNoteFormControls} handleChange={handleCreateNoteChange} attemptedSubmit={attemptedCreateNoteSubmit}/>}
            fullWidth={true}
        />
    )
}

export default CreateNoteDialog