import React, { Component, Fragment } from 'react';


// Material UI
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import grey from '@material-ui/core/colors/grey';
import IconButton from '@material-ui/core/IconButton';

// Icons
import CloseIcon from '@material-ui/icons/Close';


//Form
import CreateUserForm from '../forms/CreateUserForm'
import CustomDialog from "../../../components/Dialogs/customDialog";
import GreenButton from "../../../components/CustomButtons/GreenButton";



const useStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
    appBar: {
        backgroundColor: grey[300]
    },
    title: {
        color: 'black',
        flexGrow: 1
    },

});

function CreateUserDialog(props) {
    const { createUserDialogOpen, toggleCreateUserDialog, createUserFormControls, handleCreateUserInputChange, onCreateUserSubmit, attemptedCreateUserSubmit } = props;
    const classes = useStyles();

    return (
        <Fragment>
            <CustomDialog
                open={createUserDialogOpen}
                onClose={toggleCreateUserDialog}
                title=""
                subTitle="Add User"
                content={<CreateUserForm createUserFormControls={createUserFormControls} handleCreateUserInputChange={handleCreateUserInputChange} attemptedCreateUserSubmit={attemptedCreateUserSubmit} />}
                actions={<Fragment>
                    <GreenButton type="button" onClick={() => onCreateUserSubmit()}>Add User</GreenButton></Fragment>}
            />
        </Fragment>


    );
}

export default CreateUserDialog;