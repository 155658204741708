/**
 * API URL PATHs
 */
//forward slash in front of API means getting the data from the domain root, not the current page
const config = {
    account: "/api/account",
    project: "/api/project",
    facility: "/api/facility",
    intervalData: "/api/intervalData",
    admin: "/api/admin",
    
};

export default config;
