
const PROJECTS_MOUNTED = 'PROJECTS_MOUNTED';
const TOGGLE_CREATE_PROJECT_DIALOG = 'TOGGLE_CREATE_PROJECT_DIALOG';

const addProjectsToState = (projects) => {
    return {
        type: PROJECTS_MOUNTED,
        payload: projects
    };
};



const toggleCreateProjectDialog = (currentState) => {
    return {
        type: TOGGLE_CREATE_PROJECT_DIALOG,
        payload: currentState
    };
};

export {
    addProjectsToState,
    toggleCreateProjectDialog,
    PROJECTS_MOUNTED,
    TOGGLE_CREATE_PROJECT_DIALOG
}