import React, { Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { HotTable } from '@handsontable/react';
import Handsontable from 'handsontable';
import ImportDataComponent from '../../../ImportData/ImportDataComponent'
import MeterSectionTitleBar from "../components/MeterSectionTitleBar";




export default function ImportDataFacilityView(props) {
    const { facilityId, projectId } = props;

    return (
        <Grid container>
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                <div style={{maxWidth: 1400, width: '100%'}}>
                    <MeterSectionTitleBar title={"Import Data"}/>
                    <Grid container style={{padding: 10, width: '100%'}}>
                        <ImportDataComponent facilityId={facilityId} projectId={projectId} onDataSubmissionSuccess={props.onDataSubmissionSuccess}/>
                    </Grid>
                </div>
            </Grid>
        </Grid>
        

    );
}
