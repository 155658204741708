import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    formControl: {
        margin: theme.spacing(3),
    },
    group: {
        margin: theme.spacing(1, 0),
    },
}));

export default function RadioButtonsGroup({ group, handleRadioChange}) {
    const classes = useStyles();
    const [value, setValue] = React.useState('female');

    function handleChange(event) {
        //something is super weird here. the event shows as synthetic. but you can access target if you go to that directly
        handleRadioChange(event.target.name, event.target.value)
        setValue(event.target.value);
    }

    return (
        <div className={classes.root}>
            <FormControl component="fieldset" className={classes.formControl}>
                <FormLabel component="legend">{group.label}</FormLabel>
                <RadioGroup
                    aria-label="Gender"
                    name={group.name }
                    className={classes.group}
                    value={group.selected}
                    onChange={handleChange}
                >
                    {group.options.map(field => (
                        <FormControlLabel value={field.value} control={<Radio />} label={field.label} />
                        ))}
                </RadioGroup>
            </FormControl>
            </div>
    );
}