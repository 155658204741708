const TOGGLE_IMPORT_FACILITY_DATA_DIALOG = 'TOGGLE_IMPORT_FACILITY_DATA_DIALOG';
const FACILITY_SELECTED = 'FACILITY_SELECTED';
const SELECTED_FACILITY_VIEW = 'SELECTED_FACILITY_VIEW';
const TOGGLE_FACILITY_SIDEBAR = 'TOGGLE_FACILITY_SIDEBAR';
const SELECTED_FACILITY_SUBVIEW = 'SELECTED_FACILITY_SUBVIEW';


const toggleImportDataDialog = (currentState) => {
    return {
        type: TOGGLE_IMPORT_FACILITY_DATA_DIALOG,
        payload: currentState
    };
};

const selectFacility = (facility) => {
    
    return {
        
        type: FACILITY_SELECTED,
        payload: facility
    };
};

const selectFacilityView = (view, subview) => {
    return {
        type: SELECTED_FACILITY_VIEW,
        payload: {
            view,
            subview
        }
    };
};


const toggleFacilitySideBar = (open) => {
    return {
        type: TOGGLE_FACILITY_SIDEBAR,
        payload: open
    };
};

export {
    toggleImportDataDialog,
    selectFacility,
    selectFacilityView,
    toggleFacilitySideBar,
    TOGGLE_IMPORT_FACILITY_DATA_DIALOG,
    FACILITY_SELECTED,
    SELECTED_FACILITY_VIEW,
    TOGGLE_FACILITY_SIDEBAR,
};

