import React, { Component } from "react";
import * as THREE from "three";

let array = [147.6,139,141,143,142,147,141,139,133,140,142,142,141,141,137,141,144,144,136,135,167,186,177,180,204,203,246,267,371,400,434,441,448,453,454,456,457,452,453,454,450,451,458,465,459,456,453,460,464,453,441,443,446,441,440,443,449,450,454,448,451,442,458,445,440,420,400,363,260,232,223,215,211,209,180,173,174,175,180,181,170,164,167,171,165,164,163,165,157,154,156,154,154,154,153,150];


class ThreeTest extends Component {
    constructor(props) {
        super(props);
        this.three = React.createRef();
    }
    
    componentDidMount() {

        let container = this.three.current;
        var camera, scene, renderer;
        var line;
        init();
        animate();
        function init() {
            //
            camera = new THREE.PerspectiveCamera( 27, window.innerWidth / window.innerHeight, 1, 4000 );
            camera.position.z = 1000;
            camera.position.y = 300;
            scene = new THREE.Scene();
            
            for (let k = -50; k < 50; k++) {
                var segments = array.length;
                var geometry = new THREE.BufferGeometry();
                var material = new THREE.LineBasicMaterial( { vertexColors: THREE.VertexColors } );
                var positions = [];
                var colors = [];
                let sizes = [];
                var r = 800;
            
                for ( var i = 0; i < segments; i ++ ) {
                    var x = i*3;
                    var y = array[i] + Math.random()*20;
                    var z = k*2;
                    // positions
                    positions.push( x, y, z );
                    // colors
                    colors.push( 255 );
                    colors.push( 0 );
                    colors.push( 0 );
                    sizes.push(1000)
                }
            
            
            // debugger;
            
                geometry.setAttribute( 'position', new THREE.Float32BufferAttribute( positions, 3 ) );
                geometry.setAttribute( 'color', new THREE.Float32BufferAttribute( colors, 3 ) );
                geometry.setAttribute( 'size', new THREE.Float32BufferAttribute( sizes, 1 ).setUsage( THREE.DynamicDrawUsage ) );
                geometry.computeBoundingSphere();
                line = new THREE.Points( geometry, material );
                scene.add( line );
            }
            //
            renderer = new THREE.WebGLRenderer();
            renderer.setPixelRatio( window.devicePixelRatio );
            renderer.setSize( window.innerWidth, window.innerHeight );
            renderer.outputEncoding = THREE.sRGBEncoding;
            container.appendChild( renderer.domElement );
            
            //
            window.addEventListener( 'resize', onWindowResize, false );
        }
        function onWindowResize() {
            camera.aspect = window.innerWidth / window.innerHeight;
            camera.updateProjectionMatrix();
            renderer.setSize( window.innerWidth, window.innerHeight );
        }
        //
        function animate() {
            requestAnimationFrame( animate );
            render();
        }
        function render() {
            // var time = Date.now() * 0.001;
            // line.rotation.x = time * 0.25;
            // line.rotation.y = time * 0.5;
            scene.rotation.y += 0.01;
            renderer.render( scene, camera );
        }
        
        
    }
    render() {
        return (
            <div ref={this.three} />
        )
    }
}


export default ThreeTest
