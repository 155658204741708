import moment from 'moment'

function makeHandsontableFromVerticalCsv(data, dataType) {
    let array = [];
    data.forEach(x => {

        let row = [];
        let keys = Object.keys(x);
        let lastDateTimeWasEmptyOrInvalid = false;
        keys.forEach((key, index) => {
            if (index === 0 && (!x[key] || !moment(x[key])._isValid)) {
                lastDateTimeWasEmptyOrInvalid = true;
            } else if (index === 1 && lastDateTimeWasEmptyOrInvalid) {
                // do nothing
            } else {
                row.push(x[key]);
                
            }
        });
        if (row.length !== 0) {
            array.push(row);
        }
    });
    
    let dataTypeLabel;
    switch (dataType) {
        case 'kwh':
            dataTypeLabel = "Usage (kWh)";
            break;
        case 'kw':
            dataTypeLabel = "Demand (kW)";
            break;
    }
    
    let columns = [
        {
            // validator: function(value, callback) {
            //         let temp = moment(value);
            //         if (temp._isValid === true && value) {
            //             callback(true)
            //         } else {
            //             callback(false)
            //         }
            //
            //    
            // }
            type: 'date',
            validator: 'date',
            dateFormat: 'M/D/YY H:mm',
            correctFormat: true,
            // datePicker additional options (see https://github.com/dbushell/Pikaday#configuration)
        },
        {
            type: 'numeric',
            validator: 'numeric',
            numericFormat: {
                pattern: '0,0.00'
            }
        }
    ]
    
    let colHeaders = ['Datetime', dataTypeLabel];
    return {
        data: array,
        rowHeaders: false,
        colHeaders,
        columns,
        width: 'auto',
        height: 500,
        rowHeaderWidth: 180,
        licenseKey: 'non-commercial-and-evaluation',
        stretchH: 'all',
    }
}

function makeHandsontableFromHorizontalCsv(data, intervalDuration) {
    let array = [];
    let colHeaders = ['Date'];
    let keys = Object.keys(data[0]);
    
    // Make colHeaders
    let startTime = moment().hour(0).minute(0).second(0);
    switch (intervalDuration) {
        case 15: 
            for (let i = 1; i <= 96; i++) {
                colHeaders.push(moment(startTime).add(i * intervalDuration, 'minutes').format("H:mm"))
            }
            break;
        case 30:
            for (let i = 1; i <= 48; i++) {
                colHeaders.push(moment(startTime).add(i * intervalDuration, 'minutes').format("H:mm"))
            }
            break;
        case 60:
            for (let i = 1; i <= 24; i++) {
                colHeaders.push(moment(startTime).add(i * intervalDuration, 'minutes').format("H:mm"))
            }
            break;
    }
    
    data.forEach(x => {
        let row = [];
        let lastDateWasEmptyOrInvalid = false;
        keys.forEach((key, index) => {
            if (index === 0 && moment(x[key])._isValid) {
                row.push(x[key]);
            } else if (index === 0) {
                lastDateWasEmptyOrInvalid = true;
            } else if (index !== 0 && !lastDateWasEmptyOrInvalid) {
                let number = parseFloat(x[key]);
                if (number) {
                    row.push(number);
                } else {
                    row.push(null)
                }
                
            }
            
        });
        if (row.length !== 0) {
            array.push(row);
        }
        

    });
    
    let columns = [];

    keys.forEach((key, index) => {
        if (index !== 0) {
            columns.push({
                type: 'numeric',
                validator: 'numeric',
                numericFormat: {
                    pattern: '0,0.00'
                }
            })
        } else {
            columns.push({
                type: 'date',
                dateFormat: 'MM/DD/YYYY',
                correctFormat: true,
                defaultDate: '01/01/1900',
                validator: function(value, callback) {
                    let temp = moment(value);
                    if (temp._isValid === true) {
                        callback(true)
                    } else {
                        callback(false)
                    }
                }
                // datePicker additional options (see https://github.com/dbushell/Pikaday#configuration)
            })
        }
    })

    return {
        data: array,
        // rowHeaders: true,
        colHeaders,
        columns,
        width: 'auto',
        height: 500,
        rowHeaderWidth: 180,
        licenseKey: 'non-commercial-and-evaluation',
        stretchH: 'all',
    }
}

export {makeHandsontableFromVerticalCsv, makeHandsontableFromHorizontalCsv}