import React, {Component} from 'react'
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import {withStyles, makeStyles} from "@material-ui/core/styles";


import Icon from '@material-ui/core/Icon';

const useStyles = makeStyles(theme => ({
    root: {
        paddingLeft: 15,
        paddingRight: 15,

    },
    cardIcon: {
        margin: theme.spacing(0),
        color: theme.palette.primary.dark,
        fontSize: 25,
        // display: 'inline-block'
    },
    warningIcon: {
        margin: theme.spacing(0),
        color: theme.palette.warning.main,
        fontSize: 25,
        // display: 'inline-block',
    },
    margin: {
        margin: theme.spacing(0),
    },
}));

function CurrentPaymentMethod(props) {


        const {organizationPaymentMethod, fetchingPaymentMethod} = props;
        const classes = useStyles();
       
        return (
            <Grid container className={classes.root}>

                <Grid item xs={8}>
                    <div style={{padding: 10}}>
                        
                    
                    {organizationPaymentMethod ? 
                        <div>
                            
                        
                        <div style={{display: 'flex', justifyItems: 'center'}}>
                            <Icon className={classes.cardIcon}>
                                credit_card
                            </Icon>
                            <Typography style={{display: 'inline-block', marginLeft: 8, fontWeight: 800}}>{organizationPaymentMethod.brand.replace(/^\w/, c => c.toUpperCase())} ending in {organizationPaymentMethod.last4}</Typography>
                        </div>
                            <div>
                            <Typography>Expires: {organizationPaymentMethod.exp_month} / {organizationPaymentMethod.exp_year}</Typography>
                        </div>
                        </div> : 
                        <div > 
                            {fetchingPaymentMethod ? null : 
                            <div style={{display: 'flex', alignItems: 'center'}}> 
                            <Icon className={classes.warningIcon}>
                                warning
                            </Icon>
                            <Typography style={{paddingLeft: 5}}>No card on file</Typography>
                            </div>}
                        </div>
                    }
                    </div>
                </Grid>
            </Grid>
            
        )
    
}

export default CurrentPaymentMethod