import React, { Component, Fragment } from 'react';

// Material UI
import Button from '@material-ui/core/Button';

import CreateProjectForm from './CreateProjectForm';
import CustomDialog from "../../components/Dialogs/customDialog";
import CustomPulseLoader from "../../components/Spinners/pulse-loader";
import EditProjectForm from "./EditProject/EditProjectForm";
import GreenButton from "../../components/CustomButtons/GreenButton";


function CreateProjectDialog(props) {
    const { 
        loading,
        createProjectDialogOpen,
        toggleCreateProjectDialog,
        createProjectFormControls,
        handleCreateProjectInputChange,
        onCreateProjectSubmit,
        attemptedCreateProjectSubmit } = props;

    return (
            <CustomDialog
                open={createProjectDialogOpen}
                onClose={toggleCreateProjectDialog}
                subTitle="Add Project"
                content={<EditProjectForm 
                    formControls={createProjectFormControls} 
                    handleInputChange={handleCreateProjectInputChange} 
                    attemptedSubmit={attemptedCreateProjectSubmit} />
                }
                actions={<Fragment>
                    {/*<Button type="button" onClick={() => toggleCreateProjectDialog()}>Cancel</Button>*/}
                    <GreenButton type="button" onClick={() => onCreateProjectSubmit()}>{loading ? <CustomPulseLoader /> : 'Add Project'}</GreenButton>
                </Fragment>}
            />

    );
}

export default CreateProjectDialog;