

const exportDataAsCsv = (handsontableSettings) => {
    // debugger;
    var data = handsontableSettings.data;
    var csv = ' ,';
    
    handsontableSettings.colHeaders.forEach(x => csv+= x + ',');
    csv += "\n";
    data.forEach(function(row, index) {
        csv += handsontableSettings.rowHeaders[index] + ',';
        csv += row.join(',');
        csv += "\n";
    });

    console.log(csv);
    var hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
    hiddenElement.target = '_blank';
    hiddenElement.download = 'data.csv';
    hiddenElement.click();
}

export default exportDataAsCsv