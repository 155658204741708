import React, { Fragment } from 'react';

// Material UI
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

// Components
import CustomDialog from "../../../components/Dialogs/customDialog";

const useStyles = makeStyles({
    button: {
        color: 'red'
    }

});

function DeleteFacilityDialog(props) {
    const {
        deleteFacilityDialogOpen,
        toggleDeleteFacilityDialog,
        selectedFacilityForDelete,
        onDeleteFacilitySubmit,
    } = props;
    const classes = useStyles();

    return (
        <Fragment>
            {selectedFacilityForDelete === null ? null :
                <CustomDialog 
                    open={deleteFacilityDialogOpen}
                    onClose={toggleDeleteFacilityDialog}
                    subTitle="DELETE"
                    // title={selectedFacilityForDelete.name}
                    content={<Typography>Are you sure you want to delete the meter "{selectedFacilityForDelete.name}"? </Typography>}
                    actions={<Button type="button" className={classes.button} onClick={() => onDeleteFacilitySubmit()}>Delete</Button>}
                />
            }
        </Fragment>
    );
}

export default DeleteFacilityDialog;