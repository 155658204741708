import {
        TOGGLE_IMPORT_FACILITY_DATA_DIALOG,
        FACILITY_SELECTED,
        SELECTED_FACILITY_VIEW,
        TOGGLE_FACILITY_SIDEBAR,
} from '../actions/facility-actions'

const selectFacility = (state = null, action) => {
    switch (action.type) {
        
        case FACILITY_SELECTED:
        return action.payload;
    }
    return state
} 

const toggleImportDataDialog = (state = false, action) => {
    switch (action.type) {
        case TOGGLE_IMPORT_FACILITY_DATA_DIALOG:
        return !action.payload;
    }
    return state;
};

const facilityView = (state = 'overview', action) => {
    switch (action.type) {
        case SELECTED_FACILITY_VIEW:
            return action.payload.view;
    }
    return state;
};

const facilitySubview = (state = '', action) => {
    switch (action.type) {
        case SELECTED_FACILITY_VIEW:
            if (action.payload.subview) {
                return action.payload.subview;
            } else {
                return null
            }
            
    }
    return state;
};

const toggleFacilitySidebar = (state = true, action) => {
    switch (action.type) {
        case TOGGLE_FACILITY_SIDEBAR:
        return !action.payload;
    }
    return state;
};


export { selectFacility, toggleImportDataDialog, facilityView, facilitySubview, toggleFacilitySidebar }