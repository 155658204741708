import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import PrivateRoute from "components/PrivateRoute";
import SignIn from "layouts/Login";
import Home from "./layouts/Home.jsx";
import ResetPassword from "./layouts/ResetPassword"
import registerServiceWorker from "./registerServiceWorker";
import { unregister } from "./registerServiceWorker";

import { Provider } from 'react-redux';
import store from'./store'


import "./assets/css/material-dashboard-react.css?v=1.6.0";
import 'handsontable/dist/handsontable.full.css';

const hist = createBrowserHistory();

ReactDOM.render(
    <div>
        <Provider store={store}>
            <Router history={hist}>
                <Switch>
                    {/*<Redirect from="/" to="/dashboard" component={Admin} />*/}
                    <Route path="/ResetPassword" component={ResetPassword} />
                    <Route path="/login" component={SignIn} />
                    <PrivateRoute path="/" component={Home} />


                </Switch>
            </Router>
        </Provider>
    </div>
    ,
    document.getElementById("root")
);

unregister();
