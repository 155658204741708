import React from 'react'
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";


function OverviewSidebarUtilityAccountSection(props) {
    const {selectedFacility, toggleEditMeterDialogOpen} = props;
    return (
        <Grid container>
            <Grid item xs={12}>
                <Grid container >
                    <Typography variant={"subtitle2"} style={{textTransform: 'none', flexGrow: 1}}>
                        Meter
                    </Typography>

                    
                </Grid>
                

            </Grid>
            <Grid container style={{padding: 5}}>
                <Grid item xs={12}>
                    Meter Number: {selectedFacility.meterNumber ? selectedFacility.meterNumber :
                    <Link onClick={toggleEditMeterDialogOpen}>Add</Link>}
                </Grid>
                <Grid item xs={12}>
                    Account Number: {selectedFacility.accountNumber ? selectedFacility.accountNumber :
                    <Link onClick={toggleEditMeterDialogOpen}>Add</Link>}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default OverviewSidebarUtilityAccountSection