import React, {Component} from 'react'
import moment from "moment";
import 'moment-timezone'
import CustomSlider from "../../../../../components/Sliders/CustomSlider";

//Material UI
import Typography from '@material-ui/core/Typography';
import {withStyles} from "@material-ui/core";
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';

const styles = theme => ({
    root: {
        width: 300,
    },
});

function ValueLabelComponent(event, selectedFacility) {
    // const { children, open, value } = props;

    const popperRef = React.useRef(null);
    React.useEffect(() => {
        if (popperRef.current) {
            popperRef.current.update();
        }
    });
    
    let label = moment.tz(selectedFacility.intervalDataStartDate, selectedFacility.timezone).add(event.value, 'days').format('MM/DD/YYYY');

    return (
        <Tooltip
            PopperProps={{
                popperRef,
            }}
            open={event.open}
            enterTouchDelay={0}
            placement="top"
            title={<Typography>{label}</Typography>}
        >
            {event.children}
        </Tooltip>
    );
}


class DateRanges extends Component {
    
    state = {
        baseline: {
            startDate: 1000000,
            endDate: 2000000,
            
        },
        marks: [
            {
                value: 0,
                label: moment.tz(this.props.selectedFacility.intervalDataStartDate, this.props.selectedFacility.timezone).format('MM/DD/YYYY')
            },
            {
                value: moment.tz(this.props.selectedFacility.intervalDataEndDate, this.props.selectedFacility.timezone).diff(moment.tz(this.props.selectedFacility.intervalDataStartDate, this.props.selectedFacility.timezone), 'days'),
                label: moment.tz(this.props.selectedFacility.intervalDataEndDate, this.props.selectedFacility.timezone).format('MM/DD/YYYY')
            },
        ]
    };
    
    getDate = (value) => {
        return moment.tz(this.props.selectedFacility.intervalDataStartDate, this.props.selectedFacility.timezone).add(value, 'days').format('MM/DD/YYYY')
    };
    
    
    // componentDidUpdate(prevProps, prevState, snapshot) {
    //     const {periodLength} = this.props;
    //     debugger;
    // }

    render() {
        const {selectedFacility, classes} = this.props;
        let temp = moment.tz(this.props.selectedFacility.intervalDataStartDate, this.props.selectedFacility.timezone).format('MM/DD/YYYY HH:mm:ss');
        debugger;
        let duration = moment.tz(selectedFacility.intervalDataEndDate, this.props.selectedFacility.timezone).diff(moment.tz(selectedFacility.intervalDataStartDate, this.props.selectedFacility.timezone), 'days');
        return (
            <div className={classes.root}>
                {/*<FormLabel component="legend">Gender</FormLabel>*/}
                <Typography>
                    Select Baseline Range
                </Typography>
                <CustomSlider
                    defaultValue={[1, 50]}
                    // getAriaValueText={(value) => this.valuetext(value)}
                    aria-labelledby="discrete-slider"
                    color={'secondary'}
                    valueLabelDisplay="auto"
                    ValueLabelComponent={(event) => ValueLabelComponent(event, selectedFacility)}
                    step={1}
                    marks={this.state.marks}
                    min={0}
                    valueLabelFormat={x => this.getDate(x)}
                    max={duration}
                />
            </div>
        )
    }
}

export default withStyles(styles)(DateRanges)