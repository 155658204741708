import React, { Component } from 'react';
import WeatherStationMap from "./WeatherStationMap";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

class WeatherStation extends Component {


    render() {
        
        const {selectedFacility} = this.props;
        return (
            <Grid container style={{width: '100%', height: '100%'}}>
                <Grid item lg={12}>

                        <Grid container style={{height: '100%', minHeight: 500, width: '100%', padding: 10}}>
                            <WeatherStationMap selectedFacility={selectedFacility}/>
                            {/*TODO: take this away*/}
                            {/*Hiding for now till deploying*/}
                        </Grid>
                    
                </Grid>
                
            </Grid>
            

        );
    }
}

export default WeatherStation;