import React, {Component} from 'react'

//Material UI
import Paper from '@material-ui/core/Paper';
import {Grid, Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import FacilitiesTable from "../../ProjectHome/containers/FacilitiesTable";

class CustomInfoLayout extends Component {

    render() {
        const {title, mainContent, topRightContent = null} = this.props;

        return (
            <Grid item xs={12}
                  style={{paddingRight: 10, paddingLeft: 10, paddingBottom: 5, height: '100%'}}>

                <Grid container style={{justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                    <Typography style={{
                        fontSize: 18,
                        padding: '16px 0 8px 8px',
                        color: '#4a4a4a',
                        flexGrow: 1,
                        fontWeight: 300
                    }}>
                        {title}
                    </Typography>
                    <div style={{height: '100%', justifyContent: 'center'}}>
                        {topRightContent ? topRightContent : null}
                    </div>
                </Grid>
                <Paper >
                    {mainContent}


                </Paper>


            </Grid>
        )
    }
}

export default CustomInfoLayout