import React from 'react'
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import {makeStyles} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormLabel from '@material-ui/core/FormLabel';
import MaskedInput from 'react-text-mask'
import {Typography} from "@material-ui/core";
import CustomOutlinedInput from "../../../components/Inputs/CustomOutlinedInput";

const useStyles = makeStyles(theme => ({

    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),

    },
    formControl: {
        margin: theme.spacing(1),
    },

}));

const EditFacilityForm = (props) => {
    const {handleEditUserInputChange, editUserFormControls, attemptedEditUserSubmit} = props;
    const classes = useStyles();
    let keys = Object.keys(editUserFormControls);

    return (
        <form onSubmit={() => alert("submitted")} >
            <Grid container >
                {/*<Grid item xs={12}>*/}
                {/*    <div>*/}
                {/*        <Typography variant={"h6"} style={{textTransform: 'none', display: 'inline-block', marginRight: 10}}>*/}
                {/*            User:*/}
                {/*        </Typography>*/}
                {/*        <Typography variant={"h5"} style={{textTransform: 'none', display: 'inline-block'}}>*/}
                {/*            {editUserFormControls.email.value}*/}
                {/*        </Typography>*/}
                {/*    </div>*/}
                {/*    */}
                {/*</Grid>*/}
                {keys.map(key => (
                    <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}} key={key}>
                        {/*<FormControl className={classes.formControl}*/}
                        {/*             error={!editUserFormControls[key].valid && attemptedEditUserSubmit}*/}
                        {/*             style={{width: 300}}>*/}

                            {/*<InputLabel htmlFor="component-error">{editUserFormControls[key].label}</InputLabel>*/}
                            <CustomOutlinedInput
                                label={editUserFormControls[key].label}
                                id="component-error"
                                value={editUserFormControls[key].value}
                                name={key}
                                onChange={handleEditUserInputChange}
                                aria-describedby="component-error-text"
                                fullWidth
                                disabled={key === 'email'}
                                inputComponent={editUserFormControls[key].textMask ? editUserFormControls[key].textMask : 'input'}
                                error={!editUserFormControls[key].valid && attemptedEditUserSubmit}
                            />
                            {/*{editUserFormControls[key].message ? <FormHelperText*/}
                            {/*    id="component-error-text">{editUserFormControls[key].message}</FormHelperText> : null}*/}

                        {/*</FormControl>*/}
                    </Grid>
                ))}
            </Grid>
        </form>
    )

}


export default (EditFacilityForm)

