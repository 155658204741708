import React, {Component} from 'react'
import Grid from "@material-ui/core/Grid";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import OverviewSidebarAddressSection from "./components/OverviewSidebarAddressSection";
import OverviewSidebarUtilitySection from "./components/OverviewSidebarUtilitySection";
import OverviewSidebarUtilityAccountSection from "./components/OverviewSidebarUtilityAccountSection";
import {setSelectedMeterForEdit, toggleEditMeterDialogOpen} from "../../../../redux/actions/MeterActions/EditMeterActions/edit-meter-actions";
import Icon from "@material-ui/core/Icon";
import Link from "@material-ui/core/Link";

class OverviewLeftSidebar extends Component {
    
    componentDidMount() {
        const {selectedFacility} = this.props;
        this.props.setSelectedMeterForEdit(selectedFacility);
    }
    
    editFacility = () => {
        this.props.toggleEditMeterDialogOpen()
    }

    render() {
        const {selectedFacility, toggleEditMeterDialogOpen} = this.props;
        if (selectedFacility) {
            return (
                <Grid container style={{width: '100%', padding: 15}}>
                    <Grid container style={{paddingBottom: 20}}>
                        <Typography variant={"subtitle1"} style={{textTransform: 'none', flexGrow: 1}}>
                            Meter Information
                        </Typography>
                        <div style={{display: 'flex', alignItems: 'center'}} onClick={this.editFacility}>
                            <Link >
                                <Icon style={{fontSize: '1em', paddingRight: 15}}>
                                    edit
                                </Icon>
                                Edit
                            </Link>
                        </div>
                        
                    </Grid>
                   
                    
                    <OverviewSidebarAddressSection selectedFacility={selectedFacility} toggleEditMeterDialogOpen={this.editFacility}/>
                    <OverviewSidebarUtilitySection selectedFacility={selectedFacility} toggleEditMeterDialogOpen={this.editFacility}/>
                    <OverviewSidebarUtilityAccountSection selectedFacility={selectedFacility} toggleEditMeterDialogOpen={this.editFacility}/>
                    

                </Grid>
            )
        } else {
            return 'Loading'
        }

    }
}

function mapStateToProps(state) {
    return {
        selectedFacility: state.selectedFacility,
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setSelectedMeterForEdit,
        toggleEditMeterDialogOpen
    }, dispatch);
};


export default connect(mapStateToProps, mapDispatchToProps)(OverviewLeftSidebar);
