import React from 'react';

//Material UI
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';


const useStyles = makeStyles({
    root: {
        flexGrow: 1,
    },

    card: {
        maxWidth: 345,
    },

});

const projectProperties = [
    {
        name: 'address1',
        label: 'Address 1',
    },
    {
        name: 'address2',
        label: 'Address 2'
    },
    {
        name: 'city',
        label: 'City',
    },
    {
        name: 'state',
        label: 'State'
    },
    {
        name: 'postalCode',
        label: 'Zip Code',
    },
    {
        name: 'phone',
        label: 'Phone'
    }
]

function getOrganizationInfo(organization) {
    return (
    <div>
        <Typography variant="h6" color="inherit">
            {organization.name}
            </Typography>
            {projectProperties.map(property => {
                if (organization[property.name]) {
                    return (
                        <Typography color="inherit">
                            {property.label}: {organization[property.name]}
                        </Typography>
                    )
                } else {
                    //show info with blank name if null/undefined
                    return (
                        <Typography color="inherit">
                            {property.label}:
                        </Typography>
                    )
                }                   
                        
            })}

        </div>
        )
}

export default function SimpleAppBar(props) {
    const classes = useStyles();
    const { organization } = props;

    return (
        <div className={classes.root}>
            <Card>

                <CardContent>
                    {!organization ? null : getOrganizationInfo(organization)}
                </CardContent>

            </Card>


        </div>
    );
}