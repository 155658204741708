import React, {Component} from 'react'
import {bindActionCreators} from "redux";
import {addCalendarChartDataToState} from "../../../../redux/actions/interval-data-actions";
import {
    toggleCalendarChartShowPeakDemand,
    toggleCalendarChartShowTemperature
} from "../../../../redux/actions/DataChartActions/calendar-chart-actions";
import {setChartDataFilterFormObject, onDataFilterFormSubmit} from "../../../../redux/actions/meter-charts-actions";
import {connect} from "react-redux";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ChartDataFilterFormObject from "../../DataFilterForm/ChartDataFilterFormObject";

class CalendarChartSidebar extends Component {
    componentDidMount() {
        const {selectedFacility, selectedFacilitySubview} = this.props;
        if (selectedFacility) {
            let temp = new ChartDataFilterFormObject(this.props.selectedFacility);
            let chartDataFilterForm = temp.getDefaultFormByChartType(selectedFacilitySubview);
            this.props.setChartDataFilterFormObject(chartDataFilterForm);
            this.props.onDataFilterFormSubmit();
        }

    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const {selectedFacility, selectedFacilitySubview} = this.props;
        if (selectedFacility && (selectedFacilitySubview !== prevProps.selectedFacilitySubview) || (selectedFacility !== prevProps.selectedFacility)) {
            // get new filter settings
            let temp = new ChartDataFilterFormObject(this.props.selectedFacility);
            let chartDataFilterForm = temp.getDefaultFormByChartType(selectedFacilitySubview);
            this.props.setChartDataFilterFormObject(chartDataFilterForm);
            this.props.onDataFilterFormSubmit();
        }
    }
    

    toggleShowTemperature = () => {
        this.props.toggleCalendarChartShowTemperature(!this.props.calendarChartShowTemperature)
    };

    toggleShowPeakDemand = () => {
        // let showPeakDemand = this.state.showPeakDemand;
        // this.setState({showPeakDemand: !showPeakDemand});
        this.props.toggleCalendarChartShowPeakDemand(!this.props.calendarChartShowPeakDemand)
    };
    
    render() {
        return (
            <Grid container style={{padding: 15}}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={this.props.calendarChartShowPeakDemand}
                            onChange={this.toggleShowPeakDemand}
                            value={this.props.calendarChartShowPeakDemand}
                            color="primary"
                        />
                    }
                    label="Show Peak Demand"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={this.props.calendarChartShowTemperature}
                            onChange={this.toggleShowTemperature}
                            value={this.props.calendarChartShowTemperature}
                            color="primary"
                        />
                    }
                    label={'Temperature'}
                />
            </Grid>
        )
    }
}

function mapStateToProps(state) {
    return {
        selectedFacility: state.selectedFacility,
        selectedFacilitySubview: state.selectedFacilitySubview,
        intervalDataForCalendarChart: state.intervalDataForCalendarChart,
        calendarChartShowTemperature: state.calendarChartShowTemperature,
        calendarChartShowPeakDemand: state.calendarChartShowPeakDemand
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        addCalendarChartDataToState,
        toggleCalendarChartShowTemperature,
        toggleCalendarChartShowPeakDemand,
        setChartDataFilterFormObject,
        onDataFilterFormSubmit
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarChartSidebar);
