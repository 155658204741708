import React, {Fragment, Component } from 'react'

// Material UI
import Grid from '@material-ui/core/Grid';

// Components
import RadioButtonsGroup from './renderRadioGroupReact'
import DatePicker from './renderDatePickers'
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import {withStyles} from "@material-ui/core";

const styles = theme => ({
    formControl: {
        margin: theme.spacing(3),
        minWidth: 120
    },
});

class CsvOrExcelStep2Form extends Component {
    
    state = {
      timezone: "America/New_York"  
    };

    handleTimeZoneChange = (event) => {
        this.setState({timezone: event.target.value});
        
        // bad code here, two sources of truth. Need to move all step2params down to this local state.
        // Did it this way because when trying to use the timezone from props it doesn't fire componentDidUpdate for unknown reason
        this.props.handleTimeZoneChange(event);
    };


    render() {
        const {step2params, handleRadioChange, handleDateChange, importMethod, classes} = this.props;

        let radioKeys = Object.keys(step2params.radios);
        let dateKeys = Object.keys(step2params.dateRange);

        return (
            <form>

                <Grid container justify="center">
                    <Grid container justify="center" style={{maxWidth: '1200px', padding: 10}}>
                        {/*<Grid item md={3}>*/}
                        {/*    <Grid container>*/}
                        {/*    <FormControl className={classes.formControl}>*/}
                        {/*        <InputLabel id="demo-simple-select-label" >Time Zone</InputLabel>*/}
                        {/*        <Select*/}
                        {/*            labelId="demo-simple-select-label"*/}
                        {/*            id="demo-simple-select"*/}
                        {/*            value={this.state.timezone}*/}
                        {/*            onChange={this.handleTimeZoneChange}*/}
                        {/*        >*/}
                        {/*            <MenuItem value={"America/New_York"}>Eastern Time (ET)</MenuItem>*/}
                        {/*            <MenuItem value={"America/Chicago"}>Central Time (CT)</MenuItem>*/}
                        {/*            <MenuItem value={"America/Denver"}>Mountain Time (MT)</MenuItem>*/}
                        {/*            <MenuItem value={"America/Los_Angeles"}>Pacific Time (PT)</MenuItem>*/}
                        {/*            <MenuItem value={"America/Anchorage"}>Alaska Time (AT)</MenuItem>*/}
                        {/*            <MenuItem value={"Pacific/Honolulu"}>Hawaii-Aleutian Time (HT)</MenuItem>*/}
                        {/*            <MenuItem value={"Australia/Sydney"}>Australian Eastern Daylight Time (AEDT)</MenuItem>*/}
                        {/*            <MenuItem value={"Australia/Cairns"}>Australian Eastern Standard Time (AEST)</MenuItem>*/}
                        {/*            <MenuItem value={"Australia/Adelaide"}>Australian Central Daylight Time (ACDT)</MenuItem>*/}
                        {/*            <MenuItem value={"Australia/Eucla"}>Australian Central Western Standard Time (ACWST)</MenuItem>*/}
                        {/*            <MenuItem value={"Australia/Perth"}>Australian Western Standard Time (AWST)</MenuItem>*/}
                        {/*            <MenuItem value={"Australia/Darwin"}>Australian Central Standard Time (ACST)</MenuItem>*/}
                        {/*        </Select>*/}
                        {/*    </FormControl>*/}
                        {/*    </Grid>*/}
                        {/*</Grid>*/}



                        {radioKeys.map(key => {
                            let group = step2params.radios[key];
                            return (
                                <Grid item md={importMethod === 'manual' ? 3 : 4} xs={12} key={key}>
                                    <Grid containter style={{display: 'flex', justifyContent: 'center'}}>
                                        <RadioButtonsGroup group={group} handleRadioChange={handleRadioChange}/>
                                    </Grid>
                                </Grid>
                            );
                        })}





                        {importMethod === 'manual' ?
                            <Grid item md={3} xs={12} style={{padding: 10}}>


                                <Grid container alignItems="center" justify="center">
                                    <FormControl component="fieldset" className={classes.formControl}>
                                        <FormLabel component="legend">Date Range</FormLabel>
                                        {dateKeys.map(key => {
                                            let group = step2params.dateRange[key];
                                            return <Fragment>
                                                <DatePicker
                                                    group={group}
                                                    value={group.value}
                                                    handleDateChange={handleDateChange}
                                                    key={key}
                                                />
                                                <br/>
                                            </Fragment>
                                        })}
                                    </FormControl>
                                </Grid>


                            </Grid> : null}
                    </Grid>
                </Grid>


            </form>
        )
    }
    
}


export default withStyles(styles)(CsvOrExcelStep2Form)