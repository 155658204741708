import React, {Component, Fragment, useState} from 'react'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import CheckoutForm from "../../PaymentMethodRev2/CheckoutForm";
import CheckIcon from '@material-ui/icons/Check';
import {withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import moment from 'moment'
import {PulseLoader} from "react-spinners";
import {getActiveProductPlans} from "../../../../../services/admin.service";

const styles = theme => ({
    table: {
        minWidth: 650,
    },
    subscriptionBox: {
        border: '1px solid #dadce0',
        borderRadius: 5,
        padding: 10,
        '&:hover': {
            backgroundColor: theme.palette.background.default,
            // borderColor: theme.palette.primary.main,
            cursor: 'pointer'
        }
    },
    selectedSubscriptionBox: {
        padding: 10,
        borderWidth: '2px',
        borderStyle: 'solid',
        borderColor: theme.palette.primary.main,
        borderRadius: 5,
    },
    subscriptionTitle: {
        fontWeight: 600,

    },
    centerGrid: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }

});

class AccountActivationStep4 extends Component {
    
    state = {
        plans: [],
        loadingPlans: true,
    }
    
    componentDidMount = () => {
        getActiveProductPlans().then(x => {
            // let plans = x[0].plans;
            // debugger;
            this.setState({plans: x[0].plans, loadingPlans: false});
        });
    }

    render() {
        const {
            quantity,
            organizationPaymentMethod,
            onActivateSubmit,
            handleSubscriptionDurationChange,
            subscriptionDuration,
            paymentTotal,
            classes
        } = this.props;

        let date = moment().date();

        

        if (this.state.loadingPlans) {
            return null
        } else {
            return (
                <Grid container style={{display: 'flex', justifyContent: 'center'}}>
                    <div style={{width: 500}}>
                        <Grid container style={{display: 'flex', justifyContent: 'center'}}>
                            <Typography variant={"h5"} style={{paddingBottom: 15}}>Checkout</Typography>
                        </Grid>

                        <Grid container style={{display: 'flex', justifyContent: 'center'}}>
                            {this.state.plans.map(plan => {
                                return (
                                    <Grid item md={6}>
                                        <div style={{padding: 5}}>


                                            <Grid
                                                container
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                }}
                                                className={subscriptionDuration === plan.interval ? classes.selectedSubscriptionBox : classes.subscriptionBox}
                                                onClick={() => handleSubscriptionDurationChange(plan.interval)}
                                            >
                                                <Grid item xs={12} className={classes.centerGrid}>
                                                    <Typography
                                                        className={classes.subscriptionTitle}>Billed {plan.interval === 'month' ? 'Monthly' : 'Annually'}</Typography>
                                                </Grid>
                                                <Grid item xs={12} className={classes.centerGrid}>
                                                    <Typography>${plan.price}/user</Typography>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Grid>
                                )
                            })}
                            {/*<Grid item md={6}>*/}
                            {/*    <div style={{padding: 5}}>*/}


                            {/*        <Grid*/}
                            {/*            container*/}
                            {/*            style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}*/}
                            {/*            className={subscriptionDuration === 'month' ? classes.selectedSubscriptionBox : classes.subscriptionBox}*/}
                            {/*            onClick={() => handleSubscriptionDurationChange('month')}*/}
                            {/*        >*/}
                            {/*            <Grid item xs={12} className={classes.centerGrid}>*/}
                            {/*                <Typography className={classes.subscriptionTitle}>Billed Monthly</Typography>*/}
                            {/*            </Grid>*/}
                            {/*            <Grid item xs={12} className={classes.centerGrid}>*/}
                            {/*                <Typography>$39.99/user</Typography>*/}
                            {/*            </Grid>*/}
                            {/*        </Grid>*/}
                            {/*    </div>*/}
                            {/*</Grid>*/}
                            {/*<Grid item md={6}>*/}
                            {/*    <div style={{padding: 5}}>*/}
                            {/*        <Grid*/}
                            {/*            container*/}
                            {/*            style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}*/}
                            {/*            className={subscriptionDuration === 'year' ? classes.selectedSubscriptionBox : classes.subscriptionBox}*/}
                            {/*            onClick={() => handleSubscriptionDurationChange('year')}*/}
                            {/*        >*/}
                            {/*            <Grid item xs={12} className={classes.centerGrid}>*/}
                            {/*                <Typography className={classes.subscriptionTitle}>Billed Annually</Typography>*/}
                            {/*            </Grid>*/}
                            {/*            <Grid item xs={12} className={classes.centerGrid}>*/}
                            {/*                <Typography>$439.89/user</Typography>*/}
                            {/*            </Grid>*/}
                            {/*        </Grid>*/}
                            {/*    </div>*/}
                            {/*</Grid>*/}


                        </Grid>

                        {/*<Grid item md={6}>*/}
                        {/*    Monthly*/}
                        {/*</Grid>*/}
                        {/*<Grid item md={6}>*/}
                        {/*    Quarterly*/}
                        {/*</Grid>*/}

                        <Grid container style={{display: 'flex', justifyContent: 'center'}}>
                            <Table>
                                <TableRow>
                                    <TableCell>
                                        Number of Users
                                    </TableCell>
                                    <TableCell>
                                        {quantity}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        Subscription
                                    </TableCell>
                                    <TableCell>
                                        Basic
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        Payment Method
                                    </TableCell>
                                    <TableCell>

                                        {/*Card ending in {organizationPaymentMethod.last4}*/}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        Total
                                    </TableCell>
                                    <TableCell>
                                        ${paymentTotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                                    </TableCell>
                                </TableRow>

                            </Table>

                        </Grid>
                        <Grid container style={{display: 'flex', justifyContent: 'center', paddingTop: 10}}>
                            {subscriptionDuration === 'monthly' ?
                                <Fragment>
                                    <Typography>You will be charged the listed amount now and automatically
                                        on</Typography>
                                    {date > 28 ?
                                        <Typography> day {date}, or last day, of each month. Cancel
                                            anytime.</Typography> :
                                        <Typography> day {date} of each month. Cancel anytime.</Typography>
                                    }
                                </Fragment> :
                                <Fragment>
                                    <Typography>You will be charged the listed amount now and automatically
                                        on</Typography>
                                    <Typography> {moment().format('MMMM D')}, every year. Cancel anytime.</Typography>
                                </Fragment>
                            }
                        </Grid>
                        <Grid container style={{display: 'flex', justifyContent: 'center', paddingTop: 10}}>
                            <Button variant={'contained'} color={'secondary'} onClick={onActivateSubmit}>
                                Activate
                            </Button>


                        </Grid>
                    </div>

                </Grid>

            )
        }

    }



}

export default withStyles(styles)(AccountActivationStep4)