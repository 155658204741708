import config from "../config";
import { authHeader } from "../helpers/auth-header";
import { getUser } from "../services/user.service";
import { REQUESTTYPES, fetchHelper, responseStatusHandler } from "./serviceHelper";


// Get api base URLs
const PROJECT = config.project;

const projectModel =
{
    ProjectId: null,
    Name: "",
    Description: "",
    CompanyName: "",
    ContactName: "",
    ContactJobTitle: "",
    ContactPhoneNumber: "",
    ContactEmail: "",
    Utility: "",
    Notes: ""
}

const ENDPOINTS = {
    projects: {
        getProjectsByUserId: "GetProjectsByUserId",
        getProjectsByOrganizationId: "GetProjectsByOrganizationId",
        getProjectById: "GetProjectById",
        createOrUpdateProject: "CreateOrUpdateProject",
        deleteProject: "DeleteProject"
    }
};

const getProjectsForUser = () => {
    var user = getUser();
    var userId = user.userId;
    const apiResponse = fetchHelper(
        PROJECT,
        ENDPOINTS.projects.getProjectsByUserId,
        {userId: userId},
        "post"
    ).then(response => {
        // login successful if there's a user in the response
        if (!response.isSuccess) {
            //todo: do something on error
            return response;
        } else {
            return response.projects;
        }
    });
    
    return apiResponse;
};

const getProjectsForOrganization = () => {
    var user = getUser();
    var organizationId = user.organizationId;
    const apiResponse = fetchHelper(
        PROJECT,
        ENDPOINTS.projects.getProjectsByOrganizationId,
        {OrganizationId: organizationId},
        "post"
    ).then(response => {
        // login successful if there's a user in the response
        if (!response.isSuccess) {
            //todo: do something on error
        } else {
            return response.projects;
        }
    });

    return apiResponse;
};

const getProjectById = (projectId) => {
    const apiResponse = fetchHelper(
        PROJECT,
        ENDPOINTS.projects.getProjectById,
        {projectId: projectId},
        "post"
    ).then(response => {
        // login successful if there's a user in the response
        if (!response.isSuccess) {
            //todo: do something on error
        } else {
            return response.project;
        }
    });

    return apiResponse;
};

const createOrUpdateProject = (projectModel) => {
    const apiResponse = fetchHelper(
        PROJECT,
        ENDPOINTS.projects.createOrUpdateProject,
        {
            project: projectModel
        },
        "post"
    ).then(response => {
        // login successful if there's a user in the response
        if (!response.isSuccess) {
            //todo: do something on error

            return response;
        } else {
            return response;
        }
    });

    return apiResponse;
};

const deleteProject = (projectId) => {
    const apiResponse = fetchHelper(
        PROJECT,
        ENDPOINTS.projects.deleteProject,
        {
            ProjectId: projectId
        },
        "post"
    ).then(response => {
        // login successful if there's a user in the response
        if (!response.isSuccess) {
            //todo: do something on error
        } else {
            return response;
        }
    });

    return apiResponse;
};



export {
    getProjectsForUser,
    getProjectById,
    createOrUpdateProject,
    deleteProject,
    projectModel,
    getProjectsForOrganization
    // startUserSession,
    // checkEmailAddress,
    // saveEnrollmentProgress,
    // submitEnrollment,
    // submitTeamPriceQuoteRequest
};
