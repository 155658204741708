const SELECTED_CHART_VIEW = 'SELECTED_CHART_VIEW';

const selectChartView = (view) => {
    return {
        type: SELECTED_CHART_VIEW,
        payload: view
    };
};

export {
    SELECTED_CHART_VIEW,
    selectChartView
};

