import React, {Component} from 'react';
import Highcharts from 'highcharts';
import {withStyles} from '@material-ui/core/styles';
import HighchartsReact from 'highcharts-react-official';
import highchartsMore from "highcharts/highcharts-more";
import CountUp from 'react-countup';

import 'moment-timezone';


// Material UI
import Grid from '@material-ui/core/Grid';
import grey from '@material-ui/core/colors/grey';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';

import TableRow from '@material-ui/core/TableRow';

// Services
import {
    getIntervalDataLoadCurveByFacilityId,
    getIntervalDataSummaryChartByFacilityId,
    intervalDataRequestObject,

} from '../../../services/intervalData.service'

import './helper.css';

// Components
import CustomInfoLayout from "../../../components/CustomInfoLayout/CustomInfoLayout";
import CustomInfoLayout2 from "../../../components/CustomInfoLayout/CustomInfoLayout2";
import DataViewLoadingSpinner from "../spinners/DataViewLoadingSpinner";

import moment from "moment";
import SummaryChartData from "../../../js/CustomDataClasses/SummaryChartData";
import MeterHomeTopBanner from "./MeterHomeTopBanner";
import {
    getIndividualResourceMixPieChartOptions,
    getLocationEmissionsAndResources,
    getResourceMixChartOptionsFromPostalCode
} from "../Emissions/js/emissionsHelper";
import {Typography} from "@material-ui/core";
import MeterSectionTitleBar from "../components/MeterSectionTitleBar";
import {bindActionCreators} from "redux";
import {selectFacility, selectFacilityView, toggleFacilitySideBar} from "../../../redux/actions/facility-actions";
import {connect} from "react-redux";
import {setSelectedMeterForEdit} from "../../../redux/actions/MeterActions/EditMeterActions/edit-meter-actions";
import EditFacilityDialog from "../../../ProjectHome/components/dialogs/EditFacilityDialog";
import {getFacilityById} from "../../../services/facility.service";


highchartsMore(Highcharts);


const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    title: {
        paddingTop: theme.spacing(1),
        flexGrow: 1
    },
    paper: {
        padding: 20,
        marginLeft: 5,
        marginRight: 5,
        marginBottom: 10
    },
    table: {
        paddingLeft: 10,
        paddingRight: 10,
    },
    control: {
        padding: theme.spacing(2),
    },
    peakDemand: {
        height: 60,
    },
    statTitle: {
        width: '100%',
        backgroundColor: grey[300],
        //height: 60,
        padding: 10,
        border: 'solid',
        borderColor: grey[300],
        borderWidth: 1,
        borderTop: 'none',
        borderLeft: 'none',
        borderRight: 'none',
    },
    infoCard: {
        minHeight: 100,
    }

});

const DashboardSummaryChartsRequest = (projectId, facilityId, dataStartDate, dataEndDate) => {
    let request = {...intervalDataRequestObject};

    request.ProjectId = projectId;
    request.FacilityId = facilityId;
    // request.ChartType = 'summary-chart';
    // request.DaysOfWeek = [1,2,3,4,5,6,7];
    request.Months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    request.SeriesType = ['Average'];
    request.DataType = 'kw';
    request.SeriesPer = 'month';
    request.StartDate = moment.utc(dataEndDate).subtract(1, 'year').date(1);
    request.EndDate = moment.utc(dataEndDate);

    return request
};

const DashboardDemandRangeRequest = (projectId, facilityId, dataStartDate, dataEndDate) => {
    let request = {...intervalDataRequestObject};

    request.ProjectId = projectId;
    request.FacilityId = facilityId;
    // request.ChartType = 'summary-chart';
    request.DaysOfWeek = null;
    request.Months = null;
    request.SeriesType = ['Range'];
    request.DataType = 'kw';
    request.SeriesPer = 'allData';
    request.StartDate = moment.utc(dataStartDate);
    request.EndDate = moment.utc(dataEndDate);

    return request
};
const DashboardLoadCurveRequest = (projectId, facilityId, dataStartDate, dataEndDate) => {
    let request = {...intervalDataRequestObject};

    request.ProjectId = projectId;
    request.FacilityId = facilityId;
    // request.ChartType = 'summary-chart';
    request.DaysOfWeek = null;
    request.Months = null;
    request.SeriesType = ['Average'];
    request.DataType = 'kw';
    request.SeriesPer = 'allData';
    request.StartDate = moment.utc(dataStartDate);
    request.EndDate = moment.utc(dataEndDate);

    return request
};


class FacilityOverviewTemp extends Component {
    state = {
        peakAndUsageChartOptions: null,
        loadFactorChartOptions: null,
        demandRangeData: null,
        loadCurveData: null,
        demandRangeChartOptions: null,
        emissionsInfo: null,
        meterAnnualC02e: null,
        resourceMixChartOptions: null,
        intervalDataLengthInDays: null
    }

    getSummarychartData = () => {
        const {selectedFacility} = this.props;
        let intervalDataLengthInDays = moment(selectedFacility.intervalDataEndDate).diff(moment(selectedFacility.intervalDataStartDate), 'days');
        this.setState({intervalDataLengthInDays});

        let summaryChartRequest = DashboardSummaryChartsRequest(selectedFacility.projectId, selectedFacility.facilityId, selectedFacility.intervalDataStartDate, selectedFacility.intervalDataEndDate);

        getIntervalDataSummaryChartByFacilityId(summaryChartRequest).then(data => {
            let summaryChartData = new SummaryChartData(data);

            // Summary Chart
            let requestedDataPointsPeakAndUsage = ['maxKw', 'sumKwh'];
            let peakAndUsageChartOptions = summaryChartData.generateChartOptions(requestedDataPointsPeakAndUsage, 400);
            // peakAndUsageChartOptions.series[0].color = Highcharts.getOptions().colors[5];
            // peakAndUsageChartOptions.series[1].color = Highcharts.getOptions().colors[3];

            // Load Factor Chart
            let requestedDataPointsLoadFactor = ['loadFactor'];
            let loadFactorChartOptions = summaryChartData.generateChartOptions(requestedDataPointsLoadFactor, 400);
            // loadFactorChartOptions.series[0].color = Highcharts.getOptions().colors[2];
            this.setState({peakAndUsageChartOptions, loadFactorChartOptions})
        });

    };

    getDemandRangeData = () => {
        const {selectedFacility} = this.props;
        let demandRangeRequest = DashboardDemandRangeRequest(selectedFacility.projectId, selectedFacility.facilityId, selectedFacility.intervalDataStartDate, selectedFacility.intervalDataEndDate);
        getIntervalDataLoadCurveByFacilityId(demandRangeRequest).then(data => {
            this.setState({demandRangeData: data[0].data});
        })
    }
    getLoadCurveData = () => {
        const {selectedFacility} = this.props;
        let loadCurveRequest = DashboardLoadCurveRequest(selectedFacility.projectId, selectedFacility.facilityId, selectedFacility.intervalDataStartDate, selectedFacility.intervalDataEndDate);
        getIntervalDataLoadCurveByFacilityId(loadCurveRequest).then(data => {
            this.setState({loadCurveData: data[0].data})
        })
    };

    buildDemandRangeChartOptions = () => {
        let options = {
            chart: {
                height: 400,
                style: {
                    fontFamily: "Roboto"
                },
            },
            title: {
                text: ''
            },

            xAxis: {
                type: 'datetime',
                labels: {
                    formatter: function () {
                        let hours = Math.floor(this.value / 60);
                        let minutes = Math.floor(this.value % 60);
                        return moment().hours(hours).minutes(minutes).format("H:mm")
                    }
                },
                tickPositions: [360, 720, 1080]
            },

            yAxis: {
                title: {
                    text: null
                }
            },

            tooltip: {
                crosshairs: true,
                shared: true,
                valueSuffix: ' kW',
                headerFormat: '<span style="font-size:1.5em; text-align: center; width: 100%"></span><br/>',
                xDateFormat: '%H:%M',
                pointFormatter: function () {
                    // debugger;
                    // let minutesOfDay = this.x;
                    let hours = Math.floor(this.x / 60);
                    let minutes = Math.floor(this.x % 60);
                    let time = moment().hours(hours).minutes(minutes).format("H:mm");
                    let header = '<div style="font-size:1.5em; text-align: center; width: 100%">' + time + '</div><br/>';
                    let average;
                    let range;
                    if (this.options.y) {
                        average = 'Average: ' + this.y.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' kW';
                        return header + `<strong>` + average + '</strong><br/>'
                    }
                    if (this.options.low && this.options.high) {
                        range = 'Range: ' + this.low.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' kW - ' + this.high.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' kW';
                        return range
                    }

                },
            },

            series: [{
                name: 'Average kW',
                data: this.state.loadCurveData,
                zIndex: 1,
                marker: {
                    fillColor: 'white',
                    lineWidth: 2,
                    lineColor: Highcharts.getOptions().colors[0]
                }
            }, {
                name: 'Range',
                data: this.state.demandRangeData,
                type: 'arearange',
                lineWidth: 0,
                linkedTo: ':previous',
                color: Highcharts.getOptions().colors[0],
                fillOpacity: 0.3,
                zIndex: 0,
                marker: {
                    enabled: false
                },
                negativeColor: true,
                negativeFillColor: {
                    linearGradient: {
                        x1: 0,
                        y1: 1,
                        x2: 0,
                        y2: 0
                    },
                    stops: [
                        [0, 'rgba(76, 175, 80, .6)'],
                        [1, 'rgba(76, 175, 80, 0)']
                    ]
                },
                fillColor: {
                    linearGradient: {
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 1
                    },
                    stops: [
                        [0, Highcharts.getOptions().colors[0]],
                        [1, Highcharts.Color(Highcharts.getOptions().colors[0]).setOpacity(0.2).get('rgba')]
                    ]
                },
            }],
            credits: {
                enabled: false
            }
        }

        this.setState({demandRangeChartOptions: options})
    };
    
    onEditComplete = () => {
        getFacilityById(this.props.selectedFacility.facilityId).then(response => {
            debugger;
            this.props.selectFacility(response)
        })
    };


    componentDidMount = async () => {
        const {selectedFacility} = this.props;
        
        this.getSummarychartData();
        this.getDemandRangeData();
        this.getLoadCurveData();
        let emissionsInfo = getLocationEmissionsAndResources(selectedFacility.postalCode, 'region', true);
        let meterAnnualC02e = emissionsInfo.locationEmissions.emissions[3].value * selectedFacility.annualKwhValue / 1000;
        this.setState({emissionsInfo, meterAnnualC02e})
        let resourceMixChartOptions = getIndividualResourceMixPieChartOptions(emissionsInfo.locationResourceMix.resourceMix);
        this.setState({resourceMixChartOptions});
        
    };

    componentDidUpdate = (prevProps, prevState) => {
        let {loadCurveData, demandRangeData, demandRangeChartOptions} = this.state;


        if (loadCurveData && demandRangeData && demandRangeChartOptions === null) {
            this.buildDemandRangeChartOptions();
        }

    };

    render() {


        const {selectedFacility, classes} = this.props;
        return (
            <Grid container className={classes.root}>
                <MeterSectionTitleBar title={"Overview"}/>
                
                <MeterHomeTopBanner selectedFacility={selectedFacility} meterAnnualC02e={this.state.meterAnnualC02e} intervalDataLengthInDays={this.state.intervalDataLengthInDays}/>
                <Grid item md={12}>
                    <Grid container className={classes.root}>
                        <Grid item lg={6} xs={12}>
                            <CustomInfoLayout
                                title="Demand Range"
                                topRightContent={null}
                                mainContent={this.state.demandRangeChartOptions ? <HighchartsReact
                                    highcharts={Highcharts}
                                    options={this.state.demandRangeChartOptions}
                                /> : <DataViewLoadingSpinner/>}
                            />
                        </Grid>
                        <Grid item lg={6} xs={12}>
                            <CustomInfoLayout
                                title="Meter Summary"
                                topRightContent={null}
                                mainContent={this.state.peakAndUsageChartOptions ? <HighchartsReact
                                    highcharts={Highcharts}
                                    options={this.state.peakAndUsageChartOptions}
                                /> : <DataViewLoadingSpinner/>}
                            />
                        </Grid>
                        <Grid item lg={6} xs={12}>
                            <CustomInfoLayout
                                title="Load Factor"
                                topRightContent={null}
                                mainContent={this.state.loadFactorChartOptions ? <HighchartsReact
                                    highcharts={Highcharts}
                                    options={this.state.loadFactorChartOptions}
                                /> : <DataViewLoadingSpinner/>}
                            />
                        </Grid>
                        <Grid item lg={6} xs={12}>
                            <CustomInfoLayout
                                title="Resource Mix"
                                topRightContent={null}
                                mainContent={this.state.resourceMixChartOptions ? <HighchartsReact
                                    highcharts={Highcharts}
                                    options={this.state.resourceMixChartOptions}
                                /> : <DataViewLoadingSpinner/>}
                            />
                        </Grid>
                    </Grid>

                </Grid>
                <EditFacilityDialog onEditComplete={this.onEditComplete}/>

            </Grid>


        );
    }
}

function mapStateToProps(state) {
    return {
        // selectedChartView: state.selectedChartView,
        selectedFacilityView: state.selectedFacilityView,
        selectedFacilitySubview: state.selectedFacilitySubview,
        // intervalData: state.intervalData,
        // facilitySidebarOpen: state.facilitySidebarOpen,
        selectedFacility: state.selectedFacility,
        // facilities: state.facilities,
        // intervalDataForLoadCurve: state.intervalDataForLoadCurve,
        // intervalDataForZoomable: state.intervalDataForZoomable,
        // chartDataFilterFormObject: state.chartDataFilterFormObject
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setSelectedMeterForEdit,
        // selectFacilityView,
        selectFacility,
        // selectProject,
        // toggleFacilitySideBar,
        
    }, dispatch);
};

let FacilityOverview = connect(mapStateToProps, mapDispatchToProps)(FacilityOverviewTemp);

export default withStyles(styles)(FacilityOverview)
