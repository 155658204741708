import React, {Component} from 'react'

import {
    getIntervalDataSummaryChartByFacilityId,
    intervalDataRequestObject,
} from '../../../../../services/intervalData.service'
import moment from "moment";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import DataViewLoadingSpinner from "../../../spinners/DataViewLoadingSpinner";

class MeterResourceMixChart extends Component {
    state = {
        chartOptions: null,
    };
    
    componentDidMount() {
        this.setChartOptions();
    };
    
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.locationResourceMix.resourceMix.fossilFuels[0].value !== this.props.locationResourceMix.resourceMix.fossilFuels[0].value) {
            this.setChartOptions();
        }
    }

    setChartOptions = () => {
        const {locationResourceMix} = this.props;
        let resources = locationResourceMix.resourceMix.fossilFuels.concat(locationResourceMix.resourceMix.renewables);
        let data = [];
        resources.forEach(x => {
            data.push({
                name: x.name,
                y: x.value,
            })
        })
        let temp = this.getChartOptions(data);
        this.setState({chartOptions: temp})

    };


    getChartOptions = (data) => {
        return {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
                style: {
                    fontFamily: 'Roboto'
                }
            },
            title: {
                text: ''
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                    }
                }
            },
            series: [{
                name: 'Percentage',
                colorByPoint: true,
                data: data
            }],
            credits: {
                enabled: false
            }
        }
    };

    render() {

        return (
            <div style={{ maxWidth: '100%' }}>
                {this.state.chartOptions ? <HighchartsReact
                    highcharts={Highcharts}
                    options={this.state.chartOptions}
                /> : <DataViewLoadingSpinner/>}
            </div>
        )
    }
}

export default MeterResourceMixChart;