import React, {Component} from 'react'
import MeterCreateNewDate from "./components/CreateNewDate";
import NoDatesOrRanges from "./components/NoDatesOrRanges";
import EmptyGif from "../../../../../components/EmptyGif/EmptyGif";
import eventsGif from '../../../../../assets/gifs/events.gif'
import CustomDialog from "../../../../../components/Dialogs/customDialog";

class MeterDatesAndRanges extends Component {
    state = {
        createEventDialogOpen: false
    }
    render() {
        return (
            <div style={{padding: 15}}>
                <EmptyGif 
                    gif={eventsGif}
                    title={'No Events Yet'}
                    message={'No events have been added yet.'}
                    buttonText={'Create New Event'}
                />
                <CustomDialog 
                    subTitle={"Add Event"}
                    content={<MeterCreateNewDate />}
                    open={this.state.createEventDialogOpen}
                    
                />
                
            </div>
        )
    }
}

export default MeterDatesAndRanges