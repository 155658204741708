import {
    SET_EMISSIONS_OBJECT, 
    TOGGLE_EMISSIONS_INCLUDE_LOSSES,
    HANDLE_EMISSIONS_STATE_VS_REGION_CHANGE
} from "../../actions/EmissionsActions/emissions-actions";

const emissionsObject = (state = {}, action) => {
    switch (action.type) {
        case SET_EMISSIONS_OBJECT:
            return action.payload;
    }
    return state;
};

const emissionsIncludeLosses = (state = true, action) => {
    switch (action.type) {
        case TOGGLE_EMISSIONS_INCLUDE_LOSSES:
            return action.payload;
    }
    return state;
};

const emissionsStateVsRegion = (state = 'subRegion', action) => {
    switch (action.type) {
        case HANDLE_EMISSIONS_STATE_VS_REGION_CHANGE:
            return action.payload;
    }
    return state;
};

export { emissionsObject, emissionsIncludeLosses, emissionsStateVsRegion };