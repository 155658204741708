import {SET_CHART_DATA_FILTER_FORM_OBJECT} from "./meter-charts-actions";
import {
    getDegreeDays, getFacilityWeather, getFacilityWeatherRequestObject,
    getIntervalDataSummaryChartByFacilityId,
    intervalDataRequestObject
} from "../../services/intervalData.service";
import {EDIT_PROFILE_PROPERTY} from "./user-actions";
import createHandsontableSettingsFromData from "../../js/createHandsontableSettingsFromData";
import DegreeDaysClass from "../../js/CustomDataClasses/DegreeDaysClass";
import {getDefaultDegreeDayRequestObjectFromFacility} from "../../js/degreeDayFunctions";
import RegressionClass from "../../js/CustomDataClasses/RegressionClass";
import moment from "moment";
import Highcharts from "highcharts/highstock";

const GET_DEGREE_DAYS = 'GET_DEGREE_DAYS';
const DEGREE_DAY_CHART_TO_UPDATE = 'DEGREE_DAY_CHART_TO_UPDATE';
const SET_WEATHER_HANDSONTABLE_SETTINGS_REDUX = 'SET_WEATHER_HANDSONTABLE_SETTINGS_REDUX';
const CLEAR_DEGREE_DAYS_REDUX = 'CLEAR_DEGREE_DAYS_REDUX';
const SET_REGRESSION = 'SET_REGRESSION';
const SET_RAW_WEATHER_DATA_CHART_OPTIONS = 'SET_RAW_WEATHER_DATA_CHART_OPTIONS';

const setRawWeatherDataChartOptions = (selectedDataType) => {
    return (dispatch, getState) => {
        const {
            selectedFacility,

        } = getState();

        let request = {...getFacilityWeatherRequestObject};
        request.FacilityId = selectedFacility.facilityId;
        request.StartDate = selectedFacility.intervalDataStartDate;
        request.EndDate = selectedFacility.intervalDataEndDate;
        getFacilityWeather(request).then(weatherData => {

            let tableSettings = createHandsontableSettingsFromData(weatherData, 'weather-raw-data');
            dispatch({
                type: SET_WEATHER_HANDSONTABLE_SETTINGS_REDUX,
                payload: tableSettings
            });
            
            
            //Build chart
            let data = [];

            let dataTypes = [
                {
                    label: 'Temperature',
                    value: 'temperature',
                    unit: '°F',
                    axisLabel: 'Temperature (°F)'
                },
                {
                    label: 'Humidity',
                    value: 'humidity',
                    unit: '%',
                    axisLabel: 'Humidity (%)'
                },
                {
                    label: 'Dew Point',
                    value: 'dewpoint',
                    unit: '°F',
                    axisLabel: 'Dew Point (°F)'
                },
                {
                    label: 'Precipitation',
                    value: 'precipitation',
                    unit: ' mm',
                    axisLabel: 'Precipitation (mm)'
                },
                {
                    label: 'Wind Speed',
                    value: 'windSpeed',
                    unit: ' km/h',
                    axisLabel: 'Wind Speed (km/h)'
                },
                {
                    label: 'Pressure',
                    value: 'pressure',
                    unit: ' hPa',
                    axisLabel: 'Pressure (hPa)'
                },

            ];
            weatherData.forEach(row => {
                // looks like this time is working correctly
                let time = moment(row.weatherDateLocal).valueOf();
                data.push([time, row[selectedDataType]]);
            });

            const currentDataTypeObject = dataTypes.filter(x => x.value === selectedDataType)[0];

            let yAxis = {
                title: {
                    text: currentDataTypeObject.axisLabel
                }
            };

            let chartOptions = {
                chart: {
                    zoomType: 'x',
                    height: '600px'
                },
                title: {
                    text: ''
                },
                time: {
                    // useUTC: true
                    timezone: selectedFacility.timezone
                },
                subtitle: {
                    text: ''
                },
                tooltip: {
                    formatter: function() {
                        let time = moment(this.x).format('MM-DD-YYYY HH:mm');
                        return '<div>' + time + '</div><br/><div>' + this.points[0].series.name + ': ' + this.y.toFixed(2) + this.points[0].series.userOptions.suffix + '</div>'
                    }
                },
                // rangeSelector: {
                //     buttons: [{
                //         type: 'month',
                //         count: 2,
                //         text: '2m'
                //     }, {
                //         type: 'month',
                //         count: 3,
                //         text: '3m'
                //     }, {
                //         type: 'month',
                //         count: 6,
                //         text: '6m'
                //     }, {
                //         type: 'ytd',
                //         text: 'YTD'
                //     }, {
                //         type: 'year',
                //         count: 1,
                //         text: '1y'
                //     }, {
                //         type: 'all',
                //         text: 'All'
                //     }],
                //     buttonTheme: {
                //         'stroke': '#ff0000', // basic
                //         'stroke-width': 2, // hyphenated
                //         'rotation': 45 // custom
                //     }
                // },

                yAxis: yAxis,


                xAxis: {
                    type: 'datetime',
                    // categories: xAxisCategories
                },

                legend: {
                    layout: 'vertical',
                    align: 'right',
                    verticalAlign: 'middle'
                },

                series: [{
                    name: currentDataTypeObject.label,
                    data: data,
                    type: 'area',
                    suffix: currentDataTypeObject.unit,
                    negativeColor: Highcharts.getOptions().colors[0],
                    negativeFillColor: {
                        linearGradient: {
                            x1: 0,
                            y1: 1,
                            x2: 0,
                            y2: 0
                        },
                        stops: [
                            [0, Highcharts.Color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')],
                            [1, Highcharts.getOptions().colors[0]]
                        ]
                    },
                    fillColor: {
                        linearGradient: {
                            x1: 0,
                            y1: 0,
                            x2: 0,
                            y2: 1
                        },
                        stops: [
                            [0, Highcharts.getOptions().colors[0]],
                            [1, Highcharts.Color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
                        ]
                    },
                },
                ],
            };
            dispatch({
                type: SET_RAW_WEATHER_DATA_CHART_OPTIONS,
                payload: chartOptions
            });
        });

        
    }
}

const setDegreeDayChartToUpdate = (value) => {
    return {
        type: DEGREE_DAY_CHART_TO_UPDATE,
        payload: value
    };
};

const setWeatherHandsonableSettingsRedux = (tableSettings) => {
    return {
        type: SET_WEATHER_HANDSONTABLE_SETTINGS_REDUX,
        payload: tableSettings
    };
};

const clearDegreeDaysRedux = () => {
    return {
        type: CLEAR_DEGREE_DAYS_REDUX,
        payload: null
    };
}

const getDegreeDaysRedux = (selectedFacility, cddBase = 65, hddBase = 65) => {
    return (dispatch, getState) => {

        let request = getDefaultDegreeDayRequestObjectFromFacility(selectedFacility, cddBase, hddBase);

        const summaryChartsRequest = {...intervalDataRequestObject};
        summaryChartsRequest.ProjectId = selectedFacility.projectId;
        summaryChartsRequest.FacilityId = selectedFacility.facilityId;
        summaryChartsRequest.IncludePercent = false;
        summaryChartsRequest.DataType = 'kwh';
        summaryChartsRequest.SeriesType = ['Average'];
        summaryChartsRequest.SeriesPer = 'day';
        summaryChartsRequest.Months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
        summaryChartsRequest.DaysOfWeek = [1, 2, 3, 4, 5, 6, 7];
        getIntervalDataSummaryChartByFacilityId(summaryChartsRequest).then(summaryChartResponse => {

            getDegreeDays(request).then(degreeDayResponse => {
                // the first record is always funky, something Jordan said is on the backend
                degreeDayResponse.degreeDays.shift();
                //
                // let arr1 = degreeDayResponse.data.degreeDays;
                //
                //
                // let arr2 = x;

                let merged = [];

                for (let i = 0; i < degreeDayResponse.degreeDays.length; i++) {
                    merged.push({
                            ...degreeDayResponse.degreeDays[i],
                            ...(summaryChartResponse.find((itmInner) => itmInner.name === degreeDayResponse.degreeDays[i].name))
                        }
                    );
                }

                degreeDayResponse.degreeDays = merged;

                let degreeDays = new DegreeDaysClass(degreeDayResponse);
                // degreeDays.setSummaryChartData();

                dispatch({
                    type: GET_DEGREE_DAYS,
                    payload: degreeDays
                });
                dispatch({
                    type: DEGREE_DAY_CHART_TO_UPDATE,
                    payload: true
                });

                let getTableSettingsPromise = new Promise(function (resolve, reject) {
                    let tableSettings;
                    // tableSettings = createHandsontableSettingsFromData(response, 'weather-degree-days');
                    tableSettings = degreeDays.generateHandontableSettings();
                    if (tableSettings) {
                        resolve(tableSettings)
                    }
                });

                let getDegreeDayRegressionPromise = new Promise(function (resolve, reject) {
                    let regression = new RegressionClass(degreeDays.data);

                    // tableSettings = createHandsontableSettingsFromData(response, 'weather-degree-days');
                    // regression = regression.getRegression();
                    if (regression) {
                        resolve(regression)
                    }
                });

                getDegreeDayRegressionPromise.then(x => {
                    dispatch({
                        type: SET_REGRESSION,
                        payload: x
                    });
                    
                });


                getTableSettingsPromise.then(tableSettings => {
                    dispatch({
                        type: SET_WEATHER_HANDSONTABLE_SETTINGS_REDUX,
                        payload: tableSettings
                    });
                })


            })


        });


    }

};


export {
    setDegreeDayChartToUpdate,
    getDegreeDaysRedux,
    clearDegreeDaysRedux,
    setRawWeatherDataChartOptions,
    DEGREE_DAY_CHART_TO_UPDATE,
    GET_DEGREE_DAYS,
    SET_WEATHER_HANDSONTABLE_SETTINGS_REDUX,
    CLEAR_DEGREE_DAYS_REDUX,
    SET_REGRESSION,
    SET_RAW_WEATHER_DATA_CHART_OPTIONS
};

