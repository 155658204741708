import React, {Component, Fragment} from 'react';

//Redux
// Redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Redux Actions

import { selectFacility } from '../redux/actions/facility-actions'

// Moment
import moment from 'moment'

// Forms
import Step1 from './components/Step1'
import Step2 from './components/Step2'
import Step3UploadFile from './components/Step3UploadFile'

// Handsontable Settings Function
import getHandsontableSettings from './js/createImportDataHandsontable'

//Data Manipulators
import { formatHorizontalManualData, formatVerticalManualData } from './js/formatManualData'

// Upload Data Service
import { importIntervalDataJson, importIntervalDataJsonRequestObject } from '../services/intervalData.service'
import CustomDialog from "../components/Dialogs/customDialog";
import UploadingDataSpinner from "./components/spinner/UploadingDataSpinner";

//Components/Services
import ImportDataForm from './components/ImportDataForm'
import FileFormatHelp from "./components/formatDataHelp/fileFormatHelp";

import UploadingDataDialog from "./components/spinner/UploadingDataDialog";
import {makeHandsontableFromVerticalCsv, makeHandsontableFromHorizontalCsv} from './js/makeHandsontableFromCsv'

import {formatHorizontalCsvDataForSubmit, formatVerticalCsvDataForSubmit} from "./js/formatCsvDataFromTableForSubmit";
import CustomSnackbar from "../components/Snackbar/CustomSnackbar";
import Step3Manual from "./components/Step3Manual";
import {getFacilityById} from "../services/facility.service";



class ImportData extends Component {
    state = {
        facilityId: this.props.facilityId,
        projectId: this.props.projectId,
        activeStep: 0,
        importMethod: null,
        
        step2params: {
            radios: {
                dataType: {
                    name: 'dataType',
                    label: 'Data Type',
                    selected: 'kw',
                    options: [
                        {
                            value: 'kw',
                            label: 'kW'
                        },
                        {
                            value: 'kwh',
                            label: 'kWh'
                        }
                    ]
                },
                duration: {
                    name: 'duration',
                    label: 'Interval Duration',
                    selected: '15',
                    options: [
                        {
                            value: '15',
                            label: '15 Minutes'
                        },
                        {
                            value: '30',
                            label: '30 Minutes'
                        },
                        {
                            value: '60',
                            label: '1 Hour'
                        },
                    ]
                },
                orientation: {
                    name: 'orientation',
                    label: 'Orientation',
                    selected: 'horizontal',
                    options: [
                        {
                            value: 'horizontal',
                            label: 'Horizontal'
                        },
                        {
                            value: 'vertical',
                            label: 'Vertical'
                        },

                    ]
                },
            },
            dateRange: {
                startDate: {
                    name: 'startDate',
                    label: 'Start Date',
                    value: moment().subtract(1, 'year').hours(0).minutes(0).seconds(0).milliseconds(0),
                },
                endDate: {
                    name: 'endDate',
                    label: 'End Date',
                    value: moment().hours(0).minutes(0).seconds(0).milliseconds(0),
                },
            }      
        },
        errors: [],
        steps: ['Choose Import Method', 'Details', 'Upload Data'],
        handsontableSettings: null,
        csvData: {
            data: null,
            isError: null,
            errorMessages: [],
            isWarning: null,
            warningMessages: []
        },
        csvHandsontableSettings: null,
        dialogOpen: false,
        uploadingData: false,
        snackbar: {
            message: null,
            open: false,
            variant: null,
        }
    }
    
    toggleSnackbar = (value) => {
        let snackbar = {...this.state.snackbar};
        snackbar.open = value;
        this.setState({snackbar});
    }

    toggleDialog = () => {
        this.setState({dialogOpen: !this.state.dialogOpen})
    };
    
    clearFile = () => {
        this.setState({csvHandsontableSettings: null})
    };
    
    
        
    handleNext = (value) => {
        let errors = [];
        const { importMethod, activeStep, step2params, handsontableSettings } = this.state;
        switch (activeStep) {
            case 0:
                this.setState({ importMethod: value });
                break;
            case 1:
                if (importMethod === 'csv' || importMethod === 'excel') {
                    let step2paramsCopy = { ...step2params };
                    let keys = Object.keys(step2paramsCopy.radios);
                    keys.forEach(key => {
                        if (step2paramsCopy.radios[key].selected === null) {
                            errors.push(`error at ${key}`)
                        }
                    });
                    this.setState({errors})
                } 
                if (importMethod === 'manual') {
                    let handsontableSettings = getHandsontableSettings(step2params);
                    this.setState({ handsontableSettings });
                }
                
                break;
            case 2:
                this.setState({uploadingData: true});
                let duration = parseInt(this.state.step2params.radios.duration.selected);
                let dataType = this.state.step2params.radios.dataType.selected;
                const {timezone} = this.state.step2params;
                
                let request = importIntervalDataJsonRequestObject;
                request.Timezone = timezone;
                switch (importMethod) {
                    case 'manual':
                        let data = this.state.handsontableSettings.data;
                        let startDate = this.state.step2params.dateRange.startDate.value.clone();
                        let endDate = this.state.step2params.dateRange.endDate.value.clone();
                        
                        let arrayManual; 
                        switch (step2params.radios.orientation.selected) {
                            case 'horizontal':
                                arrayManual = formatHorizontalManualData(data, startDate, endDate, duration, handsontableSettings, timezone);
                                break;
                            case 'vertical':
                                arrayManual = formatVerticalManualData(data, startDate, endDate, duration, handsontableSettings, timezone);
                                break;
                        }
                        
                        request.ProjectId = this.state.projectId;
                        request.FacilityId = this.state.facilityId;
                        request.IntervalDuration = duration;
                        request.IntervalDataType = dataType;
                        request.Data = arrayManual;
                        this.uploadData(request);
                        // importIntervalDataJson(request).then(response => {
                        //     this.setState({uploadingData: false})
                        // });
                        break;
                    case 'csv':
                    case 'excel':

                        let arrayCsv;
                        switch (step2params.radios.orientation.selected) {
                            case 'horizontal':
                                arrayCsv = formatHorizontalCsvDataForSubmit(this.state.csvHandsontableSettings.data, duration, this.props.selectedFacility.timezone, this.state.csvHandsontableSettings.colHeaders);
                                break;
                            case 'vertical':
                                arrayCsv = formatVerticalCsvDataForSubmit(this.state.csvHandsontableSettings.data, this.props.selectedFacility.timezone);
                                break;
                        }
                        
                        request.ProjectId = this.state.projectId;
                        request.FacilityId = this.state.facilityId;
                        request.IntervalDuration = duration;
                        request.IntervalDataType = dataType;
                        request.Data = arrayCsv;
                        this.uploadData(request);
                        // importIntervalDataJson(request).then(response => {
                        //    
                        // })
                }
                break;
            
        }
        
        if (errors.length === 0) {
            let activeStep = this.state.activeStep;
            if (activeStep !== 2) {
                activeStep = activeStep + 1;
            }
            
            this.setState({ activeStep })
        } else {
            alert("FORM NOT FILLED")
        }
        
    };
    
    uploadData = (request) => {
        importIntervalDataJson(request).then(response => {
            let snackbar;
            if (response.success) {
                snackbar = {
                    open: true,
                    message: 'Successfully uploaded data',
                    variant: 'success'
                };
            } else {
                snackbar = {
                    open: true,
                    message: 'Something went wrong in the data upload',
                    variant: 'error'
                };
            }
            
            let facilityId = this.props.selectedFacility.facilityId;
            getFacilityById(facilityId).then(response => {
                this.props.selectFacility(response);
                if (this.props['onDataSubmissionSuccess']) {
                    this.props.onDataSubmissionSuccess();
                }
                this.setState({uploadingData: false, snackbar});
            });
            

        })
    }


    handleDateChange = (event, name) => {
        let step2params = { ...this.state.step2params };
        step2params.dateRange[name].value = event.hours(0).minutes(0).seconds(0).milliseconds(0);
        this.setState({ step2params })
    };

    handleTimeZoneChange = (event) => {
        let step2params = {...this.state.step2params};
        step2params.timezone = event.target.value;
        this.setState({step2params});
    };

    formatDataFromCSV = (data) => {
        let orientation = this.state.step2params.radios.orientation.selected;
        let dataType = this.state.step2params.radios.dataType.selected;
        let formattedData;
        let csvHandsontableSettings;
        switch (orientation) {
            case 'vertical':
                // formattedData = formatVerticalCsvData(data);
                csvHandsontableSettings = makeHandsontableFromVerticalCsv(data, dataType);
                break;
            case 'horizontal':
                // formattedData = formatHorizontalCsvData(data);
                csvHandsontableSettings = makeHandsontableFromHorizontalCsv(data, parseInt(this.state.step2params.radios.duration.selected));
                break
        };

        this.setState({csvData: formattedData, csvHandsontableSettings})
    }


    

    handleRadioChange = (name, value) => {
        let step2params = { ...this.state.step2params };
        step2params.radios[name].selected = value;
        this.setState({ step2params })

    }

    handleBack = () => {
        let activeStep = this.state.activeStep;
        activeStep--;
        this.setState({ activeStep });
    }

    handleReset = () => {
        this.setState({ activeStep: 0 });
    }

    getStepContent = () => {
        switch (this.state.activeStep) {
        case 0:
            return <Step1 handleClick={this.handleNext} />;
        case 1:
            return <Step2 
                importMethod={this.state.importMethod}
                step2params={this.state.step2params} 
                handleRadioChange={this.handleRadioChange} 
                handleDateChange={this.handleDateChange}
                handleTimeZoneChange={this.handleTimeZoneChange}
            />;
        case 2:
            switch (this.state.importMethod) {
                case 'csv':
                case 'excel':
                    switch (this.state.uploadingData) {
                        case false:
                            return <Step3UploadFile 
                                formatDataFromCSV={this.formatDataFromCSV} 
                                toggleDialog={this.toggleDialog}
                                csvHandsontableSettings={this.state.csvHandsontableSettings}
                                timezone={this.props.selectedFacility.timezone}
                                clearFile={this.clearFile}
                            />;
                        case true:
                            return <UploadingDataSpinner/>
                    }
                    
                case 'manual':
                    return (<Step3Manual 
                        handsontableSettings={this.state.handsontableSettings} 
                        orientation={this.state.step2params.radios.orientation.selected} 
                        duration={this.state.step2params.radios.duration.selected}
                        timezone={this.props.selectedFacility.timezone}
                    />);

            };
            break;
        default:
            return 'Unknown stepIndex';
        }
    }

    render() {
        const { activeStep, steps, importMethod, dataType, csvData } = this.state;
        const { handleNext, handleReset, handleBack, getStepContent } = this;

            return (
                <div style={{padding: 10, width: '100%'}}>

                        <ImportDataForm 
                            activeStep={activeStep} 
                            steps={steps} 
                            handleReset={handleReset} 
                            importMethod={importMethod} 
                            handleBack={handleBack} 
                            handleNext={handleNext} 
                            getStepContent={getStepContent} 
                            dataTypeObject={dataType}
                            csvData={csvData}
                            csvHandsontableSettings={this.state.csvHandsontableSettings}
                            
                        />
                        <CustomDialog
                            open={this.state.dialogOpen}
                            subTitle="Format Your Data"
                            content={<FileFormatHelp 
                                duration={this.state.step2params.radios.duration.selected}
                                orientation={this.state.step2params.radios.orientation.selected}
                                dataType={this.state.step2params.radios.dataType.selected}
                            />}
                            onClose={this.toggleDialog}
                        />
                        <UploadingDataDialog open={this.state.uploadingData} message={"Uploading your meter data and fetching temperature data..."}/>
                        <CustomSnackbar openSnackbar={this.toggleSnackbar} snackbar={this.state.snackbar}/>
                </div>
            );
        }
    
};

function mapStateToProps(state) {
    return {
        selectedFacility: state.selectedFacility,
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        selectFacility,
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportData)