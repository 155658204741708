import React, {Component} from 'react'
import ReactSVG from "react-svg";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {withStyles} from "@material-ui/core";
import CountUp from 'react-countup';

const styles = theme => ({
    card: {
        border: '2px solid #e0e0e0',
        borderRadius: 5,
        margin: theme.spacing(1)
    },
    textArea: {
        padding: theme.spacing(1),
        
    }
});

class EquivalentCard extends Component {
    
    state = {
        prevProps: {
            value: 0,
        },
    };
    
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.value !== this.state.prevProps.value) {
            this.setState({prevProps});
        }
        
    }

    render() {
        let {description, value, image, classes} = this.props;
        return (
            <Grid container style={{height: '100%'}}>
                <Grid item container className={classes.card}>
                    <Grid item xs={6}>
                        <ReactSVG src={image} style={{height: '80%', width: '80%'}}/>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container item justify="flex-end" className={classes.textArea}>
                            <CountUp
                                start={this.state.prevProps.value}
                                end={value}
                                duration={1}
                                separator=","
                                decimal="."
                                decimals={2}
                                useEasing={true}
                                style={{color: '#03a9f4', fontWeight: 600, fontSize: '1.5em'}}
                            />
                        </Grid>
                        <Grid item container className={classes.textArea}>
                            <Typography variant="body2">{description}</Typography>
                        </Grid>
                        
                        
                    </Grid>
                
                </Grid>
            </Grid>
        )
    }
}

export default withStyles(styles)(EquivalentCard);