import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Material UI
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import blue from '@material-ui/core/colors/blue';
import grey from '@material-ui/core/colors/grey';

// Logo
import reactlogo from "../../assets/img/ReactLogo_NoShadow.svg"

// Carousel
import LoginCarousel from './Carousel'

const styles = theme => ({
    main: {
        // width: 'auto',
        // display: 'block', // Fix IE 11 issue.
        // maxWidth: 400,
        // marginLeft: theme.spacing.unit * 3,
        // marginRight: theme.spacing.unit * 3,
        // [theme.breakpoints.up(800 + theme.spacing.unit * 3 * 2)]: {
        //     width: 800,
        //     // marginLeft: 'auto',
        //     // marginRight: 'auto',
        // },
    },
    paper: {
        marginTop: theme.spacing.unit * 8,
        marginRight: theme.spacing.unit * 1,
        marginLeft: theme.spacing.unit * 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        // padding: `${theme.spacing.unit * 1}px ${theme.spacing.unit * 1}px ${theme.spacing.unit * 1}px`,
        minHeight: 500,
        // maxWidth: 400
        
    },
    carousel: {
        // marginTop: theme.spacing.unit * 8,
        // display: 'flex',
        // flexDirection: 'column',
        // alignItems: 'center',
        // padding: `${theme.spacing.unit * 0}px ${theme.spacing.unit * 0}px ${theme.spacing.unit * 0}px`,
        // minHeight: 500,
        // maxWidth: 400,
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    avatar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing.unit,
    },
    submit: {
        marginTop: theme.spacing.unit * 3,
    },
    hidden:{
        display: 'none'
    },
    red:{
        color: 'red'
    },
    forgotPassword: {
        color: blue[700],
        '&:hover': {
            color: blue[900],
            textDecoration: 'underline',
            cursor: 'pointer'
        }
    }
});

function LogInTemplate(props) {
    const { classes,
        title,
        content,
        toggleViewText,
        toggleView
    } = props;
    //console.log(props);

    return (
        <main className={classes.main}>
            <CssBaseline />

            <Grid container xs alignItems="center" justify="center">
                
                    <Paper className={classes.paper}>
                        <Grid container >
                            <Grid item xs={12} lg={6} style={{maxWidth: 400, border: 'solid', borderWidth: '0 1px 0 0', borderColor: grey[300]}}>
                                <div style={{padding: 20, textAlign: 'center'}}>
                                    <img style={{width: 140, height: 140, margin:10}} src={reactlogo} alt="Logo" />
                                    <Typography component="h1" variant="h5">
                                        {title}
                                    </Typography>
                                    {content}
                                    <Grid container justify="center" style={{paddingTop: 10}}>
                                        <Typography className={classes.forgotPassword} onClick={toggleView}>{toggleViewText}</Typography>
                                    </Grid>
                                </div>
                                
                            </Grid>
                            <Grid item xs={0} lg={6}>
                                <div className={classes.carousel}>
                                    <LoginCarousel />
                                </div>
                                
                            </Grid>
                        </Grid>


                    </Paper>
                

            </Grid>

        </main>
    );
}

LogInTemplate.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LogInTemplate);