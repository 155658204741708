import React, {Component} from 'react'
import {Link} from "react-router-dom";
import AntTab from "../../../../../components/AntTabs/AntTab";
import AntTabs from "../../../../../components/AntTabs/AntTabs";

function MeterSettingsTabs(props) {
    const {selectedFacility} = props;
    const {projectId, facilityId} = selectedFacility;

    let tabs = [

        {
            label: 'General',
            link: `/${projectId}/${facilityId}/settings/general`,
            chart: 'general'
        },
        {
            label: 'Dates',
            link: `/${projectId}/${facilityId}/settings/dates`,
            chart: 'dates'
        },
    ];

    let selectedIndex;
    tabs.forEach((x, index) => {
        if (x.chart === props.selectedFacilitySubview) {
            selectedIndex = index;
        }
    });

    return (
        <AntTabs value={selectedIndex}>
            {tabs.map(tab => (
                <Link to={tab.link} style={{textDecoration: 'none'}}  >
                    <AntTab label={tab.label} style={{padding: 0}} />
                </Link>

            ))}
        </AntTabs>
    )

}

export default MeterSettingsTabs