import React, {Component} from 'react'
import Dialog from '@material-ui/core/Dialog'
import { withStyles } from '@material-ui/core/styles';
import {ScaleLoader} from "react-spinners";

import Grid from "@material-ui/core/Grid";

import sun from '../../assets/img/svg/sun-white.svg'
import buildings from '../../assets/img/svg/buildings.svg'
import meter from '../../assets/img/svg/meter.svg'
import moon from '../../assets/img/svg/moon.svg'
import ReactSVG from "react-svg";
import {Translate} from "@material-ui/icons";
import './style.css'
// import LoadingHighchart from "./highchart-loading";
import LoadingWeatherHighchart from "./highchart-loading";

import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import RegularButton from "../CustomButtons/Button";

// const useStyles = makeStyles(theme => ({
//     root: {
//         // backgroundColor: "transparent",
//     },
//
//     paper: {
//         backgroundColor: "transparent",
//         boxShadow: "none",
//         overflow: "hidden"
//     },
//     background: {
//         width: 600,
//         height: 400
//     }
//     // test: {
//     //     transform: 'translateX(200px)'
//     // }
// }));

const styles = theme => ({
    root: {
        // backgroundColor: "transparent",
    },

    paper: {
        backgroundColor: "transparent",
        boxShadow: "none",
        overflow: "hidden"
    },
    background: {
        width: 600,
        height: 400
    }
});

let array = [147.6,139,141,143,142,147,141,139,133,140,142,142,141,141,137,141,144,144,136,135,167,186,177,180,204,203,246,267,371,400,434,441,448,453,454,456,457,452,453,454,450,451,458,465,459,456,453,460,464,453,441,443,446,441,440,443,449,450,454,448,451,442,458,445,440,420,400,363,260,232,223,215,211,209,180,173,174,175,180,181,170,164,167,171,165,164,163,165,157,154,156,154,154,154,153,150];


class LoadingWeather extends Component {

    tick = () => {

        
        
        // debugger;
        // chartOptions.series[0].data = array;
        // this.setState(prevState => ({
        //     chartOptions: chartOptions
        // }));
        
    }
    
    //
    // afterChartCreated = (chart) => {
    //     debugger;
    //     this.internalChart = chart;
    // };
    //
    // componentDidMount = () => {
    //     // example of use
    //    
    //     debugger;
    //     this.internalChart.addSeries({ data: [1, 2, 3] })
    // }
    

    render() {
        const {classes} = this.props;
       let options = {
           chart: {
               backgroundColor: 'transparent'
           },
           title: {
               text: ''
           },

           subtitle: {
               text: ''
           },

           yAxis: {
               title: {
                   text: 'Demand',
               },
               minorGridLineWidth: 0,
               // lineColor: 'transparent',
               labels: {
                   enabled: false
               },
               // minorTickLength: 0,
               // tickLength: 0,
               gridLineColor: 'transparent',
           },

           xAxis: {
               accessibility: {
                   rangeDescription: 'Range: 2010 to 2017'
               },
               lineWidth: 0,
               minorGridLineWidth: 0,
               gridLineColor: 'transparent',
               labels: {
                   enabled: false
               },
               minorTickLength: 0,
               tickLength: 0
           },

           legend: {
               enabled: false,
           },
           tooltip: {
               enabled: false
           },
           exporting: {
               enabled: false
           },
           plotOptions: {
               series: {
                   animation: {
                       duration: 6000
                   },
                   pointStart: 2010
               }
           },

           series: [{
               name: 'Installation',
               color: 'rgb(250,250,250,.3)',
               data: [147.6,139,141,143,142,147,141,139,133,140,142,142,141,141,137,141,144,144,136,135,167,186,177,180,204,203,246,267,371,400,434,441,448,453,454,456,457,452,453,454,450,451,458,465,459,456,453,460,464,453,441,443,446,441,440,443,449,450,454,448,451,442,458,445,440,420,400,363,260,232,223,215,211,209,180,173,174,175,180,181,170,164,167,171,165,164,163,165,157,154,156,154,154,154,153,150]
           }],

           responsive: {
               rules: [{
                   condition: {
                       maxWidth: 500
                   },
                   chartOptions: {
                       legend: {
                           layout: 'horizontal',
                           align: 'center',
                           verticalAlign: 'bottom'
                       }
                   }
               }]
           }
       }

        // this.yourFunction();

        return (
            <Dialog
                open={this.props.open}
                aria-labelledby="simple-dialog-title"
                BackdropProps={{
                    classes: {
                        root: classes.root
                    }
                }
                }
                PaperProps ={{
                    classes: {
                        root: classes.paper
                    }
                }}
            >
                <Grid container alignItems="center" justify="center" className={classes.background}>
                    <Grid item xs={12}>
                        <Grid container alignItems="center" justify="center" className={"background"}>
                            {/*<div className="line" />*/}
                            <div className="meter-line" />
                            <div className="sun-wrapper">
                                <ReactSVG src={sun} className={"sun"}/>
                            </div>
                            <ReactSVG src={buildings} className={"buildings"}/>
                            <ReactSVG src={meter} className={"meter"} ref={"test"}/>
                            {/*<HighchartsReact*/}
                            {/*    highcharts={Highcharts}*/}
                            {/*    options={options}*/}
                            {/*    className={"chart"}*/}
                            {/*    callback={ this.afterChartCreated }*/}
                            {/*/>*/}
                            <LoadingWeatherHighchart />
                            {/*<LoadingHighchart />*/}
                        </Grid>
                    </Grid>
                    {/*<Grid item xs={12}>*/}
                    {/*    <Grid container alignItems="center" justify="center">*/}
                    {/*        <Typography >Loading Data</Typography>*/}
                    {/*    </Grid>*/}
                    {/*</Grid>*/}

                </Grid>

            </Dialog>
        )
    }

    
}

export default withStyles(styles)(LoadingWeather)