import {SET_CHART_OPTIONS, SET_PLOTLY_CHART_OPTIONS} from "../meter-charts-actions";
import GhgClass from "../../../js/CustomDataClasses/GhgClass";

const SET_EMISSIONS_OBJECT = 'SET_EMISSIONS_OBJECT';
const TOGGLE_EMISSIONS_INCLUDE_LOSSES = 'TOGGLE_EMISSIONS_INCLUDE_LOSSES';
const HANDLE_EMISSIONS_STATE_VS_REGION_CHANGE = 'HANDLE_EMISSIONS_STATE_VS_REGION_CHANGE';

const setEmissionsObject = () => {
    return (dispatch, getState) => {
        const {
            selectedFacility,
            emissionsStateVsRegion,
            emissionsIncludeLosses
        } = getState();

        let emissionsObject = new GhgClass(selectedFacility.postalCode, emissionsStateVsRegion, emissionsIncludeLosses);
        

        // clear chart options
        dispatch({
            type: SET_EMISSIONS_OBJECT,
            payload: emissionsObject
        });
    }
};

const toggleEmissionsIncludeLosses = (boolean) => {
    return {
        type: TOGGLE_EMISSIONS_INCLUDE_LOSSES,
        payload: boolean
    };
};

const handleEmissionsStateVsRegionChange = (string) => {
    return {
        type: HANDLE_EMISSIONS_STATE_VS_REGION_CHANGE,
        payload: string
    };
};

export {
    SET_EMISSIONS_OBJECT,
    TOGGLE_EMISSIONS_INCLUDE_LOSSES,
    HANDLE_EMISSIONS_STATE_VS_REGION_CHANGE,
    setEmissionsObject,
    toggleEmissionsIncludeLosses,
    handleEmissionsStateVsRegionChange
};
