import React from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import CustomOutlinedInput from "../../../components/Inputs/CustomOutlinedInput";

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    // textField: {
    //     width: '90%'
    // },
    dense: {
        marginTop: 19,
    },
    menu: {
        width: 200,
    },
}));

export default function NoteForm(props) {
    const classes = useStyles();
    const {formControls, handleChange, attemptedSubmit} = props;
    return (
        <Grid container style={{width: '100%'}}>
            <Grid item xs={12}>
                <CustomOutlinedInput
                    id="standard-multiline-flexible"
                    label={formControls.title.label}
                    fullWidth
                    name={formControls.title.name}
                    value={formControls.title.value}
                    onChange={handleChange}
                    className={classes.textField}
                    margin="normal"
                    placeHolder={formControls.title.placeHolder}
                    required={formControls.title.validationRules.isRequired}
                    error={attemptedSubmit && !formControls.title.valid}
                />
            </Grid>
            <Grid item xs={12}>
                <CustomOutlinedInput
                    id="standard-multiline-flexible"
                    name={formControls.notes.name}
                    label={formControls.notes.label}
                    multiline={true}
                    // rowsMax="4"
                    rows={10}
                    value={formControls.notes.value}
                    onChange={handleChange}
                    className={classes.textField}
                    placeHolder={formControls.notes.placeHolder}
                    required={formControls.notes.validationRules.isRequired}
                    error={attemptedSubmit && !formControls.notes.valid}
                />
            </Grid>
            
        </Grid>
        
    );
}