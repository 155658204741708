
import React, { Component, Fragment } from "react";

import { Grid, Paper } from "@material-ui/core";
import { withStyles } from '@material-ui/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

import { green } from '@material-ui/core/colors';



import { addUsersToState } from '../../redux/actions/user-actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';


import OrganizationSettingsHomeComponent from '../components/OrganizationSettingsHomeComponent'

import {
    getOrganizationUsers,
    getOrganization,
    createOrUpdateUserRequestObject,
    createOrUpdateUser,
    deleteUser
} from "../../services/admin.service"
import validate from '../../js/validate'
import CustomSnackbar from "../../components/Snackbar/CustomSnackbar";
import PhoneNumberTextMaskInput from "../../components/TextMasks/PhoneNumberTextMaskInput";


const styles = theme => ({
    paper: {
        margin: 20,
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        color: theme.palette.common.white,
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[600],
        }
    },
});

function getUserForm(type) {
    let form = {
        email: {
            label: 'Email',
            value: null,
            valid: false,
            touched: false,
            message: 'required',
            validationRules: {
                isRequired: true
            },
            textMask: null,
        },
        firstName: {
            label: 'First Name',
            value: null,
            valid: true,
            touched: false,
            message: '',
            validationRules: {

            },
            textMask: null,
        },
        lastName: {
            label: 'Last Name',
            value: null,
            valid: true,
            touched: false,
            message: '',
            validationRules: {

            },
            textMask: null,
        },
        phoneNumber: {
            label: 'Phone Number',
            value: null,
            valid: true,
            touched: false,
            message: '',
            validationRules: {

            },
            textMask: PhoneNumberTextMaskInput
        },
    }
    
    if (type === 'edit') {
        form.email.valid = true;
    }
    
    return form
    
}

class OrganizationSettingsContainer extends Component {

    state = {
        // users: null,
        organization: null,
        createUserFormIsValid: false,
        // attemptedCreateFacilitySubmit: false,
        createUserDialogOpen: false,
        createUserFormControls: getUserForm('create'),

        // Edit User
        editUserFormIsValid: true,
        attemptedEditUserSubmit: false,
        editUserDialogOpen: false,
        selectedUserForEdit: null,
        editUserFormControls: getUserForm('edit'),

        // Delete User
        selectedUserForDelete: null,
        deleteUserDialogOpen: false,
        
        //buy more users dialog
        buyMoreUsersDialog: false,
        

        snackbar: {
            open: false,
            message: '',
            varient: ''
        }

    }

    toggleEditOrganizationDialog = (project = null) => {
        let editProjectDialogOpen = !this.state.editProjectDialogOpen;
        this.setState({ editProjectDialogOpen, selectedProjectForEdit: project })
    }

    openSnackbar = (value) => {
        let snackbar = { ...this.state.snackbar }
        snackbar.open = value;
        this.setState({ snackbar })
    }
    
    toggleBuyMoreUsersDialog = () => {
        let buyMoreUsersDialog = !this.state.buyMoreUsersDialog;
        this.setState({ buyMoreUsersDialog })
    }

    toggleCreateUserDialog = () => {
        let userInfo = JSON.parse(window.sessionStorage.user);
        const {subscription} = userInfo;
        if (this.props.users.length >= subscription.quantity) {
            this.toggleBuyMoreUsersDialog();
        } else {
            let createUserDialogOpen = !this.state.createUserDialogOpen;
            this.setState({ createUserDialogOpen })
        }
        
    }

    toggleEditUserDialog = (user = null) => {

        if (user !== null) {


            // set the state to the project to edit
            let editUserFormControls = { ...this.state.editUserFormControls }
            editUserFormControls.email.value = user.email;
            editUserFormControls.firstName.value = user.firstName;
            editUserFormControls.lastName.value = user.lastName;
            editUserFormControls.phoneNumber.value = user.phoneNumber;

            this.setState({ editUserFormControls })
        }      

        let editUserDialogOpen = !this.state.editUserDialogOpen;
        this.setState({ editUserDialogOpen, selectedUserForEdit: user })
    }

    toggleDeleteUserDialog = (user = null) => {
        let deleteUserDialogOpen = !this.state.deleteUserDialogOpen;
        this.setState({ deleteUserDialogOpen, selectedUserForDelete: user })
    }

    onCreateUserSubmit = () => {    
        let submittedForm = { ...this.state.createUserFormControls };
        let keys = Object.keys(submittedForm);
        let finalSubmission = {};
        keys.forEach(key => { finalSubmission[key] = submittedForm[key].value });

        if (this.state.createUserFormIsValid) {
            let userRequest = {...createOrUpdateUserRequestObject};

            userRequest.OrganizationId = window.sessionStorage.user.organizationId;
            userRequest.Email = finalSubmission.email;
            userRequest.FirstName = finalSubmission.firstName;
            userRequest.LastName = finalSubmission.lastName;
            userRequest.PhoneNumber = finalSubmission.phoneNumber;

            createOrUpdateUser(userRequest).then((response)=>{
                if (response.isSuccess){
                    this.toggleCreateUserDialog();
                    this.setState({ attemptedCreateUserSubmit: false })

                    // set snackbar
                    let snackbar = { ...this.state.snackbar };
                    snackbar.message = response.successMessage ? response.successMessage : "Successfully Added User";
                    snackbar.open = true;
                    snackbar.variant = "success";
                    this.setState({ snackbar });

                    this.updateTable();
                }else{
                    // set snackbar
                    let snackbar = { ...this.state.snackbar };
                    snackbar.message = "Something went wrong. Please try again or contact React Energy Support.";
                    snackbar.open = true;
                    snackbar.variant = "error";
                    this.setState({ snackbar });
                }
            });
            
        } else {
            this.setState({ attemptedCreateUserSubmit: true })
        }
    }

    onEditUserSubmit = () => {
        let submittedForm = { ...this.state.editUserFormControls };
        let keys = Object.keys(submittedForm);
        let finalSubmission = {};
        keys.forEach(key => { finalSubmission[key] = submittedForm[key].value });
        
        if (this.state.editUserFormIsValid) {
            let userRequest = {...createOrUpdateUserRequestObject};

            userRequest.UserId = this.state.selectedUserForEdit.userId;
            userRequest.OrganizationId = this.state.selectedUserForEdit.organizationId;
            userRequest.Email = finalSubmission.email;
            userRequest.FirstName = finalSubmission.firstName;
            userRequest.LastName = finalSubmission.lastName;
            userRequest.PhoneNumber = finalSubmission.phoneNumber;

            createOrUpdateUser(userRequest).then((response)=>{
                if (response.isSuccess){
                    this.toggleEditUserDialog();
                    this.setState({ attemptedEditUserSubmit: false })

                    // set snackbar
                    let snackbar = { ...this.state.snackbar };
                    snackbar.message = response.successMessage ? response.successMessage : "Successfully Updated User";
                    snackbar.open = true;
                    snackbar.variant = "success";
                    this.setState({ snackbar });

                    this.updateTable();
                }else{
                    // set snackbar
                    let snackbar = { ...this.state.snackbar };
                    snackbar.message = "Something went wrong. Please try again or contact React Energy Support.";
                    snackbar.open = true;
                    snackbar.variant = "error";
                    this.setState({ snackbar });
                }
            });
            
        } else {
            this.setState({ attemptedEditUserSubmit: true })
        }
    }

    onDeleteUserSubmit = () => {
        const {selectedUserForDelete} = this.state;
        this.toggleDeleteUserDialog();
        // TODO Delete Project. projectId = this.state.selectedProjectForDelete.projectId
        deleteUser(selectedUserForDelete.userId).then(response => {
            if (response.isSuccess) {
                let snackbar = { ...this.state.snackbar };
                snackbar.message = `Succesfully deleted ${selectedUserForDelete.email}`;
                snackbar.open = true;
                snackbar.variant = "success";
                this.setState({ snackbar });
                this.updateTable();
            } else {
                let snackbar = { ...this.state.snackbar };
                snackbar.message = `Delete Unsuccessful`;
                snackbar.open = true;
                snackbar.variant = "error";
                this.setState({ snackbar });
            }
        });
        // set snackbar
        
    }


    handleCreateUserInputChange = (event) => {
        const value = event.target.value;
        const name = event.target.name;

        const updatedControls = { ...this.state.createUserFormControls };

        const updatedFormElement = { ...updatedControls[name] }

        updatedFormElement.value = value;
        updatedFormElement.touched = true;
        updatedFormElement.valid = validate(value, updatedFormElement.validationRules);

        updatedControls[name] = updatedFormElement;


        // Check if form is valid
        let formIsValid = true;
        for (let inputIdentifier in updatedControls) {
            formIsValid = updatedControls[inputIdentifier].valid && formIsValid;
        }

        this.setState({
            createUserFormControls: updatedControls,
            createUserFormIsValid: formIsValid
        })
    }

    handleEditUserInputChange = (event) => {
        const value = event.target.value;
        const name = event.target.name;
        const updatedControls = { ...this.state.editUserFormControls };

        const updatedFormElement = { ...updatedControls[name] }

        updatedFormElement.value = value;
        updatedFormElement.touched = true;
        updatedFormElement.valid = validate(value, updatedFormElement.validationRules);

        updatedControls[name] = updatedFormElement;


        // Check if form is valid
        let formIsValid = true;
        
        for (let inputIdentifier in updatedControls) {
            formIsValid = updatedControls[inputIdentifier].valid && formIsValid;
        }
        this.setState({
            editUserFormControls: updatedControls,
            editUserFormIsValid: formIsValid
        })
    }

    componentDidMount() {
        
        this.updateTable();
        getOrganizationUsers().then(response => {
            var users = response.users;
            // this.setState({users})
            this.props.addUsersToState(response.users)
        });
        getOrganization().then(response => {
            var organization = response.organization;
            this.setState({organization})
        });
    }
    
    updateTable = () => {
        getOrganizationUsers().then(response => {
            this.props.addUsersToState(response.users)
        });
    }

    render() {
        const { users, classes } = this.props;

        // let userInfo = JSON.parse(window.sessionStorage.user);
        // const {subscription} = userInfo;
        // if (users.length >= subscription.quantity) {
        //
        // }
        
        return (
            <div style={{width: '100%'}}>
                <OrganizationSettingsHomeComponent
                    users={users}
                    organization={this.state.organization}

                    // Create User
                    createUserFormIsValid={this.state.createUserFormIsValid}
                    createUserFormControls={this.state.createUserFormControls}
                    onCreateUserSubmit={this.onCreateUserSubmit}
                    createUserDialogOpen={this.state.createUserDialogOpen}
                    toggleCreateUserDialog={this.toggleCreateUserDialog}
                    attemptedCreateUserSubmit={this.state.attemptedCreateUserSubmit}
                    handleCreateUserInputChange={this.handleCreateUserInputChange}
                    
                    //Buy More Users
                    buyMoreUsersDialog={this.state.buyMoreUsersDialog}
                    toggleBuyMoreUsersDialog={this.toggleBuyMoreUsersDialog}

                    // Edit User
                    editUserFormIsValid={this.state.editUserFormIsValid}
                    attemptedEditUserSubmit={this.state.attemptedEditUserSubmit}
                    editUserDialogOpen={this.state.editUserDialogOpen}
                    selectedUserForEdit={this.state.selectedUserForEdit}
                    editUserFormControls={this.state.editUserFormControls}
                    toggleEditUserDialog={this.toggleEditUserDialog}
                    onEditUserSubmit={this.onEditUserSubmit}
                    handleEditUserInputChange={this.handleEditUserInputChange}

                    // Delete User
                    selectedUserForDelete={this.state.selectedUserForDelete}
                    deleteUserDialogOpen={this.state.deleteUserDialogOpen}
                    onDeleteUserSubmit={this.onDeleteUserSubmit}
                    toggleDeleteUserDialog={this.toggleDeleteUserDialog}

                    // Snackbar
                    snackbar={this.state.snackbar}
                    openSnackbar={this.openSnackbar}
                    
                    
                />
                <CustomSnackbar
                    snackbar={this.state.snackbar}
                    openSnackbar={this.openSnackbar}
                />
            </div>
        )




    }
}

function mapStateToProps(state) {
    return {
        users: state.users,
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ addUsersToState }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(OrganizationSettingsContainer));

