import React from 'react'
import eGridZipcodes from "../../../../assets/GhgJsons/eGridZipcodes";
import eGridSubregionTotalEmissions from "../../../../assets/GhgJsons/eGridSubregionTotalEmissions";
import eGridSubregionResourceMix from "../../../../assets/GhgJsons/eGridSubregionResourceMix";
import eGridStateEmissions from "../../../../assets/GhgJsons/eGridStateEmissions";
import eGridStateResourceMix from "../../../../assets/GhgJsons/eGridStateResourceMix";

function subRegionEmissionChartOptions(subRegionEmissions) {
    let formatted = formatSubRegionEmissions(subRegionEmissions);

    return (
        {
            chart: {
                type: 'column'
            },
            title: {
                text: 'Monthly Average Rainfall'
            },
            subtitle: {
                text: 'Source: WorldClimate.com'
            },
            xAxis: {},
            yAxis: {
                min: 0,
                title: {
                    text: 'Rainfall (mm)'
                }
            },
            tooltip: {
                headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                    '<td style="padding:0"><b>{point.y:.1f} mm</b></td></tr>',
                footerFormat: '</table>',
                // shared: true,
                useHTML: true
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            series: [{
                name: 'Tokyo',
                data: [49.9]

            }, {
                name: 'New York',
                data: [83.6]

            }, {
                name: 'London',
                data: [48.9]

            }, {
                name: 'Berlin',
                data: [42.4]

            }]
        }
    )
};

function formatSubRegionEmissions(subRegionEmissions) {
    let array = [
        {
            abbr: 'co2',
            label: <div>CO<sub>2</sub></div>,
            name: 'Carbon Dioxide',
            value: subRegionEmissions.co2,
        },
        {
            abbr: 'ch4',
            label: <div>CH<sub>4</sub></div>,
            name: 'Methane',
            value: subRegionEmissions.ch4,
        },
        {
            abbr: 'n2o',
            label: <div>N<sub>2</sub>0</div>,
            name: 'Nitrous Oxide',
            value: subRegionEmissions.n2o,
        },
        {
            abbr: 'co2e',
            label: <div>CO<sub>2</sub>E</div>,
            name: 'Carbon Dioxide Equivalent',
            value: subRegionEmissions.co2e,
        },
        {
            abbr: 'annualNox',
            label: <div>Annual NO<sub>x</sub></div>,
            name: 'Annual Oxides of Nitrogen',
            value: subRegionEmissions.annualNox,
        },
        {
            abbr: 'ozoneNox',
            label: <div>Ozone NO<sub>x</sub></div>,
            name: 'Ozone Oxides of Nitrogen',
            value: subRegionEmissions.ozoneNox,
        },
        {
            abbr: 'so2',
            label: <div>SO<sub>2</sub></div>,
            name: 'Sulfur Dioxide',
            value: subRegionEmissions.so2,
        },
    ];
    return {
        subRegion: subRegionEmissions.subRegion,
        subRegionName: subRegionEmissions.subRegionName,
        emissions: array
    }

};

function formatSubRegionResourceMix(formatSubRegionResourceMix) {
    let fossilFuels = [
        {
            name: 'Coal',
            value: formatSubRegionResourceMix.coal
        },
        {
            name: 'Oil',
            value: formatSubRegionResourceMix.oil
        },
        {
            name: 'Gas',
            value: formatSubRegionResourceMix.gas
        },
        {
            name: 'Nuclear',
            value: formatSubRegionResourceMix.nuclear
        },
        {
            name: 'Other Fossil Fuels',
            value: formatSubRegionResourceMix.otherFossil
        },

    ];
    let renewables = [

        {
            name: 'Hydro',
            value: formatSubRegionResourceMix.hydro
        },
        {
            name: 'Biomass',
            value: formatSubRegionResourceMix.biomass
        },
        {
            name: 'Wind',
            value: formatSubRegionResourceMix.wind
        },
        {
            name: 'Solar',
            value: formatSubRegionResourceMix.solar
        },
        {
            name: 'Geothermal',
            value: formatSubRegionResourceMix.solar
        },
        {
            name: 'Other Renewable',
            value: formatSubRegionResourceMix.other
        },
    ];

    return {
        subRegion: formatSubRegionResourceMix.subRegion,
        subRegionName: formatSubRegionResourceMix.subRegion,
        resourceMix: {
            fossilFuels,
            renewables,
        }
    }

};

function formatResourceMixForPieChart(resourceMix) {
    let fossilFuels = [
        {
            name: 'Coal',
            value: resourceMix.fossilFuels[0].value
        },
        {
            name: 'Oil',
            value: resourceMix.fossilFuels[1].value
        },
        {
            name: 'Gas',
            value: resourceMix.fossilFuels[2].value
        },
        {
            name: 'Nuclear',
            value: resourceMix.fossilFuels[3].value
        },
        {
            name: 'Other Fossil Fuels',
            value: resourceMix.fossilFuels[4].value
        },

    ];
    // let renewables = [
    //
    //     {
    //         name: 'Hydro',
    //         value: resourceMix.renewables[0].value
    //     },
    //     {
    //         name: 'Biomass',
    //         value: resourceMix.renewables[1].value
    //     },
    //     {
    //         name: 'Wind',
    //         value: resourceMix.renewables[2].value
    //     },
    //     {
    //         name: 'Solar',
    //         value: resourceMix.renewables[3].value
    //     },
    //     {
    //         name: 'Geothermal',
    //         value: resourceMix.renewables[4].value
    //     },
    //     {
    //         name: 'Other Renewable',
    //         value: resourceMix.renewables[5].value
    //     },
    // ];
    let renewables = [
        {
            name: 'Renewables',
            value: resourceMix.renewables[0].value + resourceMix.renewables[1].value + resourceMix.renewables[2].value + resourceMix.renewables[3].value + resourceMix.renewables[4].value + resourceMix.renewables[5].value
        }
    ]

    return fossilFuels.concat(renewables)


}

function getIndividualResourceMixPieChartOptions(resourceMix) {
    let temp = formatResourceMixForPieChart(resourceMix);
    let data = [];
    temp.forEach(x => data.push({name: x.name, y: x.value}));
    return {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie'
        },
        title: {
            text: ''
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                },
                // showInLegend: true
            }
        },
        series: [{
            name: 'Percentage',
            colorByPoint: true,
            data,
        }],
        credits: {
            enabled: false
        }
    }
}

function getLocationEmissionsAndResources(postalCode, stateOrRegion, includeLosses) {
    let location;
    let locationEmissionsTemp = null;
    let locationResourceMixTemp = null;
    if (postalCode) {
        location = eGridZipcodes.filter(x => x.zipcode.toString() === postalCode);

    }
    if (location.length === 0) {
        //TODO: throw an error. If it's 0 then the zipcode isn't real
    } else {
        location = location[0];
        switch (stateOrRegion) {
            case 'region':
                let temp = eGridSubregionTotalEmissions.filter(x => x.subRegion === location.subRegion1)[0];
                locationEmissionsTemp = {...temp};
                locationResourceMixTemp = eGridSubregionResourceMix.filter(x => x.subRegion === location.subRegion1)[0];
                break;
            case 'state':
                locationEmissionsTemp = eGridStateEmissions.filter(x => x.state === location.state)[0];
                locationResourceMixTemp = eGridStateResourceMix.filter(x => x.state === location.state)[0];
                break;
        }

    }

    if (stateOrRegion === 'region' && includeLosses) {
        let gridGrossLoss = 1 + locationEmissionsTemp.gridGrossLoss;
        locationEmissionsTemp.co2e *= gridGrossLoss;
        locationEmissionsTemp.co2 *= gridGrossLoss;
        locationEmissionsTemp.n2o *= gridGrossLoss;
        locationEmissionsTemp.ch4 *= gridGrossLoss;
        locationEmissionsTemp.annualNox *= gridGrossLoss;
        locationEmissionsTemp.ozoneNox *= gridGrossLoss;
        locationEmissionsTemp.so2 *= gridGrossLoss;

    }


    let locationEmissions = formatSubRegionEmissions(locationEmissionsTemp);
    let locationResourceMix = formatSubRegionResourceMix(locationResourceMixTemp);
    return {locationEmissions, locationResourceMix}
}

export {
    subRegionEmissionChartOptions,
    formatSubRegionEmissions,
    formatSubRegionResourceMix,
    getIndividualResourceMixPieChartOptions,
    getLocationEmissionsAndResources
}