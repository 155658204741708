import moment from 'moment'
import 'moment-timezone'

export default function createHandsontableSettingsFromData(data, view, dataType, intervalDuration, timezone) {
    let settings;
    switch (view) {
        case 'load-curve':
            settings = getLoadCurveHandsontable(data, intervalDuration);
            break;
        case 'summary-chart':
            settings = getSummaryChartHandsontable(data);
            break;
        case 'heat-map':
            settings = getHeatMapHandsontable(data, dataType);
            break;
        case 'zoomable-chart':
        case 'scatter-plot':
            settings = getZoomableChartHandsontable(data, timezone);
            break;
        case 'demand-duration':
            settings = getDemandDurationHandsontable(data, timezone);
            break;
        case 'weather-degree-days':
            settings = getDegreeDaysHandsontable(data);
            break;
        case 'weather-raw-data':
            settings = getRawWeatherDataHandsonTable(data);
            break;
        case 'regression':
            settings = getRegressionDataHandsonTable(data);
    }

    return settings
}

function getLoadCurveHandsontable(data, intervalDuration) {
    let colHeaders = [];
    let rowHeaders = [];
    let dataForTable = [];
    let columns = [];

    
    // let intervalDuration;
    //TODO this is bad code below, need to use the facility/meter property of interval duration
    let start;
    if (intervalDuration === 15) {
        // intervalDuration = 15;
        start = moment().hour(0).minute(15).second(0);
        for (let i = 0; i < 96; i++) {
            colHeaders.push(moment(start).add(15 * i, 'minutes').format("HH:mm"));
            columns.push({data: i, type: 'numeric'})
        }
    } else if (intervalDuration === 30) {
        intervalDuration = 30;
        start = moment().hour(0).minute(30).second(0);
        for (let i = 0; i < 48; i++) {
            colHeaders.push(moment(start).add(30 * i, 'minutes').format("HH:mm"));
            columns.push({data: i, type: 'numeric'})
        }
    } else if (intervalDuration === 60) {
        intervalDuration = 60;
        start = moment().hour(1).minute(0).second(0);
        for (let i = 0; i < 24; i++) {
            colHeaders.push(moment(start).add(i, 'hours').format("HH:mm"));
            columns.push({data: i, type: 'numeric'})
        }
    }

    data.forEach(series => {
        rowHeaders.push(series.name);
    });
    // debugger;
    for (let i = 0; i < data.length; i++) {
        dataForTable.push(Array(1440/intervalDuration).fill(0));
        //first time stamp
        let row = data[i];
        for (let j = 0; j < row.data.length; j++) {
                //find index
                let hours = Math.floor(row.data[j][0] / 60);
                let minutes = row.data[j][0] % 60;
                // Round it so the 95 index works. Technically the minutes re 59.999 for the last interval of the day which makes it index 94.9999 by calc if there's no rounding
                let index = Math.round((hours * 60/intervalDuration + minutes / intervalDuration) - 1);
                //rounds to 2 decimal places (toFixed makes it a string and that's no bueno)
                dataForTable[i][index] = (Math.round(row.data[j][1] * 100) / 100);

        }
    }

    return {
        colHeaders,
        rowHeaders,
        data: dataForTable,
        columns,
        width: 'auto',
        height: 500,
        rowHeaderWidth: 180,
        licenseKey: 'non-commercial-and-evaluation',
        stretchH: 'all',
        className: "htCenter",
    }    

}

function getSummaryChartHandsontable(data) {

    let colHeaders = ['Max kW', 'Average kW', 'Min kW', 'Max kWh', 'Average kWh', 'Min kWh', 'Total kWh', 'Load Factor'];
    let rowHeaders = [];
    let dataForTable = [];
    let columns = [];

    data.forEach(series => {
        rowHeaders.push(series.name);
        dataForTable.push([series.maxKw, Math.round(series.avgKw * 100) / 100, series.minKw, series.maxKwh, Math.round(series.avgKwh * 100) / 100, series.minKwh, series.sumKwh, series.loadFactor]);
    })

    colHeaders.forEach((header, index) => columns.push({ data: index, type: 'numeric' }))

    return {
        colHeaders,
        rowHeaders,
        data: dataForTable,
        columns,
        width: 'auto',
        height: 500,
        rowHeaderWidth: 180,
        licenseKey: 'non-commercial-and-evaluation',
        stretchH: 'all',
        className: "htCenter",
    }  
}

function getHeatMapHandsontable(data, dataType = "kw") {
    let colHeaders;
    if (dataType === 'kw') {
        colHeaders = ['kW']
    } else {
        colHeaders = ['kWh']
    }
    
    let rowHeaders = [];
    let dataForTable = [];
    let columns = [{data: 0, type: 'numeric'}]; 
    
    
    data.forEach(interval => {
        rowHeaders.push(moment.utc(interval[0]).add(interval[1], 'minutes').format('MM/DD/YYYY HH:mm'));
        dataForTable.push([interval[2]])
    });

    return {
        colHeaders,
        rowHeaders,
        data: dataForTable,
        columns,
        width: 'auto',
        height: 500,
        rowHeaderWidth: 180,
        licenseKey: 'non-commercial-and-evaluation',
        stretchH: 'all',
        className: "htCenter",
    }
}

function getZoomableChartHandsontable(data, timezone) {
    let colHeaders = ['kW', 'kWh', 'Temperature'];
    let rowHeaders = [];
    let dataForTable = [];
    let columns = [{data: 0, type: 'numeric'}, {data: 1, type: 'numeric'}, {data: 2, type: 'numeric'}];

    data.forEach(interval => {
        rowHeaders.push(moment.tz(interval.dateTimestamp, timezone).format('MM/DD/YYYY HH:mm'));
        dataForTable.push([interval.kW, interval.kWh, interval.temperature])
    });
    
    // debugger;

    return {
        colHeaders,
        rowHeaders,
        data: dataForTable,
        columns,
        width: 'auto',
        height: 500,
        rowHeaderWidth: 180,
        licenseKey: 'non-commercial-and-evaluation',
        stretchH: 'all',
        className: "htCenter",
    }
}

function getDemandDurationHandsontable(data, timezone) {
    let colHeaders = ['kW', 'kWh', 'Percent (%)'];
    let rowHeaders = [];
    let dataForTable = [];
    let columns = [{data: 0, type: 'numeric'}, {data: 1, type: 'numeric'}, {data: 2, type: 'numeric'}];

    data.forEach(interval => {
        rowHeaders.push(moment.tz(interval.dateTimestamp, timezone).format('MM/DD/YYYY HH:mm'));
        dataForTable.push([interval.kW, interval.kWh, (interval.percentOverFiltered).toFixed(4)])
    });

    // debugger;

    return {
        colHeaders,
        rowHeaders,
        data: dataForTable,
        columns,
        width: 'auto',
        height: 500,
        rowHeaderWidth: 180,
        licenseKey: 'non-commercial-and-evaluation',
        stretchH: 'all',
        className: "htCenter",
    }
}

function getRawWeatherDataHandsonTable(data) {
    let colHeaders = ['Temperature', 'Humidity', 'Dew Point', 'Precipitation', 'Wind Speed', 'Wind Direction', 'Pressure'];
    let rowHeaders = [];
    let dataForTable = [];
    let columns = [];

    data.forEach(row => {
        rowHeaders.push(moment(row.weatherDateLocal).format('MM/DD/YYYY HH:mm'));
        dataForTable.push([row.temperature, row.humidity, row.dewpoint, row.precipitation, row.windSpeed, row.windDirection, row.pressure])
        // in case there are null values check before toFixed()
    });

    colHeaders.forEach((header, index) => columns.push({ data: index, type: 'numeric' }));

    return {
        colHeaders,
        rowHeaders,
        data: dataForTable,
        columns,
        width: 'auto',
        height: 500,
        rowHeaderWidth: 180,
        licenseKey: 'non-commercial-and-evaluation',
        stretchH: 'all',
        className: "htCenter",
    }
}

function getDegreeDaysHandsontable(data) {

    const {heatingDegreeBase, coolingDegreeBase, degreeDays} = data;

    let colHeaders = ['HDD Base: ' + heatingDegreeBase + '°F', 'CDD Base: ' + coolingDegreeBase + '°F', 'Peak Demand (kW)', 'Usage (kWh)'];
    let rowHeaders = [];
    let dataForTable = [];
    let columns = [];

    degreeDays.forEach(day => {
        rowHeaders.push(day.name);
        // in case there are null values check before toFixed()
        dataForTable.push([
            day.heatingDegreeDays ? day.heatingDegreeDays.toFixed(2) : day.heatingDegreeDays, 
            day.coolingDegreeDays ? day.coolingDegreeDays.toFixed(2): day.coolingDegreeDays,
            day.maxKw ? day.maxKw : null,
            day.sumKwh ? day.sumKwh : null,
            
            ]);
    });

    colHeaders.forEach((header, index) => columns.push({ data: index, type: 'numeric' }));

    return {
        colHeaders,
        rowHeaders,
        data: dataForTable,
        columns,
        width: 'auto',
        height: 500,
        rowHeaderWidth: 180,
        licenseKey: 'non-commercial-and-evaluation',
        stretchH: 'all',
        className: "htCenter",
    }
}

function getRegressionDataHandsonTable(data) {

    let colHeaders = ['HDDs', 'CDDs', 'Consumption (kWh)', 'Prediction (kWh)', 'Variance (kWh)'];
    let rowHeaders = [];
    let dataForTable = [];
    let columns = [];

    data.forEach(day => {
        rowHeaders.push(day.name);
        // in case there are null values check before toFixed()
        dataForTable.push([
            day.heatingDegreeDays ? day.heatingDegreeDays : day.heatingDegreeDays, 
            day.coolingDegreeDays ? day.coolingDegreeDays : day.coolingDegreeDays,
            day.sumKwh ? day.sumKwh : null,
            day.prediction ? day.prediction : null,
            day.variance ? day.variance : null,
            
            ]);
    });

    colHeaders.forEach((header, index) => columns.push({ data: index, type: 'numeric' }));

    return {
        colHeaders,
        rowHeaders,
        data: dataForTable,
        columns,
        width: 'auto',
        height: 500,
        rowHeaderWidth: 180,
        licenseKey: 'non-commercial-and-evaluation',
        stretchH: 'all',
        className: "htCenter",
    }
}