import Slider from '@material-ui/core/Slider';
import {withStyles} from "@material-ui/core";
import orange from "@material-ui/core/colors/orange";

const CustomSlider = withStyles({
    root: {
        color: orange[800],
        height: 2,
        padding: '15px 0',
    },
    rail: {
        height: 2,
        opacity: 0.5,
        backgroundColor: '#bfbfbf',
    },
})(Slider);

export default CustomSlider