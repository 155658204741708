/* eslint-disable */
import React, { Component, Fragment } from "react";

import Highcharts from 'highcharts/highstock';

import highchartsExporting from "highcharts/modules/exporting";
import highchartsHeatmap from "highcharts/modules/heatmap";
import highchartsBoost from "highcharts/modules/boost";
// import highcharts3d from "highcharts-3d";

//Material UI
import { withStyles } from '@material-ui/core/styles';

//Colors 
import grey from '@material-ui/core/colors/grey';

import CustomTabsPaper from '../../../components/tabs/CustomTabsPaper'

//Views
import ChartsView from '../../components/Charts/ChartsView'
import TableView from '../../components/Charts/TableView'

highchartsHeatmap(Highcharts);
highchartsExporting(Highcharts);
highchartsBoost(Highcharts);
// highcharts3d(Highcharts);


let borderColor = grey[300];

const styles = theme => ({
    content: {
        flexGrow: 1,
        border: 'solid',
        borderColor: borderColor,
        borderWidth: 1,
        borderTop: 'none'
    },
    title: {
        backgroundColor: grey[200],
        padding: 5,
        paddingLeft: 20,
        paddingRight: 20,
        align: 'center',
        border: 'solid',
        //backgroundColor: grey[200],
        borderColor: borderColor,
        borderWidth: 1,
        borderTop: 'none',
        borderBottom: 'none',
        borderLeft: 'none',
    },
    chartTitleBar: {
        border: 'solid',
        borderColor: borderColor,
        borderWidth: 1,
    },
    chartSection: {
        border: 'solid',
        borderColor: borderColor,
        borderWidth: 1,
        borderTop: 'none',
        paddingTop: 10
    },
    chartTypeTab: {
        border: 'solid',
        borderColor: borderColor,
        borderWidth: 1,
        borderTop: 'none',
        borderBottom: 'none',
        borderRight: 'none',
        paddingLeft: 10,
        paddingRight: 10,
        height: '100%',
        color: grey[700],
        backgroundColor: grey[200],
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: grey[300],
        }
    },
    toggleContainer: {
        //margin: theme.spacing(2, 0),
    },
});

class MainChartContainer extends Component {
    getChartsView = () => {
        
        return <ChartsView
            chartOptions={this.props.chartOptions}
            selectedFacilityView={this.props.selectedFacilityView}
            selectedFacilitySubview={this.props.selectedFacilitySubview}
            handleChartOptionChange={this.props.handleChartOptionChange}
            dataFilterMessage={this.props.dataFilterMessage}
            selectedFacility={this.props.selectedFacility}

            toggleDurationView={this.props.toggleDurationView}
            toggleTemperatureView={this.props.toggleTemperatureView}
            toggleShowDatesAndRangesView={this.props.toggleShowDatesAndRangesView}
            durationView={this.props.durationView}
            temperatureView={this.props.temperatureView}
            showMeterDatesAndRanges={this.props.showMeterDatesAndRanges}
        />
    }
    
    state = {
        tabs: [
            {
                label: "Chart",
                value: "chart",
            },
            {
                label: "Table",
                value: 'table',
            }
        ],
        currentTab: 0,
        currentComponent: this.getChartsView()
    }
    
    

    setTab = (value) => {
        let currentComponent;
        switch (value) {
            case 0:
                currentComponent = this.getChartsView();
                break;
            case 1:
                currentComponent = <TableView chartTableSettings={this.props.chartTableSettings} exportDataAsCsv={this.props.exportDataAsCsv}/>
                break;
        }

        this.setState({ currentComponent, currentTab: value });

    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.chartOptions !== this.props.chartOptions || prevProps.durationView !== this.props.durationView) {
            let currentComponent = this.getChartsView();
            this.setState({ currentComponent, currentTab: 0 })
        }
    }


    render() {
        return (
            <Fragment>
                <CustomTabsPaper tabs={this.state.tabs} currentComponent={this.state.currentComponent} setTab={this.setTab} currentTab={this.state.currentTab}/>
            </Fragment>
        )
    }
    
}

export default withStyles(styles)(MainChartContainer)



