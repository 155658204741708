import React, {Component} from 'react'
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import Grid from "@material-ui/core/Grid";
import MeterSectionTitleBar from "../components/MeterSectionTitleBar";

import WeatherTabs from "./components/Tabs";
import DegreeDays from "./DegreeDays";
import WeatherStation from "./WeatherStation";
import RawWeatherData from "./RawWeatherData";
import GreenButton from "../../../components/CustomButtons/GreenButton";
import exportDataAsCsvFunciton from "../../js/exportDataAsCsv";
import CloudDownload from "@material-ui/icons/CloudDownload";
import {Typography} from "@material-ui/core";
import {degreeDaysHandsontableSettings} from "../../../redux/reducers/weather-reducers";

class Weather extends Component {

    getComponent = () => {
        const {selectedFacility, selectedFacilitySubview} = this.props;
        switch (selectedFacilitySubview) {
            case 'location':
                return <WeatherStation selectedFacility={selectedFacility}/>;
            case 'degree-days':
                return <DegreeDays/>;
            case 'raw-data':
                return <RawWeatherData selectedFacility={selectedFacility}/>;
        }
    }

    getRightContent = () => {
        switch (this.props.selectedFacilitySubview) {
            case 'degree-days':
            case 'raw-data':
                return (
                    <div style={{padding: 5}}>
                        <GreenButton disabled={!this.props.weatherHandsontableSettings} onClick={() => exportDataAsCsvFunciton(this.props.weatherHandsontableSettings)}>
                            <CloudDownload/>
                            <Typography style={{paddingLeft: 5}}>
                                Export Data
                            </Typography>

                        </GreenButton>
                    </div>
                )
        }
    }

    render() {
        const {selectedFacility, selectedFacilitySubview} = this.props;
        return (
            <Grid container>
                <MeterSectionTitleBar title={"Weather"} rightContent={this.getRightContent()}/>
                <div style={{width: '100%'}}>
                    <WeatherTabs selectedFacility={selectedFacility} selectedFacilitySubview={selectedFacilitySubview}/>
                </div>
                {this.getComponent()}
            </Grid>
        )
    }
}

function mapStateToProps(state) {
    return {
        selectedFacility: state.selectedFacility,
        selectedFacilitySubview: state.selectedFacilitySubview,
        weatherHandsontableSettings: state.weatherHandsontableSettings
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Weather);
