import React, {Fragment} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import grey from '@material-ui/core/colors/grey';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';

import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    appBar: {
        // backgroundColor: grey[300],
        backgroundColor: 'white',
        boxShadow: 'none',
        minWidth: 400,
        borderBottom: theme.palette.border.bottomGreyBorder
    },
    title: {
        color: 'black',
        flexGrow: 1,
        textTransform: 'none',
        fontWeight: 400
    },
    dialogContent: {
        display: 'flex',
        justifyContent: 'center'
    }

}));



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

function CustomDialog(props) {
    const { open, subTitle = null, title = null, onClose, content, actions = null, fullWidth = false } = props;
    const classes = useStyles();

    return (
        <div > 
                <Dialog 
                    open={open} 
                    onClose={onClose} 
                    fullWidth={fullWidth}
                    TransitionComponent={Transition}
                >
                    <AppBar position="static" className={classes.appBar}>
                        <Toolbar>
                            <Typography variant="h6" className={classes.title}>
                                {subTitle}
                            </Typography>
                            <Typography variant="h5" className={classes.title}>
                                {title}
                            </Typography>
                            <IconButton aria-label="close" onClick={onClose}>
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    
                    <DialogContent className={classes.dialogContent}>
                        <Grid container>
                            {content}
                        </Grid>
                               
                    </DialogContent>
                    <DialogActions>
                        {actions}
                    </DialogActions>
                </Dialog>
        </div>


    );
}

export default CustomDialog;