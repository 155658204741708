import React from 'react';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';

// DatePicker
import MomentUtils from '@date-io/moment';
import {
    DatePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";

// Moment
import moment from 'moment'

// Charts
import HighchartForCalendarChartMonthView from './HighchartForCalendarChartMonthView'
import HighchartForCalendarChartDayView from "./HighchartForCalendarChartDayView";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
    },
    table: {
        minWidth: 650,
    },
    tableCell: {
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#e6e6e6'
    },
    tableGreyCell: {
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#e6e6e6',
        backgroundColor: '#f2f2f2'
    },
    tableNoDataCell: {
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#e6e6e6',
        backgroundColor: '#fafafa'
    }
}));

function getHeaders() {
    return ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
}

function createCalendar(currentMoment, currentMonthData) {
    let month = currentMoment.month();
    let year = currentMoment.year();
    let firstDay = moment().date(1).month(month).year(year).hour(0).minutes(0).seconds(0);;
    let daysInMonth = 32 - new Date(year, month, 32).getDate();
    let firstDayNumber = firstDay.day();

    let array = [];
    let date = 1;
    let count = 1;
    for (let i = 0; i < 6; i++) {
        
        let week = [];
        for (let j = 0; j < 7; j++) {
            //let currentData = currentMonthData.dayData.find(x => x.date == date);
            let currentData = [];
            let finalSeries = [];
            let tempSeries = [];
            for (let i = 0; i < currentMonthData.dayData.length; i++) {
                if (currentMonthData.dayData[i].date === date) {
                    currentData = currentMonthData.dayData[i].data
                    
                    currentData.forEach(x => {
                        finalSeries.push([x.dateTimestamp, x.kW]);
                        tempSeries.push([x.dateTimestamp, x.temperature])
                    })
                }
            }
            if (i === 0 && j < firstDayNumber) {
                week.push({ date: 0, series: null })
            } else if (date > daysInMonth && j !== 0) {
                week.push({ date: 0, series: null })
            } else if (date > daysInMonth && j == 0) {
                break
            } else {
                week.push({ date, series: finalSeries, tempSeries })
                finalSeries = [];
                date++;
                count++;
            }
        }
        array.push(week)
    }

    return array;
    
}

function getDataPickerViews(currentView) {
    switch (currentView) {
        case 'month':
            return ["year", "month"]
        case 'day':
            return ["year", "month", "date"]
    }
}
function formatDateLabel(moment, view) {
    switch (view) {
        case 'month':
            return moment.format("MMMM YYYY")
        case 'day':
            return moment.format("ddd MMM, D YYYY")
    }
}

export default function CalendarChartTable(props) {
    const { 
        currentMoment, 
        handleNext, 
        handlePrevious, 
        handleDateChange, 
        changeViewToDay, 
        changeViewToMonth, 
        currentView, 
        dataStartDate, 
        dataEndDate, 
        currentMonthData, 
        peakDemand, 
        selectDate, 
        currentDayData,
        showPeakDemand,
        comparisonLoadCurveData,
        showComparisonLoadCurve,
        selectedFacility,
        temperatureView
    } = props;
    
    const classes = useStyles();
    let calendar = createCalendar(currentMoment, currentMonthData, temperatureView);
    let datePickerViews = getDataPickerViews(currentView);
    let headers = getHeaders();
    return (
        
        <div>
            <Toolbar>
                <Grid container spacing={24} justify="space-between">
                    <Grid item>
                        <ButtonGroup variant="contained" size="small" aria-label="Small contained button group" boxShadow={1}>
                            <Button onClick={handlePrevious}><KeyboardArrowLeft /></Button>
                            <Button onClick={handleNext}><KeyboardArrowRight /></Button>
                        </ButtonGroup>
                    </Grid>
                    <Grid item>
                        <MuiPickersUtilsProvider utils={MomentUtils} style={{ justify: 'center' }}>
                            <DatePicker 
                                value={currentMoment} 
                                // variant="inline" 
                                onChange={handleDateChange} 
                                views={datePickerViews} 
                                minDate={dataStartDate} 
                                maxDate={dataEndDate}
                                labelFunc={(moment) => formatDateLabel(moment, currentView)}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item>
                        <ButtonGroup variant="contained" size="small" aria-label="Small contained button group">
                            <Button onClick={changeViewToDay}>Day  </Button>
                            <Button onClick={changeViewToMonth}>Month</Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
            </Toolbar>
            {
                currentView === 'month' ?
                    <Grid container style={{ padding: 5 }}>
                        <Table className={classes.table}>
                            <colgroup>
                                {headers.map(header => <col style={{ width: "14.285%" }} />)}
                            </colgroup>
                            <TableHead>
                                <TableRow>
                                    {headers.map((header, index) => <TableCell className={classes.tableCell} key={index}>{header}</TableCell>)}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                
                                {calendar.map((week, index) => (
                                    <TableRow key={index}>
                                        {week.map((day) => {
                                            let isLastDay = false;
                                            if (day.date === currentMonthData.dayData[currentMonthData.dayData.length - 1].date) {
                                                isLastDay = true;
                                            }
                                            if (day.date === 0) {
                                                return <TableCell align="right" className={classes.tableGreyCell} onClick={e => console.log(e, day)}></TableCell> 
                                            } else if (day.series.length !== 0) {
                                                
                                                // when we get the day view set up add 'onClick={e => selectDate(e, day)}'
                                                return (
                                                <TableCell align="right" className={classes.tableCell} onClick={e => selectDate(e, day)} padding="none">
                                                    <span style={{paddingRight: 15}}>{day.date}</span>
                                                    <br />
                                                    <HighchartForCalendarChartMonthView 
                                                        series={day.series}
                                                        temperatureView={temperatureView}
                                                        tempSeries={day.tempSeries}
                                                        peakDemand={peakDemand} 
                                                        showPeakDemand={showPeakDemand}
                                                        comparisonLoadCurveData={comparisonLoadCurveData}
                                                        showComparisonLoadCurve={showComparisonLoadCurve}
                                                        isLastDay={isLastDay}
                                                        selectedFacility={selectedFacility}
                                                    />
                                                </TableCell>
                                                )
                                            } else {
                                                return <TableCell align="right" className={classes.tableNoDataCell} onClick={e => console.log(e, day)}>
                                                    <span style={{paddingRight: 15}}>{day.date}</span></TableCell>
                                            }
                                            
                                        })}
                                    </TableRow>
                                    
                                ))}
                                
                            </TableBody>
                        </Table>
                    </Grid>
                    :
                    currentDayData ? 
                        <HighchartForCalendarChartDayView 
                            series={currentDayData.data} 
                            peakDemand={peakDemand} 
                            showPeakDemand={showPeakDemand}
                            comparisonLoadCurveData={comparisonLoadCurveData}
                            showComparisonLoadCurve={showComparisonLoadCurve}
                            selectedFacility={selectedFacility}
                            temperatureView={temperatureView}
                        /> : "loading"
            }
        </div>

    );
}