// CardSection.js
import React from 'react';
import {CardCVCElement, CardElement, CardExpiryElement, CardNumberElement} from 'react-stripe-elements';

// Material UI
import InputLabel from "@material-ui/core/InputLabel";

import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import TextField from "@material-ui/core/TextField";
import {withStyles} from '@material-ui/core/styles';

const styles = theme => ({
    root: {
        // padding: 15
    },
    section: {
        padding: 5
    },
    elementContainer: {
        border: "1px solid #bdbdbd",
        borderRadius: 5,
        padding: 8,
        marginTop: 5
    },


});


class CardSection extends React.Component {
    render() {
        const {classes} = this.props;
        
        return (
            <Grid container className={classes.root}>
                <Grid item xs={12} className={classes.section}>
                    <InputLabel >
                        Card Number
                    </InputLabel>
                    <div className={classes.elementContainer}>
                            <CardNumberElement />
                    </div>
                </Grid>
                <Grid item xs={6} className={classes.section}>
                    <InputLabel>
                        Expires
                    </InputLabel>
                    <div className={classes.elementContainer}>
                        <CardExpiryElement />
                    </div>
                </Grid>
                <Grid item xs={6} className={classes.section}>
                    <InputLabel>
                        CVC
                    </InputLabel>
                    <div className={classes.elementContainer}>
                        <CardCVCElement />
                    </div>
                </Grid>
                <Grid item xs={8} className={classes.section}>
                    <InputLabel >
                        Name on Card
                    </InputLabel>
                    <div className={classes.elementContainer}>
                        <Input
                            placeholder="e.g. Jane H Smith"
                            disableUnderline={true}
                        />
                    </div>
                </Grid>
                <Grid item xs={4} className={classes.section}>
                    <InputLabel >
                        Zip Code
                    </InputLabel>
                    <div className={classes.elementContainer}>
                        <Input
                            placeholder="e.g. 77062"
                            disableUnderline={true}
                        />
                    </div>
                </Grid>
                
            </Grid>
        );
    }
}

export default withStyles(styles)(CardSection);