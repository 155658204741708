import createHandsontableSettingsFromData from "../createHandsontableSettingsFromData";
import React from 'react'
import regressionTest from "../../FacilityHome/components/Regression/regressionTest";

class RegressionClass {
    constructor(data) {
        // this.data = data;
        const {regression, daysArray} = this.getRegression(data);
        this.regression = regression;
        this.array = daysArray;
    };


    generateHandontableSettings = () => {
        return createHandsontableSettingsFromData(this.array, 'regression')
    };
    
    getRegression = (data) => {
        let degreeDayData = data.degreeDays;
        let inputArray = [];
        let outputArray = [];
        let daysArray = [];
        degreeDayData.forEach(day => {
            if (day.heatingDegreeDays !== null && day.coolingDegreeDays !== null && (day.sumKwh || day.sumKwh === 0) ) {
                daysArray.push({name: day.name, sumKwh: day.sumKwh, coolingDegreeDays: day.coolingDegreeDays, heatingDegreeDays: day.heatingDegreeDays});
                inputArray.push([day.coolingDegreeDays, day.heatingDegreeDays]);
                outputArray.push([day.sumKwh])
            }
        });
        let regression = regressionTest(inputArray, outputArray);
        inputArray.forEach((x, index) => {
            let prediction = regression.predict(x)[0];
            daysArray[index]['prediction'] = prediction;
            daysArray[index]['variance'] = daysArray[index].sumKwh - prediction;
            
        });

        return {regression, daysArray}
    };

    getSummaryChartOptions = () => {

        // let data = this.props.degreeDays.degreeDays;
        let dates = [];
        let prediction = [];
        let actual = [];
        let variance = [];
        this.array.forEach(interval => {
            dates.push(interval.name);
            actual.push(interval.sumKwh);
            prediction.push(interval.prediction);
            variance.push(interval.variance)
        });

        return {
            chart: {
                // type: 'column',
                zoomType: 'x',
                height: '500px',
                style: {
                    fontFamily: "Roboto"
                },
            },
            title: {
                // text: 'Degree Days'
                text: ''
            },
            subtitle: {
                // text: 'CDD Base: ' + coolingDegreeBase + '°F | HDD Base: ' + heatingDegreeBase + '°F'
                text: ''
            },
            xAxis: {
                categories: dates,
                crosshair: true
            },
            yAxis: [{
                    title: {
                        text: 'Consumption (kWh)'
                    },
                    opposite: true
                }],


            tooltip: {
                // headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                // pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                //     '<td style="padding:0"><b>{point.y:.1f} days</b></td></tr>',
                // footerFormat: '</table>',
                formatter: function () {
                    let temp = this;
                    // debugger;
                    let points = this.points;
                    let header = points[0].x;
                    let rows = [];
                    points.forEach(point => {
                        rows.push('<br/>' + point.series.userOptions.name + ': ' + '<span style="color:' + point.series.color + '; font-weight: 600">' + point.y.toFixed(2) + point.series.userOptions.suffix + '</span>')
                    });
                    let final = header;
                    rows.forEach(row => {
                        final += row;
                    })
                  
                    return final

                },
                shared: true,
                useHTML: true
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            series: [{
                name: 'Actual',
                data: actual,
                yAxis: 0,
                suffix: ' kWh'

            }, {
                name: 'Prediction',
                data: prediction,
                color: '#EF5350',
                yAxis: 0,
                suffix: ' kWh'

            }, {
                type: 'line',
                name: 'Variance',
                data: variance,
                color: '#4caf50',
                yAxis: 0,
                suffix: ' kWh'

            }],
            credits: {
                enabled: false
            }
        };
    }

}

export default RegressionClass;