import React, {Component} from 'react'
import eventsGif from '../../../../../../assets/gifs/events.gif'
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import GreenButton from "../../../../../../components/CustomButtons/GreenButton";

class NoDatesOrRanges extends Component {

    render() {

        return (
            <div style={{width: '100%'}}>
                <Grid container style={{display: 'flex', justifyItems: 'center'}}>
                    <Grid item xs={12}>
                        <Grid container style={{display: 'flex', justifyContent: 'center'}}>
                            <img src={eventsGif} alt={'time gif'}/>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container style={{display: 'flex', justifyContent: 'center'}}>
                            <Typography variant={"h5"}>No Events Yet</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container style={{display: 'flex', justifyContent: 'center'}}>
                            <Typography variant={"subtitle1"} style={{textTransform: 'none'}}>No events have been added yet.</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container style={{display: 'flex', justifyContent: 'center'}}>
                            <GreenButton variant={"contained"} color={"secondary"}>Create New Event</GreenButton>
                        </Grid>
                    </Grid>


                </Grid>
            </div>
            
        )
    }
}

export default NoDatesOrRanges