import React, { Component, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import EditUserForm from '../forms/EditUserForm';
import CustomDialog from "../../../components/Dialogs/customDialog";
import GreenButton from "../../../components/CustomButtons/GreenButton";

function EditUserDialog(props) {
    const {
        editUserDialogOpen,
        toggleEditUserDialog,
        editUserFormControls,
        handleEditUserInputChange,
        onEditUserSubmit,
        attemptedEditUserSubmit,
        selectedUserForEdit
    } = props;

    return (
        <Fragment>
            {selectedUserForEdit === null ? null :
                <CustomDialog
                    open={editUserDialogOpen}
                    onClose={toggleEditUserDialog}
                    title=""
                    subTitle="Update User"
                    content={<EditUserForm editUserFormControls={editUserFormControls} handleEditUserInputChange={handleEditUserInputChange} attemptedEditUserSubmit={attemptedEditUserSubmit} />}
                    actions={<Fragment><GreenButton type="button" onClick={() => onEditUserSubmit()}>Update User</GreenButton></Fragment>}
                />
            }
        </Fragment>


    );
}

export default EditUserDialog;