/* eslint-disable */
import React, {Component, Fragment} from "react";

// Routing
import PrivateRoute from "components/PrivateRoute";
import { Switch } from 'react-router-dom';

// Redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toggleFacilitySideBar, selectFacility } from '../redux/actions/facility-actions'
import { selectProject } from '../redux/actions/project-actions'
import {openSnackbar, setSnackbar} from "../redux/actions/HomeActions/home-actions";


// Containers
import DashboardContainer from '../Dashboard/containers/DashboardContainer.jsx';
import ProjectHomeContainer from '../ProjectHome/containers/ProjectHomeContainer.jsx';
import FacilityHomeContainer from '../FacilityHome';
import OrganizationSettingsContainer from '../OrganizationSettings/containers/OrganizationSettingsContainer';

//Components
import AccountHome from '../Account/Components/AccountHome';

// Components
import TopNavbar from '../components/Navbars/TopNavbar.jsx'
import Footer from '../components/Footer/Footer'
import TermsOfUse from '../Legal/TermsOfUse'


// Material UI
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme } from '@material-ui/core/styles';
import { MuiThemeProvider } from '@material-ui/core/styles'
import Fab from '@material-ui/core/Fab';

import blue from '@material-ui/core/colors/blue';
import orange from '@material-ui/core/colors/orange';
import grey from '@material-ui/core/colors/grey';
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import CustomDialog from "../components/Dialogs/customDialog";
import ReportBug from "../components/ReportBug";
import validate from "../js/validate";
import CustomSnackbar from "../components/Snackbar/CustomSnackbar";

//services
import {getUser, submitFeedback, refreshToken, logout} from '../services/user.service';
import {setActiveUser} from "../redux/actions/user-actions";
import {addOrganizationSubscription, changeTheme} from "../redux/actions/admin-actions";
import ActivateSubscription from "../Account/Components/Subscription/Activate Subscription";
import {getOrganizationSubscription} from "../services/admin.service";
import Privacy from "../Legal/Privacy";
import StillThereDialog from "../components/StillThereDialog";
import GreenButton from "../components/CustomButtons/GreenButton";

const theme2 = createMuiTheme({
    palette: {
        primary: {
            main: blue[500]
            // main: '#000147'
        },
        secondary: {
            main: orange[800]
        },
        background: {
            default: '#ffffff'
            // default: '#ffffff'
        },
        text: {
            primary: grey[800],
        }
    },
    status: {
        danger: 'orange',
    },
    typography: {
        // fontSize: 14,
    },
});

const theme = createMuiTheme({
    palette: {
        primary: {
            main: blue[500]
            // main: '#000147'
        },
        secondary: {
            main: orange[800],
            contrast: '#ffffff'
        },
        background: {
            default: '#ffffff',
            grey: grey[200],
            sidebar: grey[50],
            // default: '#ffffff'
            // default: '#ffffff'
        },
        warning: {
            light: '#ffb74d',
            main: '#ff9800',
            dark: '#f57c00',
            contrast: '#fff'
        },
        success: {
            light: '#81c784',
            main: '#4caf50',
            dark: '#388e3c',
            contrast: '#fff'
        },
        text: {
            primary: grey[800],
            formLabel: grey[600],
        },
        common: {
            black: "#000",
            white: "#fff",
        },
        appBar: {
            main: '#fff',
            contrast: grey[700],
            // main: '#673ab7',
            // contrast: '#fff',
        },
        subTitleBar: {
            main: '#f5f5f5',
        },
        border: {
          main: '#e8e8e8',
          bottomGreyBorder: '1px solid ' + grey[300],  
        },
        button: {
            green: {
                main: '#59C894',
                contrast: '#fff',
                hover: '#3bb37b'
            },
            disabled: grey[200]
        },
        icons: {
            grey: grey[400]
        }
        
    },
    status: {
        danger: 'orange',
    },
    typography: {
        // fontSize: 14,
    },
});

const theme3 = createMuiTheme({
    palette: {
        primary: {
            main: '#3c5574'
            // main: '#000147'
        },
        secondary: {
            main: '#c96500',
            contrast: '#ffffff'
        },
        background: {
            default: grey[50]
            // default: '#ffffff'
        },
        warning: {
            light: '#ffb74d',
            main: '#ff9800',
            dark: '#f57c00',
            contrast: '#fff'
        },
        success: {
            light: '#81c784',
            main: '#4caf50',
            dark: '#388e3c',
            contrast: '#fff'
        },
        text: {
            primary: grey[800],
        },
        common: {
            black: "#000",
            white: "#fff",
        },
        appBar: {
            main: '#3c5574',
            contrast: '#fff',
            // main: '#673ab7',
            // contrast: '#fff',
        }

    },
    status: {
        danger: 'orange',
    },
    typography: {
        // fontSize: 14,
    },
});

// burnt orange
const theme4 = createMuiTheme({
    palette: {
        primary: {
            main: '#c96500'
            // main: '#000147'
        },
        secondary: {
            main: '#3c5574',
            contrast: '#ffffff'
        },
        background: {
            default: grey[50]
            // default: '#ffffff'
        },
        warning: {
            light: '#ffb74d',
            main: '#ff9800',
            dark: '#f57c00',
            contrast: '#fff'
        },
        success: {
            light: '#81c784',
            main: '#4caf50',
            dark: '#388e3c',
            contrast: '#fff'
        },
        text: {
            primary: grey[800],
        },
        common: {
            black: "#000",
            white: "#fff",
        },
        appBar: {
            main: '#c96500',
            contrast: '#fff',
            // main: '#673ab7',
            // contrast: '#fff',
        }

    },
    status: {
        danger: 'orange',
    },
    typography: {
        // fontSize: 14,
    },
});

//Aggie Maroon
const theme5 = createMuiTheme({
    palette: {
        primary: {
            main: '#500000'
            // main: '#000147'
        },
        secondary: {
            main: '#3c5574',
            contrast: '#ffffff'
        },
        background: {
            default: grey[50]
            // default: '#ffffff'
        },
        warning: {
            light: '#ffb74d',
            main: '#ff9800',
            dark: '#f57c00',
            contrast: '#fff'
        },
        success: {
            light: '#81c784',
            main: '#4caf50',
            dark: '#388e3c',
            contrast: '#fff'
        },
        text: {
            primary: grey[800],
        },
        common: {
            black: "#000",
            white: "#fff",
        },
        appBar: {
            main: '#500000',
            contrast: '#fff',
            // main: '#673ab7',
            // contrast: '#fff',
        }

    },
    status: {
        danger: 'orange',
    },
    typography: {
        // fontSize: 14,
    },
});

const styles = theme => ({
    root: {
        display: 'flex',
        //backgroundColor: grey[50]
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(0),
        
    },
    toolbar: theme.mixins.toolbar,
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
});

const timoutWarningDelay = 30;
const timoutNowDelay = 1;

// Set timeout variables.
const timoutWarning = 1000 * 60 * timoutWarningDelay; // Display warning in 45 seconds.
const timoutNow = 1000 * 60 * timoutNowDelay; // Warning has been shown, give the user 20 seconds to interact

let warningTimer;
let timeoutTimer;
let timeoutWarningSecondsDisplayInterval;

class HomeFunc extends Component {
    
    state = {
        reportBug: {
            open: false,
            content: {
                subject: null,
                message: null,
            }
        },
        snackbar: {
            open: false,
            content: null,
            variant: null,
        },
        userSubscriptionIsActive: false,
        stillThereDialog: false,
        confirmedStillThere: false,
        secondsTillLogout: null,
        secondsLeft: timoutNow / 1000,
    };
    
    toggleStillThereDialog = ( value = null ) => {
        //
        // if (this.state.stillThereDialog){
        //     sessionStorage.setItem("LastUserFeedback", new Date());
        // }
        this.setState({stillThereDialog: !this.state.stillThereDialog});
    };
    
    confirmStillThere = () => {
        // this.setState({confirmedStillThere: true})
        sessionStorage.setItem("LastUserFeedback", new Date());
        this.resetTimeOutTimer();
        this.toggleStillThereDialog();
    };
    
    toggleReportBugDialog = () => {
        let reportBug = {...this.state.reportBug};
        reportBug.open = !reportBug.open;
        this.setState({reportBug})
    };
    
    toggleSnackbar = (value) => {
        this.props.openSnackbar(value);
    };

    handleInputChange = (event) => {
        const value = event.target.value;
        const name = event.target.name;
        
        let reportBug = {...this.state.reportBug};
        reportBug.content[name] = value;
        this.setState({reportBug})

    };
    
    onReportBugSubmit = () => {
        const {subject, message} = this.state.reportBug.content;
        let temp = {...this.state};
        
        //send to backend
        submitFeedback(subject, message).then(response => {
            //todo: do something on success/failure here
        });
        
        let snackbar = {
            open: true,
            message: 'Thanks for you feedback!',
            variant: 'success'
        };
        this.toggleReportBugDialog();        // this.setState({snackbar})
        this.props.setSnackbar(snackbar)

    };
    
    componentDidUpdate = (prevProps) => {
        const {selectFacility, selectProject, selectedFacility, selectedProject} = this.props;
        if (prevProps.match.params.projectId !== this.props.match.params.projectId) {

        }
        const path = this.props.location.pathname;
        
        let params = path.split('/');

        if (params[1].length === 0 && selectedProject !== null) {
            selectProject(null);
            selectFacility(null)
        }
        if (params.length < 3 && selectedFacility !== null) {
            selectFacility(null)
        }

        if (this.props.activeUser !== prevProps.activeUser) {
            let temp = this.props.activeUser;
            // debugger;

        }

    };
    
    componentDidMount = () => {
        this.props.setActiveUser();
        
        this.resetTimeOutTimer();
    };
    
    resetActiveUser = () => {
        this.props.setActiveUser();
    };
    
    refreshTokenTimer = () => {
        setInterval(function () {
            try{
                
                let lastUserFeedbackString = sessionStorage.getItem("LastUserFeedback");
                let lastUserFeedbackDateString = Date.parse(lastUserFeedbackString);
                let lastUserFeedbackDate = new Date(lastUserFeedbackDateString);

                let seconds = 1000 * 60; //60 seconds
                let compareDate = new Date(lastUserFeedbackDate.getTime() + seconds);

                let now = new Date();
                
                if (compareDate > now){
                    refreshToken();
                }
            }catch{
                //swallow
            }
        }, 1000 * 60); //60 seconds
    };
    
    startWarningTimer = () => {
        warningTimer = setTimeout(this.idleWarning, timoutWarning);
    };
    
    setUserLastClick = () => {
        // debugger;
        if (this.state.stillThereDialog) {
            this.toggleStillThereDialog();
            sessionStorage.setItem("LastUserFeedback", new Date());
        }
        
        this.resetTimeOutTimer();
    };

    resetTimeOutTimer = () => {
        clearInterval(timeoutWarningSecondsDisplayInterval);
        clearTimeout(timeoutTimer);
        clearTimeout(warningTimer);
        this.refreshTokenTimer();
        this.startWarningTimer();
        //$("#timeout").dialog('close');
    }
    
    setSecondsLeft = () => {
        let secondsLeft = this.state.secondsLeft - 1;
        this.setState({secondsLeft});
    }

    idleWarning = () => {
        clearTimeout(warningTimer);
        this.setState({secondsLeft: timoutNow / 1000});
        timeoutTimer = setTimeout(this.idleTimeout, timoutNow);

        timeoutWarningSecondsDisplayInterval = setInterval(this.setSecondsLeft, 1000);

        // let r = confirm("Are you still there?");
        
        this.setState({stillThereDialog: true, confirmedStillThere: false});

    }

    idleTimeout = () => {
        clearInterval(timeoutWarningSecondsDisplayInterval);
        sessionStorage.removeItem("user");
        window.location.reload();
    }
    
    render() {

        
        const {
            facilitySidebarOpen,
            toggleFacilitySideBar,
            selectedFacility,
            selectedProject,
            facilities,
            selectedFacilityView,
            activeUser,
            classes,
        } = this.props;

        if (this.props.activeUser) {
            //TODO: if !this.props.activeUser.subscription instead of "true"
            if (!this.props.activeUser.subscription) {
                return (
                    <MuiThemeProvider theme={theme2}>
                        <ActivateSubscription activeUser={this.props.activeUser} resetUserInfo={this.resetUserInfo}/>
                    </MuiThemeProvider>
                )
            } else {
                return (

                    <MuiThemeProvider theme={theme}>
                        <div className={classes.root} onClick={this.setUserLastClick}>
                            <TopNavbar
                                location={this.props.location}
                                match={this.props.match}
                                history={this.props.history}
                                facilitySidebarOpen={facilitySidebarOpen}
                                toggleFacilitySideBar={toggleFacilitySideBar}
                                selectedFacility={selectedFacility}
                                selectedProject={selectedProject}
                                facilities={facilities}
                                selectedFacilityView={selectedFacilityView}
                                activeUser={activeUser}
                            />
                            
                            <CssBaseline />

                            <main className={classes.content}>
                                <div className={classes.toolbar}  />
                                <div style={{minHeight: '90vh'}}>
                                    <Switch>
                                        <PrivateRoute path="/terms" component={TermsOfUse} />
                                        <PrivateRoute path="/privacy" component={Privacy} />
                                        <PrivateRoute path="/account/:component" component={AccountHome} />
                                        <PrivateRoute path="/account" component={AccountHome} />
                                        {/*<PrivateRoute path="/organization-settings" component={OrganizationSettingsContainer} />*/}
                                        <PrivateRoute path="/:projectId/:facilityId/:view/:subview?" component={FacilityHomeContainer}/>
                                        <PrivateRoute path="/:projectId/:facilityId" component={FacilityHomeContainer}/>
                                        <PrivateRoute path="/:projectId" component={ProjectHomeContainer}/>
                                        <PrivateRoute path="/" component={DashboardContainer}/>
                                    </Switch>
                                </div>
                                
                                {/*<div>*/}
                                {/*    <Footer />*/}
                                {/*</div>*/}

                            </main>

                        </div>
                        <Fab className={classes.fab} color="secondary" onClick={this.toggleReportBugDialog}>
                            <Icon>
                                message
                            </Icon>
                        </Fab>
                        
                        <StillThereDialog open={this.state.stillThereDialog} toggleStillThereDialog={this.toggleStillThereDialog} onSubmit={this.confirmStillThere} secondsLeft={this.state.secondsLeft}/>
                        
                        <CustomDialog
                            subTitle="Report Issue"
                            content={<ReportBug handleChange={this.handleInputChange} fields={this.state.reportBug.content}/>}
                            open={this.state.reportBug.open}
                            onClose={this.toggleReportBugDialog}
                            actions={<GreenButton onClick={this.onReportBugSubmit}>Submit</GreenButton>}
                        />
                        {/*<CustomSnackbar snackbar={this.state.snackbar} openSnackbar={this.toggleSnackbar} />*/}
                        <CustomSnackbar openSnackbar={this.toggleSnackbar} snackbar={this.props.snackbar}/>

                    </MuiThemeProvider>
                )
            }
        } else {
            return 'Loading'
        }
        
    }
    }
    

function mapStateToProps(state) {
    return {
        facilitySidebarOpen: state.facilitySidebarOpen,
        selectedFacility: state.selectedFacility,
        selectedProject: state.selectedProject,
        facilities: state.facilities,
        selectedFacilityView: state.selectedFacilityView,
        activeUser: state.activeUser,
        tempThemeColor: state.tempThemeColor,
        snackbar: state.snackbar,
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        toggleFacilitySideBar, 
        selectFacility, 
        selectProject, 
        setActiveUser, 
        addOrganizationSubscription, 
        changeTheme, 
        openSnackbar,
        setSnackbar
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(HomeFunc));

