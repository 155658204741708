import React, {Component} from 'react'

import {withStyles} from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Grid from '@material-ui/core/Grid';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import AccountActivationStep1 from "./Step1";
import AccountActivationStep2 from "./Step2";
import {
    createOrUpdateOrganizationSubscription, getActiveProductPlans,
    getOrganizationPaymentMethods
} from "../../../../../services/admin.service";
import AccountActivationStep4 from "./Step4";
import AccountActivationStep3 from "./Step3";
import CustomSnackbar from "../../../../../components/Snackbar/CustomSnackbar";
import {ScaleLoader} from "react-spinners";
import Dialog from '@material-ui/core/Dialog';

const styles = theme => ({
    root: {
        width: '100%',
    },
    paper: {
        backgroundColor: "transparent",
        boxShadow: "none",
        overflow: "hidden"
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
    stepper: {
        backgroundColor: 'transparent',
        // maxWidth: 1200,    
        padding: '0 30px',
    }

});

class AccountActivationStepper extends Component {

    state = {
        activeStep: 0,
        quantity: 1,
        fetchingPaymentMethod: false,
        agreeToTerms: false,
        subscriptionDuration: 'month',
        organizationPaymentMethod: null,
        paymentTotal: 40,
        submittingActivation: false,
        steps: [
            {
                label: 'Select number of Users',
            },
            {
                label: 'Enter Payment Method',
            },
            {
                label: 'Terms',
            },
            {
                label: 'Activate',
            },

        ],
        snackbar: {
            message: null,
            open: false,
            variant: null
        },
    };

    handleSubscriptionDurationChange = (value) => {
        if (value === 'month' || value === 'year') {
            this.setState({subscriptionDuration: value}, () => this.calculatePayment());

        }
    }

    handleNext = () => {
        if (this.state.activeStep + 1 < this.state.steps.length) {
            this.setState({activeStep: this.state.activeStep + 1})
        }

    };

    calculatePayment = () => {
        let costPerUser;
        if (this.state.subscriptionDuration === 'month') {
            costPerUser = 39.99
        } else if (this.state.subscriptionDuration === 'year') {
            costPerUser = 439.89
        }
        let paymentTotal = this.state.quantity * costPerUser;
        this.setState({paymentTotal})
    };

    openSnackbar = (value) => {
        let snackbar = {...this.state.snackbar}
        snackbar.open = value;
        this.setState({snackbar})
    }

    handleBack = () => {
        if (this.state.activeStep - 1 >= 0) {
            this.setState({activeStep: this.state.activeStep - 1})
        }

    };

    setAgreeToTerms = (value) => {
        this.setState({agreeToTerms: value})
    }

    handleUserCountChange = (value) => {
        if (value > 0) {
            this.setState({quantity: value}, () => this.calculatePayment())

        }


    };

    getPaymentMethod = () => {
        this.setState({fetchingPaymentMethod: true});
        getOrganizationPaymentMethods().then(x => {
            if (x.length > 0) {
                this.setState({organizationPaymentMethod: x[0].card});
                this.setState({organizationPaymentMethodId: x[0].paymentMethodId});
            } else if (x.length === 0) {
                this.setState({organizationPaymentMethod: null})
            }
            this.setState({fetchingPaymentMethod: false, activeStep: this.state.activeStep + 1});
        });
    };


    // TODO: remove this "wait" funciton
    wait = (ms) => {
        let start = new Date().getTime();
        let end = start;
        while (end < start + ms) {
            end = new Date().getTime();
        }
    }

    onActivateSubmit = () => {
        this.setState({submittingActivation: true});
        createOrUpdateOrganizationSubscription(this.state.subscriptionDuration, this.state.quantity).then(x => {
            let snackbar;
            if (x.isSuccess) {
                snackbar = {...this.state.snackbar};
                snackbar.open = true;
                snackbar.message = "Passwords did not match. Try again.";
                snackbar.variant = "error";
            } else {
                snackbar = {...this.state.snackbar};
                snackbar.open = true;
                snackbar.message = "Something went wrong. Please reach out to React Energy support";
                snackbar.variant = "error";
            }
            this.setState({snackbar})
        });
        // let snackbar = {...this.state.snackbar};
        // snackbar.open = true;
        // snackbar.message = "Something went wrong. Please reach out to React Energy support";
        // snackbar.variant = "error";
        // this.setState({snackbar});

        this.props.resetUserInfo();
        this.setState({submittingActivation: false})

    }

    getComponent = () => {
        switch (this.state.activeStep) {
            case 0:
                return <AccountActivationStep1 quantity={this.state.quantity}
                                               handleUserCountChange={this.handleUserCountChange}/>
                break;
            case 1:
                return <AccountActivationStep2 onSubmit={this.getPaymentMethod}
                                               organizationPaymentMethod={this.state.organizationPaymentMethod}/>;
                break;
            case 2:
                return <AccountActivationStep3 agreeToTerms={this.state.agreeToTerms}
                                               setAgreeToTerms={this.setAgreeToTerms} handleNext={this.handleNext}/>;
                break;
            case 3:
                return <AccountActivationStep4
                    organizationPaymentMethod={this.state.organizationPaymentMethod}
                    quantity={this.state.quantity}
                    onActivateSubmit={this.onActivateSubmit}
                    subscriptionDuration={this.state.subscriptionDuration}
                    handleSubscriptionDurationChange={this.handleSubscriptionDurationChange}
                    paymentTotal={this.state.paymentTotal}
                    submittingActivation={this.state.submittingActivation}
                />
        }
    };

    componentDidMount = () => {
        getActiveProductPlans().then(x => {
            // debugger;
        })
    };

    render() {
        const {classes} = this.props;
        if (this.state.submittingActivation) {
            return (
                <Dialog
                    open={this.state.submittingActivation}
                    PaperProps={{
                        classes: {
                            root: classes.paper
                        }
                    }}
                >
                    <Grid container
                          style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

                        <div style={{display: 'block'}}>
                            <Typography style={{color: 'white'}}>Activating your account</Typography>
                            <div style={{width: '100%', display: 'flex', justifyContent: 'center', paddingTop: 10}}>
                                <ScaleLoader color={'white'}/>
                            </div>

                        </div>


                    </Grid>
                </Dialog>
            )
        } else {
            return (
                <div className={classes.root}>


                    <Grid container style={{display: 'flex', justifyContent: 'center', padding: '20px 0'}}>
                        <Typography variant={'h5'}>React Energy: Account Activation</Typography>
                    </Grid>
                    <CustomSnackbar snackbar={this.state.snackbar} openSnackbar={this.openSnackbar}/>

                    <Stepper activeStep={this.state.activeStep} className={classes.stepper}>

                        {this.state.steps.map((step, index) => (
                            <Step key={step.label}>
                                <StepLabel>{step.label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <div style={{padding: 15, minHeight: '50vh', display: 'flex', alignItems: 'center'}}>
                        {this.getComponent()}
                    </div>
                    <div>
                        <Button
                            disabled={this.state.activeStep === 0}
                            onClick={this.handleBack}
                            className={classes.button}
                        >
                            Back
                        </Button>
                        {this.state.activeStep !== this.state.steps.length - 1 ?
                            <Button
                                variant={'contained'}
                                color={'primary'}
                                onClick={this.handleNext}
                                className={classes.button}
                                //TODO: uncomment the line below
                                // disabled={
                                //     (this.state.activeStep === 1 && this.state.organizationPaymentMethod === null) 
                                //     ||
                                //     (this.state.activeStep === 2 && this.state.agreeToTerms === false)
                                // }
                            >
                                {this.state.activeStep === this.state.steps.length - 1 ? 'Activate' : 'Next'}
                            </Button> : null}
                    </div>
                </div>
            )
        }

    }
}

export default withStyles(styles)(AccountActivationStepper)