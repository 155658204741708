import React, { Component, Fragment } from "react";

// Redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// import { selectUser } from '../../redux/actions/user-actions';

//Material UI
import MaterialTable from 'material-table'


// Components
import LinkText from '../../components/Link/LinkText';
import EditDeleteAddIcons from '../components/buttons/FacilityEditDeleteAddIcons'





class UsersTable extends Component {
    
    state = {
        
    };

    render() {

        
        

        const { toggleEditUserDialog, toggleDeleteUserDialog } = this.props;


        return (
            <div>

                <div style={{ maxWidth: '100%' }}>
                    <MaterialTable
                        columns={[
                            {
                                title: 'Last Name',
                                field: 'lastName'
                            },
                            { title: 'First Name', field: 'firstName' },
                            { title: 'Email', field: 'email'},
                            {
                                title: 'Edit', render: rowData => <EditDeleteAddIcons userObject={rowData} toggleEditUserDialog={toggleEditUserDialog} toggleDeleteUserDialog={toggleDeleteUserDialog} />
                            },


                        ]}
                        data={this.props.users}
                        title=""
                        style={{ boxShadow: 'none' }}
                        options={{
                            search: true,
                            pageSize: 10,
                            padding: 'dense',

                        }}
                    />
                </div>
            </div>




        );

    }
}

function mapStateToProps(state) {
    return {
        users: state.users,
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersTable);

