import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Material UI
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import blue from '@material-ui/core/colors/blue';
import grey from '@material-ui/core/colors/grey';

// Services
import { login } from "../services/user.service";

// Logo
import reactlogo from "../assets/img/ReactLogo_NoShadow.svg"

// Carousel
import LoginCarousel from './components/Carousel'
import LogInTemplate from "./components/loginTemplate";
import CustomPulseLoader from "../components/Spinners/pulse-loader";

const styles = theme => ({
    main: {
        // width: 'auto',
        // display: 'block', // Fix IE 11 issue.
        // maxWidth: 400,
        // marginLeft: theme.spacing.unit * 3,
        // marginRight: theme.spacing.unit * 3,
        // [theme.breakpoints.up(800 + theme.spacing.unit * 3 * 2)]: {
        //     width: 800,
        //     // marginLeft: 'auto',
        //     // marginRight: 'auto',
        // },
    },
    paper: {
        marginTop: theme.spacing.unit * 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
        minHeight: 500,
        maxWidth: 400
    },
    carousel: {
        marginTop: theme.spacing.unit * 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 0}px ${theme.spacing.unit * 0}px ${theme.spacing.unit * 0}px`,
        minHeight: 500,
        maxWidth: 400
    },
    avatar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing.unit,
    },
    submit: {
        marginTop: theme.spacing.unit * 3,
    },
    hidden:{
        display: 'none'
    },
    red:{
        color: 'red'
    },
    forgotPassword: {
        color: blue[700],
        '&:hover': {
            color: blue[900],
            textDecoration: 'underline',
            cursor: 'pointer'
        }
    },
    message: {
        color: grey[700],
        margin: 10,
        textAlign: 'center'
    }
});

function LogIn(props) {
    const { classes,
        onFormSubmit,
        form,
        handleChange,
        currentView,
        toggleView,
        onNewPasswordSubmit, 
        submitting
    } = props;
    //console.log(props);
    
    if (currentView === 'forgotPassword') {
        return (

            <LogInTemplate
                title="Forgot Password"
                toggleView={() => toggleView('logIn')}
                content={<form className={classes.form}>
                    <Typography className={classes.message}>
                        If you have forgotten your password, enter the email address associated with your account and we will email you a new temporary password for you account. 
                        <br/> 
                        Once you log in you can reset your password on the account page.
                    </Typography>
                    {/*<FormLabel className={form.error === "" ? 'hidden' : classes.red}>{form.error}</FormLabel>*/}
                    <FormControl margin="normal" required fullWidth>
                        <InputLabel htmlFor="email">Email Address</InputLabel>
                        <Input id="email" name="email" autoComplete="email" autoFocus value={form.email} onChange={(e) => handleChange(e)} />
                    </FormControl>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        style={{backgroundColor: "#0D5891", color: "white"}}
                        className={classes.submit}
                        onClick={onNewPasswordSubmit}
                        disabled={submitting}
                    >
                        {submitting ? <CustomPulseLoader /> : 'Send Reset'}
                    </Button>
                </form>}
                toggleViewText="Back to Sign In"
            />)
    } else if (currentView === 'emailSent') {
        return (
            <LogInTemplate
                title="Email Sent"
                toggleView={() => toggleView('logIn')}
                content={<form className={classes.form}>
                    <Typography className={classes.message}>
                        If this email is in our system, an email with password reset instructions has been sent to the address provided.
                    </Typography>
                    <br />
                    <Typography className={classes.message}>
                        If you run into issues with the password reset, please reach out to us at Support@ReactEnergy.io.
                    </Typography>
                </form>}
                toggleViewText="Back to Login"
            />
        )
    } else {
        return (

            <LogInTemplate
                title="Sign In"
                toggleView={() => toggleView('forgotPassword')}
                content={<form className={classes.form} onSubmit={onFormSubmit}>
                    <FormLabel className={form.error === "" ? 'hidden' : classes.red}>{form.error}</FormLabel>
                    <FormControl margin="normal" required fullWidth>
                        <InputLabel htmlFor="email">Email Address</InputLabel>
                        <Input id="email" name="email" autoComplete="email" autoFocus value={form.email} onChange={(e) => handleChange(e)} />
                    </FormControl>
                    <FormControl margin="normal" required fullWidth>
                        <InputLabel htmlFor="password">Password</InputLabel>
                        <Input name="password" type="password" id="password" autoComplete="current-password"  value={form.password} onChange={(e) => handleChange(e)}/>
                    </FormControl>
                    {/*<FormControlLabel*/}
                    {/*    control={<Checkbox value="remember" color="primary" />}*/}
                    {/*    label="Remember me"*/}
                    {/*/>*/}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        style={{backgroundColor: "#0D5891", color: "white"}}
                        className={classes.submit}
                        onClick={onFormSubmit}
                        disabled={submitting}
                    >
                        {submitting ? <CustomPulseLoader color={'#ffffff'}/> : 'Sign In'}
                    </Button>
                </form>}
                toggleViewText="Forgot Password?"
            />)
    }
    
}

LogIn.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LogIn);