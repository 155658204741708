import { combineReducers } from 'redux';

import { selectedProject, facilitiesOfProject, toggleDeleteProjectDialog, toggleEditProjectDialog, fetchingFacilities } from './project-reducers';
import { 
    selectFacility, 
    toggleImportDataDialog, 
    facilityView,
    facilitySubview,
    toggleFacilitySidebar,
} from './facility-reducers'

import {
    rawIntervalData,
    intervalDataForZoomable,
    intervalDataForHeatMap,
    intervalDataForExport,
    intervalDataForDemandDuration,
    intervalDataForCalendarChart,
    intervalDataForSurfacePlot,
    intervalDataForLoadCurve,
    intervalDataForSummaryChart
} from './interval-data-reducer';

import {
    chartDataFilterFormObject,
    chartOptions,
    plotlyChartOptions,
    chartTableSettings,
    dataFilterMessage,
    chartCanUpdateData,
    durationView,
    temperatureView,
    showMeterDatesAndRanges
} from "./meter-charts-reducers";

import { toggleCreateProjectDialog, projects } from './dashboard-reducers';
import { 
    toggleStripeCreditCardDialog, 
    paymentMethods, 
    organizationSubscription,
    tempThemeColor
} from './admin-reducers';

import {
    degreeDays, 
    degreeDayChartToUpdate,
    weatherHandsontableSettings,
    regressionRedux,
    rawWeatherDataChartOptions
} from "./weather-reducers";

import {selectedChartView} from "./chart-reducers";

import {calendarChartShowPeakDemand, calendarChartShowTemperature} from "./DataChartReducers/calendar-chart-reducers";

import {emissionsObject, emissionsIncludeLosses, emissionsStateVsRegion} from "./EmissionsReducers/emissions.reducers";

import {
    editMeterFormIsValid,
    attemptedEditMeterSubmit,
    editMeterDialogOpen,
    selectedMeterForEdit,
    editMeterFormControls,
    fetchingNewWeatherData,
} from "./MeterReducers/EditMeterReducers/edit-meter-reducers";

import { editedUser, usersOfOrganization, activeUser } from './user-reducer';

import {snackbar} from "./HomeReducers/home-reducers";
import { reducer as formReducer } from 'redux-form'
import {setContact} from './contact-reducer'

const allReducers = combineReducers({
    // User Level
    editedUserProperties: editedUser,
    users: usersOfOrganization,
    activeUser: activeUser,
    
    //HomeLevel
    snackbar,

    // Dashboard Level
    projects: projects,

    createProjectDialogOpen: toggleCreateProjectDialog,

    // Admin Level
    paymentMethods: paymentMethods,
    changeCreditCardDialogOpen: toggleStripeCreditCardDialog,
    organizationSubscription: organizationSubscription,
    tempThemeColor: tempThemeColor,

    // Project Level
    selectedProject: selectedProject,
    facilities: facilitiesOfProject,
    deleteProjectDialogOpen: toggleDeleteProjectDialog,
    editProjectDialogOpen: toggleEditProjectDialog,
    fetchingFacilities: fetchingFacilities,

    // Facility Level
    selectedFacilityView: facilityView,
    selectedFacilitySubview: facilitySubview,
    facilitySidebarOpen: toggleFacilitySidebar,
    selectedFacility: selectFacility,
    
    // Edit Meter
    editMeterFormIsValid,
    attemptedEditMeterSubmit,
    editMeterDialogOpen,
    selectedMeterForEdit,
    editMeterFormControls,
    fetchingNewWeatherData,
    
    
    // Charts
    selectedChartView: selectedChartView,
    
    // Calendar Chart
    calendarChartShowPeakDemand: calendarChartShowPeakDemand,
    calendarChartShowTemperature: calendarChartShowTemperature,
    
    // Emissions
    emissionsObject,
    emissionsIncludeLosses,
    emissionsStateVsRegion,
    
    // Chart Tool Reducers
    chartDataFilterFormObject: chartDataFilterFormObject,
    chartOptions: chartOptions,
    plotlyChartOptions: plotlyChartOptions,
    chartTableSettings: chartTableSettings,
    dataFilterMessage: dataFilterMessage,
    chartCanUpdateData: chartCanUpdateData,
    durationView: durationView,
    temperatureView,
    showMeterDatesAndRanges,

    // Interval Data
    intervalData: rawIntervalData,
    intervalDataForZoomable: intervalDataForZoomable,
    intervalDataForDemandDuration: intervalDataForDemandDuration,
    intervalDataForHeatMap: intervalDataForHeatMap,
    intervalDataForCalendarChart: intervalDataForCalendarChart,
    intervalDataForSurfacePlot: intervalDataForSurfacePlot,
    intervalDataForExport: intervalDataForExport,
    intervalDataForLoadCurve: intervalDataForLoadCurve,
    intervalDataForSummaryChart: intervalDataForSummaryChart,
    importFacilityDataDialogOpen: toggleImportDataDialog,
    initialName: setContact,
    form: formReducer,
    
    // Weather
    degreeDays,
    degreeDayChartToUpdate,
    weatherHandsontableSettings,
    regressionRedux,
    rawWeatherDataChartOptions

    
});

export default allReducers;