import React, { Fragment } from 'react';

// Material UI
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

// Components
import CustomDialog from "../../components/Dialogs/customDialog";

const useStyles = makeStyles({
    button: {
        color: 'red'
    }
});

function DeleteProjectDialog(props) {
    const {
        deleteProjectDialogOpen,
        toggleDeleteProjectDialog,
        selectedProjectForDelete,
        onDeleteProjectSubmit,
    } = props;
    const classes = useStyles();

    return (
        <Fragment>
            {selectedProjectForDelete === null ? null :
                <CustomDialog
                    open={deleteProjectDialogOpen}
                    onClose={toggleDeleteProjectDialog}
                    subTitle="Delete"
                    // title={selectedProjectForDelete.name}
                    content={<Typography>Are you sure you want to delete the project "{selectedProjectForDelete.name}"?</Typography>
                    }
                    actions={<Button type="button" className={classes.button} onClick={() => onDeleteProjectSubmit()}>Delete</Button>}
                />
            }
        </Fragment>


    );
}

export default DeleteProjectDialog;