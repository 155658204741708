import React from 'react'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import CheckoutForm from "../../PaymentMethodRev2/CheckoutForm";
import CheckIcon from '@material-ui/icons/Check';

function AccountActivationStep2(props) {

    const {onSubmit, organizationPaymentMethod} = props;

    if (organizationPaymentMethod === null) {
        return (
            <Grid container style={{display: 'flex', justifyContent: 'center'}}>
                <Grid container style={{display: 'flex', justifyContent: 'center'}}>
                    <Typography variant={"h5"} style={{paddingBottom: 15}}>Payment Method</Typography>
                    
                </Grid>
                <Grid container style={{display: 'flex', justifyContent: 'center'}}>
                    <CheckoutForm onSubmit={onSubmit} showCancel={false}/>
                </Grid>
            </Grid>

        )
    } else {
        return (
            <Grid container style={{display: 'flex', justifyContent: 'center'}}>
                <Grid container style={{display: 'flex', justifyContent: 'center'}}>
                    <CheckIcon style={{color: 'green', fontSize: 100}}/>
                </Grid>
                <Grid container style={{display: 'flex', justifyContent: 'center'}}>
                    <Typography>Card ending in {organizationPaymentMethod.last4}</Typography>
                </Grid>
            </Grid>
            
        )
    }
    
}

export default AccountActivationStep2