import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function StillThereDialog(props) {

    
    const {open, toggleStillThereDialog, onSubmit, secondsLeft} = props;

    return (
            <Dialog
                open={open}
                onClose={toggleStillThereDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Are you still working?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        If you are done working, you will be logged out in {secondsLeft} seconds.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {/*<Button onClick={toggleStillThereDialog} color="primary">*/}
                    {/*    Disagree*/}
                    {/*</Button>*/}
                    <Button onClick={onSubmit} color="primary" autoFocus>
                        Yes, I'm here
                    </Button>
                </DialogActions>
            </Dialog>
    );
}