import {
    CardCvcElement,
    CardElement,
    CardExpiryElement,
    CardNumberElement, Elements,
    useElements,
    useStripe
} from "@stripe/react-stripe-js";
import React from "react";

import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import {CardCVCElement} from "react-stripe-elements";
import Input from "@material-ui/core/Input";
import grey from '@material-ui/core/colors/grey';
import Divider from '@material-ui/core/Divider';
import Icon from "@material-ui/core/Icon";

import validate from "../../../../../../js/validate";
import {createOrUpdatePaymentMethod, getOrganizationPaymentMethods} from "../../../../../../services/admin.service";
import CustomSnackbar from "../../../../../../components/Snackbar/CustomSnackbar";
import PulseLoader from 'react-spinners/PulseLoader';
import LoadingButton from "../../../../../../components/CustomButtons/LoadingButton";
import {loadStripe} from "@stripe/stripe-js";
import PoweredByStripeSVG from '../../../../../../assets/img/svg/powered_by_stripe.svg';
import ReactSVG from "react-svg";


const useStyles = makeStyles(theme => ({
    card: {
        margin: 5,
        maxWidth: 400
    },

    root: {
        padding: 15
    },
    section: {
        padding: 5
    },
    elementContainer: {
        border: "1px solid #bdbdbd",
        borderRadius: 5,
        padding: 8,
        marginTop: 5
    },
    buttonGroup: {
        padding: 5
    }
}));



export default function CardForm(props) {
    const stripe = useStripe();
    const elements = useElements();

    const [name, setName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [submitting, setSubmitting] = React.useState(false);
    const [emailIsValid, setEmailIsValid] = React.useState(false);
    const [nameIsValid, setNameIsValid] = React.useState(false);
    const [snackbar, setSnackbar] = React.useState({
        open: false,
        message: '',
        variant: ''
    });

    const openSnackbar = (value) => {
        let temp = { ...snackbar};
        temp.open = value;
        setSnackbar(temp)
    };

    const handleSubmit = async (event, name, email) => {
        setSubmitting(true);
        if (name.length !== 0) {
            const {paymentMethod} = await stripe.createPaymentMethod({
                type: 'card',
                card: elements.getElement(CardElement),
                billing_details: {
                    name
                }
            });

            if (paymentMethod){
                createOrUpdatePaymentMethod(paymentMethod.id).then(x => {
                    if (x.isSuccess) {
                        setSnackbar({
                            open: true,
                            message: 'Successfully updated Payment Method',
                            variant: 'success'
                        });
                        if (props['onSubmit']) {
                            props.onSubmit();
                            props.onClose();
                        }
                        
                    } else {
                        setSnackbar({
                            open: true,
                            message: 'The information entered was invalid, please try again',
                            variant: 'error'
                        })
                    }
                    setSubmitting(false);
                    
                    
                });
            } else {
                setSubmitting(false);

            }
        } else {
            setSnackbar({
                open: true,
                message: 'Please enter a name for the credit card',
                variant: 'error'
            });
            setSubmitting(false);

        }
        

    };

    const classes = useStyles();



    const changeName = (event) => {
        let name = event.target.value;
        let validationRules = {
            minLength: 2
        };
        let isValid = validate(name, validationRules);
        setNameIsValid(isValid);
        setName(event.target.value);
    };

    const changeEmail = (event) => {
        let email = event.target.value;
        let validationRules = {
            isEmail: true
        };
        let isValid = validate(email, validationRules);
        setEmailIsValid(isValid);
        setEmail(event.target.value);
    };



    return (
            <Card className={classes.card}>
                <CustomSnackbar
                    snackbar={snackbar}
                    openSnackbar={openSnackbar}
                />
                <form onSubmit={handleSubmit}>
                    <Grid container style={{display: 'flex', justifyContent: 'center', paddingTop: 10}} >
                        <ReactSVG src={PoweredByStripeSVG} />
                    </Grid>
                    <Grid container className={classes.root}>
                        <Divider variant="middle"/>
                        <Grid item xs={12} className={classes.section}>
                            <InputLabel >
                                Name on Card
                            </InputLabel>
                
                                
                
                            
                            <div className={classes.elementContainer}>
                                <Input
                                    placeholder="e.g. Jane H Smith"
                                    disableUnderline={true}
                                    onChange={changeName}
                                    style={{width: '100%'}}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} className={classes.section}>
                            <InputLabel >
                                Card Information
                            </InputLabel>
                            <div className={classes.elementContainer}>
                                <CardElement options={{
                                    style: {
                                        base: {
                                            fontSize: '16px',
                                            color: grey[800],
                                            '::placeholder': {
                                                color: '#bdbdbd',
                                            },
                                        },
                                        invalid: {
                                            color: 'red',
                                        },
                                    },
                                }}/>
                            </div>
                        </Grid>
                        <div className={classes.buttonGroup}>

                            <LoadingButton
                                onClick={(event) => handleSubmit(event, name, email)}
                                variant={"contained"}
                                loading={submitting}
                                color={"primary"}
                                message={'Update'}
                                disabled={!stripe}
                            />
                            {props.showCancel ? 
                            <Button onClick={props.onClose} color={'primary'} style={{paddingLeft: 10}} disabled={!stripe}>
                                Cancel
                            </Button> : null}
                            
                        </div>

                    </Grid>

                </form>
            </Card>
    );
};