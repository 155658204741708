import React, { Component } from 'react';
import { css } from '@emotion/core';
// First way to import
//import { ClipLoader } from 'react-spinners';
// Another way to import
import PulseLoader from 'react-spinners/PulseLoader';
import { useTheme } from '@material-ui/core/styles';


const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

function CustomPulseLoader(props) {
    const theme = useTheme();
    
    let color;
    props['color'] ? color = props.color : color = theme.palette.primary.main;
    
        return (
            <div className='sweet-loading'>
                <PulseLoader
                    css={override}
                        sizeUnit={"px"}
                        // size={10}
                        color={color}
                    loading={true}
                />
            </div>
        )
}

export default CustomPulseLoader;