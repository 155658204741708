import React, { Component, Fragment } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import grey from '@material-ui/core/colors/grey';
import IconButton from '@material-ui/core/IconButton';

// Icons
import CloseIcon from '@material-ui/icons/Close';
import EditUserForm from "../forms/EditUserForm";
import CustomDialog from "../../../components/Dialogs/customDialog";


const useStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
    appBar: {
        backgroundColor: grey[300]
    },
    title: {
        color: 'black',
        flexGrow: 1,
        display: 'inline-block'
    },
    button: {
        color: 'red'
    }

});


function DeleteUserDialog(props) {
    const {
        deleteUserDialogOpen,
        toggleDeleteUserDialog,
        selectedUserForDelete,
        onDeleteUserSubmit,
    } = props;
    const classes = useStyles();
    return (
        <Fragment>
            {selectedUserForDelete === undefined || selectedUserForDelete === null ? null :

                
                <CustomDialog
                open={deleteUserDialogOpen}
                onClose={toggleDeleteUserDialog}
                title=""
                subTitle="Delete"
                content={`Are you sure you want to delete '${selectedUserForDelete.email}' from your organization?`}
                actions={<Fragment><Button type="button" className={classes.button} onClick={() => onDeleteUserSubmit()}>Delete</Button></Fragment>}
                />
            }
        </Fragment>


    );
}

export default DeleteUserDialog;