import config from "../config";
import { authHeader } from "../helpers/auth-header";
import { getUser } from "../services/user.service";
import { REQUESTTYPES, fetchHelper, responseStatusHandler } from "./serviceHelper";

// Get api base URLs
const ADMIN = config.admin;

const createOrUpdateUserRequestObject = {
    UserId: null, //int
    UserName: "",
    Email: "",
    Password: "",
    FirstName: "",
    LastName: "",
    OrganizationId: null, //int
    OrganizationName: "",
    PhoneNumber: ""
};

const ENDPOINTS = {
    admin: {
        getUser: "GetUser",
        deleteUser: "DeleteUser",
        getOrganizationPaymentMethods: "GetOrganizationPaymentMethods",
        getActiveProductPlans: "GetActiveProductPlans",
        createOrUpdatePaymentMethod: "CreateOrUpdatePaymentMethod",
        removePaymentMethod: "RemovePaymentMethod",
        createOrUpdateOrganizationSubscription: "CreateOrUpdateOrganizationSubscription",
        cancelOrganizationSubscription: "CancelOrganizationSubscription",
        createOrUpdateUser: "CreateOrUpdateUser",
        getOrganizationUsers: "GetOrganizationUsers",
        getOrganization: "GetOrganization",
        getOrganizationSubscription: "GetOrganizationSubscription",
        getConfigurationSettings: "GetConfigurationSettings"
    }
};


const getOrganization = () => {

    var user = getUser();
    var organizationId = user.organizationId;

    const apiResponse = fetchHelper(
        ADMIN,
        ENDPOINTS.admin.getOrganization,
        {OrganizationId: organizationId},
        "post"
    ).then(response => {

        // login successful if there's a user in the response
        if (!response.isSuccess) {
            //todo: do something on error
        } else {
            return response;
        }
    });

    return apiResponse;
};

const getOrganizationSubscription = () => {

    const apiResponse = fetchHelper(
        ADMIN,
        ENDPOINTS.admin.getOrganizationSubscription,
        {},
        "post"
    ).then(response => {

        // debugger;

        // login successful if there's a user in the response
        if (!response.isSuccess) {
            //todo: do something on error
        } else {

            if (window.sessionStorage.user) {
                
                const user = JSON.parse(window.sessionStorage.user);
                
                user.subscription = response.subscription;

                sessionStorage.setItem("user", JSON.stringify(user));
            }
            
            return response.subscription;
        }
    });

    return apiResponse;
};


const getOrganizationPaymentMethods = () => {

    var user = getUser();
    var organizationId = user.organizationId;
    
    const apiResponse = fetchHelper(
        ADMIN,
        ENDPOINTS.admin.getOrganizationPaymentMethods,
        {OrganizationId: organizationId},
        "post"
    ).then(response => {

        // login successful if there's a user in the response
        if (!response.isSuccess) {
            //todo: do something on error
        } else {
            return response.organizationPaymentMethods;
        }
    });

    return apiResponse;
};

const getOrganizationUsers = () => {

    var user = getUser();
    var organizationId = user.organizationId;

    const apiResponse = fetchHelper(
        ADMIN,
        ENDPOINTS.admin.getOrganizationUsers,
        {OrganizationId: organizationId},
        "post"
    ).then(response => {

        // login successful if there's a user in the response
        if (!response.isSuccess) {
            //todo: do something on error
        } else {
            return response;
        }
    });

    return apiResponse;
};

const getActiveProductPlans = () => {

    const apiResponse = fetchHelper(
        ADMIN,
        ENDPOINTS.admin.getActiveProductPlans,
        {},
        "post"
    ).then(response => {

        // login successful if there's a user in the response
        if (!response.isSuccess) {
            //todo: do something on error
        } else {
            return response.products;
        }
    });

    return apiResponse;
};

const createOrUpdatePaymentMethod = (paymentMethodId) => {
    const apiResponse = fetchHelper(
        ADMIN,
        ENDPOINTS.admin.createOrUpdatePaymentMethod,
        {
            StripePaymentMethodId: paymentMethodId
        },
        "post"
    ).then(response => {
        // login successful if there's a user in the response
        if (!response.isSuccess) {
            //todo: do something on error
        } else {
            return response;
        }
    });

    return apiResponse;
};

const removePaymentMethod = (paymentMethodId) => {
    const apiResponse = fetchHelper(
        ADMIN,
        ENDPOINTS.admin.removePaymentMethod,
        {
            StripePaymentMethodId: paymentMethodId
        },
        "post"
    ).then(response => {
        // login successful if there's a user in the response
        if (!response.isSuccess) {
            //todo: do something on error
        } else {
            return response;
        }
    });

    return apiResponse;
};

const cancelOrganizationSubscription = () => {

    var user = getUser();
    var organizationId = user.organizationId;
    
    const apiResponse = fetchHelper(
        ADMIN,
        ENDPOINTS.admin.cancelOrganizationSubscription,
        {
            OrganizationId: organizationId
        },
        "post"
    ).then(response => {
        // login successful if there's a user in the response
        if (!response.isSuccess) {
            //todo: do something on error
        } else {
            return response;
        }
    });

    return apiResponse;
};

const createOrUpdateUser = (createOrUpdateUserRequestObject) => {

    if (!createOrUpdateUserRequestObject.OrganizationId){
        var user = getUser();
        createOrUpdateUserRequestObject.OrganizationId = user.organizationId;
    }

    const apiResponse = fetchHelper(
        ADMIN,
        ENDPOINTS.admin.createOrUpdateUser,
        {
            User: createOrUpdateUserRequestObject
        },
        "post"
    ).then(response => {
        // login successful if there's a user in the response
        if (!response.isSuccess) {
            return response;
            //todo: do something on error
        } else {
            return response;
        }
    });
    
    return apiResponse;
};

const deleteUser = (userId) => {

    const apiResponse = fetchHelper(
        ADMIN,
        ENDPOINTS.admin.deleteUser,
        {
            UserId: userId
        },
        "post"
    ).then(response => {
        // login successful if there's a user in the response
        if (!response.isSuccess) {
            //todo: do something on error
        } else {
            return response;
        }
    });

    return apiResponse;
};

//intervalTimePeriod can just be a string "Monthly" or "Annually"
const createOrUpdateOrganizationSubscription = (stripePlanId, quantity) => {

    let user = getUser();
    let organizationId = user.organizationId;
    
    const apiResponse = fetchHelper(
        ADMIN,
        ENDPOINTS.admin.createOrUpdateOrganizationSubscription,
        {
            OrganizationId: organizationId,
            StripePlanId: stripePlanId,
            Quantity: quantity
        },
        "post"
    ).then(response => {
        // login successful if there's a user in the response
        if (!response.isSuccess) {
            //todo: do something on error
        } else {
            return response;
        }
    });

    return apiResponse;
};

const getConfigurationSettings = () => {

    const apiResponse = fetchHelper(
        ADMIN,
        ENDPOINTS.admin.getConfigurationSettings,
        {},
        "post"
    ).then(response => {
        // login successful if there's a user in the response
        if (!response.isSuccess) {
            //todo: do something on error
        } else {
            return response.settings;
        }
    });

    return apiResponse;
};


export {
    getOrganizationPaymentMethods,
    getActiveProductPlans,
    createOrUpdatePaymentMethod,
    createOrUpdateOrganizationSubscription,
    removePaymentMethod,
    createOrUpdateUser,
    cancelOrganizationSubscription,
    getOrganizationSubscription,
    createOrUpdateUserRequestObject,
    getOrganizationUsers,
    getOrganization,
    deleteUser,
    getConfigurationSettings
};
