import {
    SET_CHART_DATA_FILTER_FORM_OBJECT,
    SET_PLOTLY_CHART_OPTIONS,
    SET_CHART_OPTIONS,
    SET_CHART_TABLE_SETTINGS,
    SET_DATA_FILTER_MESSAGE,
    SET_CHART_CAN_UPDATE_DATA,
    SET_DURATION_VIEW,
    SET_TEMPERATURE_VIEW,
    SET_SHOW_METER_DATES_AND_RANGES
} from '../actions/meter-charts-actions'

import {SELECTED_FACILITY_VIEW} from "../actions/facility-actions";

const chartDataFilterFormObject = (state = null, action) => {
    switch (action.type) {
        
        case SET_CHART_DATA_FILTER_FORM_OBJECT:
        return action.payload;
    }
    return state
};

const chartOptions = (state = null, action) => {
    switch (action.type) {

        case SET_CHART_OPTIONS:
            return action.payload;
    }
    return state
};

const plotlyChartOptions = (state = null, action) => {
    switch (action.type) {

        case SET_PLOTLY_CHART_OPTIONS:
            return action.payload;
    }
    return state
};

const chartTableSettings = (state = null, action) => {
    switch (action.type) {

        case SET_CHART_TABLE_SETTINGS:
            return action.payload;
    }
    return state
};

const dataFilterMessage = (state = null, action) => {
    switch (action.type) {

        case SET_DATA_FILTER_MESSAGE:
            return action.payload;
    }
    return state
};

const chartCanUpdateData = (state = null, action) => {
    switch (action.type) {

        case SET_CHART_CAN_UPDATE_DATA:
            return action.payload;
    }
    return state
};

const durationView = (state = 'week', action) => {
    switch (action.type) {

        case SET_DURATION_VIEW:
            return action.payload;
    }
    return state
};

const temperatureView = (state = false, action) => {
    switch (action.type) {

        case SET_TEMPERATURE_VIEW:
            return action.payload;
    }
    return state
};

const showMeterDatesAndRanges = (state = false, action) => {
    switch (action.type) {

        case SET_SHOW_METER_DATES_AND_RANGES:
            return action.payload;
    }
    return state
};



export { 
    chartDataFilterFormObject,
    chartOptions,
    plotlyChartOptions,
    chartTableSettings,
    dataFilterMessage,
    chartCanUpdateData,
    durationView,
    temperatureView,
    showMeterDatesAndRanges
}