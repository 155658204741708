import {getUser} from "../../services/user.service";

const GET_ORGANIZATION_USERS = 'GET_ORGANIZATION_USERS';
const EDIT_PROFILE_PROPERTY = 'EDIT_PROFILE_PROPERTY';
const SET_ACTIVE_USER = 'SET_ACTIVE_USER';

const editProfileProperty = (object) => {
    return {
        type: EDIT_PROFILE_PROPERTY,
        payload: object
    };
};

const addUsersToState = (users) => {
    return {
        type: GET_ORGANIZATION_USERS,
        payload: users
    };
};

const setActiveUser = () => {
    let user = getUser();
    // debugger;
    return {
        type: SET_ACTIVE_USER,
        payload: user
    };
};


export {
    editProfileProperty,
    EDIT_PROFILE_PROPERTY,
    addUsersToState,
    GET_ORGANIZATION_USERS,
    SET_ACTIVE_USER,
    setActiveUser
}
