const TOGGLE_SHOW_PEAK_DEMAND = 'TOGGLE_SHOW_PEAK_DEMAND';
const SET_CALENDAR_CHART_TEMPERATURE_VIEW = 'SET_CALENDAR_CHART_TEMPERATURE_VIEW';

const toggleCalendarChartShowPeakDemand = (boolean) => {
    return {
        type: TOGGLE_SHOW_PEAK_DEMAND,
        payload: boolean
    };
};

const toggleCalendarChartShowTemperature = (boolean) => {
    return {
        type: SET_CALENDAR_CHART_TEMPERATURE_VIEW,
        payload: boolean
    };
};

export {
    TOGGLE_SHOW_PEAK_DEMAND,
    SET_CALENDAR_CHART_TEMPERATURE_VIEW,
    toggleCalendarChartShowPeakDemand,
    toggleCalendarChartShowTemperature
};
