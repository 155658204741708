import React, {Component} from 'react'
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import MeterSectionTitleBar from "../components/MeterSectionTitleBar";
import MeterSettingsTabs from "./components/Tabs";
import MeterDatesAndRanges from "./components/DatesAndRanges";

class MeterSettings extends Component {
    
    getComponent = () => {
        const {selectedFacilitySubview} = this.props;
        switch (selectedFacilitySubview) {
            case 'dates':
                return <MeterDatesAndRanges />;
            case 'general':
                return 'general'
        }
    };
    render() {
        return (
            <div>
                <MeterSectionTitleBar title={"Meter Settings"}/>
                <MeterSettingsTabs 
                    selectedFacility={this.props.selectedFacility}
                    selectedFacilitySubview={this.props.selectedFacilitySubview}
                />
                {this.getComponent()}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        selectedFacility: state.selectedFacility,
        selectedFacilitySubview: state.selectedFacilitySubview

    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({

    }, dispatch);
};


export default connect(mapStateToProps, mapDispatchToProps)(MeterSettings)

