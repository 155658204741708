const SET_SNACKBAR = 'SET_SNACKBAR';

const setSnackbar = (snackbar) => {
    return {
        type: SET_SNACKBAR,
        payload: snackbar
    };
};

const openSnackbar = (open = false) => {
    return (dispatch, getState) => {
        const {
            snackbar
        } = getState();
        let snackbarTemp = {...snackbar};
        snackbarTemp.open = open;
        dispatch({
            type: SET_SNACKBAR,
            payload: snackbarTemp
        });
    }
};

export {
    setSnackbar,
    openSnackbar,
    SET_SNACKBAR
};

