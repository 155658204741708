import React, {Component} from 'react'
import moment from "moment";

//Material UI
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import {withStyles} from "@material-ui/core";
import Tooltip from '@material-ui/core/Tooltip';

import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

//Components
import ScheduleSlider from "./ScheduleSlider";


const styles = theme => ({
    root: {
        width: '100%',
        padding: theme.spacing(2)
    },
});

function valueLabelComponent(event) {
    const popperRef = React.useRef(null);
    React.useEffect(() => {
        if (popperRef.current) {
            popperRef.current.update();
        }
    });
    
    
    // let label = event.value;
    let label = moment().hour(0).minutes(0).seconds(0).add(event.value*15, 'minutes').format('HH:mm');

    return (
        <Tooltip
            PopperProps={{
                popperRef,
            }}
            open={event.open}
            enterTouchDelay={0}
            placement="top"
            title={<Typography>{label}</Typography>}
        >
            {event.children}
        </Tooltip>
    );
}


class Schedule extends Component {
    formatValueAsTime = (value) => {
        return moment().hour(value / 4).minutes((value % 4)*15).format("HH:mm");
    };
    
    state = {
        schedule: [
            {
                label: 'Sunday',
                day: 0,
                startTime: moment().hour(8).minutes(0).seconds(0).milliseconds(0),
                endTime: moment().hour(17).minutes(0).seconds(0).milliseconds(0),
                unoccupiedDay: false
            },{
                label: 'Monday',
                day: 1,
                startTime: moment().hour(8).minutes(0).seconds(0).milliseconds(0),
                endTime: moment().hour(17).minutes(0).seconds(0).milliseconds(0),
                unoccupiedDay: false
            },{
                label: 'Tuesday',
                day: 2,
                startTime: moment().hour(8).minutes(0).seconds(0).milliseconds(0),
                endTime: moment().hour(17).minutes(0).seconds(0).milliseconds(0),
                unoccupiedDay: false
            },{
                label: 'Wednesday',
                day: 3,
                startTime: moment().hour(8).minutes(0).seconds(0).milliseconds(0),
                endTime: moment().hour(17).minutes(0).seconds(0).milliseconds(0),
                unoccupiedDay: false
            },{
                label: 'Thursday',
                day: 4,
                startTime: moment().hour(8).minutes(0).seconds(0).milliseconds(0),
                endTime: moment().hour(17).minutes(0).seconds(0).milliseconds(0),
                unoccupiedDay: false
            },{
                label: 'Friday',
                day: 5,
                startTime: moment().hour(8).minutes(0).seconds(0).milliseconds(0),
                endTime: moment().hour(17).minutes(0).seconds(0).milliseconds(0),
                unoccupiedDay: false
            },{
                label: 'Saturday',
                day: 6,
                startTime: moment().hour(8).minutes(0).seconds(0).milliseconds(0),
                endTime: moment().hour(17).minutes(0).seconds(0).milliseconds(0),
                unoccupiedDay: false
            },{
                label: 'Holidays',
                day: 7,
                startTime: moment().hour(8).minutes(0).seconds(0).milliseconds(0),
                endTime: moment().hour(17).minutes(0).seconds(0).milliseconds(0),
                unoccupiedDay: false
            },
        ],
        groups: {
            weekdays: true,
            weekends: true
        }
    };
    
    changeSchedule = (schedule) => {
        this.setState({schedule})
    };
    
    toggleGroupedDays = (event) => {
        let groups = {...this.state.groups};
        groups[event.currentTarget.value] = !groups[event.currentTarget.value];
        this.setState({groups});
        
    }

    render() {
        const {classes} = this.props;
        const {weekends, weekdays} = this.state.groups;
        
        return (
            <div className={classes.root}>
                
                <Typography variant={"h4"}>
                    Select Occupied Hours
                </Typography>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={weekdays}
                            onChange={this.toggleGroupedDays}
                            value="weekdays"
                            color="secondary"
                        />
                    }
                    label="Similar Weekdays"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={weekends}
                            onChange={this.toggleGroupedDays}
                            value="weekends"
                            color="secondary"
                        />
                    }
                    label="Similar Weekends & Holidays"
                />
                <ScheduleSlider day={0} changeSchedule={this.changeSchedule} schedule={this.state.schedule} groups={this.state.groups}/>
                <ScheduleSlider day={1} changeSchedule={this.changeSchedule} schedule={this.state.schedule} groups={this.state.groups}/>
                <ScheduleSlider day={2} changeSchedule={this.changeSchedule} schedule={this.state.schedule} groups={this.state.groups}/>
                <ScheduleSlider day={3} changeSchedule={this.changeSchedule} schedule={this.state.schedule} groups={this.state.groups}/>
                <ScheduleSlider day={4} changeSchedule={this.changeSchedule} schedule={this.state.schedule} groups={this.state.groups}/>
                <ScheduleSlider day={5} changeSchedule={this.changeSchedule} schedule={this.state.schedule} groups={this.state.groups}/>
                <ScheduleSlider day={6} changeSchedule={this.changeSchedule} schedule={this.state.schedule} groups={this.state.groups}/>
                <ScheduleSlider day={7} changeSchedule={this.changeSchedule} schedule={this.state.schedule} groups={this.state.groups}/>

            </div>
        )
    }
}

export default withStyles(styles)(Schedule)