import React from 'react'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import CheckoutForm from "../../PaymentMethodRev2/CheckoutForm";
import CheckIcon from '@material-ui/icons/Check';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {makeStyles} from "@material-ui/core/styles";
import TermsOfUseContent from "../../../../../Legal/TermsOfUseContent";

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',

    },
});

function AccountActivationStep3(props) {
    const {setAgreeToTerms, agreeToTerms, handleNext}  = props;
    
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
        setAgreeToTerms(value);
        if (value) {
            handleNext();
        }
        
    };
    
    
    
    const classes = useStyles();
    return (
        <Grid container style={{display: 'flex', justifyContent: 'center'}}>
            {/*<Grid container style={{display: 'flex', justifyContent: 'center'}}>*/}
            {/*    <Typography>Terms of Service</Typography>*/}
            {/*</Grid>*/}
            <Button variant="contained" color="primary" onClick={handleClickOpen}>
                Read Terms of Use
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">React Energy Terms of Use</DialogTitle>
                <DialogContent>
                    {/*<DialogContentText id="alert-dialog-description">*/}
                    {/*    Let Google help apps determine location. This means sending anonymous location data to*/}
                    {/*    Google, even when no apps are running.*/}
                    {/*</DialogContentText>*/}
                    <TermsOfUseContent />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClose(false)} color="primary">
                        Disagree
                    </Button>
                    <Button onClick={() => handleClose(true)} color="primary" autoFocus>
                        Agree
                    </Button>
                </DialogActions>
            </Dialog>



        </Grid>

    )

}

export default AccountActivationStep3