import React, {Component} from 'react';


import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {
    setChartCanUpdateData,
    setChartDataFilterFormObject,
    onDataFilterFormSubmit,
    setDurationView,
    setChartOptions,
    setTemperatureView,
    setShowMeterDatesAndRanges
} from "../../../redux/actions/meter-charts-actions";


import CalendarChartContainer from './CalendarChart';
import MainChartsContainer from './MainChartsComponent'


import {withStyles} from '@material-ui/core/styles';


import Grid from '@material-ui/core/Grid';
import {getProjectById} from "../../../services/project.service";
import {getFacilitiesByProjectId, getFacilityById} from "../../../services/facility.service";
import ChartDataFilterFormObject from "../DataFilterForm/ChartDataFilterFormObject";
import exportDataAsCsvFunciton from "../../js/exportDataAsCsv";
import SurfacePlot from "./SurfacePlot";
import AntTabs from "../../../components/AntTabs/AntTabs";
import AntTab from "../../../components/AntTabs/AntTab";
import {Link} from "react-router-dom";
import {Typography} from "@material-ui/core";
import {selectChartView} from "../../../redux/actions/chart-actions";
import ChartsTabs from "./Tabs";
import Toolbar from '@material-ui/core/Toolbar';
import MeterSectionTitleBar from "../components/MeterSectionTitleBar";
import GreenButton from "../../../components/CustomButtons/GreenButton";
import CloudDownload from "@material-ui/icons/CloudDownload";
import moment from "moment";


const styles = theme => ({
    root: {
        flexGrow: 1,
        paddingLeft: theme.spacing(1)
    },
    title: {
        paddingTop: theme.spacing(1),
        flexGrow: 1
    }
});


class DataViewTemplateTemp extends Component {

    state = {
        currentTab: 0,
    }

    toggleDurationView = () => {
        this.props.setChartOptions(null);
        const {selectedFacilitySubview, selectedFacility} = this.props;
        const {timezone} = selectedFacility;
        let chartOptions;
        if (this.props.durationView === "week") {
            this.props.setDurationView("day");
            if (selectedFacilitySubview === 'scatter-plot') {
                let timeSeriesData = this.props.intervalDataForZoomable;
                chartOptions = timeSeriesData.generateScatterPlotChartOptions(this.props.chartDataFilterFormObject.dataType, "day", timezone, selectedFacility.intervalDuration);
            } else if (selectedFacilitySubview === 'heat-map') {
                let heatMapData = this.props.intervalDataForHeatMap;
                chartOptions = heatMapData.generateChartOptions(this.props.chartDataFilterFormObject.dataType, "day");
            }


        } else {

            this.props.setDurationView("week");
            if (selectedFacilitySubview === 'scatter-plot') {
                let timeSeriesData = this.props.intervalDataForZoomable;
                chartOptions = timeSeriesData.generateScatterPlotChartOptions(this.props.chartDataFilterFormObject.dataType, "week", timezone, selectedFacility.intervalDuration);
            } else if (selectedFacilitySubview === 'heat-map') {
                let heatMapData = this.props.intervalDataForHeatMap;
                chartOptions = heatMapData.generateChartOptions(this.props.chartDataFilterFormObject.dataType, "week");
            }


        }
        this.props.setChartOptions(chartOptions);

    };

    toggleTemperatureView = () => {
        debugger;
        const {selectedFacility} = this.props;
        const {timezone} = selectedFacility;
        let temperatureView = !this.props.temperatureView;
        this.props.setTemperatureView(temperatureView);
        let timeSeriesData = this.props.intervalDataForZoomable;
        let chartOptions = timeSeriesData.generateZoomableChartOptions(this.props.chartDataFilterFormObject.dataType, timezone, temperatureView);
        this.props.setChartOptions(chartOptions);
    };
    
    toggleShowDatesAndRangesView = () => {
        const {selectedFacility} = this.props;
        const {timezone} = selectedFacility;
        let showMeterDatesAndRanges = !this.props.showMeterDatesAndRanges;
        this.props.setShowMeterDatesAndRanges(showMeterDatesAndRanges);
        let timeSeriesData = this.props.intervalDataForZoomable;
        let plotLines;
        let plotBands;
        if (showMeterDatesAndRanges) {
            plotLines = [{
                color: '#0000ff',
                width: 2,
                value: moment().year(2019).month(5).valueOf(),
                label: {
                    text: 'Event Here'
                }
            }, {
                color: '#FF0000',
                width: 2,
                value: moment().year(2019).month(7).valueOf(),
                label: {
                    text: 'Event Here 2'
                },
                events: {
                    click: function() {
                        alert('clicked the line')
                    }
                }
            }];
            plotBands = [{
                color: 'orange', // Color value
                from: moment().year(2019).month(4).valueOf(), // Start of the plot band
                to: moment().year(2019).month(6).valueOf(), // End of the plot band
                label: {
                    text: 'Corona Virus'
                },
                events: {
                    click: function() {
                        alert('clicked the band')
                    },
                }
            }, {
                color: 'yellow', // Color value
                from: moment().year(2019).month(6).valueOf(), // Start of the plot band
                to: moment().year(2019).month(7).valueOf(), // End of the plot band
                label: {
                    text: 'Corona Virus'
                },
                events: {
                    click: function() {
                        alert('clicked the band')
                    }
                }
            }];
        }

        let chartOptions = timeSeriesData.generateZoomableChartOptions(this.props.chartDataFilterFormObject.dataType, timezone, this.props.temperatureView, plotLines, plotBands);
        this.props.setChartOptions(chartOptions);
    };

    setDefaultDataFilterForm = (view) => {
        const {selectedFacility} = this.props;
        switch (view) {
            case 'load-curve':
            case 'summary-chart':
            case 'heat-map':
            case 'demand-duration':
            case 'zoomable-chart':
            case 'calendar-chart':
            case 'scatter-plot':
            case 'surface-plot':
                let defaultForm = new ChartDataFilterFormObject(selectedFacility).getDefaultFormByChartType(view);
                this.setDataFilterForm(defaultForm);
        }
    };

    setDataFilterForm = (chartDataFilterForm) => {
        //set state and call the default graph to populate
        this.setState({chartDataFilterForm}, this.onDataFilterFormSubmit);
        this.props.setChartDataFilterFormObject(chartDataFilterForm);
    };

    exportDataAsCsv = () => {
        exportDataAsCsvFunciton(this.props.chartTableSettings);
    };

    componentDidMount() {
        const {facilityId, projectId, selectedFacility, selectedFacilitySubview} = this.props;
        if (selectedFacility.maximumkWValue) {
            // this.setDefaultDataFilterForm(selectedFacilitySubview);
        }
        // this.props.onDataFilterFormSubmit();
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.selectedFacility !== this.props.selectedFacility || prevProps.selectedFacilitySubview !== this.props.selectedFacilitySubview) {
            // this.props.onDataFilterFormSubmit();
        }
    }

    getComponent() {

        const {

            //parent
            projectId,
            facilityId,

            //Table
            // exportDataAsCsv,

            //redux
            chartOptions,
            chartTableSettings,
            dataFilterMessage,
            chartCanUpdateData,
            setChartCanUpdateData,
            selectedFacilityView,
            selectedFacilitySubview,
            selectedFacility
        } = this.props;

        switch (this.props.selectedFacilitySubview) {
            case 'zoomable-chart':
            case 'demand-duration':
            case 'summary-chart':
            case 'load-curve':
            case 'heat-map':
            case 'scatter-plot':
                return <MainChartsContainer
                    chartOptions={chartOptions}
                    selectedFacilityView={selectedFacilityView}
                    selectedFacilitySubview={selectedFacilitySubview}
                    chartTableSettings={chartTableSettings}
                    dataFilterMessage={dataFilterMessage}
                    exportDataAsCsv={this.exportDataAsCsv}
                    selectedFacility={selectedFacility}
                    //chart functions
                    toggleDurationView={this.toggleDurationView}
                    toggleTemperatureView={this.toggleTemperatureView}
                    toggleShowDatesAndRangesView={this.toggleShowDatesAndRangesView}
                    durationView={this.props.durationView}
                    temperatureView={this.props.temperatureView}
                    showMeterDatesAndRanges={this.props.showMeterDatesAndRanges}
                />;

            case 'surface-plot':
                return <SurfacePlot
                    selectedFacility={selectedFacility}
                    plotlyChartOptions={this.props.plotlyChartOptions}
                    chartTableSettings={chartTableSettings}
                    exportDataAsCsv={this.exportDataAsCsv}/>;

            case 'calendar-chart':
                return <CalendarChartContainer
                    chartCanUpdateData={chartCanUpdateData}
                    setChartCanUpdateData={setChartCanUpdateData}
                />;
        }
    };
    
    getRightContent = () => {
        switch (this.props.selectedFacilitySubview) {
            case 'zoomable-chart':
            case 'demand-duration':
            case 'summary-chart':
            case 'load-curve':
            case 'heat-map':
            case 'scatter-plot':
            case 'surface-plot':
                return (
                    <div style={{padding: 5}}>
                        <GreenButton onClick={() => exportDataAsCsvFunciton(this.props.chartTableSettings)}>
                            <CloudDownload/>
                            <Typography style={{paddingLeft: 5}}>
                                Export Data
                            </Typography>

                        </GreenButton>
                    </div>
                )
        }
    }

    render() {


        const {classes} = this.props;
        return (

            <Grid container className={classes.root}>
                <Grid item xs={12}>
                    <MeterSectionTitleBar 
                        title={"Charts"} 
                        rightContent={this.getRightContent()}/>
                    
                    
                        
                    

                </Grid>
                <div style={{width: '100%'}}>
                <ChartsTabs
                    selectedFacility={this.props.selectedFacility}
                    selectedFacilitySubview={this.props.selectedFacilitySubview}
                    
                />
                </div>


                <Grid item md={12} >
                    {this.getComponent()}
                </Grid>
            </Grid>
        )
    }
}

function mapStateToProps(state) {
    return {
        selectedFacilitySubview: state.selectedFacilitySubview,
        chartDataFilterFormObject: state.chartDataFilterFormObject,
        chartOptions: state.chartOptions,
        plotlyChartOptions: state.plotlyChartOptions,
        chartTableSettings: state.chartTableSettings,
        dataFilterMessage: state.dataFilterMessage,
        chartCanUpdateData: state.chartCanUpdateData,
        selectedFacility: state.selectedFacility,
        selectedFacilityView: state.selectedFacilityView,
        durationView: state.durationView,
        temperatureView: state.temperatureView,
        showMeterDatesAndRanges: state.showMeterDatesAndRanges,
        intervalDataForZoomable: state.intervalDataForZoomable,
        intervalDataForHeatMap: state.intervalDataForHeatMap,


    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        selectChartView,
        onDataFilterFormSubmit,
        setChartDataFilterFormObject,
        setChartCanUpdateData,
        setTemperatureView,
        setDurationView,
        setChartOptions,
        setShowMeterDatesAndRanges
    }, dispatch);
};

let DataViewTemplate = connect(mapStateToProps, mapDispatchToProps)(DataViewTemplateTemp);

export default withStyles(styles)(DataViewTemplate)

