import React, {Component} from 'react'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid';
import PrivacyContent from "./PrivacyContent";

class Privacy extends Component {
    render() {
        return (
            <Grid container alignItems="center" justify="center">
                <Grid item xs={10}>

                    <Paper style={{marginTop: 20}}>
                        <PrivacyContent />
                    </Paper>

                </Grid>
            </Grid>
        )
    }
}

export default Privacy