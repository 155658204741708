import moment from 'moment'

export default function getHandsontableSettings(step2params) {
        let colHeaders = getColumnHeaders(step2params);
        let rowHeaders = getRowHeaders(step2params);
        let data = getTempData(step2params);
        let columns = getColumns(step2params);

    return {
        colHeaders,
        rowHeaders,
        data,
        columns,
        width: 'auto',
        height: 500,
        rowHeaderWidth: 180,
        licenseKey: 'non-commercial-and-evaluation',
        stretchH: 'all',
        maxRows: rowHeaders.length
    }       
}



const getStep2Values = (step2params) => {
    const orientation = step2params.radios.orientation.selected;
    const duration = parseInt(step2params.radios.duration.selected);
    const dataType = step2params.radios.dataType.selected;
    const startDate = step2params.dateRange.startDate.value;
    const endDate = step2params.dateRange.endDate.value;
    const endDateLastInterval = moment(endDate).add(1, 'day');
    const totalDays = endDateLastInterval.diff(startDate, 'days');
    const intervalsPerDay = duration === 15 ? 96 : duration === 30 ? 48 : 24;
    return { orientation, duration, dataType, startDate, endDate, totalDays, intervalsPerDay, endDateLastInterval }
}

const getColumnHeaders = (step2params) => {
    const { orientation, duration, dataType, intervalsPerDay } = getStep2Values(step2params);
    switch (orientation) {
        case 'horizontal':
            let array = [];
            let prev = moment().hour(0).minutes(0).seconds(0).milliseconds(0);
            for (let i = 0; i < intervalsPerDay; i++) {
                array.push(prev.add(duration, 'minutes').format("HH:mm"))
            }
            return array;
        case 'vertical':
            switch (dataType) {
                case 'kw':
                    return ['kW'];
                case 'kwh':
                    return ['kWh'];
            }
    }
}

const getColumns = (step2params) => {
    const { orientation, intervalsPerDay, dataType } = getStep2Values(step2params);
    switch (orientation) {
        case 'horizontal':
            let array = [];
            for (let i = 0; i < intervalsPerDay; i++) {
                array.push({ data: i, type: 'numeric' })
            }
            return array;
        case 'vertical':
            return [{ data: dataType, type: 'numeric' }]
    }
}


const getRowHeaders = (step2params) => {
    const { orientation, duration, startDate, totalDays, endDateLastInterval } = getStep2Values(step2params);
    let array = [];
    switch (orientation) {
        case "horizontal":
            for (let i = 0; i < totalDays; i++) {
                array.push(startDate.clone().add(i, 'days').format('MM/DD/YYYY'))
            }
            return array;
        case "vertical":
            let totalMinutes = endDateLastInterval.diff(startDate, 'minutes');
            let totalIntervals = (totalMinutes / duration);
            for (let i = 1; i <= totalIntervals; i++) {
                array.push(startDate.clone().add(duration * i, 'minutes').format('MM/DD/YYYY HH:mm'))
            }
            return array;
    }
};


const getTempData = (step2params) => {
    const { orientation, totalDays, intervalsPerDay } = getStep2Values(step2params);
    //debugger;
    let startDate = step2params.dateRange.startDate.value.clone();
    let endDate = step2params.dateRange.endDate.value.clone();
    let duration = parseInt(step2params.radios.duration.selected);
    
    let array = [];
    switch (orientation) {
        case 'horizontal':
            for (let i = 0; i < totalDays; i++) {
                let singleRowArray = [];
                for (let j = 0; j < intervalsPerDay; j++) {
                    singleRowArray.push(null)
                }
                array.push(singleRowArray)
            }
            return array

        case 'vertical':
            let diff = endDate.diff(startDate, 'minutes');
            let totalIntervals = diff / duration;
            for (let i = 0; i < totalIntervals; i++) {
                array.push([null])
            }
            return array;
    }

}