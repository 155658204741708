import moment from 'moment'
import 'moment-timezone';
import getDSTDays from "./DstDays";

function formatHorizontalCsvDataForSubmit(data, duration, timezone, colHeaders) {
    // Checking user data is sorted by ascending or descending dates to get start and end dates
    let startDate;
    let endDate;
    let firstInterval = moment.tz(data[0][0], timezone);
    let lastInterval = moment.tz(data[data.length - 1][0], timezone);
    if (firstInterval.isBefore(lastInterval)) {
        startDate = firstInterval.clone();
        endDate = lastInterval.clone()
    } else {
        startDate = lastInterval.clone();
        endDate = firstInterval.clone();
    }

    const {fallBackDays, springForwardDays} = getDSTDays(startDate, endDate);
    
    let array = [];
    let springForwardDayIntervals = [];

    data.forEach(row => {
        let t = moment(row[0]).hour(0).minutes(0).seconds(0).millisecond(0).format("YYYY-MM-DD HH:mm");
        let date = moment.tz(t, timezone);
        for (let i = 1; i < row.length; i++) {

            // use the column headers from the table to define the hours and minutes
            let time = colHeaders[i].split(":");
            let hours = parseInt(time[0]);
            let minutes = parseInt(time[1]);
            let datetime;
            
            // for the "spring forward" DST adjustment, the user should leave the skipped hour blank. So if it jumps from 2:00am - 3:00am
            // the user should leave any interval in that period blank. But, moment will still read the time hour = 2 and minutes = 30 (for example)
            // as hour = 1 and minutes = 30. So, to get around that, on spring forward days I log all the time periods that have been pushed so far,
            // and if the new datetime is a duplicate of a time already in that springForwardDayIntervals array I know if it that skipped hour 
            if (springForwardDays.includes(date.format("YYYY-MM-DD"))) {
                datetime = date.clone().hours(hours).minutes(minutes);
                let isSecondInterval = springForwardDayIntervals.find(x => {
                    return x === datetime.format();
                });
                if (isSecondInterval) {
                    // do nothing
                } else {
                    
                    array.push({
                        DateTime: date.clone().hours(hours).minutes(minutes).format(),
                        Value: checkDataValueType(row[i])
                    });

                    
                }
                springForwardDayIntervals.push(date.clone().hours(hours).minutes(minutes).format())
                
            } else {

                array.push({
                    DateTime: date.clone().hours(hours).minutes(minutes).format(),
                    Value: checkDataValueType(row[i])
                });
            }
            
            
        }
    });
    return array;
}

function formatVerticalCsvDataForSubmit(data, timezone) {
    debugger;

    // Checking user data is sorted by ascending or descending dates to get start and end dates
    let startDate;
    let endDate;
    let firstInterval = moment.tz(data[0][0], timezone);
    let lastInterval = moment.tz(data[data.length - 1][0], timezone);
    if (firstInterval.isBefore(lastInterval)) {
        startDate = firstInterval.clone();
        endDate = lastInterval.clone()
    } else {
        startDate = lastInterval.clone();
        endDate = firstInterval.clone();
    }

    let daylightSavingsFallBackArray = [];
    const {fallBackDays, springForwardDays} = getDSTDays(startDate, endDate);

    let array = [];
    data.forEach(interval => {
        let t = moment(interval[0]).format('YYYY-MM-DD HH:mm');
        let time = moment.tz(t, timezone);
        // let zone = moment.tz.zone("Pacific/Honolulu");
        // debugger;

        // moment-timezone always uses the earlier instance of the duplicative hour, meaning that if 
        // we do not do anything, the time shift will not occur until the 2:00am hour when in reality
        // it occurs at the 2nd 1:00am hour. Below is the manual code to check if it's the daylight 
        // savings day, and if it is the second instance of a time inside the 1:00am hour it changes 
        // the offset to the correct new offset


        let zoneInfo = moment.tz.zone(timezone);
        let offsets = [...new Set(zoneInfo.offsets)];

        if (fallBackDays.includes(time.format("YYYY-MM-DD"))) {


            let isSecondInterval = daylightSavingsFallBackArray.find(x => {
                return x === time.format();
            });
            if (isSecondInterval) {
                let p = time.utcOffset();
                // p -= 60;
                // time.utcOffset(p).add(1, 'hour');
                
                let tempP = moment.tz.zone(timezone).utcOffset(time.valueOf());
                let newOffset = offsets.filter(x => x > tempP);
                
                // Why multiple by -1? The utc offset seems to be set up weird. If you pull the offsets with the moment.tz.zone(timezone)
                // function, it returns time to the West as positive. So for example eastern US time is either +240 (4 hours) of +300 (5 hours).
                // If you take an Eastern time and run it through time.tz.zone(timezone).utcOffset(time.valueOf()) it also returns positive numbers.
                // But, when you set a UTC offset for Eastern time you have to set it with the negative value (-300) for it to be correct
                
                time.utcOffset(-1*newOffset[0]).add(Math.abs(offsets[1] - offsets[0]), 'minutes');
               
            }
            daylightSavingsFallBackArray.push(time.format());

        }


        array.push({DateTime: time.format(), Value: checkDataValueType(interval[1])});
    });
    return array;
}


function checkDataValueType(value) {
    let temp = parseFloat(value);
    if (typeof temp === 'number') {
        return temp;
    } else {
        return null
    }
}

// function checkDataValueTypeVertical(value) {
//     let temp = parseFloat(value);
//     if (typeof temp === 'number') {
//         return temp;
//     } else {
//         return null
//     }
// }
//
//
// function checkDataValueTypeHorizontal(value) {
//     let temp = parseFloat(value);
//     if (typeof temp === 'number') {
//         return value;
//     } else {
//         return null
//     }
// }

export {formatHorizontalCsvDataForSubmit, formatVerticalCsvDataForSubmit}