import React, { Component } from 'react';
import { css } from '@emotion/core';
// First way to import
//import { ClipLoader } from 'react-spinners';
// Another way to import
import GridLoader from 'react-spinners/GridLoader';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

class CustomGridSpinner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        }
    }
    render() {
        let {color = '#2196f3'} = this.props;
        return (
            <div className='sweet-loading'>
                <GridLoader
                    css={override}
                    sizeUnit={"px"}
                    size={20}
                    color={color}
                    loading={this.state.loading}
                />
            </div>
        )
    }
}

export default CustomGridSpinner;