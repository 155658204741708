import React, {Component, Fragment} from 'react'


// Material UI
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

function getNumberOfIntervals(duration) {
    switch (duration) {
        case '15':
            return '96 ';
        case '30':
            return '48 ';
        case '60':
            return '24 '
    }
}

function getDataType(dataType) {
    switch (dataType) {
        case 'kw':
            return 'kW';
        case 'kwh':
            return 'kWh';
    }
}

class FileFormatHelp extends Component {
    render() {
        const {duration, orientation, dataType} = this.props;
        if (orientation === 'horizontal') {
            return (
                <Grid container>
                    <Grid item xs={12}>
                        <Typography style={{fontWeight: 700, display: 'inline-block'}}>Orientation: </Typography>
                        <Typography style={{display: 'inline-block', paddingLeft: 5}}>Horizontal</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography style={{fontWeight: 700}}>First Row: Time (Interval Ending)</Typography>
                        <Typography>
                            The first row will hold the time values for the data. We do not actually look at these
                            values, but
                            we assume you have them there since almost all
                            horizontal interval data files have that for reference.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography style={{fontWeight: 700, paddingTop: 10}}>First Column: Dates</Typography>
                        <Typography>
                            The first column will hold the dates for each rows data.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography style={{fontWeight: 700, paddingTop: 10}}>Data</Typography>
                        <Typography>
                            Since you are uploading {duration} minute data, you will
                            have {getNumberOfIntervals(duration)}
                            intervals, or columns, of data.
                        </Typography>
                    </Grid>
                </Grid>

            )
        } else {
            return (
                <Grid container>
                    <Grid item xs={12}>
                        <Typography style={{fontWeight: 700, display: 'inline-block'}}>Orientation: </Typography>
                        <Typography style={{display: 'inline-block', paddingLeft: 5}}>Vertical</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography style={{fontWeight: 700}}>First Row</Typography>
                        <Typography>
                            The first row is reserved for your own titles for the columns on your file. We do not look
                            at the first row.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography style={{fontWeight: 700}}>First Column: Datetime</Typography>
                        <Typography>
                            The first column in your file should hold the datetimes. Any date format that Microsoft
                            Excel accepts should work fine, and please
                            use military time. Remeber that the datetimes should reflect the <strong>interval ENDING
                            time</strong>, so for example,
                            if your interval is 3:45-4:00, the datetime should show 4:00.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography style={{fontWeight: 700}}>Second Column: Data</Typography>
                        <Typography>
                            The second column in your file should hold the data ({getDataType(dataType)}) values.
                        </Typography>
                    </Grid>
                </Grid>


            )
        }

    }

}

export default FileFormatHelp;