import React, {Component} from 'react'
import Grid from '@material-ui/core/Grid';
import moment from 'moment'
import MomentUtils from '@date-io/moment';
import {
    MuiPickersUtilsProvider,
    KeyboardDateTimePicker
} from '@material-ui/pickers';
import TextField from '@material-ui/core/TextField';
import CustomColorPicker from "../../../../../../components/ColorPicker/CustomColorPicker";
import eventsGif from '../../../../../../assets/gifs/events.gif'
import CustomOutlinedInput from "../../../../../../components/Inputs/CustomOutlinedInput";

class MeterCreateNewDate extends Component {
    
    state = {
        startDateTime: moment(),
        color: {
            r: '0',
            g: '0',
            b: '255',
            a: '100',
        },
        form: {
            label: {
                value: null,
                name: 'label'
            },
            description: {
                value: null,
                name: 'description'
            },
        },
        tabs: [
            {
                label: 'When'
            },
            {
                label: 'Details'
            }
        ]
        
    };

    handleColorChange = (color) => {
        this.setState({ color: color.rgb });
    };

    handleDateChange = (date) => {
        this.setState({startDate: date})
    };
    
    handleTextChange = event => {
        let value = event.target.value;
        let name = event.target.name;
        let form = {...this.state.form};
        form[name].value = value;
        this.setState({form})

    }
    
    render() {
        const {label, description} = this.state.form;
        return (
            <div style={{width: '100%'}}>
                <Grid item xs={12}>
                    <CustomOutlinedInput
                        id="standard-basic"
                        label={null}
                        placeHolder={"Event Name"}
                        required={true}
                        value={label.value}
                        name={label.name}
                        onChange={this.handleTextChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomOutlinedInput
                        id="standard-basic"
                        label={"Description"}
                        value={description.value}
                        onChange={this.handleTextChange}
                        name={description.name}
                        multiline={true}
                        rows={5}
                    />
                </Grid>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <Grid container>
                        <KeyboardDateTimePicker
                            // disableToolbar
                            hideTabs
                            // variant="inline"
                            format="MM/DD/YYYY HH:mm"
                            margin="normal"
                            id="date-picker"
                            label="Date and Time"
                            value={this.state.startDateTime}
                            onChange={this.handleDateChange}
                            // openTo={"year"}
                            // views={["year", "month", "date"]}
                            autoOk={true}
                            inputVariant={"outlined"}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />

                    </Grid>
                </MuiPickersUtilsProvider>
                <div>
                    <CustomColorPicker 
                        color={this.state.color} 
                        onChange={this.handleColorChange}
                    />
                </div>
            </div>
        )
    }
}

export default MeterCreateNewDate