import React from 'react'
import CustomDialog from "../../../../components/Dialogs/customDialog";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

function DeleteNoteDialog(props) {
    const {
        open, 
        onClose,
        deleteNote,
        onDeleteNoteSubmit
    } = props;

    if (deleteNote) {
        return (
            <CustomDialog
                open={open}
                subTitle="Delete Note"
                onClose={onClose}
                actions={<Button style={{color: 'red'}} onClick={onDeleteNoteSubmit}>Delete</Button>}
                content={<Typography>Are you sure you want to delete note "{deleteNote.title}"?</Typography>}
                fullWidth={true}
            />
        )
    } else {
        return (
            <CustomDialog
                open={open}
                subTitle="Uh Oh..."
                onClose={onClose}
                content={<Typography>Something went wrong"</Typography>}
                fullWidth={true}
            />
        )
    }
}

export default DeleteNoteDialog