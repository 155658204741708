import React, {Component} from 'react'

//Material UI
//Material UI
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {withStyles, useTheme} from '@material-ui/core/styles';
import PaymentMethod from "./PaymentMethod";
import CurrentSubscription from "./CurrentSubscription";
import PaymentMethodRev2 from "./PaymentMethodRev2";
import ActivateSubscription from "./Activate Subscription";
import Icon from "@material-ui/core/Icon";
import moment from 'moment'
import {getOrganizationPaymentMethods} from "../../../services/admin.service";
import CustomInfoLayout from "../../../components/CustomInfoLayout/CustomInfoLayout";
import ChangePasswordForm from "../Password/ChangePasswordForm";

const styles = theme => ({
    root: {
        padding: 15,
        // minHeight: 200
    },
    error: {
        backgroundColor: theme.palette.error.main,
        padding: 10,
    },
    warning: {
        backgroundColor: theme.palette.warning.main,
        padding: 10,
    },
    icon: {
        // margin: theme.spacing(0),
        color: '#ffffff',
        fontSize: 30
    },
});

class AccountSubscription extends Component {

    state = {
        //default to value of length 1 just to not show it by default, probably a better way to do this
        paymentMethods: null,
        organizationPaymentMethod: null,
        organizationPaymentMethodId: null,
        fetchingPaymentMethod: null,
    };

    getPaymentMethod = () => {
        this.setState({fetchingPaymentMethod: true});
        getOrganizationPaymentMethods().then(x => {
            if (Array.isArray(x)) {
                if (x.length > 0) {
                    this.setState({organizationPaymentMethod: x[0].card});
                    this.setState({organizationPaymentMethodId: x[0].paymentMethodId});
                } else if (x.length === 0) {
                    this.setState({organizationPaymentMethod: null})
                }
            } else {
                this.setState({organizationPaymentMethod: null})
            }
            this.setState({fetchingPaymentMethod: false})
        });
    };

    componentDidMount = () => {
        this.getPaymentMethod()
    };


    getBannerMessage = (activeUser) => {
        const {classes} = this.props;

        if (this.props.activeUser.subscription.cancelAtPeriodEnd) {
            return (
                <Grid item xs={12} style={{padding: 10}}>
                    <Paper className={classes.error}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <Icon className={classes.icon}>
                                error
                            </Icon>
                            <Typography style={{color: 'white', paddingLeft: 10}}>Your subscription has been
                                cancelled and is set to expire
                                on {moment(activeUser.subscription.currentPeriodEndDate).format('MMMM D')}.</Typography>
                        </div>
                    </Paper>
                </Grid>
            )
        } else if (this.state.organizationPaymentMethod === null && this.state.fetchingPaymentMethod === false) {
            return (
                <Grid item xs={12} style={{padding: 10}}>
                    <Paper className={classes.warning}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <Icon className={classes.icon}>
                                warning
                            </Icon>
                            <Typography style={{color: 'white', paddingLeft: 10}}>You do not have any payment methods on
                                file. Please add a payment method or your subscription will end on
                                on {moment(activeUser.subscription.currentPeriodEndDate).format('MMMM D')}.</Typography>
                        </div>
                    </Paper>
                </Grid>
            )


        }

    }

    render() {

        // let userInfo = JSON.parse(window.sessionStorage.user);

        //TODO: REMOVE THIS WHEN DONE TESTING
        // userInfo.subscription = null;

        const {classes, activeUser, resetActiveUser} = this.props;

        return (
            <div style={{width: '100%'}}>
                {this.getBannerMessage(activeUser)}
                <Grid container style={{display: "flex", justifyContent: 'center'}}>
                    <Grid item xs={12} style={{maxWidth: 1000}}>
                        <Grid item xs={12}>
                            <Grid container >
                            <CurrentSubscription
                                activeUser={activeUser}
                                resetActiveUser={resetActiveUser}
                            />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container>
                            <PaymentMethodRev2
                                getPaymentMethod={this.getPaymentMethod}
                                organizationPaymentMethod={this.state.organizationPaymentMethod}
                                organizationPaymentMethodId={this.state.organizationPaymentMethodId}
                                fetchingPaymentMethod={this.state.fetchingPaymentMethod}
                                activeUser={activeUser}
                            />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                
            </div>
        )


    }
}

export default withStyles(styles)(AccountSubscription)