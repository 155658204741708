import React from 'react'
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

function OverviewSidebarAddressSection(props) {
    const {selectedFacility} = props;
    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant={"subtitle2"} style={{textTransform: 'none'}}>
                    Address
                </Typography>
            </Grid>
            <Grid container style={{padding: 5}}>
                {selectedFacility.address1 ?
                    <Grid item xs={12}>
                        {selectedFacility.address1}
                    </Grid> : null
                }
                {selectedFacility.address2 ?
                    <Grid item xs={12}>
                        {selectedFacility.address2}
                    </Grid> : null
                }
                <Grid item xs={12}>
                    {selectedFacility.city ? selectedFacility.city + ',' : null} {selectedFacility.state ? selectedFacility.state : null} {selectedFacility.postalCode}
                </Grid>
            </Grid>
            

        </Grid>
    )
}

export default OverviewSidebarAddressSection