import {withStyles} from "@material-ui/core";
import Tab from "@material-ui/core/Tab";
import React from "react";


const AntTab = withStyles(theme => ({
    root: {
        textTransform: 'none',
        minWidth: 40,
        height: 15,
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(1.5),
        marginLeft: theme.spacing(1.5),
        marginTop: 0,
        marginBottom: 0,
        paddingTop: 0,
        paddingBottom: 0,
        fontFamily: [
        //     '-apple-system',
        //     'BlinkMacSystemFont',
        //     '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            color: '#000',
            opacity: 1,
        },
        '&$selected': {
            // color: '#1890ff',
            color: '#000',
            // fontWeight: theme.typography.fontWeightMedium,
        },
        '&:focus': {
            color: '#000',
        },
    },
    
    selected: {},
}))(props => <Tab disableRipple {...props} />);

export default AntTab
