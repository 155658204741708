import React, {Component, Fragment} from 'react';


// Material UI

import Button from '@material-ui/core/Button';

import CreateFacilityForm from '../forms/CreateFacilityForm'
import CustomDialog from "../../../components/Dialogs/customDialog";
import CustomPulseLoader from "../../../components/Spinners/pulse-loader";
import GreenButton from "../../../components/CustomButtons/GreenButton";


function CreateFacilityDialog(props) {
    const {
        createFacilityDialogOpen, 
        toggleCreateFacilityDialog, 
        createFacilityFormControls, 
        handleCreateFacilityInputChange, 
        onCreateFacilitySubmit, 
        attemptedCreateFacilitySubmit,
        loading,
    } = props;

    return (
        <Fragment>
            <CustomDialog
                open={createFacilityDialogOpen}
                onClose={toggleCreateFacilityDialog}
                title={null}
                subTitle="Add Meter"
                content={<CreateFacilityForm
                    formControls={createFacilityFormControls}
                    handleInputChange={handleCreateFacilityInputChange}
                    attemptedSubmit={attemptedCreateFacilitySubmit}
                />}
                actions={<GreenButton type="button" onClick={() => onCreateFacilitySubmit()}>{loading ? <CustomPulseLoader /> : 'Add Meter'}</GreenButton>}
            />
        </Fragment>


    );
}

export default CreateFacilityDialog;