import React, {Component} from 'react'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const endUserLicenseAgreement = [
    {
        header: "",
        paragraphs: [
            <div><span style={{fontWeight: 600}}>IMPORTANT—PLEASE READ CAREFULLY:</span>This End-User License Agreement
                is a legal contract between You and React Energy, (“Licensor” or “React”) that covers your use of
                the React Energy Application, which includes computer software and, as applicable, associated media,
                databases,
                printed materials, and “online” or electronic documentation (the “Software“).</div>,

            <div>BY INSTALLING OR OTHERWISE USING THE SOFTWARE, YOU AGREE TO BE BOUND BY THE TERMS OF THIS AGREEMENT AND
                THE ADDITIONAL TERMS FOUND ON <a href="https://portal.reactenergy.io/terms">poral.reactenergy.io/terms</a>, INCLUDING THE WARRANTY DISCLAIMERS,
                LIMITATIONS OF
                LIABILITY AND TERMINATION PROVISIONS BELOW.</div>,

            <div>IF YOU DO NOT AGREE TO THE TERMS OF THIS END USER LICENSE AGREEMENT AND/OR THE ADDITIONAL TERMS, DO NOT
                INSTALL OR USE THE SOFTWARE, AND EXIT NOW.</div>,

            <div>IF YOU ARE NOT WILLING OR UNABLE TO ACCEPT THESE TERMS AND CONDITIONS, YOU SHOULD CLICK THE “DISAGREE”
                BUTTON BELOW. IF YOU DO NOT ACCEPT THESE TERMS YOU WILL NOT BE GRANTED ACCESS TO USE THE SOFTWARE OR THE
                LICENSOR WEB SITE (reactenergy.io, or the “SITE”).</div>,

            <div>USE OF THE SOFTWARE WITHOUT SIGNIFYING YOUR AGREEMENT TO THESE TERMS IN THE MANNER INDICATED ABOVE IS
                STRICTLY PROHIBITED.</div>

        ]
    },
    {
        header: 'ADDITIONAL TERMS',
        paragraphs: [
            <div>This End User License Agreement, together with the Additional Terms, as they might change from
                time-to-time, shall constitute the entire agreement (collectively the <span
                    style={{fontWeight: 600}}>Agreement</span>).</div>,

        ]
    },
    {
        header: 'DISCLAIMER OF WARRANTIES',
        paragraphs: [
            <div>THE SOFTWARE AND THE SITE ARE COPYRIGHTED AND LICENSED (NOT SOLD). THIS AGREEMENT REPRESENTS THE ENTIRE
                AGREEMENT CONCERNING THE SOFTWARE BETWEEN YOU AND LICENSOR, AND IT SUPERSEDES ANY PRIOR PROPOSAL,
                REPRESENTATION, OR UNDERSTANDING BETWEEN THE PARTIES.</div>,

            <div>THE SOFTWARE IS LICENSED “WHERE IS” AND “AS IS,” AND LICENSOR DISCLAIMS ANY AND ALL WARRANTIES TO THE
                EXTENT PERMITTED BY LAW, WHETHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, WARRANTIES AS TO THE
                ACCURACY, RELIABILITY, COMPLETENESS, OR CORRECTNESS OF THE SOFTWARE, AND OF ANY RESULTS.</div>,

            <div>TO THE EXTENT PERMITTED BY LAW, LICENSOR DISCLAIMS, AMONG OTHERS, ANY IMPLIED WARRANTIES OF
                MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND ALL WARRANTIES ARISING FORM COURSE OF DEALING OR
                USAGE OF TRADE.</div>,

            <div>YOU ACKNOWLEDGE THAT THERE ARE RISKS INHERENT IN THE USE OF THE SOFTWARE, HARDWARE AND INTERNET
                CONNECTIVITY THAT MAY RESULT IN INTERRUPTIONS OR ERRORS IN THE SERVICE, LOSS OF PRIVACY,
                CONFIDENTIALITY, INFORMATION OR PROPERTY, AS WELL AS OTHER LOSSES, DAMAGES OR ADVERSE OCCURRENCES,
                REGARDLESS OF THE MEASURES TAKEN TO AVOID SUCH OCCURRENCES.</div>,

            <div><span style={{fontWeight: 600}}>IN NO EVENT WILL LICENSOR BE LIABLE TO YOU OR ANY THIRD PARTY IN CONTRACT, TORT, STRICT LIABILITY OR
                OTHERWISE, FOR ANY INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES ARISING OUT OF THE
                USE OF OR INABILITY TO USE THE SOFTWARE, AND LOST PROFITS OR DAMAGES
                EXTENDING FROM THE LOSS OR CORRUPTION OF DATA, EVEN IF LICENSOR HAS
                BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</span> SOME STATES DO NOT ALLOW THE LIMITATION OR
                EXCLUSION OF
                LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO
                YOU.</div>

        ],
    },
    {
        header: 'LICENSE GRANT',
        paragraphs: [
            <div>Licensor hereby grants to You, and You accept, a nonexclusive and nontransferable personal license to
                use the Software according to the terms set out herein. The license granted extends only to use of the
                Software in connection with services offered by Licensor. All other uses are strictly prohibited.</div>,

            <div>The Software may only be used by You personally. Use by an individual other than yourself is not
                permitted without separate authorization from Licensor.</div>,

            <div>Licensor reserves the right to refuse service, terminate accounts, remove or edit content, or cancel
                logins in its sole and absolute discretion.</div>
        ]
    },
    {
        header: 'LICENSOR’S RIGHTS',
        paragraphs: [
            <div>You acknowledge and agree that the Software is the proprietary property of Licensor and is protected
                under copyright and / or patent law both in the U.S. and in countries foreign thereto. You further
                acknowledge and agree that all right, title, and interest in and to the Software, including intellectual
                property rights associated therewith, are and shall remain with Licensor. This Agreement does not convey
                to You an interest in or to the Software, but only a limited right of use revocable in accordance with
                the terms of this Agreement.</div>
        ]
    },
    {
        header: 'YOUR ACCOUNT',
        paragraphs: [
            <div>You may be given an account and/or password that grants You access to the Software and/or additional
                features accessible through the Site. You are responsible for maintaining the confidentiality of your
                account or password. You may not transfer this account or the License granted hereunder to a third party
                without prior written consent of Licensor. You agree to accept full responsibility for all activities
                that occur under your account or password. Licensor reserves the right to refuse service, terminate
                accounts, remove or edit content, in its sole discretion.</div>
        ]
    },
    {
        header: `RESTRICTIONS`,
        paragraphs: [
            <div>You agree that You may not modify, adapt, translate, reverse engineer, or create derivative works based
                upon the Software or any part thereof. You also agree that You may not reverse assemble, reverse
                compile, or otherwise translate the Software or any portion thereof.</div>,

            <div>You further agree that You will not assign, sublicense, transfer, pledge, lease, rent, loan, or share
                your rights under this Agreement.</div>,

            <div>You still further agree that You may not use the Software for the direct benefit of, or for purpose of
                rendering services to, any third party business entities or organizations.</div>
        ]
    },
    {
        header: `AMENDMENTS TO THE AGREEMENT`,
        paragraphs: [
            <div>Licensor may amend this Agreement at any time by posting the amended terms on the Site at
                <a href="https://portal.reactenergy.io/terms"> reactenergy.io/terms</a>. Except as stated elsewhere, all amended terms shall automatically be
                effective 30
                days after they are initially posted. Additionally, we will endeavor to notify You of any such change
                using the contact information that You provided to us when You register on the site.</div>
        ]
    },

];

const additionalTerms = [
    {
        header: "TERM",
        paragraphs: [
            <div>This Agreement is effective upon your acceptance of its terms and conditions and your successful
                activation of the Software, and shall continue until terminated. Either party may terminate this
                Agreement at any time upon thirty (30) days advance written notice to the other party. Licensor may
                terminate this Agreement with or without notice to You at any time and for any reason.</div>,
        ]
    },
    {
        header: 'DISPUTE RESOLUTION',
        paragraphs: [
            <div>This Agreement shall be governed in all respects by the laws of the State of Texas as they apply to
                agreements entered into and to be performed entirely within Texas between Texas residents, without
                regard to conflict of law provisions. You agree that any claim or dispute You may have against Licensor
                must be resolved by a court located in Tarrant County, Texas, except as otherwise agreed by the parties.
                You agree to submit to the personal jurisdiction of the courts located within Tarrant County, Texas, for
                the purpose of litigating all such claims or disputes.</div>,

        ]
    },
    {
        header: 'NO AGENCY',
        paragraphs: [
            <div>No agency, partnership, joint venture, employee-employer or franchiser-franchisee relationship is
                intended or created by this Agreement.</div>,

        ],
    },
    {
        header: 'AMENDMENTS TO THE AGREEMENT',
        paragraphs: [
            <div>Licensor may amend this Agreement at any time by posting the amended terms on the Site at
                <a href="https://portal.reactenergy.io/terms"> portal.reactenergy.io/terms</a>. Except as stated elsewhere, all amended terms shall automatically be
                effective 30 days after they are initially posted. Additionally, we will endeavor to notify You of any
                such change using the contact information that You provided to us when You register on the site.</div>,

            <div>This Agreement may not be otherwise amended except in a writing signed by You and Licensor. This
                Agreement sets forth the entire understanding and agreement between us with respect to the subject
                matter hereof. The following Sections survive any termination of this Agreement: Disclaimers, Release,
                Liability, Indemnity, Restrictions, Dispute Resolution, and Proprietary Information.</div>,
        ]
    },
    {
        header: 'LIMITATION OF LIABILITY',
        paragraphs: [
            <div>Licensor’s cumulative liability to You or any other party for any loss or damages resulting from any
                claims, demands, or actions arising out of or relating to this Agreement or the Software shall not
                exceed the license fee paid to Licensor for the use of the Software and Site, if any.</div>,

            <div>Under no circumstances shall Licensor be responsible for user error including, but not limited to,
                erroneous data input, misuse of the Software or Site, incorrect interpretation of data or missing data.
                Licensor shall additionally not be responsible for errors in the transmission or storage of your data
                that are beyond its control or supervision.</div>
        ]
    },
    {
        header: 'PRIVACY POLICY',
        paragraphs: [
            <div>When You create your login as a prerequisite to using the Software and the Site, Licensor will ask You
                to provide certain personal information which will be stored and retained by Licensor. Additionally,
                certain information and data that is obtained before and/or during tests performed using the Software
                may be transmitted to Licensor and stored. All information and data collected by Licensor is owned and
                used by Licensor and Licensor reserves the right to use the information and data for any internal
                purpose.</div>,

            <div>Information that is collected in aggregate is information that is not tied to any particular user. This
                sort of information is primarily used for internal purposes in evaluating and improving the
                effectiveness of the Software and the databases it utilizes. Licensor may, at its sole discretion, share
                this aggregate information with third parties.</div>,

            <div>Licensor uses the information that You provide for purposes such as responding to your requests,
                improving our products, helping us serve you better, and communicating with you.</div>,

            <div>Licensor will not share your personal information with any third party without your prior permission,
                except in response to an order from a court of competent jurisdiction or in similar circumstances.
                Licensor may use your e-mail and physical addresses to send information to you that we think might be of
                interest to you.</div>,

            <div>Licensor will not sell, rent, swap or authorize any third party to use the information or data in a way
                that personally identifies You without your permission. If you have additional questions about
                Licensor’s privacy policy, refer to <a href="https://portal.reactenergy.io/privacy"> portal.reactenergy.io/privacy</a> and/or contact Licensor
                directly.</div>
        ]
    },
    {
        header: `PROPRIETARY INFORMATION`,
        paragraphs: [
            <div>The Software and all information and materials related thereto constitute proprietary information and
                trade secrets of Licensor. You shall use your best efforts to insure the confidentiality of the Software
                and all related materials and information supplied by Licensor.</div>,

            <div>You warrant that You will not disclose, use, modify, copy, or reproduce the Software or any of the
                information or materials supplied by Licensor except in accordance with this Agreement or after first
                obtaining the written permission of Licensor.</div>,

            <div>You specifically agree to prevent your employees, agents, attorneys and representatives, if any, from
                disclosing such proprietary information and shall hold Licensor harmless and protect and indemnify
                Licensor in the event of any disclosure by such persons.</div>,

            <div>The non-disclosure provisions of this section shall continue beyond the term of the contract and shall
                be binding and enforceable even after termination of this Agreement.</div>
        ]
    },
    {
        header: `INDEMNITY`,
        paragraphs: [
            <div>You will indemnify and hold us (including our officers, directors, agents, subsidiaries, joint ventures
                and employees), harmless from any claim or demand, including reasonable attorneys’ fees, made by any
                third party due to or arising out of your breach of this Agreement, or your violation of any law or the
                rights of a third party using the Software.</div>
        ]
    },
    {
        header: `GOVERNING LAW`,
        paragraphs: [
            <div>This Agreement shall be construed and governed in accordance with the laws of the State of Texas,
                without regard to the choice of law provisions of that state, and all actions, regardless of the form or
                nature of such, to enforce this license or for the breach of same shall be brought within on (1) year
                from the occurrence of the grounds for such action in either state or federal court in Tarrant County,
                Texas.</div>
        ]
    },
    {
        header: `COSTS OF LITIGATION`,
        paragraphs: [
            <div>If any action is brought by either party to this Agreement against the other party regarding the
                subject matter hereof, the prevailing party shall be entitled to recover, in addition to any other
                relief granted, reasonable attorney fees and expenses of litigation.</div>
        ]
    },
    {
        header: `SEVERABILITY`,
        paragraphs: [
            <div>Should any term of this Agreement be declared void or unenforceable by any court of competent
                jurisdiction, such declaration shall have no effect on the remaining terms hereof.</div>
        ]
    },
    {
        header: `NO WAIVER`,
        paragraphs: [
            <div>The failure of either party to enforce any rights granted hereunder or to take action against the other
                party in the event of any breach hereunder shall not be deemed a waiver by that party as to subsequent
                enforcement of rights or subsequent actions in the event of future breaches.</div>,

            <div>It is specifically agreed that the breach of this Agreement, and in particular the section concerning
                non-disclosure of proprietary information, will result in irreparable injury and the party who claims
                such a breach shall be entitled to specific performance and injunctive relief to correct and enjoin such
                breach in addition to all other remedies which might be available.</div>
        ]
    },
    {
        header: `CANCELLATION/REFUND POLICY`,
        paragraphs: [
            <div>If you are not satisfied with any React product purchased directly from React (reactenergy.io), contact
                support@reactenergy.io within 30 days of your purchase to inquire about a refund or to cancel your
                account. Refunds requested more than 30 days following a purchase will not be issued.</div>,

            // <div>The React refund policy applies only to accounts purchased through reactenergy.io or directly from the
            //     React Energy sales team. If you purchased React through a third party, any request for cancellation or
            //     refund must be made through the third party from which it was purchased.</div>
        ]
    },

];

class TermsOfUseContent extends Component {
    render() {
        return (

            <Grid container style={{padding: 20}}>
                <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                    <Typography variant={"h6"}>END USER LICENSE AGREEMENT</Typography>
                </Grid>
                {endUserLicenseAgreement.map(section => (
                    <div>
                        <Typography variant="subtitle1" style={{marginBottom: 10}}>
                            {section.header}
                        </Typography>
                        {section.paragraphs.map(paragraph => (
                            <div style={{marginBottom: 10}}>
                                <Typography variant="body">
                                    {paragraph}
                                </Typography>
                            </div>

                        ))}
                    </div>

                ))}
                <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', padding: '20px 0'}}>
                    <Typography variant={"h6"}>TERMS</Typography>
                </Grid>
                {additionalTerms.map(section => (
                    <div>
                        <Typography variant="subtitle1" style={{marginBottom: 10}}>
                            {section.header}
                        </Typography>
                        {section.paragraphs.map(paragraph => (
                            <div style={{marginBottom: 10}}>
                                <Typography variant="body">
                                    {paragraph}
                                </Typography>
                            </div>

                        ))}
                    </div>

                ))}

            </Grid>
        )
    }
}

export default TermsOfUseContent