import React, { Fragment, Component, useState } from 'react'

// Material UI
import { withStyles, makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import blue from '@material-ui/core/colors/blue';

//Pickers
import {
    DatePicker,
    TimePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';

import moment from 'moment'







const BootstrapInput = withStyles(theme => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: '#fcfcfc',
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            //'-apple-system',
            //'BlinkMacSystemFont',
            //'"Segoe UI"',
            //'Roboto',
            //'"Helvetica Neue"',
            //'Arial',
            //'sans-serif',
            //'"Apple Color Emoji"',
            //'"Segoe UI Emoji"',
            '"Roboto"',
        ].join(','),
        '&:focus': {
            //borderRadius: 4,
            //borderColor: '#80bdff',
            //boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);

const useStyles = makeStyles(theme => ({
    paper: {
        margin: 20,
    },
    weekTable: {
        backgroundColor: '#fcfcfc',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#e6e6e6',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#ededed'
        }

    },
    selectedWeekTable: {
        color: 'white',
        backgroundColor: theme.palette.primary.light,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#e6e6e6',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.primary.main
        }
    },
    gridSection: {
        paddingLeft: 5,
        paddingRight: 5,

    },
    hr: {
        border: 0,
        clear: 'both',
        display: 'block',
        width: '100%',
        backgroundColor: '#e6e6e6',
        height: '1px',
        marginTop: 10,
        marginBottom: 10
    },
    formSectionTitle: {
        variant: 'overline'
    }
}));



const DataTypeDropdown = ({ chartDataFilterForm, handleDataFilterFormSelectChange }) => {

    const classes = useStyles();
    return (
        <Grid container xs={12} className={classes.gridSection}>
            <Typography variant="overline">Data Type</Typography>
            <Grid xs={12}>
                <NativeSelect
                    value={chartDataFilterForm.dataType}
                    onChange={handleDataFilterFormSelectChange}
                    name="dataType"
                    style={{ width: '100%' }}
                    input={<BootstrapInput name="age" id="age-customized-native-simple" />}
                >
                    <option value="kw">kW</option>
                    <option value="kwh">kWh</option>
                </NativeSelect>

            </Grid>
        </Grid>
        )  
}

const SummaryLevelDropdown = ({ chartDataFilterForm, handleDataFilterFormSelectChange }) => {

    const classes = useStyles();
    return (
        <Grid container xs={12} className={classes.gridSection}>
            <Typography variant="overline">Summary Level</Typography>
            <Grid xs={12}>
                <NativeSelect
                    value={chartDataFilterForm.seriesPer}
                    onChange={handleDataFilterFormSelectChange}
                    name="seriesPer"
                    style={{ width: '100%' }}
                    input={<BootstrapInput name="age" id="age-customized-native-simple" />}
                >
                    <option value="allData">All Data</option>
                    <option value="year">Year</option>
                    <option value="month">Month</option>
                    <option value="week">Week</option>
                    <option value="dayOfWeek">Day Of Week</option>
                    <option value="day">Day</option>
                </NativeSelect>
            </Grid>

        </Grid>
    )
}

const DaysSection = ({ chartDataFilterForm, handleDataFilterFormCheckboxChange, handleAggregateDataFilterFormInputs }) => {
    const classes = useStyles();
    return (
        <Grid container className={classes.gridSection}>
            <Grid xs={12} >
                <Typography variant="overline">Days</Typography>
                <Grid container>
                    <Grid item xs={4} className={classes.weekTable}>
                        <Grid container spacing={0} direction="column" alignItems="center" justify="center" onClick={() => handleAggregateDataFilterFormInputs('all', 'days')}>
                            All
                                    </Grid>
                    </Grid>
                    <Grid item xs={4} className={classes.weekTable}>
                        <Grid container spacing={0} direction="column" alignItems="center" justify="center" onClick={() => handleAggregateDataFilterFormInputs('weekdays', 'days')}>
                            Weekdays
                                    </Grid>
                    </Grid>
                    <Grid item xs={4} className={classes.weekTable}>
                        <Grid container spacing={0} direction="column" alignItems="center" justify="center" onClick={() => handleAggregateDataFilterFormInputs('weekends', 'days')}>
                            Weekends
                                    </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid xs={12}>
                <Grid container>
                    {chartDataFilterForm.days.map((day, index) =>
                        <Grid item style={{ width: '14.28%' }} className={day.value === true ? classes.selectedWeekTable : classes.weekTable} onClick={() => handleDataFilterFormCheckboxChange('days', index)} >
                            <Grid container spacing={0} direction="column" alignItems="center" justify="center">
                                {day.label}
                            </Grid>
                        </Grid>
                    )}

                </Grid>
            </Grid>
        </Grid>
    )
}

const MonthsSection = ({ chartDataFilterForm, handleDataFilterFormCheckboxChange, handleAggregateDataFilterFormInputs }) => {
   
    const classes = useStyles();
    return (
        <Grid container xs={12} className={classes.gridSection}>
            <Divider />
            <Typography variant="overline">Months</Typography>
            <Grid container>
                <Grid item xs={6} className={classes.weekTable}>
                    <Grid container spacing={0} direction="column" alignItems="center" justify="center" onClick={() => handleAggregateDataFilterFormInputs('all', 'months')}>
                        All
                                    </Grid>
                </Grid>
                <Grid item xs={6} className={classes.weekTable}>
                    <Grid container spacing={0} direction="column" alignItems="center" justify="center" onClick={() => handleAggregateDataFilterFormInputs('clear', 'months')}>
                        Clear

                                    </Grid>
                </Grid>
                <Grid xs={12}>
                    <Grid container>
                        {chartDataFilterForm.months.map((month, index) =>
                            <Grid item xs={3} className={month.value === true ? classes.selectedWeekTable : classes.weekTable} onClick={() => handleDataFilterFormCheckboxChange('months', index)} >
                                <Grid container spacing={0} direction="column" alignItems="center" justify="center">
                                    {month.label}
                                </Grid>
                            </Grid>
                        )}

                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

const YearsSection = ({ chartDataFilterForm, handleDataFilterFormCheckboxChange, handleAggregateDataFilterFormInputs, selectedFacility }) => {
   
    let numberOfYears = chartDataFilterForm.years.length;
    let gridWidth;
    if (numberOfYears === 1) {
        gridWidth = 12
    } else if (numberOfYears % 3 === 0) {
        gridWidth = 4
    } else if (numberOfYears % 2 === 0) {
        gridWidth = 6
    } else {
        gridWidth = 12
    }
    
    const classes = useStyles();
    return (
        <Grid container xs={12} className={classes.gridSection}>
            <Divider />
            <Typography variant="overline">Years</Typography>
            <Grid container>
                <Grid item xs={12} className={classes.weekTable}>
                    <Grid container spacing={0} direction="column" alignItems="center" justify="center" onClick={() => handleAggregateDataFilterFormInputs('all', 'years')}>
                        All
                    </Grid>
                </Grid>
                <Grid xs={12}>
                    <Grid container>
                        {chartDataFilterForm.years.map((year, index) =>
                            <Grid item xs={gridWidth} className={year.value === true ? classes.selectedWeekTable : classes.weekTable} onClick={() => handleDataFilterFormCheckboxChange('years', index)} >
                                <Grid container spacing={0} direction="column" alignItems="center" justify="center">
                                    {year.label}
                                </Grid>
                            </Grid>
                        )}

                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

const DataTypesSection = ({ chartDataFilterForm, handleDataFilterFormCheckboxChange, handleAggregateDataFilterFormInputs }) => {

    const classes = useStyles();
    return (
        <Grid container xs={12} className={classes.gridSection}>
            <Divider />
            <Typography variant="overline">Data Types</Typography>
            <Grid container>
                <Grid item xs={6} className={classes.weekTable}>
                    <Grid container spacing={0} direction="column" alignItems="center" justify="center" onClick={() => handleAggregateDataFilterFormInputs('all', 'dataTypeOptions')}>
                        All
                    </Grid>
                </Grid>
                <Grid item xs={6} className={classes.weekTable}>
                    <Grid container spacing={0} direction="column" alignItems="center" justify="center" onClick={() => handleAggregateDataFilterFormInputs('clear', 'dataTypeOptions')}>
                        Clear

                    </Grid>
                </Grid>
                <Grid xs={12}>
                    <Grid container>
                        {chartDataFilterForm.dataTypeOptions.map((dataType, index) => {
                            if (index < 6) {
                                return <Grid item xs={4} className={dataType.value === true ? classes.selectedWeekTable : classes.weekTable} onClick={() => handleDataFilterFormCheckboxChange('dataTypeOptions', index)} >
                                    <Grid container spacing={0} direction="column" alignItems="center" justify="center">
                                        {dataType.label}
                                    </Grid>
                                </Grid>
                            } else {
                                return <Grid item xs={6} className={dataType.value === true ? classes.selectedWeekTable : classes.weekTable} onClick={() => handleDataFilterFormCheckboxChange('dataTypeOptions', index)} >
                                    <Grid container spacing={0} direction="column" alignItems="center" justify="center">
                                        {dataType.label}
                                    </Grid>
                                </Grid>
                                
                            }
                        })}

                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

const SeriesTypeSection = ({ chartDataFilterForm, handleDataFilterFormCheckboxChange, handleAggregateDataFilterFormInputs }) => {

    const classes = useStyles();
    return (
        <Grid container className={classes.gridSection}>
            <Grid container xs={12} >
                <Typography variant="overline">Series Type</Typography>
                <Grid container>
                    <Grid item xs={12} className={classes.weekTable}>
                        <Grid container spacing={0} direction="column" alignItems="center" justify="center" onClick={() => handleAggregateDataFilterFormInputs('all', 'seriesTypes')}>
                            All
                                    </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid xs={12}>
                <Grid container>
                    {chartDataFilterForm.seriesTypes.map((seriesType, index) =>
                        <Grid item xs={4} className={seriesType.value === true ? classes.selectedWeekTable : classes.weekTable} onClick={() => handleDataFilterFormCheckboxChange('seriesTypes', index)} >
                            <Grid container spacing={0} direction="column" alignItems="center" justify="center">
                                {seriesType.shortLabel}
                            </Grid>
                        </Grid>
                    )}

                </Grid>

            </Grid>
        </Grid>
    )
}

const SeriesTypeDropdown = ({ chartDataFilterForm, handleSeriesTypeDropdownChange }) => {

    const classes = useStyles();
    const currentSelected = chartDataFilterForm.seriesTypes.filter(type => type.value === true);
    const currentValue = currentSelected.label;

    return (
        <Grid container xs={12} className={classes.gridSection}>
            <Typography variant="overline">Series Type</Typography>
            <Grid xs={12}>
                <NativeSelect
                    value={currentValue}
                    onChange={handleSeriesTypeDropdownChange}
                    name="seriesTypes"
                    style={{ width: '100%' }}
                    input={<BootstrapInput name="age" id="age-customized-native-simple" />}
                >
                    <option value="Average">Average</option>
                    <option value="Minimum">Minimum</option>
                    <option value="Maximum">Maximum</option>
                </NativeSelect>
            </Grid>

        </Grid>
    )
}

const DateRangeSection = ({ chartDataFilterForm, handleDataFilterFormDateAndTimeChange, selectedFacility }) => {
    // debugger;
    let minDate = moment.utc(selectedFacility.intervalDataStartDate).hour(0).minute(0).seconds(0).millisecond(0);
    let maxDate = moment(selectedFacility.intervalDataEndDate).hour(23).minute(59).seconds(59).millisecond(999);
    const classes = useStyles();
    return (
        <Grid item xs={12} className={classes.gridSection}>
            <Typography variant="overline">Date Range</Typography>
            <Grid container alignItems="center" justify="center">
                <Grid item xs={6} style={{ paddingRight: 3 }} >
                    <MuiPickersUtilsProvider utils={MomentUtils} >
                        <DatePicker variant="inline" value={chartDataFilterForm.startDate} onChange={(event) => handleDataFilterFormDateAndTimeChange(event.format('YYYY-MM-DD'), 'startDate')} minDate={minDate} maxDate={chartDataFilterForm.endDate} format="MM/DD/YYYY" views={["year", "month", "date"]} />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={6} style={{ paddingLeft: 3 }}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DatePicker variant="inline" value={chartDataFilterForm.endDate} onChange={(event) => {handleDataFilterFormDateAndTimeChange(event.format('YYYY-MM-DD'), 'endDate'); console.log('selected: ', event); console.log('max: ', maxDate)}} minDate={chartDataFilterForm.startDate} maxDate={maxDate} format="MM/DD/YYYY" views={["year", "month", "date"]} />
                    </MuiPickersUtilsProvider>
                </Grid>
            </Grid>


        </Grid>
    )
}

// const TimeRangeSection = ({ chartDataFilterForm, handleDataFilterFormDateAndTimeChange }) => {
//
//     const classes = useStyles();
//     return (
//         <Grid item className={classes.gridSection} xs={12}>
//             <Typography variant="overline">Time Range</Typography>
//             <Grid container alignItems="center" justify="center">
//                 <Grid item xs={6} style={{ paddingRight: 3 }}>
//                     <MuiPickersUtilsProvider utils={MomentUtils}>
//                         <TimePicker clearable value={chartDataFilterForm.startTime} onChange={(event) => handleDataFilterFormDateAndTimeChange(event.format('YYYY-MM-DD HH:mm:ss'), 'startTime')} />
//                     </MuiPickersUtilsProvider>
//                 </Grid>
//
//                 <Grid item xs={6} style={{ paddingLeft: 3 }}>
//                     <MuiPickersUtilsProvider utils={MomentUtils}>
//                         <TimePicker clearable value={chartDataFilterForm.endTime} onChange={(event) => {handleDataFilterFormDateAndTimeChange(event.format('YYYY-MM-DD HH:mm:ss'), 'endTime')}} />
//                     </MuiPickersUtilsProvider>
//                 </Grid>
//             </Grid>
//
//
//         </Grid>
//     )
// }

const TimeRangeSection = ({ chartDataFilterForm, handleDataFilterFormDateAndTimeChange }) => {
    const classes = useStyles();
    let times = [];
    let start = moment('1/1/2020').hours(0).minutes(0).milliseconds(0);
    for (let i = 0; i < 48; i++) {
        let value = start.clone().add(i * 30, 'minutes');
        let label = value.format("HH:mm");
        times.push({value: value.valueOf(), label})
    }
    
    let endTimes = [];
    let sameDay = true;
    let start2 = chartDataFilterForm.startTime ? moment(parseInt(chartDataFilterForm.startTime)) : moment('1/1/2020').hours(0).minutes(0).milliseconds(0);
    for (let j = 1; sameDay; j++) {
        let value = start2.clone().add(j * 30, 'minutes');
        let label = value.format("HH:mm");
        endTimes.push({value: value.valueOf(), label});
        if (!value.isSame(start2, 'day')) {
            sameDay = false
        }
    }

    
    
    return (
        <Grid container xs={12} className={classes.gridSection}>
            <Grid xs={6}>
                <Typography variant="overline">Start Time</Typography>
                <Grid xs={12} style={{padding: '0 3px'}}>
                    <NativeSelect
                        value={chartDataFilterForm.startTime}
                        onChange={(event) => {
                            // let time = moment(parseInt(event.target.value)).format('HH:mm');
                            let time = event.target.value;
                            handleDataFilterFormDateAndTimeChange(time, 'startTime')
                        }}
                        name="seriesTypes"
                        style={{ width: '100%' }}
                        input={<BootstrapInput name="age" id="age-customized-native-simple" />}
                    >
                        {times.map((x, index) => (
                            <option key={index} value={x.value}>{x.label}</option>
                        ))}
                    </NativeSelect>
                </Grid>
            </Grid>
            <Grid xs={6}>
                <Typography variant="overline">End Time</Typography>
                <Grid xs={12} style={{padding: '0 3px'}}>
                    <NativeSelect
                        value={chartDataFilterForm.endTime}
                        onChange={(event) => {
                            // let time = moment(parseInt(event.target.value)).format('HH:mm');
                            let time = event.target.value;
                            handleDataFilterFormDateAndTimeChange(time, 'endTime')
                        }}
                        name="seriesTypes"
                        style={{ width: '100%' }}
                        input={<BootstrapInput name="age" id="age-customized-native-simple" />}
                    >
                        {endTimes.map(x => (
                            <option value={x.value}>{x.label}</option>
                        ))}
                    </NativeSelect>
                </Grid>
            </Grid>
            

        </Grid>
    )
}


export { DataTypeDropdown, SummaryLevelDropdown, DaysSection, MonthsSection, DataTypesSection, SeriesTypeSection, DateRangeSection, TimeRangeSection, SeriesTypeDropdown, YearsSection }