import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

// Material UI
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import blue from '@material-ui/core/colors/blue';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';


// Logo
import reactlogo from "../assets/img/ReactLogo_NoShadow.svg"
import {Link} from "react-router-dom";


const styles = theme => ({
    main: {
        // width: 'auto',
        // display: 'block', // Fix IE 11 issue.
        // maxWidth: 400,
        // marginLeft: theme.spacing.unit * 3,
        // marginRight: theme.spacing.unit * 3,
        // [theme.breakpoints.up(800 + theme.spacing.unit * 3 * 2)]: {
        //     width: 800,
        //     // marginLeft: 'auto',
        //     // marginRight: 'auto',
        // },
    },
    paper: {
        marginTop: theme.spacing.unit * 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
        minHeight: 500,
        maxWidth: 400
    },
    avatar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing.unit,
    },
    submit: {
        marginTop: theme.spacing.unit * 2,
    },
    hidden: {
        display: 'none'
    },

});

function ResetPasswordContent(props) {
    const {
        classes,
        onSubmit,
        form,
        handleChange,
        handleShowPassword,
        passwordChanged,
    } = props;
    //console.log(props);

    let keys = Object.keys(form);

    return (
        <main className={classes.main}>
            <CssBaseline/>

            <Grid container xs alignItems="center" justify="center">
                <Grid item xs={12} lg={4} style={{maxWidth: 400}}>
                    <Paper className={classes.paper}>
                        <img style={{width: 140, height: 140, margin: 10}} src={reactlogo} alt="Logo"/>
                        {passwordChanged ?
                            <Fragment>
                                <Typography component="h1" variant="h5">
                                    Back to Login
                                </Typography>
                                <Typography style={{paddingTop: 10}}>
                                    Your password was succesfully reset. Click the button below to go to the log in page.
                                </Typography>
                                <Link to={`/`}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                >
                                    Log In
                                </Button>
                                </Link>
                            </Fragment>
                            
                            :
                            <Fragment>
                                <Typography component="h1" variant="h5">
                                    Reset Password
                                </Typography>
                                <Typography style={{paddingTop: 10}}>
                                    Your password must be at least 8 characters in length, and must contain at least one
                                    uppercase letter, lowercase letter, number, and special character.
                                </Typography>
                                <form className={classes.form}>
                                    <FormLabel
                                        className={form.error === "" ? 'hidden' : classes.red}>{form.error}</FormLabel>
                                    {keys.map(key => (
                                        <FormControl margin="normal" required fullWidth>
                                            <InputLabel htmlFor="confirmPassword">{form[key].label}</InputLabel>
                                            <Input
                                                name={key}
                                                type={form[key].showPassword ? 'text' : 'password'}
                                                id={key}
                                                value={form[key].value}
                                                onChange={(e) => handleChange(e)}
                                                error={form[key].touched && !form[key].valid}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={() => handleShowPassword(key)}
                                                        >
                                                            {form[key].showPassword ? <Visibility/> :
                                                                <VisibilityOff/>}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                    ))}

                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                        onClick={onSubmit}
                                        disabled={!form.password.valid || !form.confirmPassword.valid}
                                    >
                                        Reset Password
                                    </Button>
                            
                            </form>
                            </Fragment>
                        }
                            </Paper>
                            </Grid>
                            </Grid>

                            </main>
                            );
                            }

                            ResetPasswordContent.propTypes = {
                            classes: PropTypes.object.isRequired,
                            };

                            export default withStyles(styles)(ResetPasswordContent);