import React from 'react'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    icon: {
        margin: theme.spacing(0),
        color: '#4a4a4a',
        fontSize: 30
    },
    margin: {
        margin: theme.spacing(2),
    },


}));


function SubscriptionCounter(props) {

    const {quantity, handleQuantityChange} = props;

    const onAdd = (event) => {
        handleQuantityChange(quantity + 1)
    }

    const onSubtract = (event) => {
        if (quantity - 1 > 0) {
            handleQuantityChange(quantity - 1)
        }
        
    }

    const classes = useStyles();

    return (

            
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <IconButton aria-label="Edit" className={classes.margin} size="small" onClick={onSubtract}>
                        <Icon className={classes.iconEdit}>
                            remove
                        </Icon>
                    </IconButton>
                    <Typography variant={"h2"}>{quantity}</Typography>
                    <IconButton aria-label="Edit" className={classes.margin} size="small" onClick={onAdd}>
                        <Icon className={classes.icon}>
                            add
                        </Icon>
                    </IconButton>
                </div>
       

    )
}

export default SubscriptionCounter